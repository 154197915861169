import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { MerchantLoginService, VerifyOtpService } from "../../../Services/Api/Merchant/merchantAuth";
import { SuccessToast, ErrorToast, InfoToast } from "../../../Utils/SweetAlert";
import { Helmet } from 'react-helmet-async';

const Login = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isTwoStep, setIsTwoStep] = useState(false);
  const [twoStepUserData, setTwoStepUserData] = useState({});
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>CPay | Merchant Login</title>
      </Helmet>
    <div className="main-wrapper login-body">
      <div className="login-wrapper">
        <div className="container">
          <img className="img-fluid logo-dark mb-2" src="/inner-assets/img/logo.png" alt="Logo" />
          <div className="loginbox">
            <div className="login-right">
              <div className="login-right-wrap">
                <h1>Login</h1>
                <p className="account-subtitle">Access to our dashboard</p>
                <Formik
                  enableReinitialize
                  initialValues={{
                    otp: "",
                    email: "",
                    password: "",
                    isOtpScreen: isTwoStep,
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().email()
                      .when("isOtpScreen", {
                        is: false,
                        then: Yup.string().required("Email is required.")
                      }),
                    isOtpScreen: Yup.boolean(),
                    otp: Yup.string().when("isOtpScreen", {
                      is: true,
                      then: Yup.string().required("Otp is required")
                    }),
                    password: Yup
                      .string()
                      .when("isOtpScreen", {
                        is: false,
                        then: Yup.string().required("Password is required.")
                      })
                  })}
                  validateOnChange
                  onSubmit={async (values, { setSubmitting }) => {
                    if (isTwoStep === false) {
                      const loginPostObj = {
                        email: values.email,
                        password: values.password,
                      }
                      await MerchantLoginService(loginPostObj).then((res) => {
                        setSubmitting(false);
                        const twostepenabled = res?.data?.data?.user?.two_step_enabled || 0;
                        if (twostepenabled === 0) {
                          localStorage.setItem('merchantAccessToken', res?.data?.data?.token);
                          localStorage.setItem('merchantUserData', JSON.stringify(res?.data?.data?.user));
                          SuccessToast(res?.data?.message || 'Login successful.');
                          navigate('/merchant/dashboard');
                        } else {
                          InfoToast(res?.data?.message || 'Two step verification is required for login')
                          setTwoStepUserData(res?.data?.data?.user || {})
                          setIsTwoStep(true);
                        }
                      }).catch((err) => {
                        if (typeof err.response.data.message !== 'undefined') {
                          ErrorToast(err.response.data.message || 'Server Error!!');
                        } else {
                          ErrorToast(err?.message || 'Server Error!!');
                        }
                        setSubmitting(false);
                      })
                    } else {
                      const postOtpVerifyObj = {
                        user_id: twoStepUserData._id,
                        otp: values.otp,
                        motive: 'LOGIN'
                      }
                      VerifyOtpService(postOtpVerifyObj).then((res) => {
                        setSubmitting(false);
                        localStorage.setItem('merchantAccessToken', res?.data?.data?.token);
                        localStorage.setItem('merchantUserData', JSON.stringify(res?.data?.data?.user));
                        SuccessToast(res?.data?.message || 'Login successful.');
                        navigate('/merchant/dashboard');
                      }).catch((err) => {
                        if (typeof err.response.data.message !== 'undefined') {
                          ErrorToast(err.response.data.message || 'Server Error!!');
                        } else {
                          ErrorToast(err?.message || 'Server Error!!');
                        }
                        setSubmitting(false);
                      })
                    }
                  }}
                >
                  {({
                    errors,
                    touched,
                    isValid,
                    dirty,
                    isSubmitting,
                    handleSubmit,
                    handleChange
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        {!isTwoStep && (
                          <>
                            <div className="form-group">
                              <label className="form-control-label">Email <span className='text-danger'>*</span></label>
                              <Field
                                type="email"
                                className={
                                  `form-control ${errors.email && touched.email ? "input-error" : null}`
                                }
                                onChange={handleChange}
                                placeholder="Enter email"
                                id="email"
                                name="email"
                              />
                              <ErrorMessage name="email" component="span" className="error" />
                            </div>
                            <div className="form-group">
                              <label className="form-control-label">Password <span className='text-danger'>*</span></label>
                              <div className="pass-group">
                                <Field
                                  type={isShowPassword ? 'text' : 'password'}
                                  className={
                                    `form-control ${errors.password && touched.password ? "input-error" : null}`
                                  }
                                  onChange={handleChange}
                                  id="password"
                                  name="password"
                                  placeholder="Enter password"
                                />
                                <span onClick={() => setIsShowPassword(!isShowPassword)} 
                                className={`fas ${isShowPassword ? 'fa-eye-slash' : 'fa-eye'} toggle-password`} />
                              </div>
                                <ErrorMessage name="password" component="span" className="error" />
                            </div>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-6">
                                  <div className="custom-control custom-checkbox rememberBlue">
                                    {/* <input type="checkbox" className="custom-control-input" id="cb1" /> &nbsp;
                                    <label className="custom-control-label" htmlFor="cb1">Remember me</label> */}
                                     <label className="custom_check" htmlFor="cb1">
                                      <input type="checkbox" name="invoice" id="cb1"/>Remember me
                                      <span className="checkmark" />
                                    </label>
                                  </div>
                                </div>
                                <div className="col-6 text-end">
                                  <Link className="forgot-link" to="/merchant/auth/forgot-password">Forgot Password ?</Link>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {isTwoStep && (
                          <div className="form-group">
                            <label className="form-control-label">OTP</label>
                            <Field
                              type="text"
                              className={
                                `form-control ${errors.otp && touched.otp ? "input-error" : null}`
                              }
                              onChange={handleChange}
                              id="otp"
                              name="otp"
                            />
                            <ErrorMessage name="otp" component="span" className="error" />
                          </div>
                        )}
                        <button
                          type="submit"
                          className={`btn btn-lg btn-block btn-primary w-100 ${!(dirty && isValid) ? "disabled-btn" : ""}`}
                          disabled={isSubmitting}
                        >
                          Login&nbsp;&nbsp;
                          {isSubmitting && (
                            <i className="fas fa-spinner fa-spin"></i>
                          )}
                        </button>
                        <div className="login-or">
                          <span className="or-line" />
                          <span className="span-or">or</span>
                        </div>
                        <div className="text-center dont-have">Don't have an account yet? <Link to="/merchant/auth/register">Register</Link></div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Login
