import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CustomerFilterComponent } from "../Common";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import {
  GetCustomerListService,
  getGenerateReport,
} from "../../../Services/Api/Merchant/booking";
import format from "date-fns/format";
import { ErrorToast } from "../../../Utils/SweetAlert";
import { saveAs } from "file-saver";
import { onImageError } from "../../../Utils/CommanFunctions";

const Customers = ({ primaryCurrency }) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [customerListPagination, setCustomerListPagination] = useState([]);
  const [resetDate, setResetDate] = useState(0);

  const handleReset = async ({ fDate, fStatus, fUser }) => {
    await setResetDate(0);
    setResetDate(1);
    setPage(0);
  };
  const handleGenerateReport = async ({ fDate, fStatus, fUser }) => {
    setResetDate(0);
    setPage(0);
    const postData = {
      filter_date: fDate || "",
      filter_status: fStatus || "",
      filter_user: fUser || "",
    };
    await getGenerateReport(postData)
      .then((res) => {
        handleReset(fDate, fStatus, fUser);
        if (res?.data?.data?.url) {
          saveAs(res?.data?.data?.url, res?.data?.data?.file_name);
        } else {
          ErrorToast("Export data does not exist.");
        }
      })
      .catch((err) => {
        handleReset(fDate, fStatus, fUser);
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  const getCustomerList = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetCustomerListService({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setCustomerListPagination(res?.data?.data?.customers);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setCustomerListPagination([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setCustomerListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //     ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //     ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await getCustomerList(limit, pageNew);
  };
  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await getCustomerList(parseInt(event.target.value, 10), 0);
  };

  useEffect(() => {
    getCustomerList(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>CPay | Merchant Customer</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Customers</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/merchant/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Customers</li>
                </ul>
              </div>
            </div>
          </div>
          <CustomerFilterComponent
            isShowCategory={true}
            isShowUser={true}
            handleReset={handleReset}
            resetDate={resetDate}
            handleGenerateReport={handleGenerateReport}
          />
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="custom-pagination">
                    <div className="table-responsive">
                      <table className="table table-center table-hover datatable">
                        <thead className="thead-light">
                          <tr>
                            <th>Customer</th>
                            <th>Status</th>
                            <th>Email</th>
                            <th>Amount Paid</th>
                            <th>Registered On</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isLoading && (
                            <>
                              {customerListPagination.map((customer, index) => (
                                <tr key={index}>
                                  <td>
                                    <h2 className="table-avatar">
                                      <Link
                                        to="/merchant/customers"
                                        className="avatar avatar-sm me-3"
                                      >
                                        {/* <span className="avatar avatar-sm me-3"> */}
                                        <img
                                          className="avatar-img rounded-circle"
                                          src={
                                            customer?.customer?.profile_picture || ""
                                          }
                                          onError={onImageError}
                                          alt="User"
                                        />
                                        {/* </span> */}
                                      </Link>
                                      <Link
                                        to="/merchant/customers"
                                        // className="avatar avatar-sm me-2"
                                      >
                                        {/* <span style={{ fontSize:"15px",color:"#2c3038" }}> */}
                                        {customer?.customer?.name}
                                        <span
                                          style={{
                                            fontSize: "13px",
                                            color: "#2c3038",
                                          }}
                                        >
                                          {customer?.customer?.mobile}
                                        </span>
                                        {/* </span> */}
                                      </Link>
                                    </h2>
                                  </td>
                                  <td>
                                    {customer?.customer?.is_blocked == 0 && (
                                      <span className="badge badge-pill bg-success-light">
                                        Active
                                      </span>
                                    )}
                                    {customer?.customer?.is_blocked == 1 && (
                                      <span className="badge badge-pill bg-danger-light">
                                        Inactive
                                      </span>
                                    )}
                                  </td>
                                  <td>{customer?.customer?.email}</td>
                                  <td>
                                    <span className="currency-type-text">
                                      {primaryCurrency || ""}{" "}
                                    </span>
                                    {customer?.amount_paid}
                                  </td>
                                  <td>
                                    {format(
                                      new Date(customer?.createdAt),
                                      "dd MMMM, yyyy"
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                          <Pagination
                            totalItem={totalRecords}
                            itemsPerPage={limit}
                            page={page}
                            handleChangePage={handlePageChange}
                            handleChangeRowsPerPage={handleLimitChange}
                            colSpan={6}
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customers;
