import React from "react";
import { Route, Routes } from "react-router-dom";
import { Register, ForgotPassword, Login, ResetPassword } from "../../Components/Auth/Client";

export default function ClientAuthRoutes() {
  return (
    <>
      <Routes>
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/reset-password/:token" element={<ResetPassword />} />
      </Routes>
    </>
  );
}
