import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export const SuccessToast = (message) => (
  Toast.fire({
    icon: 'success',
    title: message
  })
);

export const ErrorToast = (message) => (
  Toast.fire({
    icon: 'error',
    title: message
  })
);

export const WarningToast = (message) => (
  Toast.fire({
    icon: 'warning',
    title: message
  })
);

export const InfoToast = (message) => (
  Toast.fire({
    icon: 'info',
    title: message
  })
);

export const Confirmation = (message) => (
  Swal.fire({
    title: 'Are you sure?',
    icon: 'warning',
    text: message,
    buttons: true,
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonText: 'Yes',
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    reverseButtons: true
  })
);
// export const TextArea = async ({ denyButtonText = "Cancel" }) => (
//   await Swal.fire({
//     input: "textarea",
//     inputLabel: "Message",
//     inputPlaceholder: "Type your comment here...",
//     inputAttributes: {
//       "aria-label": "Type your message here",
//     },
//     confirmButtonText: "Reject",
//     denyButtonText: denyButtonText,
//     showDenyButton: true,
//     confirmButtonColor: "#dc3741",
//     denyButtonColor: "#3085d6",
//     showCancelButton: false,
//     allowOutsideClick: false,
//     returnInputValueOnDeny: true,
//     reverseButtons: true,
//   })
// );
export const TextArea = async ({ denyButtonText = "Cancel" }) =>
  await Swal.fire({
    input: "textarea",
    name:"reason",
    inputLabel: "Message",
    inputPlaceholder: "Type your reason here...",
    inputAttributes: {
      "aria-label": "Type your message here",
    },
    confirmButtonText: "Reject",
    denyButtonText: denyButtonText,
    showDenyButton: true,
    confirmButtonColor: "#dc3741",
    denyButtonColor: "#3085d6",
    showCancelButton: false,
    allowOutsideClick: false,
    returnInputValueOnDeny: true,
    reverseButtons: true,
    preConfirm : (msg) =>{
      if(!msg){
      Swal.showValidationMessage(`Please enter reason`);
    }
    }
  });
export const Approve = async ({ denyButtonText = "Cancel",amount }) => (
Swal.fire({
  title: '<h4 class="my-2">Approve Credit Request</h4>',
  html: `<input type="number" name="approve_amount" id="approve_amount" min="1" max="${amount}" class="swal2-input" placeholder="Please enter amount" value=${amount}>
  <textarea name="approve_reason" id="approve_reason" class="swal2-input height-5em" placeholder="Please enter comment"></textarea>`,
  confirmButtonText: 'Approve',
  denyButtonText: denyButtonText,
  confirmButtonColor: "#15d57c",
  denyButtonColor: "#3085d6",
  showCancelButton: true,
  allowOutsideClick: false,
  returnInputValueOnDeny: true,
  reverseButtons: true,
  focusConfirm: false,
  preConfirm: () => {
    const approve_amount = Swal.getPopup().querySelector('#approve_amount').value
    const approve_reason = Swal.getPopup().querySelector('#approve_reason').value
    if (!approve_amount && !approve_reason) {
      Swal.showValidationMessage(`Please enter amount and comment`);
    } else if (!approve_amount) {
      Swal.showValidationMessage(`Please enter amount`);
    } else if (approve_amount <= 0){
      Swal.showValidationMessage(`Amount must be grater than 0`);
    }else if (!approve_reason) {
      Swal.showValidationMessage(`Please enter comment`);
    } 
    // else {
    //   if (approve_amount > amount) {
    //     Swal.showValidationMessage(`Approve amount is exceeded original payment amount`);
    //   }
    // }
    return { amount: approve_amount, reason: approve_reason }
  }
}).then((result) => {
  return result;
})
);

export const CloseCredit = async ({ denyButtonText = "Cancel" }) => (
    Swal.fire({
      title: '<h4 class="my-2">Close Credit</h4>',
      html: `<textarea name="comment" id="comment" class="swal2-input height-5em" placeholder="Please enter comment"></textarea>`,
      confirmButtonText: 'Close Credit',
      denyButtonText: denyButtonText,
      confirmButtonColor: "#dc3741",
      denyButtonColor: "#3085d6",
      showCancelButton: true,
      allowOutsideClick: false,
      returnInputValueOnDeny: true,
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        const comment = Swal.getPopup().querySelector('#comment').value
        if (!comment) {
          Swal.showValidationMessage(`Please enter comment`);
        }
        return ( comment)
      }
    }).then((result) => {
      return result;
    })
  );

  export const verificationConfirmation = (message) => (
    Swal.fire({
      icon: 'warning',
      text: message,
      buttons: true,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Join',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true
    })
  );

export const CustomToast = (title, message, icon) => (
  Swal.fire({
    title: title,
    text: message,
    icon: icon,
    customClass: {
      title: 'custom-sweet-alert-title',
    } 
  })
);