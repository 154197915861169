import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { EditWalletAmountService } from "../../../Services/Api/Client/customerAuth";
import {
  bankAccountListService,
  GetEditWalletRequestById,
} from "../../../Services/Api/Client/customerWalletRequest";
import { fileUploadToBucket } from "../../../Services/Api/Client/fileUpload";
import * as moment from "moment";
import DragAndDropFile from "../../../Utils/DragAndDropFile";
import { useDebounce } from "use-debounce";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import format from "date-fns/format";

const EditWalletAmount = () => {
  const fileTypes = ["image/*", ".pdf", ".docx", ".doc", ".pptx"];
  const [imageKey, setImageKey] = useState(0);
  const [debounceKey] = useDebounce(imageKey, 1000);

  const typeOFPayment = [
    { label: "CASH DEPOSIT", value: "CASH_DEPOSIT" },
    { label: "CHEQUE DEPOSIT", value: "CHEQUE_DEPOSIT" },
    { label: "BANK TRANSFER", value: "BANK_TRANSFER" },
  ];

  const history = useNavigate();
  const [photoID, setPhotoID] = useState("");
  const [items, setItems] = useState("");
  const [name, setName] = useState("");
  const [logo, setLogo] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [isBankShow, setIsBankShow] = useState(false);
  const [bankName, setBankName] = useState([]);
  const [beneficiary, setBeneficiary] = useState("");
  const [title, setTitle] = useState("");
  const [iban, setIban] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [bankCurrency, setBankCurrency] = useState("");
  const [disableField, setDisableField] = useState(false);
  const [editWalletTransaction, setEditWalletTransaction] = useState([]);
  const [tranId, setTranId] = useState("");
  const [userId, setuserId] = useState("");
  const [titleName, setTitleName] = useState("");
  const stateData = useLocation();
  const { id } = stateData?.state || {};

  const signInSchema = Yup.object().shape({
    bank_reference: Yup.string().required("Bank reference is required"),
    user_reference: Yup.string().required("Your reference is required"),
    destination_bank: Yup.object().nullable().required("Please select a bank"),
    deposited_amount: Yup.number()
      .nullable()
      .positive()
      .required("Deposited amount is required"),
    transaction_currency: Yup.string()
      .nullable()
      .required("Please select a transaction currency type"),
    transaction_amount: Yup.number()
      .nullable()
      .positive()
      .required("Credited / Transation amount is required"),
    date_of_payment: Yup.date().required("Please select a Date"),
    comments: Yup.string().required("Comments is required"),
    receipt_file: Yup.mixed(),
  });
  const initialValues = {
    date_of_payment: editWalletTransaction?.date_of_payment || "",
    destination_bank: editWalletTransaction?.destination_bank
      ? {
          ...editWalletTransaction?.destination_bank,
          label: editWalletTransaction?.destination_bank?.title,
          value: editWalletTransaction?.destination_bank?.title,
        }
      : "",
    on_behalf_of: name,
    deposited_currency: editWalletTransaction?.deposited_currency || "",
    deposited_amount: editWalletTransaction?.deposited_amount || "",
    transaction_currency: "AED",
    transaction_amount: editWalletTransaction?.transaction_amount || "",
    bank_reference: editWalletTransaction?.bank_reference || "",
    user_reference: editWalletTransaction?.user_reference || "",
    comments: editWalletTransaction?.comments || "",
    motive: editWalletTransaction?.motive || "",
    receipt_file: "",
    // receipt_file: editWalletTransaction?.receipt_file || "",
  };
  const bankAccountList = async () => {
    bankAccountListService()
      .then((res) => {
        if (res?.data?.data?.accounts.length > 0) {
          let bankarr = res?.data?.data?.accounts;
          bankarr = bankarr.map((value) => {
            return {
              label: value.title,
              value: value.id,
              cur: value.currency,
              logo: value.logo,
              accountNumber: value.account_number,
              beneficiary: value.beneficiary,
              iban: value.iban,
              swiftCode: value.swift_code,
              title: value.title,
            };
          });
          setBankName(bankarr);
        } else {
          // ErrorToast("No Results.");
          setBankName([]);
        }
      })
      .catch((err) => {
        setBankName([]);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  const disable = () => {
    if (bankCurrency == "AED") {
      setDisableField(true);
    } else {
      setDisableField(false);
    }
  };
  const editWalletReuestList = async () => {
    await GetEditWalletRequestById(id).then((res) => {
      const editRequest = res?.data?.data?.transaction || {};
      setEditWalletTransaction(editRequest);
      setTranId(res?.data?.data?.transaction?._id);
      setuserId(res?.data?.data?.transaction?.user_id);
      let bankData = res?.data?.data?.transaction?.destination_bank;
      if (typeof bankData !== "undefined" && bankData !== "") {
        setTitle(bankData.id);
        setIban(bankData.iban);
        setLogo(bankData.logo);
        setAccountNumber(bankData.account_number);
        setBeneficiary(bankData.beneficiary);
        setBankCurrency(bankData.currency);
        setSwiftCode(bankData.swift_code);
        setIsBankShow(true);
        setTitleName(bankData.title);
      }
    });
  };
  useEffect(() => {
    bankAccountList();
    editWalletReuestList();
  }, []);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("clientUserData"));
    if (items) {
      setItems(items);
      setPhotoID(items.id);
      setName(items.name);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Customer Edit Wallet Amount</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Edit Wallet Amount</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/client/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/client/wallet">Wallet</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Wallet Amount</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/client/wallet-request"
                  className="btn btn-primary me-1"
                >
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card bg-white">
                <div className="card-body">
                  <ul className="nav nav-tabs nav-tabs-solid nav-justified"></ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane show active"
                      id="cash-wallet-amount"
                    >
                      <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={signInSchema}
                        validateOnChange
                        onSubmit={async (values, { setSubmitting }) => {
                          const postObj = {
                            transaction_id: tranId,
                            user_id: userId,
                            motive: values.motive,
                            date_of_payment: format(
                              new Date(values.date_of_payment),
                              "yyyy-MM-dd"
                            ),
                            destination_bank: title,
                            on_behalf_of: values.on_behalf_of,
                            deposited_currency: bankCurrency,
                            deposited_amount: values.deposited_amount,
                            transaction_currency: "AED",
                            transaction_amount: values.transaction_amount,
                            bank_reference: values.bank_reference,
                            user_reference: values.user_reference,
                            comments: values.comments,
                            receipt_file: values.receipt_file,
                          };
                          await EditWalletAmountService(postObj)
                            .then((res) => {
                              SuccessToast(
                                res?.data?.message || "Customer Added Amount."
                              );
                              setSubmitting(false);
                              setTimeout(() => {
                                history("/client/wallet-request");
                              }, 1000);
                            })
                            .catch((err) => {
                              if (
                                typeof err.response.data.message !== "undefined"
                              ) {
                                ErrorToast(
                                  err.response.data.message || "Server Error!!"
                                );
                              } else {
                                ErrorToast(err?.message || "Server Error!!");
                              }
                              setSubmitting(false);
                            });
                          setTimeout(() => {
                            setSubmitting(false);
                          }, 400);
                        }}
                      >
                        {(formik) => {
                          const {
                            touched,
                            errors,
                            setFieldValue,
                            handleChange,
                            isSubmitting,
                            values,
                          } = formik;
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-8">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label className="label-dark">
                                          Date of Payment{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <DatePicker
                                          autoComplete="off"
                                          className={`form-control ${
                                            errors.date_of_payment &&
                                            touched.date_of_payment
                                              ? "input-error"
                                              : null
                                          }`}
                                          name="date_of_payment"
                                          onChange={(date) =>
                                            setFieldValue(
                                              "date_of_payment",
                                              date
                                            )
                                          }
                                          // selected ={values.date_of_payment}
                                          maxDate={new Date()}
                                          value={moment(
                                            values.date_of_payment
                                          ).format("YYYY-MM-DD")}
                                          peekNextMonth
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="select"
                                        />
                                        <ErrorMessage
                                          name="date_of_payment"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group destination-dropdown">
                                        <label htmlFor="bank">
                                          Destination/Bank{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                          options={bankName}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "destination_bank",
                                              e
                                            );
                                            setFieldValue("cur", e.cur);
                                            setFieldValue("logo", e.logo);
                                            setFieldValue(
                                              "accountNumber",
                                              e.account_number
                                            );
                                            setFieldValue(
                                              "beneficiary",
                                              e.beneficiary
                                            );
                                            setFieldValue("iban", e.iban);
                                            setFieldValue(
                                              "swiftCode",
                                              e.swift_code
                                            );
                                            setFieldValue("title", e.id);
                                            setFieldValue("titleName", e.title);
                                            setTitleName(e.title);
                                            setTitle(e.value);
                                            setIban(e.iban);
                                            setLogo(e.logo);
                                            setAccountNumber(e.accountNumber);
                                            setBeneficiary(e.beneficiary);
                                            setBankCurrency(e.cur);
                                            setSwiftCode(e.swiftCode);
                                            if (!isBankShow) {
                                              setIsBankShow(true);
                                            }
                                            // disable()
                                          }}
                                          name="destination_bank"
                                          value={values.destination_bank}
                                        />
                                        <ErrorMessage
                                          name="destination_bank"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group ">
                                        <label>
                                          Amount deposited{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span
                                              className="input-group-text"
                                              id="basic-addon3"
                                            >
                                              {bankCurrency || "-"}
                                            </span>
                                          </div>
                                          <Field
                                            type="text"
                                            aria-describedby="basic-addon3"
                                            className={`form-control ${
                                              errors.deposited_amount &&
                                              touched.deposited_amount
                                                ? "input-error"
                                                : null
                                            }`}
                                            name="deposited_amount"
                                            onChange={handleChange}
                                            value={values.deposited_amount}
                                            onBlur={(e) => {
                                              if (bankCurrency === "AED") {
                                                setFieldValue(
                                                  "transaction_amount",
                                                  e.target.value
                                                );
                                              }
                                            }}
                                          />
                                        </div>
                                        <label>
                                          Actual currency and amount been
                                          deposited
                                        </label>
                                        <ErrorMessage
                                          name="deposited_amount"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Amount to be credited{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span
                                              className="input-group-text"
                                              id="basic-addon3"
                                            >
                                              AED
                                            </span>
                                          </div>
                                          <Field
                                            type="text"
                                            className={`form-control ${
                                              errors.transaction_amount &&
                                              touched.transaction_amount
                                                ? "input-error"
                                                : null
                                            }`}
                                            name="transaction_amount"
                                            disabled={disableField}
                                            // value={bankCurrency != "AED" ? values.transaction_amount : values.deposited_amount}
                                            value={values.transaction_amount}
                                            onChange={handleChange}
                                          />
                                        </div>
                                        <span>
                                          Conversion rate :&nbsp;&nbsp;
                                          {values.deposited_amount != "" &&
                                          values.transaction_amount != "" ? (
                                            <>
                                              <span className="currency-type-text">
                                                AED
                                              </span>{" "}
                                              {(
                                                values.deposited_amount /
                                                values.transaction_amount
                                              ).toFixed(4)}{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                        <br />
                                        <label>
                                          Amount and currency you claim to
                                          credit into your account
                                        </label>
                                        <ErrorMessage
                                          name="transaction_amount"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          On Behalf of{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          onChange={handleChange}
                                          className={`form-control ${
                                            errors.on_behalf_of &&
                                            touched.on_behalf_of
                                              ? "input-error"
                                              : null
                                          }`}
                                          name="on_behalf_of"
                                          value={name}
                                          disabled
                                        />
                                        <ErrorMessage
                                          name="on_behalf_of"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Bank Reference{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          className={`form-control ${
                                            errors.bank_reference &&
                                            touched.bank_reference
                                              ? "input-error"
                                              : null
                                          }`}
                                          name="bank_reference"
                                          onChange={handleChange}
                                          value={values.bank_reference}
                                        />
                                        <ErrorMessage
                                          name="bank_reference"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Your Reference{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          className={`form-control ${
                                            errors.user_reference &&
                                            touched.user_reference
                                              ? "input-error"
                                              : null
                                          }`}
                                          name="user_reference"
                                          onChange={handleChange}
                                          value={values.user_reference}
                                        />
                                        <ErrorMessage
                                          name="user_reference"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group destination-dropdown">
                                        <label htmlFor="bank">
                                          Type of Payment{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                          options={typeOFPayment}
                                          onChange={(e) => {
                                            setFieldValue("motive", e.value);
                                          }}
                                          name="motive"
                                          value={typeOFPayment.filter(
                                            (option) =>
                                              option.value === values.motive
                                          )}
                                        />
                                        <ErrorMessage
                                          name="motive"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Comments{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          as="textarea"
                                          rows={5}
                                          cols={5}
                                          type="text"
                                          className={`form-control ${
                                            errors.comments && touched.comments
                                              ? "input-error"
                                              : null
                                          }`}
                                          name="comments"
                                          onChange={handleChange}
                                          value={values.comments}
                                        />
                                        <ErrorMessage
                                          name="comments"
                                          component="span"
                                          className="error"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group dropzone-image-area">
                                        <label>Receipt</label>
                                        <DragAndDropFile
                                          randerKey={debounceKey}
                                          maxFileSize={2000000} //2mb
                                          label="Drag and drop a image or click"
                                          fileTypes={[
                                            "image/*",
                                            ".pdf",
                                            ".docx",
                                            ".doc",
                                            ".pptx",
                                          ]}
                                          className={`form-control dropzoneBox ${
                                            errors.receipt_file &&
                                            touched.receipt_file
                                              ? "input-error"
                                              : null
                                          }`}
                                          handleChange={async (file) => {
                                            setFieldValue(
                                              "receipt_file",
                                              file[0]
                                            );
                                            let imageData = file[0];
                                            const imagePostData = {
                                              user_id: items.id,
                                              file_name: imageData?.name,
                                              file_module: "WALLET_RECEIPT",
                                              mime_type: imageData?.type,
                                              document_sides: "NONE",
                                            };
                                            await fileUploadToBucket({
                                              data: imagePostData,
                                              file: imageData,
                                            })
                                              .then(async (res) => {
                                                setFieldValue(
                                                  "receipt_file",
                                                  res?.data?.data?.s3_key
                                                );
                                              })
                                              .catch((err) => {
                                                if (
                                                  typeof err.response.data
                                                    .message !== "undefined"
                                                ) {
                                                  ErrorToast(
                                                    err.response.data.message ||
                                                      "Server Error!!"
                                                  );
                                                } else {
                                                  ErrorToast(
                                                    err?.message ||
                                                      "Server Error!!"
                                                  );
                                                }
                                              });
                                          }}
                                          acceptedFiles={fileTypes}
                                        />
                                        <ErrorMessage
                                          name="receipt_file"
                                          component="span"
                                          className="error"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* col-md-8 */}
                                <div
                                  className={`col-md-4${
                                    !isBankShow ? ` d-none` : ``
                                  }`}
                                >
                                  <div className="text-center">
                                    <img src={logo} alt="bank logo" />{" "}
                                  </div>
                                  <table className="table table-stripped table-responsive custom-table">
                                    <tbody>
                                      <tr>
                                        <td style={{ width: "10%" }}>Bank</td>
                                        <th>{titleName}</th>
                                      </tr>
                                      <tr>
                                        <td style={{ width: "10%" }}>
                                          Holder / Beneficiary
                                        </td>
                                        <th>{beneficiary}</th>
                                      </tr>
                                      <tr>
                                        <td style={{ width: "10%" }}>
                                          Account Number
                                        </td>
                                        <th>{accountNumber}</th>
                                      </tr>
                                      <tr>
                                        <td style={{ width: "10%" }}>IBAN</td>
                                        <th>{iban}</th>
                                      </tr>
                                      <tr>
                                        <td style={{ width: "10%" }}>
                                          Swift Code
                                        </td>
                                        <th>{swiftCode}</th>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div
                                    style={{
                                      textAlign: "center",
                                      margin: "20px 0px",
                                    }}
                                  >
                                    <img
                                      src={editWalletTransaction.receipt_file}
                                      alt="receip_file"
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="text-center  mt-4">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={isSubmitting}
                                >
                                  Submit&nbsp;&nbsp;
                                  {isSubmitting && (
                                    <i className="fas fa-spinner fa-spin"></i>
                                  )}
                                </button>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditWalletAmount;
