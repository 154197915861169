import React, { useState, useEffect } from 'react'
import { WalletFilterComponent } from '../Common'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import { GetWalletExport, GetWalletListFilter, MerchantGetWalletBalanceService } from "../../../Services/Api/Merchant/Wallet";
import { ErrorToast } from "../../../Utils/SweetAlert";
import { Pagination } from "../../../Utils";
import format from 'date-fns/format';
import { StartCase } from 'react-lodash';
import { saveAs } from "file-saver";
import _ from 'lodash'

const WalletReport = () => {

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [customerWalletTransaction, setCustomerWalletTransaction] = useState([]);
  const [widgetData, setWidgetData] = useState([]);
  const [countCurrency, setCountCurrency] = useState("");
  const [walletListFilter, setWalletListFilter] = useState({
    offset : page || 0 ,
    limit : limit || 10,
    filter_date : "",
    filter_status : ""
  });
  // const [depositedCurrency, setDepositedCurrency] = useState([])

  const [resetDate,setResetDate] = useState(0)

  const handleReset = async ({fDate,fStatus}) =>{
    await setResetDate(0);
    setResetDate(1);
    setPage(0);
    const postData={
      offset : 0,
      limit : limit || 10,
      filter_date : fDate || "",
      filter_status : fStatus || ""
    }
    setWalletListFilter(postData);
  }

  const handleSubmitFilter = async ({fDate,fStatus}) => {
    setResetDate(0)
    setPage(0);
    const postData={
      offset : 0,
      limit : limit || 10,
      filter_date : fDate || "",
      filter_status : fStatus || ""
    }
    setWalletListFilter(postData);
  }

  const handleExportFilter = async ({fDate,fStatus}) => {
    setResetDate(0)
    setPage(0);
    const postData={
      filter_date : fDate || "",
      filter_status : fStatus || ""
    }
      await GetWalletExport(postData)
            .then((res) => {
               if (res?.data?.data?.url) {
                saveAs(res?.data?.data?.url, res?.data?.data?.file_name);
            } else {
                ErrorToast("Export data does not exist.");
            }
            })
            .catch((err) => {
                if (typeof err.response.data.message !== "undefined") {
                    ErrorToast(err.response.data.message || "Server Error!!");
                 } else {
                    ErrorToast(err?.message || "Server Error!!");
                }
            });
  }

  const getCustomerList = async (requestPayload) => {
    setIsLoading(true);
    await GetWalletListFilter(requestPayload)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setCustomerWalletTransaction(res?.data?.data?.transactions);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setCustomerWalletTransaction([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setCustomerWalletTransaction([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    setWalletListFilter(prevState => ({...prevState, offset: pageNew}));
         
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    //  await setWalletListFilter(parseInt(event.target.value, 10), 0);
     await setWalletListFilter({offset : 0 ,
      limit : event.target.value || 10,
      filter_date : "",
      filter_status : ""});
     
  };

  useEffect(() => {
    getCustomerList(walletListFilter);
  }, [walletListFilter]);

  const getWalletBalanceData = async () => {
    MerchantGetWalletBalanceService().then((res) => {
      setWidgetData(res?.data?.data?.widget_counts || {})
    }).catch((err) => {
      // if (typeof err.response.data.message !== 'undefined') {
      //   ErrorToast(err.response.data.message || 'Server Error!!');
      // } else {
      //   ErrorToast(err?.message || 'Server Error!!');
      // }
    })
  }
  
  useEffect(() => {
    getWalletBalanceData()
    const item = JSON.parse(localStorage.getItem('merchantUserData'));
    setCountCurrency(item?.primary_currency)
  }, [])
 
  return (
    <>
      <Helmet>
        <title>CPay | Merchant Wallet Report</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Wallet Transactions</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/merchant/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item active"> Wallet Transactions</li>
                </ul>
              </div>
            </div>
          </div>
          <WalletFilterComponent isShowCategory={false} isShowUser={false} handleSubmitFilter={handleSubmitFilter} handleReset={handleReset} resetDate={resetDate}  handleExportFilter={handleExportFilter} />
          <div className="row">
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img src="/inner-assets/img/icons/invoices-icon1.svg" alt="logo" />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount"><span className="currency-type-text">{countCurrency || ''}</span> {widgetData?.pending_amount} </div>
                    </div>
                  </div>
                  <p className="inovices-all">Total Pending Transactions <span>{widgetData?.pending_transactions_count}</span></p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img src="/inner-assets/img/icons/invoices-icon2.svg" alt="logo" />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount"><span className="currency-type-text">{countCurrency || ''}</span> {widgetData?.credit_amount}</div>
                    </div>
                  </div>
                  <p className="inovices-all">Credit Transactions <span>{widgetData?.credit_transactions_count}</span></p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img src="/inner-assets/img/icons/invoices-icon3.svg" alt="logo" />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount"><span className="currency-type-text">{countCurrency || ''}</span> {widgetData?.debit_amount}</div>
                    </div>
                  </div>
                  <p className="inovices-all">Debit Transactions <span>{widgetData?.debit_transactions_count}</span></p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-stripped table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th>Reference Number</th>
                          <th>Pax Details</th>
                          <th>Type</th>
                          {/* <th>Date Of Payment</th>
                          <th>Bank Details</th>
                          <th>On Behalf Of</th>
                          <th>Deposited Amount</th> */}
                          {/* <th>Credited Amount</th> */}
                          <th>Amount</th>
                          {/* <th>Bank Reference</th>
                          <th>User Reference</th> */}
                          <th>Transaction Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {customerWalletTransaction.map((customer, index) => (
                              <tr key={index}>
                                    <td>
                                        {(limit * page) + (index+1) }
                                    </td>
                                {customer?.reference_number ?
                                    <td>
                                        {customer?.reference_number}<br />
                                        <span> {format(new Date(customer?.createdAt), "dd MMMM, yyyy, hh:mm a")}</span>
                                    </td> : <td>-</td>
                                }

                                    <td>
                                      {customer?.booking?.product === "flight" && (
                                        <>
                                          {customer?.booking?.metadata?.travellers.map(
                                            (traveller, index) => (
                                              <div key={index}>
                                                {index + 1}. {traveller?.title}{" "}
                                                {traveller?.first_name}{" "}
                                                {traveller?.last_name}{" "}
                                                <span>
                                                  ({traveller?.passenger_type})
                                                </span>
                                              </div>
                                            )
                                          )}
                                        </>
                                      )}
                                      {customer?.booking?.product === "hotel" && (
                                        <>
                                          {customer?.booking?.metadata?.travellers.map(
                                            (traveller, index) => (
                                              <div key={index}>
                                                {index + 1}. {traveller?.title}{" "}
                                                {traveller?.first_name}{" "}
                                                {traveller?.last_name}{" "}
                                              </div>
                                            )
                                          )}
                                        </>
                                      )}
                                      {customer?.booking?.product === "visa" && (
                                        <div>
                                          {customer?.booking?.metadata?.visa?.first_name}{" "}
                                          {customer?.booking?.metadata?.visa?.last_name}
                                        </div>
                                      )}
                                    </td>

                                    <td>
                                        <StartCase string={_.toLower(_.replace(customer?.motive, "_", " "))} />
                                    </td>
                                {/* {customer?.date_of_payment ?
                                    <td>
                                        {format(new Date(customer?.date_of_payment), "dd MMMM, yyyy")}
                                    </td> : <td>-</td>
                                }
                                {customer?.destination_bank?.title ?
                                    <td>
                                        <span>{customer.destination_bank?.title}</span>-
                                        <span>{customer.destination_bank?.account_number}</span>&nbsp;&nbsp;
                                        <span>{customer.destination_bank?.currency}</span><br />
                                        <span>{customer.destination_bank?.beneficiary}</span>
                                    </td> : <td>-</td>
                                }
                                {customer?.on_behalf_of ?
                                    <td>
                                         {customer?.on_behalf_of}
                                    </td> : <td>-</td>
                                }
                                {customer?.deposited_amount ?
                                    <td>
                                        <span className="currency-type-text">{customer?.deposited_currency}</span> {customer?.deposited_amount}
                                    </td> : <td>-</td>
                                } */}
                                {customer?.transaction_amount ?
                                    <td>
                                        <span className="currency-type-text">{customer?.transaction_currency}</span> {customer?.transaction_amount}
                                    </td> : <td>-</td>
                                }
                                {/* {customer?.bank_reference ?
                                    <td>
                                        {customer?.bank_reference}
                                    </td> : <td>-</td>
                                }
                                {customer?.user_reference ?
                                    <td>
                                        {customer?.user_reference}
                                    </td> : <td>-</td>
                                } */}
                                {customer?.transaction_type === "DEBIT" &&
                                    <td>
                                        <span className="badge badge-pill bg-danger-light">
                                          Debit
                                        </span>
                                    </td>
                                }
                                {customer?.transaction_type === "CREDIT" &&
                                    <td>
                                        <span className="badge badge-pill bg-success-light">
                                          Credit
                                        </span>
                                    </td>
                                }
                              </tr>))}
                            {customerWalletTransaction.length === 0 && (
                                <tr>
                                  <td className="text-center" colSpan={6}>No Records Found.</td>
                                </tr>
                            )}
                          </>)}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={6}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WalletReport