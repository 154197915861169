import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import ViewBooking from '../Bookings/ViewBooking';
import { Helmet } from 'react-helmet-async';

const ComponentPrint = () => {

    const componentRef = useRef()

    const handlePrint = useReactToPrint({
        content : () => componentRef.current,
        documentTitle : "booking_receipt"
    });
    
  return (
    <>
       <Helmet>
        <title>CPay | Merchant booking details</title>
      </Helmet>
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row justify-content-center">
            <div className="col-xl-10 set-flex-css">
            <button
                type="button"
                className="btn btn-primary px-3"
                onClick={handlePrint}
              >&nbsp;Print Receipt&nbsp;
              </button>
            </div>
          </div>
        </div>
          <ViewBooking ref={componentRef} />
      </div>
    </div>
    </>
  )
}

export default ComponentPrint