import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';

const EditBooking = () => {
  return (
    <>
      <Helmet>
        <title>CPay | Merchant EditBooking</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Edit Booking</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/merchant/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Users</Link></li>
                  <li className="breadcrumb-item active">Edit Booking</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card invoices-add-card">
                <div className="card-body">
                  <form action="#" className="invoices-form">
                    <div className="invoices-main-form">
                      <div className="row">
                        <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                          <div className="form-group">
                            <label>Customer Name</label>
                            <div className="multipleSelection">
                              <div className="selectBox">
                                <p className="mb-0">Select Customer</p>
                                <span className="down-icon"><i data-feather="chevron-down" /></span>
                              </div>
                              <div id="checkBoxes-one">
                                <p className="checkbox-title">Customer Search</p>
                                <div className="form-custom">
                                  <input type="text" className="form-control bg-grey" placeholder="Enter Customer Name" />
                                </div>
                                <div className="selectBox-cont">
                                  <label className="custom_check w-100">
                                    <input type="checkbox" name="username" />
                                    <span className="checkmark" /> Brian Johnson
                                  </label>
                                  <label className="custom_check w-100">
                                    <input type="checkbox" name="username" />
                                    <span className="checkmark" /> Russell Copeland
                                  </label>
                                  <label className="custom_check w-100">
                                    <input type="checkbox" name="username" />
                                    <span className="checkmark" /> Greg Lynch
                                  </label>
                                  <label className="custom_check w-100">
                                    <input type="checkbox" name="username" />
                                    <span className="checkmark" /> John Blair
                                  </label>
                                  <label className="custom_check w-100">
                                    <input type="checkbox" name="username" />
                                    <span className="checkmark" /> Barbara Moore
                                  </label>
                                  <label className="custom_check w-100">
                                    <input type="checkbox" name="username" />
                                    <span className="checkmark" /> Hendry Evan
                                  </label>
                                  <label className="custom_check w-100">
                                    <input type="checkbox" name="username" />
                                    <span className="checkmark" /> Richard Miles
                                  </label>
                                </div>
                                <button type="submit" className="btn w-100 btn-primary">Apply</button>
                                <button type="reset" className="btn w-100 btn-grey">Reset</button>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Po Number</label>
                            <input className="form-control" type="text" placeholder="Enter Reference Number" />
                          </div>
                        </div>
                        <div className="col-xl-5 col-md-6 col-sm-12 col-12">
                          <h4 className="invoice-details-title">Booking details</h4>
                          <div className="invoice-details-box">
                            <div className="invoice-inner-head">
                              <span>Booking No. <Link to="#">IN093439#@09</Link></span>
                            </div>
                            <div className="invoice-inner-footer">
                              <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  <div className="invoice-inner-date">
                                    <span>
                                      Date <Link to="#">15-06-2022</Link>
                                    </span>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                  <div className="invoice-inner-date invoice-inner-datepic">
                                    <span>
                                      Due Date <Link to="#">15-06-2022</Link>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="invoice-item">
                      <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6">
                          <div className="invoice-info">
                            <strong className="customer-text">Booking From <Link className="small" to="#">Edit Address</Link></strong>
                            <p className="invoice-details invoice-details-two">
                              Darren Elder <br />
                              806 Twin Willow Lane, Old Forge,<br />
                              Newyork, USA <br />
                            </p>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">
                          <div className="invoice-info">
                            <strong className="customer-text">Booking To</strong>
                            <p className="invoice-details invoice-details-two">
                              Walter Roberson <br />
                              299 Star Trek Drive, Panama City, <br />
                              Florida, 32405, USA <br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="invoice-add-table">
                      <h4>Item Details</h4>
                      <div className="table-responsive">
                        <table className="table table-center add-table-items">
                          <thead>
                            <tr>
                              <th>Items</th>
                              <th>Category</th>
                              <th>Quantity</th>
                              <th>Price</th>
                              <th>Amount</th>
                              <th>Discount</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="add-row">
                              <td>
                                <input type="text" className="form-control" />
                              </td>
                              <td>
                                <input type="text" className="form-control" />
                              </td>
                              <td>
                                <input type="text" className="form-control" />
                              </td>
                              <td>
                                <input type="text" className="form-control" />
                              </td>
                              <td>
                                <input type="text" className="form-control" />
                              </td>
                              <td>
                                <input type="text" className="form-control" />
                              </td>
                              <td className="add-remove text-end">
                                <Link to="#" className="add-btn me-2"><i className="fas fa-plus-circle" /></Link>
                                <Link to="#" className="copy-btn me-2"><i className="fe fe-copy" /></Link><Link to="#" className="remove-btn"><i className="fe fe-trash-2" /></Link>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-7 col-md-6">
                        <div className="invoice-fields">
                          <h4 className="field-title">More Fields</h4>
                          <div className="field-box">
                            <p>Payment Details</p>
                            <Link className="btn btn-primary" to="#" data-bs-toggle="modal" data-bs-target="#bank_details"><i className="fas fa-plus-circle me-2" />Add Bank Details</Link>
                          </div>
                        </div>
                        <div className="invoice-faq">
                          <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                            <div className="faq-tab">
                              <div className="panel panel-default">
                                <div className="panel-heading" role="tab" id="headingTwo">
                                  <p className="panel-title">
                                    <Link className="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordion" to="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                      <i className="fas fa-plus-circle me-1" /> Add Terms &amp; Conditions
                                    </Link>
                                  </p>
                                </div>
                                <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                                  <div className="panel-body">
                                    <textarea className="form-control" defaultValue={""} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="faq-tab">
                              <div className="panel panel-default">
                                <div className="panel-heading" role="tab" id="headingThree">
                                  <p className="panel-title">
                                    <Link className="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordion" to="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                      <i className="fas fa-plus-circle me-1" /> Add Notes
                                    </Link>
                                  </p>
                                </div>
                                <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree" data-bs-parent="#accordion">
                                  <div className="panel-body">
                                    <textarea className="form-control" defaultValue={""} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-6">
                        <div className="invoice-total-card">
                          <h4 className="invoice-total-title">Summary</h4>
                          <div className="invoice-total-box">
                            <div className="invoice-total-inner">
                              <p>Taxable Amount <span>$21</span></p>
                              <p>Round Off
                                <input type="checkbox" id="status_1" className="check" />
                                <label htmlFor="status_1" className="checktoggle">checkbox</label>
                                <span>$54</span>
                              </p>
                              <div className="links-info-one">
                                <div className="links-info">
                                  <div className="links-cont">
                                    <Link to="#" className="service-trash">
                                      <i className="feather-trash-2" />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <Link to="#" className="add-links">
                                <i className="fas fa-plus-circle me-1" /> Additional Charges
                              </Link>
                              <div className="links-info-discount">
                                <div className="links-cont-discount">
                                  <Link to="#" className="add-links-one">
                                    <i className="fas fa-plus-circle me-1" /> Add more Discount
                                  </Link>
                                  <Link to="#" className="service-trash-one">
                                    <i className="feather-trash-2" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="invoice-total-footer">
                              <h4>Total Amount <span>$ 894.00</span></h4>
                            </div>
                          </div>
                        </div>
                        <div className="upload-sign">
                          <div className="form-group service-upload">
                            <span>Upload Sign</span>
                            <input type="file" multiple />
                          </div>
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Name of the Signatuaory" />
                          </div>
                          <div className="form-group float-end mb-0">
                            <button className="btn btn-primary" type="submit">Save Booking</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditBooking