import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { getApiKeyService } from "../../../Services/Api/Merchant/ApiKey";
import { BiCopy } from "react-icons/bi";

const DevloperApi = () => {
  const [apiKey, setApiKey] = useState({});
  const [copySuccess, setCopySuccess] = useState([{
    testMerchantId : '',
    testToken:'',
    productionMerchantId: '',
    productionToken:''
  }]);
  const [isHovering, setIsHovering] = useState(false);
  const [copyMessageTime, setCopyMessageTime] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  // your function to copy here

  const copyToClipBoard = async (copyMe, type) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      copySuccess[type] = "Coppied";
      setCopySuccess(copySuccess);  
      setCopyMessageTime(true);
      setTimeout(() => {
        setCopyMessageTime(false);
        setCopySuccess({});
      }, 2000);
    } catch (err) {
       setCopyMessageTime(true);
      setTimeout(() => {
        setCopyMessageTime(false);
        setCopySuccess({});
      }, 2000);
    }
  };

  const getApiKey = async () => {
    getApiKeyService()
      .then((res) => {
        setApiKey(res?.data?.data?.apiKeys || {});
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };
  useEffect(() => {
    getApiKey();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Merchant Developer Api</title>
      </Helmet>
      <div>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col">
                  <h3 className="page-title">Generate your Unique keys</h3>
                  <p>
                    These keys are unique credentials issued to every merchant.
                    It's advisable to keep the merchant key on the server-side
                    and should not be shared with anyone.
                  </p>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/merchant/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      Developer API Keys
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="comp-sec-wrapper">
              <section className="comp-section">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card bg-white">
                      <div className="card-body">
                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified nav-tabs-gen-key">
                          <li className="nav-item">
                            <Link
                              className="nav-link active"
                              to="#bottom-justified-tab1"
                              data-bs-toggle="tab"
                            >
                              Test API Details
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to="#bottom-justified-tab2"
                              data-bs-toggle="tab"
                            >
                              Production API Details
                            </Link>
                          </li> 
                        </ul>
                        <div className="tab-content">
                          <div
                            className="tab-pane show active"
                            id="bottom-justified-tab1"
                          >
                            <form action="#">
                              <div className="form-group">
                                <label>Mechant ID</label>
                                <div className="d-flex justify-content-around">
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled="disabled"
                                    placeholder={`${apiKey?.test?.merchantId}`}
                                  />
                                  {isHovering && (
                                    <span
                                      style={{
                                        position: "relative",
                                        top: "-10px",
                                        right: "0px",
                                        fontSize: "12px",
                                      }}
                                    ></span>
                                  )}
                                  <BiCopy
                                    onClick={() =>
                                      copyToClipBoard(
                                        `${apiKey?.test?.merchantId}`,
                                        'testMerchantId'
                                      )
                                    }
                                    style={{
                                      position: "relative",
                                      top: "14px",
                                      right: "21px",
                                    }}
                                    onMouseOver={handleMouseOver}
                                    onMouseOut={handleMouseOut}
                                  />
                                  {copyMessageTime && (
                                    <small className="text-muted mt-2 ">
                                      {copySuccess.testMerchantId}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="form-group">
                                <label>Token</label>
                                <div className="d-flex justify-content-between">
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled="disabled"
                                    placeholder={`${apiKey?.test?.token}`}
                                  />
                                  <BiCopy
                                    onClick={() =>
                                      copyToClipBoard(`${apiKey?.test?.token}`, 'testToken')
                                    }
                                    className="mt-2"
                                    style={{
                                      position: "relative",
                                      top: "6px",
                                      right: "21px",
                                    }}
                                  />
                                   {copyMessageTime && (
                                    <small className="text-muted mt-2 ">
                                      {copySuccess.testToken}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="tab-pane" id="bottom-justified-tab2">
                            <form action="#">
                              <div className="form-group">
                                <label>Mechant ID</label>
                                <div className="d-flex justify-content-between">
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled="disabled"
                                    placeholder={`${apiKey?.production?.merchantId}`}
                                  />
                                  <BiCopy
                                    onClick={() =>
                                      copyToClipBoard(
                                        `${apiKey?.production?.merchantId}`,
                                        'productionMerchantId'
                                      )
                                    }
                                    className="mt-2"
                                    style={{
                                      position: "relative",
                                      top: "6px",
                                      right: "21px",
                                    }}
                                  />
                                   {copyMessageTime && (
                                    <small className="text-muted mt-2 ">
                                      {copySuccess.productionMerchantId}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="form-group ">
                                <label>Token</label>
                                <div className="d-flex justify-content-between">
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled="disabled"
                                    placeholder={`${apiKey?.production?.token}`}
                                  />
                                  <BiCopy
                                    onClick={() =>
                                      copyToClipBoard(
                                        `${apiKey?.production?.token}`,
                                        'productionToken'
                                      )
                                    }
                                    className="mt-2"
                                    style={{
                                      position: "relative",
                                      top: "6px",
                                      right: "21px",
                                    }}
                                  />
                                   {copyMessageTime && (
                                    <small className="text-muted mt-2 ">
                                      {copySuccess.productionToken}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DevloperApi;
