import React,{useState} from 'react'
import { StyledMenu } from '../../../Utils';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import moment from "moment";
import DateRangeFilter from '../../../Utils/DateRangeFilter';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const statusArr = [
  { label :'Credit', value :"CREDIT"},
  { label :'Debit', value :"DEBIT"},

];

const WalletFilterComponent = ({handleSubmitFilter, handleReset, handleExportFilter, resetDate}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [status, setStatus] = React.useState([]);
    const open = Boolean(anchorEl);
    const [passDate,setPassDate] = useState()
   
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleChangeStatus = (event) => {
      const {
        target: { value },
      } = event;
      setStatus(
        typeof value === 'string' ? value.split(',') : value,
      );
    };
    const status1 = status.map(u => u).join(",");
      
    const [openCal, setOpenCal] = useState(false);
    const onChange = (ranges) => {
      if (
        moment(ranges.startDate).format("MM-DD-YYYY") !==
        moment(ranges.endDate).format("MM-DD-YYYY")
      ) {
        setOpenCal(false);
      } else if (ranges.startDate === "" && ranges.endDate === "") {
        setOpenCal(false);
      }
      const formatDate = (date) => moment(date).format("YYYY-MM-DD");
      const endDate = ranges.endDate === null ? formatDate(ranges.startDate) : formatDate(ranges.endDate);
      const startDate = ranges.startDate ? formatDate(ranges.startDate) : "";
       setPassDate(startDate +","+endDate)
    };
  
  return (
    <div className="card report-card">
      <div className="card-body pb-0">
        <div className="row">
          <ul className="transactions-listing">
            <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
              <li>
                <div className="multipleSelection">
                  <DateRangeFilter onChange={onChange} openCal={openCal} setOpenCal={setOpenCal} resetDate={resetDate} />
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                  </StyledMenu>
                </div>
              </li> 
            </div>
            <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
              <li>
                <div className="multipleSelection">
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-multiple-checkbox-label"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-book-open me-1 select-icon"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path></svg>
                      Select Status</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={status}
                      onChange={handleChangeStatus}
                      input={<OutlinedInput label={(<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-book-open me-1 select-icon"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path></svg>
                        Select Status</>)} />}
                        renderValue={(selected) =>
                          statusArr.filter(status => selected.includes(status.value))
                                      .map(record => record.label)
                                      .join(", ")
                                    }
                    MenuProps={MenuProps}
                    onMouseDown={() => {setOpenCal(false)}}
                    > 
                     {statusArr.map((name, index) => (
                          <MenuItem key={index} value={name.value}>
                              <Checkbox checked={status.indexOf(name.value) > -1} />
                              <ListItemText primary={name.label} />
                          </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </li> 
            </div>
            <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
              <li>
                <div className="report-btn">
                  <button type="button" className="btn btn-block btn-outline-primary btn-reposrt"
                   onClick={() => handleSubmitFilter({fStatus : status1, fDate : passDate })}
                   >Submit</button>
                  <button type="button" className="btn btn-block btn-outline-primary  btn-reposrt mx-2"
                    onClick={() =>{
                        handleReset({fStatus:setStatus([]), fDate: "" });
                        setPassDate("");
                      }}
                       >Reset</button>
                       <button type="button" className="btn btn-block btn-outline-primary btn-reposrt"
                         onClick={() => handleExportFilter({fStatus : status1, fDate : passDate})}
                       >Export</button>
                  {/* <button type="button" className="btn btn-block btn-outline-primary btn-reposrt">
                  <img src="../inner-assets/img/icons/invoices-icon5.svg" alt="" className="me-2" />Genereate Report</button> */}
                </div>
              </li>
            </div>
          </ul>
       </div>
      </div>
    </div>
  )
}

export default WalletFilterComponent