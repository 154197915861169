import Api from "../../../Utils/MerchantAxios";

export const PostBankingOption = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/bank_account/merchant/add', data));
    } catch (error) {
      reject(error);
    }
  })
}
export const GetBankingOptionPaginate = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/bank_account`));
    } catch (error) {
      reject(error);
    }
  })
}
export const GetBankingOptionById = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/merchant/bankingoption/${id}`));
    } catch (error) {
      reject(error);
    }
  })
}
export const PutBankingOption = async ({ id, data }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.put(`/merchant/bankingoption/${id}`, data));
    } catch (error) {
      reject(error);
    }
  })
}
export const DeleteBankingOption = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete(`/merchant/bankingoption/${id}`));
    } catch (error) {
      reject(error);
    }
  })
}
export const StatusBankingOption = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/master/bank_account/merchant/change_status", data));
    } catch (error) {
      reject(error);
    }
  })
}

export const addBankingOptionService = async () =>{
  return new Promise(async (resolve,reject) => {
    try{
      resolve(await Api.get("/master/banks?all=0"));
    }catch(error){
      reject(error);
    }
  })
}

export const getBankingOptionByIdService = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/bank_account/${id}`));
    } catch (error) {
      reject(error);
    }
  })
};

export const getEditBankingOptionService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/master/bank_account/merchant/update",data));
    } catch (error) {
      reject(error);
    }
  })
};