import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LoginService, VerifyOtpService} from "../../../Services/Api/Client/customerAuth";
import { SuccessToast, ErrorToast, InfoToast } from "../../../Utils/SweetAlert";
import { Helmet } from "react-helmet-async";

const Login = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isTwoStep, setIsTwoStep] = useState(false);
  const [twoStepUserData, setTwoStepUserData] = useState({});
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>CPay | Customer Login</title>
      </Helmet>
      <div className="common-page">
        <span className="scene d-none">
          <small className="d-none" data-depth="0.2"></small>
        </span>
        <div className="brand-logo">
          <Link to="#" className="brand-link">
            <img
              src="/image/register-login-common/logo-2.png"
              alt="logo_not_found"
            />
          </Link>
        </div>
        <section id="register-section" className="register-section clearfix">
          <div className="common-container">
            <div className="image-container">
              <img
                src="/image/register-login-common/common-1.png"
                alt="image_not_found"
              />
            </div>
          </div>
          <div className="common-container bg-default-blue login-container">
            <div className="item-content">
              <h2 className="title-text mb-4">We are CPay</h2>
              <p className="mb-5">
                Welcome Back ! Please Login to your Account
              </p>
              <div className="register-form">
                <Formik
                  enableReinitialize
                  initialValues={{
                    otp: "",
                    email: "",
                    password: "",
                    isOtpScreen: isTwoStep,
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email()
                      .when("isOtpScreen", {
                        is: false,
                        then: Yup.string().required("Email is required."),
                      }),
                    isOtpScreen: Yup.boolean(),
                    otp: Yup.string().when("isOtpScreen", {
                      is: true,
                      then: Yup.string().required("Otp is required"),
                    }),
                    password: Yup.string().when("isOtpScreen", {
                      is: false,
                      then: Yup.string().required("Password is required."),
                    }),
                  })}
                  validateOnChange
                  onSubmit={async (values, { setSubmitting }) => {
                    if (isTwoStep === false) {
                      const loginPostObj = {
                        email: values.email,
                        password: values.password,
                      };
                      LoginService(loginPostObj)
                        .then((res) => {
                          setSubmitting(false);
                          const twostepenabled =
                            res?.data?.data?.user?.two_step_enabled || 0;
                          if (twostepenabled === 0) {
                            localStorage.setItem(
                              "clientAccessToken",
                              res?.data?.data?.token
                            );
                            localStorage.setItem(
                              "clientUserData",
                              JSON.stringify(res?.data?.data?.user)
                            );
                            SuccessToast(
                              res?.data?.message || "Login successful."
                            );
                            navigate('/client/dashboard')
                          } else {
                            InfoToast(res?.data?.message || 'Two step verification is required for login')
                            setTwoStepUserData(res?.data?.data?.user || {})
                            setIsTwoStep(true);
                          }
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    } else {
                      const postOtpVerifyObj = {
                        user_id: twoStepUserData._id,
                        otp: values.otp,
                        motive: "LOGIN",
                      };
                      VerifyOtpService(postOtpVerifyObj)
                        .then((res) => {
                          setSubmitting(false);
                          localStorage.setItem(
                            "clientAccessToken",
                            res?.data?.data?.token
                          );
                          localStorage.setItem(
                            "clientUserData",
                            JSON.stringify(res?.data?.data?.user)
                          );
                          SuccessToast(
                            res?.data?.message || "Login successful."
                          );
                          navigate("/client/dashboard");
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }
                  }}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit} id="register-form">
                        {!isTwoStep && (
                          <>
                            <div className="form-group">
                              <label
                                htmlFor="reg-email"
                                className="form-control-label"
                              >
                                Email*
                              </label>
                              <Field
                                type="email"
                                className={`form-control ${errors.email && touched.email
                                    ? "input-error"
                                    : null
                                  }`}
                                onChange={handleChange}
                                placeholder="Enter email"
                                id="email"
                                name="email"
                              />
                              <ErrorMessage
                                name="email"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="reg-password"
                                className="form-control-label"
                              >
                                Password*
                              </label>
                              <div className="pass-group">
                                <div className="pass-group">
                                  <Field
                                    type={isShowPassword ? "text" : "password"}
                                    className={`form-control ${errors.password && touched.password
                                        ? "input-error"
                                        : null
                                      }`}
                                    onChange={handleChange}
                                    id="password"
                                    name="password"
                                    placeholder="Enter password"
                                  />
                                  <span
                                    onClick={() =>
                                      setIsShowPassword(!isShowPassword)
                                    }
                                    className={`fas ${isShowPassword ? "fa-eye-slash" : "fa-eye"
                                      } toggle-password`}
                                  />
                                </div>
                              </div>
                              <ErrorMessage
                                name="password"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-6">
                                  <div className="custom-control custom-checkbox">
                                    {/* <input
                                      type="checkbox"
                                      className="custom-control-input rememberMe"
                                      id="cb1"
                                    /> */}
                                    {/* <label
                                      className="custom-control-label"
                                      htmlFor="cb1"
                                    >
                                      &nbsp;Remember me
                                    </label> */}
                                    <label className="custom_check" htmlFor="cb1">
                                      <input type="checkbox" name="invoice" id="cb1"/>Remember me
                                      <span className="checkmark" />
                                    </label>

                                  </div>
                                </div>
                                <div className="col-6 text-end">
                                  <Link
                                    to="/client/auth/forgot-password"
                                    className="forgot-link-client"
                                  >
                                    Forgot Password ?
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {isTwoStep && (
                          <div className="form-group">
                            <label htmlFor="otp" className="form-control-label">
                              OTP
                            </label>
                            <Field
                              type="text"
                              className={`form-control ${errors.otp && touched.otp ? "input-error" : null
                                }`}
                              onChange={handleChange}
                              id="otp"
                              name="otp"
                            />
                            <ErrorMessage
                              name="otp"
                              component="span"
                              className="error"
                            />
                          </div>
                        )}
                        <button
                          type="submit"
                          className="btn btn-block btn-theme  w-100"
                          disabled={isSubmitting}
                        >
                          Submit&nbsp;&nbsp;
                          {isSubmitting && (
                            <i className="fas fa-spinner fa-spin"></i>
                          )}
                        </button>
                        <div className="login-or">
                          <span className="or-line"></span>
                          <span className="span-or forgot">or</span>
                        </div>
                        <div className="text-center dont-have register-link-client">
                          Don't have an account yet?{" "}
                          <Link to="/client/auth/register">Register</Link>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
            <small className="shape-1">
              <img
                src="/image/register-login-common/cross.png"
                alt="image_not_found"
              />
            </small>
            <small className="shape-2">
              <img
                src="/image/register-login-common/flow-1.png"
                alt="image_not_found"
              />
            </small>
            <small className="shape-3">
              <img
                src="/image/register-login-common/box.png"
                alt="image_not_found"
              />
            </small>
            <small className="shape-4">
              <img
                src="/image/register-login-common/box.png"
                alt="image_not_found"
              />
            </small>
            <small className="shape-5">
              <img
                src="/image/register-login-common/circle-half.png"
                alt="image_not_found"
              />
            </small>
            <small className="shape-6">
              <img
                src="/image/register-login-common/cross.png"
                alt="image_not_found"
              />
            </small>
            <small className="shape-7">
              <img
                src="/image/register-login-common/flow-2.png"
                alt="image_not_found"
              />
            </small>
            <small className="shape-8">
              <img
                src="/image/register-login-common/circle.png"
                alt="image_not_found"
              />
            </small>
            <small className="shape-9">
              <img
                src="/image/register-login-common/circle-half.png"
                alt="image_not_found"
              />
            </small>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
