import format from 'date-fns/format'
import React from 'react'
import { Modal } from 'react-bootstrap'

const PenaltyLogs = ({ data, totalPenaltyAmount, isOpenModal, toggleModelFun }) =>  {
    return (
    <Modal
      show={isOpenModal}
      onHide={() => toggleModelFun([], 0)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="md"
    >
      <Modal.Header>
        <Modal.Title>Penalty Log History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive">
          <table className="table table-center table-hover datatable">
            <thead className="thead-light">
              <th className='text-center'>
                #
              </th>
              <th className='text-center'>
                Penalty Date
              </th>
              <th className='text-center'>
                Penalty Amount
              </th>
            </thead>
            <tbody>
              {data.length > 0 && data.map((d, index) => (
                <tr>
                  <td className='text-center'>{(index+1)}</td>
                  <td className='text-center'>{format(new Date(d?.createdAt),"dd MMMM, yyyy")}</td>
                  <td className='text-center'>AED {parseFloat(d?.penalty_amount || 0).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot className="tfoot-light">
              <tr>
                <td colSpan={3} className='text-bold'><b>Total Penalty Amount: AED {parseFloat(totalPenaltyAmount).toFixed(2)}</b></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PenaltyLogs