import React, { useState, useEffect } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';
import { Pagination } from '../../../Utils';
import { ClientActiveInactiveUser, ClientDeleteUserById, GetClientUserPaginate } from '../../../Services/Api/Client/userManagement';
import { Confirmation, ErrorToast, SuccessToast } from '../../../Utils/SweetAlert';
import format from "date-fns/format";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { onImageError } from '../../../Utils/CommanFunctions';

const User = () => {

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userListPagination, setuserListPagination] = useState([]);
  const navigate = useNavigate()

  const GetUserList = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetClientUserPaginate({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setuserListPagination(res?.data?.data?.users);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setuserListPagination([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setuserListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
    }
      const handlePageChange = async (e, pageNew) => {
        setPage(pageNew);
        await GetUserList(limit, pageNew);
      };
      const handleLimitChange = async (event) => {
        setLimit(parseInt(event.target.value, 10));
        setPage(0);
        await GetUserList(parseInt(event.target.value, 10), 0);
      };

     const deleteUserById = (userId) =>{
       Confirmation("Want to delete user?").then(async (deleteConfirm) => {
        if (deleteConfirm.isConfirmed) {
          await ClientDeleteUserById(userId)
            .then((res) => {
              SuccessToast(res?.data?.message || "User Deleted.");
              GetUserList(limit, page);
            })
            .catch((err) => {
              if (typeof err.response.data.message !== "undefined") {
                ErrorToast(err.response.data.message || "Server Error!!");
              } else {
                ErrorToast(err?.message || "Server Error!!");
              }
            });
        }
      });
     } 
     const changeActive = (user, msg="") => {
      Confirmation(msg).then(async (result) => {
        if (result.isConfirmed) {
          const postObj = {
            user_id: user._id,
            status: `${user.status ^ 1}`,
          };
          await ClientActiveInactiveUser(postObj)
            .then((res) => {
              SuccessToast(res?.data.message || "Status Updated");
              GetUserList(limit, page);
            })
            .catch((err) => {
              // if (typeof err.response.data.message !== "undefined") {
              //   ErrorToast(err.response.data.message || "Server Error!!");
              // } else {
              //   ErrorToast(err?.message || "Server Error!!");
              // }
            });
        }
      });
     };

    useEffect(() => {
      GetUserList(limit, page)
    }, [limit, page])

  return (
    <>
      <Helmet>
        <title>CPay | Customer User</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Sub Users</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/client/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item active">Sub Users</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/client/add-user" className="btn btn-primary me-1">
                  <i className="fas fa-plus" /> Add User
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Customer</th>
                          <th>Status</th>
                          <th className='text-center'>Action</th>
                          <th>Email</th>
                          <th>Role</th>
                          <th>Joining Date</th>
                        </tr>
                      </thead>
                      <tbody>
                      {!isLoading && (
                          <>
                          {userListPagination.map((user,index) => (
                        <tr key={index}>
                          <td>
                              <h2 className="table-avatar">
                                <Link to="#" className="avatar avatar-sm me-2">
                                    <img className="avatar-img rounded-circle" 
                                     src={user?.profile_picture || ""}
                                     onError={onImageError}
                                      alt="User" />
                                </Link>
                                <Link to="#">{user?.name || ""} <span>{user?.mobile || ""}</span></Link>
                              </h2>
                          </td>
                          <td>
                              {user?.status === 0 && (
                                 <button
                                 className="btn btn-sm  btn-block btn-outline-danger"
                                 onClick={() => {
                                   changeActive(user,"Want to active user?");
                                 }}
                                 >
                                 Inactive
                               </button>
                             ) }
                              {user?.status === 1 && 
                                 <button
                                className="btn btn-sm  btn-block btn-outline-success"
                                onClick={() => {
                                  changeActive(user,"Want to deactivate user?");
                                }}
                              >
                                Active
                              </button>
                              }
                          </td>
                         <td className="text-center"> 
                          <Tooltip title="Edit" className="btn btn-sm btn-white text-success me-2" onClick={() =>
                                    navigate("/client/edit-user", {
                                      state: { id: user?._id },
                                    })}>
                                    <IconButton >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>


                          <Tooltip title="Delete" className="btn btn-sm btn-white text-danger me-2" 
                          onClick={() => deleteUserById(user?._id)}  >
                                    <IconButton>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                          </td>
                          <td>
                               {user?.email || "-"}
                          </td>
                          <td>
                              {user?.user_role?.role_name || ""}
                          </td>
                          <td>
                              {format(
                                    new Date(user?.createdAt),
                                    "dd MMMM, yyyy"
                                  )}
                          </td>
                        </tr>
                        ))}
                       {userListPagination.length === 0 && (
                              <tr>
                                <td colSpan={6}>No Records Found.</td>
                              </tr>
                            )}
                         </>
                        )}
                         {isLoading && (
                          <tr>
                            <td align="center" colSpan={6}>
                              Loading...
                            </td>
                          </tr>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={6}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default User