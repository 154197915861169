import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import {
  GetMerchantResourceService,
  GetMerchantRoleListById,
  MerchantEditRoleService,
} from "../../../Services/Api/Merchant/userManagement";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";

const EditRolePermission = () => {
  const stateData = useLocation();
  const { id } = stateData?.state || {};

  const navigate = useNavigate();
  const [resourceList, setResourceList] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [defaultResource, setDefaultResource] = useState([]);

  const signInSchema = Yup.object().shape({
    role_name: Yup.string().required("Role name is required"),
    description: Yup.string().required("Description is required"),
  });
  const initialValues = {
    role_name: roleById?.role_name || "",
    description: roleById?.description || "",
    rights: defaultResource || [],
  };

  const getResource = async () => {
    GetMerchantResourceService()
      .then((res) => {
        let resorceArr = res?.data?.data?.resources || {};

        resorceArr = resorceArr.map((value) => {
          return {
            label: value.resource_name,
            value: value._id,
          };
        });
        setResourceList(resorceArr);
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };
  const getRoleList = async () => {
    await GetMerchantRoleListById(id)
      .then((res) => {
        setRoleById(res.data.data.roles || []);
        let editresorceArr = res?.data?.data?.roles?.role_meta || [];
        let roleMeta = editresorceArr.map((value) => {
          return value.role_resource._id;
        });
        setDefaultResource(roleMeta);
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };

  useEffect(() => {
    getResource();
    getRoleList();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Merchant Edit Role Permission</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Edit Role &amp; Permissions</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/merchant/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/merchant/role-management">
                      Role &amp; Permissions
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Edit Role &amp; Permissions
                  </li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/merchant/role-management"
                  className="btn btn-primary me-1"
                >
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Role Information</h5>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      const rights = [];
                      for (let i = 0; i < values.rights.length; i++) {
                        rights.push({
                          resource_id: values.rights[i],
                        });
                      }
                      const postData = {
                        id: id,
                        role_name: values.role_name,
                        description: values.description,
                        rights: rights,
                      };
                      await MerchantEditRoleService(postData)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message || "Role Edited successfully."
                          );
                          setSubmitting(false);
                          navigate("/merchant/role-management");
                          resetForm();
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const {
                        errors,
                        touched,
                        values,
                        isSubmitting,
                        handleChange,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Role Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                {/* <input type="text" className="form-control" /> */}

                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.role_name && touched.role_name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="role_name"
                                  onChange={handleChange}
                                  placeholder="Enter role"
                                  value={values.role_name}
                                />
                                <ErrorMessage
                                  name="role_name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Description{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                {/* <input type="text" className="form-control" /> */}
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.description && touched.description
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="description"
                                  placeholder="Enter description"
                                  onChange={handleChange}
                                  value={values.description}
                                />
                                <ErrorMessage
                                  name="description"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="card-header">
                            <h5 className="card-title">
                              Permissions Information
                            </h5>
                          </div>

                          {/* <div className="row mx-3 my-4">
                                                {resourceList.map((resource,index) => (
                                                    <div className="col-md-3" key={index}>
                                                       <div className="form-group">
                                                            <label className="custom_check">
                                                                    <Field
                                                                        type="checkbox"
                                                                        className={`form-control ${errors.resource?.label && touched.resource?.label
                                                                            ? "input-error"
                                                                            : null
                                                                            }`}
                                                                        name={resource?.label}
                                                                    />
                                                                   <span className="checkmark" /> 
                                                                    <label>{resource?.label}</label>
                                                            </label>                    
                                                        </div>
                                                    </div>
                                                 ))}
                                                </div> */}

                          <div className="row mx-3 my-4">
                            {resourceList.map((resource, index) => (
                              <div
                                className="col-xl-3 col-sm-6 col-12"
                                key={index}
                              >
                                <div className="form-group">
                                  <div
                                    role="group"
                                    aria-labelledby="checkbox-group"
                                  >
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="rights"
                                        value={resource.value}
                                        // value={values.rights}
                                      />
                                      &nbsp;&nbsp;&nbsp;
                                      {resource.label}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Edit Role
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRolePermission;
