import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { baseURL } from "../../../../Utils/ClientAxios";
const appBaseUrl = process.env.REACT_APP_FRONT_URL;

const RestApi = () => {
  const apiBaseURL = baseURL.replace('api/v1', 'api-docs');
  return (
    <>
      <Helmet>
        <title>CPay | Merchant API Documentation </title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">REST API</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/merchant/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Developer Settings / Api Documentation / REST API
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card bg-white">
                <div>
                  <div className="my-5">
                    <div className="font-swag rest-api-details">
                      <p>
                        The CPay REST APIs is organized around transaction
                        workflows, including: Authentication, Purchase Order and
                        Booking.
                      </p>
                      <p>
                        Try out our REST APIs on{" "}
                        <a
                          href={`${apiBaseURL}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Swagger
                        </a>
                        .
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-lg-3  p-option">
                        <div
                          className="nav flex-column nav-pills me-3 custom-design-btn"
                          id="v-pills-tab"
                          role="tablist"
                          aria-orientation="vertical"
                        >
                          <span>
                            <h5 className="mx-4">Authentication </h5>
                          </span>
                          <button
                            className="nav-link active my-2 mx-4"
                            id="get-token-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#get-token"
                            type="button"
                            role="tab"
                            aria-controls="get-token"
                            aria-selected="true"
                          >
                            Get Token{" "}
                          </button>
                          <button
                            className="nav-link my-2 mx-4"
                            id="get-balance-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#get-balance"
                            type="button"
                            role="tab"
                            aria-controls="get-balance"
                            aria-selected="false"
                          >
                            Get Balance{" "}
                          </button>
                          <span>
                            <h5 className="mx-4">Purchase Order </h5>
                          </span>
                          <button
                            className="nav-link my-2 mx-4"
                            id="flight-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#flight"
                            type="button"
                            role="tab"
                            aria-controls="flight"
                            aria-selected="true"
                          >
                            Flight{" "}
                          </button>
                          <button
                            className="nav-link my-2 mx-4"
                            id="hotel-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#hotel"
                            type="button"
                            role="tab"
                            aria-controls="hotel"
                            aria-selected="false"
                          >
                            Hotel{" "}
                          </button>
                          <button
                            className="nav-link my-2 mx-4"
                            id="visa-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#visa"
                            type="button"
                            role="tab"
                            aria-controls="visa"
                            aria-selected="true"
                          >
                            Visa{" "}
                          </button>
                          <span>
                            <h5 className="mx-4">Booking </h5>
                          </span>
                          <button
                            className="nav-link my-2 mx-4"
                            id="booking-reference-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#booking-reference"
                            type="button"
                            role="tab"
                            aria-controls="booking-reference"
                            aria-selected="true"
                          >
                            Get By Booking Reference{" "}
                          </button>
                          <button
                            className="nav-link my-2 mx-4"
                            id="update-invoice-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#update-invoice"
                            type="button"
                            role="tab"
                            aria-controls="update-invoice"
                            aria-selected="true"
                          >
                            Update Invoice Link{" "}
                          </button>
                          <button
                            className="nav-link my-2 mx-4"
                            id="update-booking-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#update-booking"
                            type="button"
                            role="tab"
                            aria-controls="update-booking"
                            aria-selected="false"
                          >
                            Update Booking{" "}
                          </button>
                          <button
                            className="nav-link my-2 mx-4"
                            id="refund-amount-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#refund-amount"
                            type="button"
                            role="tab"
                            aria-controls="refund-amount"
                            aria-selected="false"
                          >
                            Refund Amount{" "}
                          </button>
                          <button
                            className="nav-link my-2 mx-4"
                            id="cancel-booking-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#cancel-booking"
                            type="button"
                            role="tab"
                            aria-controls="cancel-booking"
                            aria-selected="true"
                          >
                            Cancel Booking{" "}
                          </button>
                          <button
                            className="nav-link my-2 mx-4"
                            id="visa-payment-status-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#visa-payment-status"
                            type="button"
                            role="tab"
                            aria-controls="visa-payment-status"
                            aria-selected="true"
                          >
                            Visa Payment Status{" "}
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-12 ">
                        <div
                          className="tab-content p-3"
                          id="v-pills-tabContent"
                        >
                          <div
                            className="tab-pane fade show active"
                            id="get-token"
                            role="tabpanel"
                            aria-labelledby="get-token-tab"
                          >
                            <div className="card-body">
                              <div className="main-head-rest">
                                <h3 className="m-20">Get Token</h3>
                                <p className="my-4">
                                  All merchants will require an{" "}
                                  <a
                                    href={`${appBaseUrl}merchant/developer-api`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    API Keys
                                  </a>{" "}
                                  which can be used to generate an access token
                                  that is used to authenticate all subsequent
                                  requests.
                                </p>
                                <p>
                                  Each access token will expire after 60 minutes
                                  at which point a new token must be requested.
                                </p>
                                <p>
                                  To authenticate with the CPay API, first make
                                  a call to the{" "}
                                  <a
                                    href={`${apiBaseURL}#/Account/get_account_token`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    Account Token
                                  </a>{" "}
                                  endpoint, passing the following in header
                                  parameters.
                                </p>
                                <ul className="my-2">
                                  <li>
                                    [x-merchant-id]: Developer Settings &gt; API
                                    Keys &gt; Mechant ID
                                  </li>
                                  <li>
                                    [x-merchant-token]: Developer Settings &gt;
                                    API Keys &gt; Token
                                  </li>
                                </ul>
                                <p>
                                  Assuming valid API keys are passed, an access
                                  token will be returned along with the Unix
                                  Timestamp when it will expire. Have a look at
                                  the <strong>Responses</strong> section of
                                  Swagger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="get-balance"
                            role="tabpanel"
                            aria-labelledby="v-pills-profile-tab"
                          >
                            <div className="card-body">
                              <div className="main-head-rest">
                                <h3 className="m-20">Get Balance</h3>
                                <p className="my-4">
                                  You can get your account balance and CPay
                                  service charge using{" "}
                                  <a
                                    href={`${apiBaseURL}#/Account/get_account_balance`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    Account Balance
                                  </a>{" "}
                                  endpoint, passing the following in header
                                  parameter
                                </p>
                                <ul className="my-2">
                                  <li>
                                    [token]: Return from{" "}
                                    <a
                                      href={`${apiBaseURL}#/Account/get_account_token`}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      Account Token
                                    </a>{" "}
                                    API call
                                  </li>
                                </ul>
                                <p>
                                  For response, Have a look at the{" "}
                                  <strong>Responses</strong> section of Swagger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade "
                            id="flight"
                            role="tabpanel"
                            aria-labelledby="flight-tab"
                          >
                            <div className="card-body">
                              <div className="main-head-rest">
                                <h3 className="m-20">Flight</h3>
                                <p className="my-4">
                                  You can create purchase order for flight using{" "}
                                  <a
                                    href={`${apiBaseURL}#/Order/post_order_create`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    Order Create
                                  </a>{" "}
                                  endpoint
                                </p>
                                <p>Required following in header parameter</p>
                                <ul className="my-2">
                                  <li>
                                    [token]: Return from{" "}
                                    <a
                                      href={`${apiBaseURL}#/Account/get_account_token`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Account Token
                                    </a>{" "}
                                    API call
                                  </li>
                                </ul>
                                <p>
                                  You also need to pass row data in the request
                                  body. Please have a look at the{" "}
                                  <strong>FlightBooking</strong> Schema for
                                  request body data in Swagger.
                                </p>
                                <p>
                                  For response, Have a look at the{" "}
                                  <strong>Responses</strong> section of Swagger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade "
                            id="hotel"
                            role="tabpanel"
                            aria-labelledby="hotel-tab"
                          >
                            <div className="card-body">
                              <div className="main-head-rest">
                                <h3 className="m-20">Hotel</h3>
                                <p className="my-4">
                                  You can create purchase order for hotel using{" "}
                                  <a
                                    href={`${apiBaseURL}#/Order/post_order_create_hotel`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Order Create
                                  </a>{" "}
                                  endpoint
                                </p>
                                <p>Required following in header parameter</p>
                                <ul className="my-2">
                                  <li>
                                    [token]: Return from{" "}
                                    <a
                                      href={`${apiBaseURL}#/Account/get_account_token`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Account Token
                                    </a>{" "}
                                    API call
                                  </li>
                                </ul>
                                <p>
                                  You also need to pass row data in the request
                                  body. Please have a look at the{" "}
                                  <strong>HotelBooking</strong> Schema for
                                  request body data in Swagger.
                                </p>
                                <p>
                                  For response, Have a look at the{" "}
                                  <strong>Responses</strong> section of Swagger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade "
                            id="visa"
                            role="tabpanel"
                            aria-labelledby="visa-tab"
                          >
                            <div className="card-body">
                              <div className="main-head-rest">
                                <h3 className="m-20">Visa</h3>
                                <p className="my-4">
                                  You can create purchase order for visa using{" "}
                                  <a
                                    href={`${apiBaseURL}#/Order/post_order_create_visa`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Order Create
                                  </a>{" "}
                                  endpoint
                                </p>
                                <p>Required following in header parameter</p>
                                <ul className="my-2">
                                  <li>
                                    [token]: Return from{" "}
                                    <a
                                      href={`${apiBaseURL}#/Account/get_account_token`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Account Token
                                    </a>{" "}
                                    API call
                                  </li>
                                </ul>
                                <p>
                                  You also need to pass row data in the request
                                  body. Please have a look at the{" "}
                                  <strong>Visa</strong> Schema for request body
                                  data in Swagger.
                                </p>
                                <p>
                                  For response, Have a look at the{" "}
                                  <strong>Responses</strong> section of Swagger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade "
                            id="booking-reference"
                            role="tabpanel"
                            aria-labelledby="booking-reference-tab"
                          >
                            <div className="card-body">
                              <div className="main-head-rest">
                                <h3 className="m-20">
                                  Get By Booking Reference
                                </h3>
                                <p className="my-4">
                                  You can get booking details using{" "}
                                  <a
                                    href={`${apiBaseURL}#/Booking/get_booking_retrieve__booking_reference__details`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Retrive Booking
                                  </a>{" "}
                                  endpoint
                                </p>
                                <p>Required following in header parameter</p>
                                <ul className="my-2">
                                  <li>
                                    [token]: Return from{" "}
                                    <a
                                      href={`${apiBaseURL}#/Account/get_account_token`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Account Token
                                    </a>{" "}
                                    API call
                                  </li>
                                </ul>
                                <p>
                                  For response, Have a look at the{" "}
                                  <strong>Responses</strong> section of Swagger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade "
                            id="update-invoice"
                            role="tabpanel"
                            aria-labelledby="update-invoice-tab"
                          >
                            <div className="card-body">
                              <div className="main-head-rest">
                                <h3 className="m-20">Update Invoice Link</h3>
                                <p className="my-4">
                                  After successful booking you need to update
                                  PDF invoice link for perticular booking using{" "}
                                  <a
                                    href={`${apiBaseURL}#/Booking/put_booking_update__booking_reference__invoice`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Update Invoice
                                  </a>{" "}
                                  endpoint
                                </p>
                                <p>
                                  For response, Have a look at the{" "}
                                  <strong>Responses</strong> section of Swagger.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade "
                            id="update-booking"
                            role="tabpanel"
                            aria-labelledby="update-booking-tab"
                          >
                            <div className="card-body">
                              <div className="main-head-rest">
                                <h3 className="m-20">Update booking</h3>
                                <div>
                                  <p className="my-4">
                                    You can update booking amount request to
                                    user using{" "}
                                    <a
                                      href={`${apiBaseURL}#/Booking/put_booking_update__booking_reference__increase_amount_modification`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Booking Update
                                    </a>{" "}
                                    endpoint
                                  </p>
                                  <p>Required following in header parameter</p>
                                  <ul className="my-2">
                                    <li>
                                      [token]: Return from{" "}
                                      <a
                                        href={`${apiBaseURL}#/Account/get_account_token`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Account Token
                                      </a>{" "}
                                      API call
                                    </li>
                                  </ul>
                                  <p>
                                    You also need to pass row data in the
                                    request body. Please have a look at the{" "}
                                    <strong>Request body -&gt; Schema</strong>{" "}
                                    for request body data in Swagger.
                                  </p>
                                  <p>
                                    For response, Have a look at the{" "}
                                    <strong>Responses</strong> section of
                                    Swagger.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="refund-amount"
                            role="tabpanel"
                            aria-labelledby="refund-amount-tab"
                          >
                            <div className="card-body">
                              <div className="main-head-rest">
                                <h3 className="m-20">Refund Amount</h3>
                                <div>
                                  <p className="my-4">
                                    You can refund amount request to
                                    user using{" "}
                                    <a
                                      href={`${apiBaseURL}#/Booking/put_booking_update__booking_reference__refund_amount`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Refund Amount
                                    </a>{" "}
                                    endpoint
                                  </p>
                                  <p>Required following in header parameter</p>
                                  <ul className="my-2">
                                    <li>
                                      [token]: Return from{" "}
                                      <a
                                        href={`${apiBaseURL}#/Account/get_account_token`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Account Token
                                      </a>{" "}
                                      API call
                                    </li>
                                  </ul>
                                  <p>
                                    You also need to pass row data in the
                                    request body. Please have a look at the{" "}
                                    <strong>Request body -&gt; Schema</strong>{" "}
                                    for request body data in Swagger.
                                  </p>
                                  <p>
                                    For response, Have a look at the{" "}
                                    <strong>Responses</strong> section of
                                    Swagger.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade "
                            id="cancel-booking"
                            role="tabpanel"
                            aria-labelledby="cancel-booking-tab"
                          >
                            <div className="card-body">
                              <div className="main-head-rest">
                                <h3 className="m-20">Cancel Booking</h3>
                                <div>
                                  <p className="my-4">
                                    You can cancel booking using{" "}
                                    <a
                                      href={`${apiBaseURL}#/Booking/post_booking_cancel`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Booking Cancel
                                    </a>{" "}
                                    endpoint
                                  </p>
                                  <p>Required following in header parameter</p>
                                  <ul className="my-2">
                                    <li>
                                      [token]: Return from{" "}
                                      <a
                                        href={`${apiBaseURL}#/Account/get_account_token`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Account Token
                                      </a>{" "}
                                      API call
                                    </li>
                                  </ul>
                                  <p>
                                    You also need to pass row data in the
                                    request body. Please have a look at the{" "}
                                    <strong>Request body -&gt; Schema</strong>{" "}
                                    for request body data in Swagger.
                                  </p>
                                  <p>
                                    For response, Have a look at the{" "}
                                    <strong>Responses</strong> section of
                                    Swagger.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade "
                            id="visa-payment-status"
                            role="tabpanel"
                            aria-labelledby="visa-payment-status-tab"
                          >
                            <div className="card-body">
                              <div className="main-head-rest">
                                <h3 className="m-20">
                                  Check Visa Payment Status
                                </h3>
                                <div>
                                  <p className="my-4">
                                    You can check visa payment status using{" "}
                                    <a
                                      href={`${apiBaseURL}#/Booking/get_booking_visa__booking_reference__payment`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Visa Payment
                                    </a>{" "}
                                    endpoint
                                  </p>
                                  <p>Required following in header parameter</p>
                                  <ul className="my-2">
                                    <li>
                                      [token]: Return from{" "}
                                      <a
                                        href={`${apiBaseURL}#/Account/get_account_token`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Account Token
                                      </a>{" "}
                                      API call
                                    </li>
                                  </ul>
                                  <p>
                                    For response, Have a look at the{" "}
                                    <strong>Responses</strong> section of
                                    Swagger.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RestApi;
