import React, { useState, createContext } from "react";

// Create Context Object
export const ClientProfileCompletion = createContext();

// Create a provider for components to consume and subscribe to changes
export const ClientProfileCompletionProvider = props => {
const clientUserData = JSON.parse(localStorage.getItem("clientUserData"));
const [count, setCount] = useState(clientUserData.profile_completed_percentage || 0);

  return (
    <ClientProfileCompletion.Provider value={[count, setCount]}>
      {props.children}
    </ClientProfileCompletion.Provider>
  );
};
