import React, { useEffect, useState } from "react";
import SideMenu from "./SideMenu";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DragAndDropFile } from "../Common";
import {
  fileUploadToBucket,
  getUploadedList,
  deleteDocument,
} from "../../../Services/Api/Client/fileUpload";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import format from "date-fns/format";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import { checkExpiration } from "../../../Utils/CommanFunctions";
import { useDebounce } from "use-debounce";
import { documentType } from "../../../Utils/CommanData";
import { Helmet } from "react-helmet-async";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const LegalDocs = () => {
  const [frontImageKey, setfrontImageKey] = useState(0);
  const [debounceKey] = useDebounce(frontImageKey, 1000);
  const [clientData, setClientData] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [vat, setVat] = useState("");

  const initialValues = {
    frontImage: "",
    backImage: "",
    ducumentType: null,
    date: "",
    validVisaImage: "",
  };


  const signInSchema = Yup.object().shape({
    frontImage: Yup.mixed().required("Front image is required"),
    backImage: Yup.mixed().when(["ducumentType"], {
      is: (ducumentType) => {
        return ducumentType?.value !== "VAT_CERTIFICATE"
      },
      then: Yup
        .mixed()
        .required("Back image is required"),
    }),
    validVisaImage: Yup.mixed().when(["ducumentType"], {
      is: (ducumentType) => {
        return ducumentType?.value === "PASSPORT"
      },
      then: Yup
        .mixed()
        .required("Valid visa image is required"),
    }),
    ducumentType: Yup.object()
      .nullable(true)
      .required("Please select Document type"),
    date: Yup.date()
      .min(new Date(), "Expiry date should not be later than today.")
      .required("Please select date"),
  });

  const getUploadedDocumentList = async () => {
    await getUploadedList()
      .then(async (res) => {
        setDocumentList(res?.data?.data?.documents);
      })
      .catch((err) => {
        setDocumentList([]);
        // if (err.response.status !== 400) {
        //   if (typeof err.response.data.message !== "undefined") {
        //     ErrorToast(err.response.data.message || "Server Error!!");
        //   } else {
        //     ErrorToast(err?.message || "Server Error!!");
        //   }
        // }
      });
  };
  const clientInfo = () => {
    const items = JSON.parse(localStorage.getItem("clientUserData"));
    if (items) {
      setClientData(items);
    }
  };

  const deleteDocumentCustomer = (deleteCustomerId) => {
    Confirmation("Want to delete record?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await deleteDocument(deleteCustomerId)
          .then((res) => {
            SuccessToast(res?.data?.message || "Deleted Successfully.");
            getUploadedDocumentList();
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  useEffect(() => {
    getUploadedDocumentList();
    clientInfo();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Customer Legal Document</title>
      </Helmet>
      <div>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="page-title">Legal Documents</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/client/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Legal Documents</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-md-4">
                <SideMenu />
              </div>
              <div className="col-xl-9 col-md-8">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Legal Documents</h5>
                    <p>Let's verify your self...</p>
                  </div>
                  <div className="card-body">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={signInSchema}
                      validateOnChange
                      // validateOnBlur={true}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        if (values?.frontImage?.name == null) {
                          ErrorToast("Please upload front side image.");
                          return false;
                        }
                        if (
                          vat != "VAT_CERTIFICATE" &&
                          values?.backImage?.name == null &&
                          vat != "COMPANY_LICENSE" &&
                          values?.backImage?.name == null
                        ) {
                          ErrorToast("Please upload back side image.");
                          return false;
                        }
                        const frontPostData = {
                          user_id: clientData._id,
                          file_name: values.frontImage.name,
                          file_module: values.ducumentType.value,
                          mime_type: values.frontImage.type,
                          document_sides: "FRONT",
                          expiry_date: format(
                            new Date(values.date),
                            "yyyy-MM-dd"
                          ),
                        };
                        const backPostData = {
                          user_id: clientData._id,
                          file_name: values.backImage?.name,
                          file_module: values.ducumentType.value,
                          mime_type: values.backImage?.type,
                          document_sides: "BACK",
                          expiry_date: format(
                            new Date(values.date),
                            "yyyy-MM-dd"
                          ),
                        };
                        const validVisaPostData = {
                          user_id: clientData._id,
                          file_name: values.validVisaImage?.name,
                          file_module: values.ducumentType.value,
                          mime_type: values.validVisaImage?.type,
                          document_sides: "VALID_VISA",
                          expiry_date: format(
                            new Date(values.date),
                            "yyyy-MM-dd"
                          ),
                        };
                        await fileUploadToBucket({
                          data: frontPostData,
                          file: values.frontImage,
                        })
                          .then(async (res) => {
                            if (
                              vat == "VAT_CERTIFICATE" ||
                              vat == "COMPANY_LICENSE"
                            ) {
                              setfrontImageKey(Math.random());
                              SuccessToast(
                                res?.data?.message ||
                                "Your document uploaded successfully."
                              );
                              getUploadedDocumentList();
                              setSubmitting(false);
                              resetForm();
                            } else {
                              await fileUploadToBucket({
                                data: backPostData,
                                file: values.backImage,
                              })
                                .then(async (res) => {
                                  setfrontImageKey(Math.random());
                                  SuccessToast(
                                    res?.data?.message ||
                                    "Your document uploaded successfully."
                                  );
                                  getUploadedDocumentList();
                                  setSubmitting(false);
                                  resetForm();
                                })
                                .catch((err) => {
                                  if (
                                    typeof err.response.data.message !==
                                    "undefined"
                                  ) {
                                    ErrorToast(
                                      err.response.data.message ||
                                      "Server Error!!"
                                    );
                                  } else {
                                    ErrorToast(
                                      err?.message || "Server Error!!"
                                    );
                                  }
                                  setSubmitting(false);
                                });
                              await fileUploadToBucket({
                                data: validVisaPostData,
                                file: values.validVisaImage,
                              })
                                .then(async (res) => {
                                  setfrontImageKey(Math.random());
                                  SuccessToast(
                                    res?.data?.message ||
                                    "Your document uploaded successfully."
                                  );
                                  getUploadedDocumentList();
                                  setSubmitting(false);
                                  resetForm();
                                })
                                .catch((err) => {
                                  if (
                                    typeof err.response.data.message !==
                                    "undefined"
                                  ) {
                                    ErrorToast(
                                      err.response.data.message ||
                                      "Server Error!!"
                                    );
                                  } else {
                                    ErrorToast(
                                      err?.message || "Server Error!!"
                                    );
                                  }
                                  setSubmitting(false);
                                });
                            }
                          })
                          .catch((err) => {
                            if (
                              typeof err.response.data.message !== "undefined"
                            ) {
                              ErrorToast(
                                err.response.data.message || "Server Error!!"
                              );
                            } else {
                              ErrorToast(err?.message || "Server Error!!");
                            }
                            setSubmitting(false);
                          });
                      }}
                    >
                      {({
                        errors,
                        touched,
                        setFieldValue,
                        isSubmitting,
                        values,
                      }) => {
                        return (
                          <Form>
                            <div className="form-row row">
                              <div className="col-md-6 mb-3">
                                <div className="form-group">
                                  <label>
                                    Select Document Type{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    onChange={(newVal) => {
                                      setFieldValue("ducumentType", newVal);
                                      setVat(newVal.value);
                                    }}
                                    value={values.ducumentType}
                                    options={documentType()}
                                    name="ducumentType"
                                    placeholder="Select document type"
                                  />
                                  <ErrorMessage
                                    name="ducumentType"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <div className="form-group">
                                  <label>
                                    Expiry Date{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <DatePicker
                                    autoComplete="off"
                                    className={`form-control ${errors.date && touched.date
                                        ? "input-error"
                                        : null
                                      }`}
                                    name="date"
                                    onChange={(date) =>
                                      setFieldValue("date", date)
                                    }
                                    selected={values.date}
                                    minDate={new Date()}
                                    placeholderText="mm-dd-yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                  />
                                  <ErrorMessage
                                    name="date"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-row row">
                              <div className="col-md-6 mb-3">
                                <div className="form-group dropzone-input-area">
                                  <label>
                                    Upload Front Side Image{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <DragAndDropFile
                                    fileTypes={[
                                      "image/*",
                                      ".pdf",
                                      ".docx",
                                      ".doc",
                                      ".pptx",
                                    ]}
                                    randerKey={debounceKey}
                                    maxFileSize={2000000} //2mb
                                    label="Drag and drop a image or click"
                                    className={`form-control ${
                                      errors.frontImage && touched.frontImage
                                        ? "input-error"
                                        : null
                                      }`}
                                    handleChange={(file) => {
                                      setFieldValue("frontImage", file[0]);
                                    }}
                                  />
                                  {(errors?.frontImage) && <span class="error">{errors?.frontImage}</span>}
                                  {/* <ErrorMessage
                                    name="frontImage"
                                    component="span"
                                    className="error"
                                  /> */}
                                </div>
                              </div>
                              {vat != "VAT_CERTIFICATE" ? (
                                <div className="col-md-6 mb-3">
                                  <div className="form-group dropzone-input-area">
                                    <label>
                                      Upload Back Side Image{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <DragAndDropFile
                                      fileTypes={[
                                        "image/*",
                                        ".pdf",
                                        ".docx",
                                        ".doc",
                                        ".pptx",
                                      ]}
                                      randerKey={debounceKey}
                                      maxFileSize={2000000} //2mb
                                      label="Drag and drop a image or click"
                                      className={`form-control ${
                                        errors.backImage && touched.backImage
                                          ? "input-error"
                                          : null
                                        }`}
                                      handleChange={(file) => {
                                        setFieldValue("backImage", file[0]);
                                      }}
                                    />
                                    {/* <ErrorMessage
                                      name="backImage"
                                      component="span"
                                      className="error"
                                    /> */}
                                    {(errors?.backImage) && <span class="error">{errors?.backImage}</span>}
                                  </div>
                                </div>
                              ) : (
                                <div></div>
                              )}{" "}
                              {vat === "PASSPORT" ? (
                                <div className="col-md-6 mb-3">
                                  <div className="form-group dropzone-input-area">
                                    <label>
                                      Upload Valid Visa Page Image{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <DragAndDropFile
                                      fileTypes={[
                                        "image/*",
                                        ".pdf",
                                        ".docx",
                                        ".doc",
                                        ".pptx",
                                      ]}
                                      randerKey={debounceKey}
                                      maxFileSize={2000000} //2mb
                                      label="Drag and drop a image or click"
                                      className={`form-control ${
                                        errors.validVisaImage && touched.validVisaImage
                                          ? "input-error"
                                          : null
                                        }`}
                                      handleChange={(file) => {
                                        setFieldValue("validVisaImage", file[0]);
                                      }}
                                    />
                                    {/* <ErrorMessage
                                      name="validVisa"
                                      component="span"
                                      className="error"
                                    /> */}
                                    {(errors?.validVisaImage) && <span class="error">{errors?.validVisaImage}</span>}
                                  </div>
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary btnstl"
                                disabled={isSubmitting}
                              >
                                Submit&nbsp;&nbsp;
                                {isSubmitting && (
                                  <i className="fas fa-spinner fa-spin"></i>
                                )}
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Legal Documents List</h5>
                    <p>Let's verify your self...</p>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive avtart-border">
                      <table className="table table-stripped table-hover">
                        <thead className="thead-light">
                          <tr>
                            <th>Document Type</th>
                            <th>Status</th>
                            {/* <th className="text-right">Action</th> */}
                            <th>Image</th>
                            <th>Image Side</th>
                            <th>Date</th>
                            <th>Expire Date</th>
                            <th>Note</th>
                          </tr>
                        </thead>
                        <tbody>
                          {documentList.map((document) => (
                            <tr
                              className={
                                checkExpiration(document.expiry_date)
                                  ? "red-tr"
                                  : ""
                              }
                              key={document.id}
                            >
                              <td>{document.file_module.replace("_", " ")}</td>
                              <td>
                                {(() => {
                                  if (checkExpiration(document.expiry_date)) {
                                    return (
                                      <span className="badge bg-warning-light">
                                        Expired
                                      </span>
                                    );
                                  }
                                  if (document.status === 0) {
                                    return (
                                      <span className="badge bg-primary-light">
                                        Pending
                                      </span>
                                    );
                                  }
                                  if (document.status === 1) {
                                    return (
                                      <span className="badge bg-success-light">
                                        Approved
                                      </span>
                                    );
                                  }
                                  if (document.status === 2) {
                                    return (
                                      <span className="badge bg-danger-light">
                                        Rejected
                                      </span>
                                    );
                                  }
                                })()}
                              </td>
                              {/* <td className="text-right">
                                {(document.status === 0 ||
                                  document.status === 2 ||
                                  checkExpiration(document.expiry_date)) && (
                                    <div className="dropdown dropdown-action">
                                      <Link
                                        to="#"
                                        className="action-icon dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="fas fa-ellipsis-h"></i>
                                      </Link>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        {document.status === 2 && (
                                          <Link
                                            className="dropdown-item"
                                            state={{
                                              documentid: document?._id,
                                              documentType: document?.file_module,
                                              expiryDate: document?.expiry_date,
                                              documentImg: document?.media_url,
                                              documentSides:
                                                document?.document_sides,
                                              userId: document?.user_id,
                                              documentMimeType:
                                                document?.mime_type,
                                            }}
                                            to="/client/edit-legal-documents"
                                          >
                                            <i className="far fa-edit me-2"></i>
                                            Edit
                                          </Link>
                                        )}
                                        <Link
                                          onClick={() =>
                                            deleteDocumentCustomer(document._id)
                                          }
                                          className="dropdown-item"
                                          to="#"
                                        >
                                          <i className="far fa-trash-alt me-2"></i>
                                          Delete
                                        </Link>
                                      </div>
                                    </div>
                                  )}
                              </td> */}
                              <td>
                                {document.mime_type.slice(0, 5) === "image" && (
                                  <Gallery>
                                    <Item
                                      className={
                                        "avatar avatar-sm me-2 avatar-img"
                                      }
                                      src={document.media_url}
                                      alt={document.id}
                                      width="1024"
                                      height="768"
                                    >
                                      {({ ref, open }) => (
                                        <img
                                          ref={ref}
                                          onClick={open}
                                          src={document.media_url}
                                          alt={document.id}
                                        />
                                      )}
                                    </Item>
                                  </Gallery>
                                )}
                                {document.mime_type === "application/pdf" && (
                                  <>
                                    <a
                                      href={document.media_url}
                                      target="_blank"
                                    >
                                      <img
                                        className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                        src="/inner-assets/img/pdf-icon.png"
                                        alt="pdf uplodaed"
                                      />
                                    </a>
                                  </>
                                )}
                                {document.mime_type ===
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                                    <>
                                      <a
                                        href={document.media_url}
                                        target="_blank"
                                      >
                                        <img
                                          to={document.media_url}
                                          target="_blank"
                                          className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                          src="/inner-assets/img/word-icon.png"
                                          alt={"wordfile uploded"}
                                        />
                                      </a>
                                    </>
                                  )}
                                {document.mime_type ===
                                  "application/vnd.openxmlformats-officedocument.presentationml.presentation" && (
                                    <a href={document.media_url} target="_blank">
                                      <img
                                        to={document.media_url}
                                        target="_blank"
                                        className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                        src="/inner-assets/img/ppt-icon.png"
                                        alt={"ppt uploded"}
                                      />
                                    </a>
                                  )}
                              </td>
                              <td>{document.document_sides}</td>
                              <td>
                                {format(
                                  new Date(document.createdAt),
                                  "dd MMMM, yyyy"
                                )}
                              </td>
                              <td>
                                {format(
                                  new Date(document.expiry_date),
                                  "dd MMMM, yyyy"
                                )}
                              </td>
                              <td>{document.reject_reason || "-"}</td>
                            </tr>
                          ))}

                          {documentList.length === 0 && (
                            <tr>
                              <td className="text-center" colSpan={8}>
                                No Records Found.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default LegalDocs;
