
import { Route, Routes,useLocation } from "react-router-dom";
import { MerchantLayout } from "../../Components/Layouts";
import { Booking, CancelBookingStatus, EditBookingStatus, EditBooking, BookingPaid, BookingUnPaid, BookingCanceled } from "../../Components/Merchant/Bookings";
import { Notification } from "../../Components/Merchant/Notifications/Index";
import { Profile } from "../../Components/Merchant/Profile/Index";
import { Settings, Faq, ChangePassword, Security, ContactUs, LegalDocs, TermsAndConditions, EditLegalDocs, AggrementDocument} from "../../Components/Merchant/Settings";
import { Dashboard } from "../../Components/Merchant/DashBoard";
import { Customer } from "../../Components/Merchant/Customers";
import { WalletCreditRequest, WalletTransaction } from "../../Components/Merchant/WalletReport";
import { User, AddUser, EditUser} from "../../Components/Merchant/User";
import { Role, AddRoleManagement, EditRolePermission } from "../../Components/Merchant/RoleManagement";
import { DevloperApi, Plugin, RestApi } from "../../Components/Merchant/DevloperSettings";
import { useEffect, useState } from "react";
import { ProfileCompletionProvider } from "../../contexts/ProfileCompletion";
import { WalletRequest, WalletDetails } from "../../Components/Merchant/WalletRequest";
import { BankingOptions, AddBankingOption, EditBankingOption} from "../../Components/Merchant/Banking";
import { Statement, TransactionDetails } from "../../Components/Merchant/Statement";
import { ComponentPrint } from "../../Components/Merchant/Common";
import { PermissionAlert } from "../../Components/Merchant/PermissionAlert";


const Logout = () => {
  useEffect(() => {
    document.cookie = "merchantCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/merchant;";
    localStorage.removeItem('merchantAccessToken');
    localStorage.removeItem('merchantUserData');
    localStorage.removeItem('merchantPermission');
     window.location.assign('/merchant/');
  }, []);
}

export default function MerchantRoutesPermission() {
    const location = useLocation()
    const route = location.pathname.slice(10)
    const [loading, setLoading] = useState(true);
    const permission = JSON.parse(localStorage.getItem('merchantPermission'));
    useEffect(() => {  
        setLoading(false);
    }, [permission]);
    
  const [primaryCurrency, setPrimaryCurrency] = useState([]);

  const merchantInformation = () => {
    const item = JSON.parse(localStorage.getItem('merchantUserData'));
    if (item) {
      setPrimaryCurrency(item?.primary_currency || '');
    }
  }
 
  useEffect(() => {
    merchantInformation();
  }, []);

  return (
    <ProfileCompletionProvider>
      <MerchantLayout />
      {!loading &&
      <Routes>
        <Route exact path="/" element={<Dashboard/>} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/notifications" element={<Notification />} />
        <Route exact path="/profile" element={<Profile />} />
       
        {permission?.indexOf("bookings")!==-1 && route==="booking" ?
            <Route exact path="/booking" element={<Booking primaryCurrency={primaryCurrency} />} />:
            <Route exact path="/booking" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("bookings")!==-1 && route==="editbookingstatus" ?
            <Route exact path="/editbookingstatus" element={<EditBookingStatus primaryCurrency={primaryCurrency} />} />:
            <Route exact path="/editbookingstatus" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("bookings")!==-1 && route==="cancelbookingstatus" ?
            <Route exact path="/cancelbookingstatus" element={<CancelBookingStatus primaryCurrency={primaryCurrency} />} />:
            <Route exact path="/cancelbookingstatus" element={<PermissionAlert/>} /> 
        }
         {permission?.indexOf("bookings")!==-1 && route==="edit-booking" ?
            <Route exact path="/edit-booking" element={<EditBooking />} />:
            <Route exact path="/edit-booking" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("bookings")!==-1 && route==="view-booking" ?
            <Route exact path="/view-booking" element={<ComponentPrint />} />:
            <Route exact path="/view-booking" element={<PermissionAlert/>} /> 
        }
         {permission?.indexOf("bookings")!==-1 && route==="booking-paid" ?
            <Route exact path="/booking-paid" element={<BookingPaid primaryCurrency={primaryCurrency} />} />:
            <Route exact path="/booking-paid" element={<PermissionAlert/>} /> 
         }
         {permission?.indexOf("bookings")!==-1 && route==="booking-unpaid" ?
            <Route exact path="/booking-unpaid" element={<BookingUnPaid primaryCurrency={primaryCurrency} />} />:
            <Route exact path="/booking-unpaid" element={<PermissionAlert/>} /> 
         }
        {permission?.indexOf("bookings")!==-1 && route==="booking-canceled" ?
            <Route exact path="/booking-canceled" element={<BookingCanceled primaryCurrency={primaryCurrency} />} />:
            <Route exact path="/booking-canceled" element={<PermissionAlert/>} /> 
         }

        {permission?.indexOf("manage_documents")!==-1 && route==="legal-documents" ?
            <Route exact path="/legal-documents" element={<LegalDocs />} />:
            <Route exact path="/legal-documents" element={<PermissionAlert/>} /> 
         }

        {permission?.indexOf("manage_documents")!==-1 && route==="edit-legal-documents" ?
            <Route exact path="/edit-legal-documents" element={<EditLegalDocs />} />:
            <Route exact path="/edit-legal-documents" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("manage_aggrements")!==-1 && route==="agreement-documents" ?
            <Route exact path="/agreement-documents" element={<AggrementDocument />} />:
            <Route exact path="/agreement-documents" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("view_customers")!==-1 && route==="customers" ?
            <Route exact path="/customers" element={<Customer primaryCurrency={primaryCurrency} />} />:
            <Route exact path="/customers" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("wallet_transactions")!==-1 && route==="wallet-transaction" ?
            <Route exact path="/wallet-transaction" element={<WalletTransaction />} />:
            <Route exact path="/wallet-transaction" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("wallet_request")!==-1 && route==="wallet-request" ?
            <Route exact path="/wallet-request" element={<WalletRequest />} />:
            <Route exact path="/wallet-request" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("wallet_request")!==-1 && route==="wallet-request-details" ?
         <Route exact path="/wallet-request-details" element={<WalletDetails />} />:
         <Route exact path="/wallet-request-details" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("credit_request")!==-1 && route==="wallet-credit-request" ?
            <Route exact path="/wallet-credit-request" element={<WalletCreditRequest/>} />:
            <Route exact path="/wallet-credit-request" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("role_management")!==-1 && route==="role-management" ?
            <Route exact path="/role-management" element={<Role />} />:
            <Route exact path="/role-management" element={<PermissionAlert/>} /> 
        }
        
        {permission?.indexOf("role_management")!==-1 && route==="add-role-management" ?
            <Route exact path="/add-role-management" element={<AddRoleManagement />} />:
            <Route exact path="/add-role-management" element={<PermissionAlert/>} /> 
        }
        
        {permission?.indexOf("role_management")!==-1 && route==="edit-role-permission" ?
            <Route exact path="/edit-role-permission" element={<EditRolePermission />} />:
            <Route exact path="/edit-role-permission" element={<PermissionAlert/>} /> 
        }

       {permission?.indexOf("user_management")!==-1 && route==="user" ?
            <Route exact path="/user" element={<User />} />:
            <Route exact path="/user" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("user_management")!==-1 && route==="add-user" ?
            <Route exact path="/add-user" element={<AddUser />} />:
            <Route exact path="/add-user" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("user_management")!==-1 && route==="edit-user" ?
            <Route exact path="/edit-user" element={<EditUser />} />:
            <Route exact path="/edit-user" element={<PermissionAlert/>} /> 
        }
        {permission?.indexOf("developer_settings")!==-1 && route==="developer-api" ?
            <Route exact path="/developer-api" element={<DevloperApi />} />:
            <Route exact path="/developer-api" element={<PermissionAlert/>} /> 
        }
        {permission?.indexOf("developer_settings")!==-1 && route==="plugin" ?
            <Route exact path="/plugin" element={<Plugin />} />:
            <Route exact path="/plugin" element={<PermissionAlert/>} /> 
        }
        {permission?.indexOf("developer_settings")!==-1 && route==="rest-api" ?
            <Route exact path="/rest-api" element={<RestApi />} />:
            <Route exact path="/rest-api" element={<PermissionAlert/>} /> 
        }
       
       {permission?.indexOf("banking_options")!==-1 && route==="banking-options" ?
            <Route exact path="/banking-options" element={<BankingOptions />} />:
            <Route exact path="/banking-options" element={<PermissionAlert/>} /> 
       }
       {permission?.indexOf("banking_options")!==-1 && route==="add-banking-options" ?
            <Route exact path="/add-banking-options" element={<AddBankingOption />} />:
            <Route exact path="/add-banking-options" element={<PermissionAlert/>} /> 
       }
       {permission?.indexOf("banking_options")!==-1 && route==="edit-banking-options" ?
        <Route exact path="/edit-banking-options" element={<EditBankingOption />} />:
        <Route exact path="/edit-banking-options" element={<PermissionAlert/>} /> 
       }

        {permission?.indexOf("statement")!==-1 && route==="statement" ?
            <Route exact path="/statement" element={<Statement />} />:
            <Route exact path="/statement" element={<PermissionAlert/>} /> 
        }
        {permission?.indexOf("statement")!==-1 && route==="transaction-details" ?
            <Route exact path="/transaction-details" element={<TransactionDetails />} />:
            <Route exact path="/transaction-details" element={<PermissionAlert/>} /> 
        }
       
        <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route exact path="/change-password" element={<ChangePassword />} />
        <Route exact path="/security" element={<Security />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/settings" element={<Settings />} />
       
        <Route exact path="/logout" element={<Logout />} />
      </Routes>
    }
    </ProfileCompletionProvider>
  );
}
