import Api from "../../../Utils/ClientAxios";

export const getUnreadNotificationCount = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/notification/unread_count`));
    } catch (error) {
      reject(error);
    }
  })
}

export const getUnreadNotification = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/notification/unread?limit=10`));
    } catch (error) {
      reject(error);
    }
  })
}

export const getAllNotification = async (id = '') => {
  return new Promise(async (resolve, reject) => {
    try {
      let notificationId = '';
      if (id !== '') {
        notificationId = `?notification_id=${id}`
      }
      resolve(await Api.get(`/master/notification/all${notificationId}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const clearAllNotificationListSearvice = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/notification/clear_all`));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetNewsFlashService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get("/master/news_flash"));
    } catch (error) {
      reject(error);
    }
  })
}