import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useState } from "react";
import { MerchanttGetWalletBalanceService } from "../../../Services/Api/Merchant/merchantAuth";
// import { ErrorToast } from "../../../Utils/SweetAlert";
// import { Pagination } from '../../../Utils';
import { GiBookPile } from "react-icons/gi";
import {
  GetBookingAnalytics,
  GetBookingListService,
} from "../../../Services/Api/Merchant/booking";
import format from "date-fns/format";
import "chartjs-adapter-date-fns";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  TimeScale,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { onImageError } from "../../../Utils/CommanFunctions";

const DashBoard = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(15);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [bookingListPagination, setBookingListPagination] = useState([]);
  const [walletData, setWalletData] = useState([]);
  // const [primaryCurrency,setPrimaryCurrency] = useState("")

  ChartJS.register(
    TimeScale,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const [options, setOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
        text: "Booking Analytics",
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "week",
        },
      },
      y: {
        title: {
          display: true,
          text: "AED",
        },
      },
    },
  });
  const [chartData, setChartData] = useState({});
  const [analyticsPeriod, setAnalyticsPeriod] = useState("week");

  const getWalletBalanceData = async () => {
    MerchanttGetWalletBalanceService()
      .then((res) => {
        setWalletData(res?.data?.data?.widget_counts || {});
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };
  const getBookingList = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetBookingListService({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setBookingListPagination(res?.data?.data?.bookings);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setBookingListPagination([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setBookingListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //     ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //     ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  // const handlePageChange = async (e, pageNew) => {
  //   setPage(pageNew);
  //   await getBookingList(limit, pageNew);
  // };
  // const handleLimitChange = async (event) => {
  //   setLimit(parseInt(event.target.value, 10));
  //   setPage(0);
  //   await getBookingList(parseInt(event.target.value, 10), 0);
  // };
  const getBookingAnalytics = async (primaryCurrency) => {
    await GetBookingAnalytics(analyticsPeriod)
      .then((res) => {
        setOptions((prevOptions) => {
          return {
            ...prevOptions,
            scales: {
              ...prevOptions.scales,
              x: {
                ...prevOptions.scales.x,
                time: {
                  ...prevOptions.scales.x.time,
                  unit: analyticsPeriod,
                },
              },
              y: {
                ...prevOptions.scales.y,
                title: {
                  ...prevOptions.scales.y.title,
                  text: primaryCurrency,
                },
              },
            },
          };
        });

        setChartData({
          datasets: [
            {
              label: "Paid",
              data: res?.data?.data?.successBooking,
              backgroundColor: "rgba(0, 128, 0, 0.8)",
            },
            {
              label: "Failed",
              data: res?.data?.data?.failedBooking,
              backgroundColor: "rgba(255, 0, 0, 0.8)",
            },
            {
              label: "Cancelled",
              data: res?.data?.data?.cancelledBooking,
              backgroundColor: "rgba(253, 166, 0, 0.8)",
            },
          ],
        });
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  useEffect(() => {
    const item = JSON.parse(localStorage.getItem("merchantUserData"));
    getBookingAnalytics(item?.primary_currency);
  }, [analyticsPeriod]);

  useEffect(() => {
    getBookingList(limit, page);
  }, []);

  useEffect(() => {
    getWalletBalanceData();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Merchant Dashboard</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card">
                <div className="card-body ">
                  <div className="dash-widget-header">
                    <span className="dash-widget-icon bg-3">
                      <i className="fas fa-file-alt" />
                    </span>
                    <div className="dash-count">
                      <div className="dash-title">CPay Overall Balance</div>
                      <div className="dash-counts">
                        <p>
                          <span className="currency-type-text">
                            {" "}
                            {walletData?.wallet?.currency || ""}{" "}
                          </span>{" "}
                          {walletData?.wallet?.total_amount || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="dash-widget-header">
                    <span className="dash-widget-icon bg-1">
                      <i className="fas fa-money-bill" />
                    </span>
                    <div className="dash-count">
                      <div className="dash-title">
                        Total CPay Credit / Balance
                      </div>
                      <div className="dash-counts">
                        <p>
                          <span className="currency-type-text">
                            {" "}
                            {walletData?.wallet?.currency || ""}{" "}
                          </span>
                          {walletData?.wallet?.cpay_credit || "0"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="dash-widget-header">
                    <span className="dash-widget-icon bg-pending-credit">
                      <i className="fas fa-money-bill" />
                    </span>
                    <div className="dash-count">
                      <div className="dash-title">Cash Display</div>
                      <div className="dash-counts">
                        <p>
                          <span className="currency-type-text">
                            {" "}
                            {walletData?.wallet?.currency || ""}{" "}
                          </span>{" "}
                          {walletData?.wallet?.amount || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="dash-widget-header">
                    <span className="dash-widget-icon bg-2">
                      <img
                        src="../inner-assets/img/turnover.svg"
                        alt="turnover-icon"
                        className="svg-width-tover"
                      />
                    </span>
                    <div className="dash-count">
                      <div className="dash-title">
                        <Link to="/merchant/statement">Total Turn Over</Link>
                      </div>
                      <div className="dash-counts">
                        <p>
                          <span className="currency-type-text">
                            {" "}
                            {walletData?.wallet?.currency || ""}{" "}
                          </span>{" "}
                          {walletData?.total_turn_over}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="dash-widget-header">
                    <span className="dash-widget-icon bg-balance">
                      <GiBookPile />
                    </span>
                    <div className="dash-count">
                      <div className="dash-title">
                        <Link to="/merchant/wallet-credit-request">
                          Pending Credit Request
                        </Link>
                      </div>
                      <div className="dash-counts">
                        <p
                          className={`btn btn-default ${
                            walletData?.pending_credit_requests?.count > 0
                              ? "ripple"
                              : ""
                          }`}
                        >
                          {walletData?.pending_credit_requests?.count}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="dash-widget-header">
                    <span className="dash-widget-icon bg-pending-wallet">
                      <GiBookPile />
                    </span>
                    <div className="dash-count">
                      <div className="dash-title">
                        <Link to="/merchant/wallet-request">
                          Pending Wallet Request
                        </Link>
                      </div>
                      <div className="dash-counts">
                        <p
                          className={`btn btn-default ${
                            walletData?.pending_wallet_requests?.count > 0
                              ? "ripple"
                              : " "
                          }`}
                        >
                          {walletData?.pending_wallet_requests?.count}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-4 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <span className="dash-widget-icon bg-2">
                    <i className="fas fa-users" />
                  </span>
                  <div className="dash-count">
                    <div className="dash-title">CPay Transcation Fee</div>
                    <div className="dash-counts">
                        <p>{walletData?.currency || ''} 4,000</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <span className="dash-widget-icon bg-3">
                    <i className="fas fa-file-alt" />
                  </span>
                  <div className="dash-count">
                    <div className="dash-title">Pending Credit</div>
                    <div className="dash-counts">
                      <p>{walletData?.currency || ''} 14,000</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row">
            <div className="col-xl-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="card-title">Booking Analytics</h5>
                    <div className="dropdown">
                      <select
                        name="period"
                        onChange={(e) => setAnalyticsPeriod(e.target.value)}
                      >
                        <option value="week">Weekly</option>
                        <option value="month">Monthly</option>
                        <option value="year">Yearly</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {Object.keys(chartData).length === 0 ? (
                    <span>Loading...</span>
                  ) : (
                    <Bar options={options} data={chartData} />
                  )}
                  {/* <div id="sales_chart" /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title">Recent Bookings</h5>
                    </div>
                    <div className="col-auto">
                      <Link
                        to="/merchant/booking"
                        className="btn-right btn btn-sm btn-outline-primary"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card card-table">
                  <div className="card-body"></div>
                  <div className="table-responsive custom-pagination">
                    <table className="table table-stripped table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th className="px-4">#</th>
                          <th>Reference Number</th>
                          <th>Pax Details</th>
                          <th>Customer</th>
                          <th>Amount</th>
                          <th>Merchant Amount</th>
                          <th>CPay Transcation Fee</th>
                          <th>Status</th>
                          <th>Update Status</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {bookingListPagination.map((booking, index) => (
                              <tr key={index}>
                                <td className="px-4">{index + 1}</td>
                                <td>
                                  {booking?.booking_reference}
                                  <br />
                                  <span>
                                    {" "}
                                    {format(
                                      new Date(booking?.createdAt),
                                      "dd MMMM, yyyy"
                                    )}
                                  </span>
                                </td>
                                <td>
                                  {booking?.product === "flight" && (
                                    <>
                                      {booking?.metadata?.travellers.map(
                                        (traveller, index) => (
                                          <div key={index}>
                                            {index + 1}. {traveller?.title}{" "}
                                            {traveller?.first_name}{" "}
                                            {traveller?.last_name}{" "}
                                            <span>
                                              ({traveller?.passenger_type})
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                  {booking?.product === "hotel" && (
                                    <>
                                      {booking?.metadata?.travellers.map(
                                        (traveller, index) => (
                                          <div key={index}>
                                            {index + 1}. {traveller?.title}{" "}
                                            {traveller?.first_name}{" "}
                                            {traveller?.last_name}{" "}
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                  {booking?.product === "visa" && (
                                    <div>
                                      {booking?.metadata?.visa?.first_name}{" "}
                                      {booking?.metadata?.visa?.last_name}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <h2 className="table-avatar">
                                    <img
                                      className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                      src={booking?.customer?.profile_picture || ""}
                                      alt="User"
                                      onError={onImageError}
                                    />
                                    {booking?.customer?.name}
                                  </h2>
                                </td>
                                <td className="text-primary">
                                  <span className="currency-type-text">
                                    {" "}
                                    {walletData?.wallet?.currency || ""}{" "}
                                  </span>
                                  {booking?.amount || "-"}
                                </td>
                                <td className="text-primary">
                                  <span className="currency-type-text">
                                    {walletData?.wallet?.currency || ""}{" "}
                                  </span>
                                  {booking?.merchant_payment_amount}
                                </td>
                                <td className="text-primary">
                                  <span className="currency-type-text">
                                    {walletData?.wallet?.currency || ""}{" "}
                                  </span>
                                  {booking?.merchant_service_charge}
                                </td>
                                <td>
                                  {booking?.status === 0 && (
                                    <span className="badge bg-warning-light">
                                      Pending
                                    </span>
                                  )}
                                  {booking?.status === 1 && (
                                    <span className="badge bg-success-light">
                                      Success
                                    </span>
                                  )}
                                  {booking?.status === 2 && (
                                    <span className="badge bg-danger-light">
                                      Failed
                                    </span>
                                  )}
                                  {booking?.status === 3 && (
                                    <span className="badge bg-danger-light">
                                      Cancelled
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {booking?.update_status === 0 && (
                                    <span>-</span>
                                  )}
                                  {booking?.update_status === 1 && (
                                    <span>Pending</span>
                                  )}
                                  {booking?.update_status === 2 && (
                                    <span>Approved</span>
                                  )}
                                  {booking?.update_status === 3 && (
                                    <span>Rejected</span>
                                  )}
                                </td>
                                {/* <td className="text-right">
                                <div className="dropdown dropdown-action">
                                  <Link
                                    to="#"
                                    className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis-h" />
                                  </Link>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <Link className="dropdown-item" to="#">
                                      <i className="far fa-edit me-2" />
                                      Edit
                                    </Link>
                                  </div>
                                </div>
                              </td> */}
                                {/* <td><span className="currency-type-text"> {walletData?.currencygit || ''} </span> 118</td>
                                <td>23 Nov 2020</td>
                                <td>
                                  <span className="badge bg-success-light">Paid</span>
                                </td>
                                <td className="text-right">
                                  <div className="dropdown dropdown-action">
                                    <Link
                                      to="#"
                                      className="action-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis-h" />
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <Link className="dropdown-item" to="#">
                                        <i className="far fa-edit me-2" />
                                        Edit
                                      </Link>
                                      <Link className="dropdown-item" to="#">
                                        <i className="far fa-eye me-2" />
                                        View
                                      </Link>
                                      <Link className="dropdown-item" to="#">
                                        <i className="far fa-trash-alt me-2" />
                                        Delete
                                      </Link>
                                    </div>
                                  </div>
                              </td> */}
                              </tr>
                            ))}
                            {bookingListPagination.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={8}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}

                        {/* <Pagination
                            totalItem={totalRecords}
                            itemsPerPage={limit}
                            page={page}
                            handleChangePage={handlePageChange}
                            handleChangeRowsPerPage={handleLimitChange}
                            colSpan={8}
                        /> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoard;
