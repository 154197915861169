import ClientHeader from "./ClientHeader";
import ClientSideBar from "./ClientSideBar";
import { useEffect, useRef, useState } from "react";
import ClientNewsFlash from "./ClientNewsFlash";
import moment from "moment";
import { listenCookieChangeClient } from "../../../Hooks/CalculatePenalty";

export default function ClientLayout(params) {
  const [openSidebar, setOpenSidebar] = useState(true);
  const sidebarRef = useRef(null); // used to get sidebar width
  const [isMobile, setMobile] = useState(document.body.clientWidth < 991);
  const [sidebarStatus, setSidebarStatus] = useState("init");
  const [showNews, setShowNews] = useState(false);

  useEffect(() => {
    // add listener only once, or many listeners would be created every render
    const mq = window.matchMedia("(max-width: 991px)");
    mq.addListener((res) => {
      setMobile(res.matches);
    });
    return () => mq.removeListener(toggleSidebar);
  }, []);

  const showSidebar =
    sidebarStatus === "open" || (!isMobile && sidebarStatus === "init");

  const toggleSidebar = (open) => {
    setSidebarStatus(open ? "open" : "close");
  };

  const toggleNews = () => {
    setShowNews(false);
    document.cookie = `clientCookie=${moment()}`;
  }
  useEffect(() => {
    listenCookieChangeClient(({ difference }) => {
      setShowNews(difference);
    }, 1000);
  }, []);


  return (
    <>
      <div className="header header-one">
        {showNews && <ClientNewsFlash showNews={showNews} toggleNews={toggleNews} />}
        <ClientHeader onClick={toggleSidebar} showSideBar={showSidebar}  class="bg-info-subtle" />
      </div>
      <div className="sidebar" id="sidebar">
        {showSidebar && <ClientSideBar setOpenSidebar={openSidebar} handleonClick={toggleSidebar} showSideBar={showSidebar} />}
      </div>
    </>
  );
}
