import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";

const BookingCanceled = ({ primaryCurrency }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <Helmet>
        <title>CPay | Merchant Booking Canceled</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Bookings</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/merchant/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Bookings</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card report-card">
            <div className="card-body pb-0">
              <div className="row">
                <div className="col-md-12">
                  <ul className="app-listing">
                    <li>
                      <div className="multipleSelection">
                        <div className="selectBox">
                          <p className="mb-0">
                            <i
                              data-feather="user-plus"
                              className="me-1 select-icon"
                            />{" "}
                            Select User
                          </p>
                          <span className="down-icon">
                            <i data-feather="chevron-down" />
                          </span>
                        </div>
                        <div id="checkBoxes">
                          <form action="#">
                            <p className="checkbox-title">Customer Search</p>
                            <div className="form-custom">
                              <input
                                type="text"
                                className="form-control bg-grey"
                                placeholder="Enter Customer Name"
                              />
                            </div>
                            <div className="selectBox-cont">
                              <label className="custom_check w-100">
                                <input type="checkbox" name="username" />
                                <span className="checkmark" /> Brian Johnson
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="username" />
                                <span className="checkmark" /> Russell Copeland
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="username" />
                                <span className="checkmark" /> Greg Lynch
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="username" />
                                <span className="checkmark" /> John Blair
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="username" />
                                <span className="checkmark" /> Barbara Moore
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="username" />
                                <span className="checkmark" /> Hendry Evan
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="username" />
                                <span className="checkmark" /> Richard Miles
                              </label>
                            </div>
                            <button
                              type="submit"
                              className="btn w-100 btn-primary"
                            >
                              Apply
                            </button>
                            <button type="reset" className="btn w-100 btn-grey">
                              Reset
                            </button>
                          </form>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="multipleSelection">
                        <div className="selectBox">
                          <p className="mb-0">
                            <i
                              data-feather="calendar"
                              className="me-1 select-icon"
                            />{" "}
                            Select Date
                          </p>
                          <span className="down-icon">
                            <i data-feather="chevron-down" />
                          </span>
                        </div>
                        <div id="checkBoxes">
                          <form action="#">
                            <p className="checkbox-title">Date Filter</p>
                            <div className="selectBox-cont selectBox-cont-one h-auto">
                              <div className="date-picker">
                                <div className="form-custom cal-icon">
                                  <input
                                    className="form-control datetimepicker"
                                    type="text"
                                    placeholder="Form"
                                  />
                                </div>
                              </div>
                              <div className="date-picker pe-0">
                                <div className="form-custom cal-icon">
                                  <input
                                    className="form-control datetimepicker"
                                    type="text"
                                    placeholder="To"
                                  />
                                </div>
                              </div>
                              <div className="date-list">
                                <ul>
                                  <li>
                                    <Link to="#" className="btn date-btn">
                                      Today
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="btn date-btn">
                                      Yesterday
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="btn date-btn">
                                      Last 7 days
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="btn date-btn">
                                      This month
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="btn date-btn">
                                      Last month
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="multipleSelection">
                        <div className="selectBox">
                          <p className="mb-0">
                            <i
                              data-feather="book-open"
                              className="me-1 select-icon"
                            />{" "}
                            Select Status
                          </p>
                          <span className="down-icon">
                            <i data-feather="chevron-down" />
                          </span>
                        </div>
                        <div id="checkBoxes">
                          <form action="#">
                            <p className="checkbox-title">By Status</p>
                            <div className="selectBox-cont">
                              <label className="custom_check w-100">
                                <input type="checkbox" name="name" />
                                <span className="checkmark" /> All Bookings
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="name" />
                                <span className="checkmark" /> Paid
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="name" />
                                <span className="checkmark" /> Overdue
                              </label>
                              <label className="custom_check w-100">
                                <input
                                  type="checkbox"
                                  name="name"
                                  defaultChecked
                                />
                                <span className="checkmark" /> Cancelled
                              </label>
                            </div>
                            <button
                              type="submit"
                              className="btn w-100 btn-primary"
                            >
                              Apply
                            </button>
                            <button type="reset" className="btn w-100 btn-grey">
                              Reset
                            </button>
                          </form>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="multipleSelection">
                        <div className="selectBox">
                          <p className="mb-0">
                            <i
                              data-feather="bookmark"
                              className="me-1 select-icon"
                            />{" "}
                            By Category
                          </p>
                          <span className="down-icon">
                            <i data-feather="chevron-down" />
                          </span>
                        </div>
                        <div id="checkBoxes">
                          <form action="#">
                            <p className="checkbox-title">Category</p>
                            <div className="form-custom">
                              <input
                                type="text"
                                className="form-control bg-grey"
                                placeholder="Enter Category Name"
                              />
                            </div>
                            <div className="selectBox-cont">
                              <label className="custom_check w-100">
                                <input type="checkbox" name="category" />
                                <span className="checkmark" /> Advertising
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="category" />
                                <span className="checkmark" /> Food
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="category" />
                                <span className="checkmark" /> Marketing
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="category" />
                                <span className="checkmark" /> Repairs
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="category" />
                                <span className="checkmark" /> Software
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="category" />
                                <span className="checkmark" /> Stationary
                              </label>
                              <label className="custom_check w-100">
                                <input type="checkbox" name="category" />
                                <span className="checkmark" /> Travel
                              </label>
                            </div>
                            <button
                              type="submit"
                              className="btn w-100 btn-primary"
                            >
                              Apply
                            </button>
                            <button type="reset" className="btn w-100 btn-grey">
                              Reset
                            </button>
                          </form>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="report-btn">
                        <Link to="#" className="btn">
                          <img
                            src="/inner-assets/img/icons/invoices-icon5.svg"
                            alt="booking"
                            className="me-2"
                          />{" "}
                          Generate report
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="card invoices-tabs-card">
            <div className="card-body card-body pt-0 pb-0">
              <div className="invoices-main-tabs">
                <div className="row align-items-center">
                  <div className="col-lg-8 col-md-8">
                    <div className="invoices-tabs">
                      <ul>
                        <li>
                          <Link to="/merchant/booking">All Bookings</Link>
                        </li>
                        <li>
                          <Link to="/merchant/booking-paid">Paid</Link>
                        </li>
                        <li>
                          <Link to="/merchant/booking-unpaid">UnPaid</Link>
                        </li>
                        <li>
                          <Link
                            to="/merchant/booking-canceled"
                            className="active"
                          >
                            Cancelled
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="/inner-assets/img/icons/invoices-icon1.svg"
                        alt="booking"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">
                          {primaryCurrency || ""}
                        </span>{" "}
                        8,78,797
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    All Bookings <span>50</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="/inner-assets/img/icons/invoices-icon2.svg"
                        alt="booking"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">
                          {primaryCurrency || ""}
                        </span>{" "}
                        4,5884
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Paid Bookings <span>60</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="/inner-assets/img/icons/invoices-icon3.svg"
                        alt="booking"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">
                          {primaryCurrency || ""}
                        </span>{" "}
                        2,05,545
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Unpaid Bookings <span>70</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="/inner-assets/img/icons/invoices-icon4.svg"
                        alt="booking"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">
                          {primaryCurrency || ""}
                        </span>{" "}
                        8,8,797
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Cancelled Bookings <span>80</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive  custom-pagination">
                    <table className="table table-stripped table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Booking ID</th>
                          <th>Category</th>
                          <th>Created on</th>
                          <th>Customer</th>
                          <th>Amount</th>
                          <th>Due date</th>
                          <th>Status</th>
                          <th className="text-end">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <label className="custom_check">
                              <input type="checkbox" name="Booking" />
                              <span className="checkmark" />
                            </label>
                            <Link
                              to="/merchant/view-booking"
                              className="invoice-link"
                            >
                              IN093439#@09
                            </Link>
                          </td>
                          <td>Advertising</td>
                          <td>16 Mar 2022</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link to="/merchant/profile">
                                <img
                                  className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                  src="/inner-assets/img/profiles/avatar-04.jpg"
                                  alt="User"
                                />{" "}
                                Barbara Moore
                              </Link>
                            </h2>
                          </td>
                          <td className="text-primary">
                            <span className="currency-type-text">
                              {primaryCurrency || ""}
                            </span>{" "}
                            1,54,220
                          </td>
                          <td>23 Mar 2022</td>
                          <td>
                            <span className="badge bg-primary-light">
                              Cancelled
                            </span>
                          </td>
                          <td className="text-end">
                            <div className="dropdown dropdown-action">
                              <Link
                                to="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <Link className="dropdown-item" to="#">
                                  <i className="far fa-edit me-2" />
                                  Edit
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/merchant/view-booking"
                                >
                                  <i className="far fa-eye me-2" />
                                  View
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  <i className="far fa-trash-alt me-2" />
                                  Delete
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="custom_check">
                              <input type="checkbox" name="Booking" />
                              <span className="checkmark" />
                            </label>
                            <Link
                              to="/merchant/view-booking"
                              className="invoice-link"
                            >
                              IN093439#@10
                            </Link>
                          </td>
                          <td>Food</td>
                          <td>14 Mar 2022</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link to="/merchant/profile">
                                <img
                                  className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                  src="/inner-assets/img/profiles/avatar-06.jpg"
                                  alt="User"
                                />{" "}
                                Karlene Chaidez
                              </Link>
                            </h2>
                          </td>
                          <td className="text-primary">
                            <span className="currency-type-text">
                              {primaryCurrency || ""}
                            </span>{" "}
                            1,222
                          </td>
                          <td>18 Mar 2022</td>
                          <td>
                            <span className="badge bg-primary-light">
                              Cancelled
                            </span>
                          </td>
                          <td className="text-end">
                            <div className="dropdown dropdown-action">
                              <Link
                                to="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <Link className="dropdown-item" to="#">
                                  <i className="far fa-edit me-2" />
                                  Edit
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/merchant/view-booking"
                                >
                                  <i className="far fa-eye me-2" />
                                  View
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  <i className="far fa-trash-alt me-2" />
                                  Delete
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="custom_check">
                              <input type="checkbox" name="Booking" />
                              <span className="checkmark" />
                            </label>
                            <Link
                              to="/merchant/view-booking"
                              className="invoice-link"
                            >
                              IN093439#@11
                            </Link>
                          </td>
                          <td>Marketing</td>
                          <td>7 Mar 2022</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link to="/merchant/profile">
                                <img
                                  className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                  src="/inner-assets/img/profiles/avatar-08.jpg"
                                  alt="User"
                                />{" "}
                                Russell Copeland
                              </Link>
                            </h2>
                          </td>
                          <td className="text-primary">
                            <span className="currency-type-text">
                              {primaryCurrency || ""}
                            </span>{" "}
                            3,470
                          </td>
                          <td>10 Mar 2022</td>
                          <td>
                            <span className="badge bg-primary-light">
                              Cancelled
                            </span>
                          </td>
                          <td className="text-end">
                            <div className="dropdown dropdown-action">
                              <Link
                                to="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <Link className="dropdown-item" to="#">
                                  <i className="far fa-edit me-2" />
                                  Edit
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/merchant/view-booking"
                                >
                                  <i className="far fa-eye me-2" />
                                  View
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  <i className="far fa-trash-alt me-2" />
                                  Delete
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="custom_check">
                              <input type="checkbox" name="Booking" />
                              <span className="checkmark" />
                            </label>
                            <Link
                              to="/merchant/view-booking"
                              className="invoice-link"
                            >
                              IN093439#@12
                            </Link>
                          </td>
                          <td>Repairs</td>
                          <td>24 Mar 2022</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link to="/merchant/profile">
                                <img
                                  className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                  src="/inner-assets/img/profiles/avatar-10.jpg"
                                  alt="User"
                                />{" "}
                                Joseph Collins
                              </Link>
                            </h2>
                          </td>
                          <td className="text-primary">
                            <span className="currency-type-text">
                              {primaryCurrency || ""}
                            </span>{" "}
                            8,265
                          </td>
                          <td>30 Mar 2022</td>
                          <td>
                            <span className="badge bg-primary-light">
                              Cancelled
                            </span>
                          </td>
                          <td className="text-end">
                            <div className="dropdown dropdown-action">
                              <Link
                                to="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <Link className="dropdown-item" to="#">
                                  <i className="far fa-edit me-2" />
                                  Edit
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/merchant/view-booking"
                                >
                                  <i className="far fa-eye me-2" />
                                  View
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  <i className="far fa-trash-alt me-2" />
                                  Delete
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="custom_check">
                              <input type="checkbox" name="Booking" />
                              <span className="checkmark" />
                            </label>
                            <Link
                              to="/merchant/view-booking"
                              className="invoice-link"
                            >
                              IN093439#@13
                            </Link>
                          </td>
                          <td>Software</td>
                          <td>17 Mar 2022</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link to="/merchant/profile">
                                <img
                                  className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                  src="/inner-assets/img/profiles/avatar-11.jpg"
                                  alt="User"
                                />{" "}
                                Jennifer Floyd
                              </Link>
                            </h2>
                          </td>
                          <td className="text-primary">
                            <span className="currency-type-text">
                              {primaryCurrency || ""}
                            </span>{" "}
                            5,200
                          </td>
                          <td>20 Mar 2022</td>
                          <td>
                            <span className="badge bg-primary-light">
                              Cancelled
                            </span>
                          </td>
                          <td className="text-end">
                            <div className="dropdown dropdown-action">
                              <Link
                                to="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <Link className="dropdown-item" to="#">
                                  <i className="far fa-edit me-2" />
                                  Edit
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/merchant/view-booking"
                                >
                                  <i className="far fa-eye me-2" />
                                  View
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  <i className="far fa-trash-alt me-2" />
                                  Delete
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <Pagination
                          colSpan={9}
                          itemsPerPage={rowsPerPage}
                          page={page}
                          totalItem={50}
                          handleChangePage={handleChangePage}
                          handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingCanceled;
