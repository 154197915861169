import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { Pagination } from '../../../Utils';
import { Helmet } from "react-helmet-async";
import {
  ClientGetWalletBalanceService,
  GetClientProfileById,
} from "../../../Services/Api/Client/customerAuth";
// import { ErrorToast } from "../../../Utils/SweetAlert";
import format from "date-fns/format";
import { StartCase } from "react-lodash";
import _ from "lodash";
import { ClientProfileCompletion } from "../../../contexts/ClientProfileCompletion";
// import { DailyPenalty, WeeklyPenalty, FortNightPenalty } from '../../../Hooks/CalculatePenalty';
import { GetDepositOptionService } from "../../../Services/Api/Client/booking";

const DashBoard = () => {
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [walletData, setWalletData] = useState([]);
  const [walletTransaction, setWalletTransaction] = useState([]);
  const [depositOption, setDepositOption] = useState([]);
  const myDataCard = { id: 4, activeId: depositOption === 1 ? 1 : 0 };
  // const navigate = useNavigate();

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };
  // const checkProfile = (count) => {
  //   if (count !== 100) {
  //     ErrorToast("Please complete your Profile 100%")
  //     navigate('/client/dashboard')
  //   }
  // }

  const getWalletBalanceData = async () => {
    ClientGetWalletBalanceService()
      .then((res) => {
        setWalletData(res?.data?.data?.widget_counts || {});
        setWalletTransaction(res?.data?.data?.transactions || {});
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };
  const [profileData, setProfileData] = useState({});
  const [count, setCount] = useContext(ClientProfileCompletion);

  const getProfileData = async () => {
    GetClientProfileById()
      .then((res) => {
        setProfileData(res?.data?.data || {});
        setCount(res?.data?.data.profile_completed_percentage);
        const items = JSON.parse(localStorage.getItem("clientUserData"));
        const data = {
          ...items,
          profile_completed_percentage:
            res?.data?.data.profile_completed_percentage,
        };
        localStorage.setItem("clientUserData", JSON.stringify(data));
      })
      .catch((err) => {
        if (typeof err.response.data.message !== "undefined") {
          // ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          // ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  const getDepositOption = async () => {
    GetDepositOptionService()
      .then((res) => {
        setDepositOption(res?.data?.data?.depositOptions?.cash);
      })
      .catch((err) => {
        // if(typeof err.response.data.message != 'undefined'){
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  useEffect(() => {
    getWalletBalanceData();
    getProfileData();
    getDepositOption();
  }, []);
  return (
    <>
      <Helmet>
        <title>CPay | Customer Dashboard</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-xl-3 col-md-4 col-sm-6 col-12">
              <div className="card">
                <div className="card-body card-height">
                  <div className="dash-widget-header">
                    <span className="dash-widget-icon bg-3">
                      <i className="fas fa-file-alt"></i>
                    </span>
                    <div className="dash-count">
                      <div className="dash-title">
                        <Link to="/client/wallet"> Wallet Balance</Link>
                      </div>
                      <div className="dash-counts">
                        <p>
                          <span className="currency-type-text">
                            {" "}
                            {walletData?.wallet?.currency || ""}{" "}
                          </span>
                          {walletData?.wallet?.total_amount}
                        </p>
                      </div>
                    </div>
                  </div>
                  <p className="text-muted mt-3 mb-0">
                    <span className="text-success me-1">
                      <i className="fas fa-arrow-up me-1"></i>
                      <span className="currency-type-text">
                        {walletData?.wallet?.currency || ""}{" "}
                      </span>{" "}
                      {walletData?.wallet?.cpay_credit}
                    </span>{" "}
                    CPay Credit{" "}
                  </p>
                  <p className="text-muted mt-3 mb-0">
                    <span className="text-success me-1">
                      <i className="fas fa-arrow-up me-1"></i>
                      <span className="currency-type-text">
                        {walletData?.wallet?.currency || ""}
                      </span>{" "}
                      {walletData?.wallet?.amount}
                    </span>{" "}
                    Card / Cash amount
                  </p>
                  {/* <div className='plus-btn' onClick={() => checkProfile(count)}> */}
                  {depositOption === 1 && (
                    <div className="plus-btn">
                      <Link to="/client/add-wallet-amount" state={myDataCard}>
                        <button className="btn btn-primary dash-btn ripple">
                          <i className="fas fa-plus" />
                        </button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-4 col-sm-6 col-12">
              <div className="card">
                <div className="card-body card-height">
                  <div className="dash-widget-header">
                    <span className="dash-widget-icon bg-2">
                      <i className="fas fa-users"></i>
                    </span>
                    <div className="dash-count">
                      <div className="dash-title">
                        <Link to="/client/bookings">Orders</Link>
                      </div>
                      <div className="dash-counts">
                        <p>{walletData?.orders} </p>
                      </div>
                    </div>
                  </div>
                  <div className="progress progress-sm mt-3">
                    <div
                      className="progress-bar bg-6"
                      role="progressbar"
                      style={{ width: "65%" }}
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p className="text-muted mt-3 mb-0">
                    <span className="text-success me-1">
                      <i className="fas fa-arrow-up me-1"></i>
                      {walletData?.orders}
                    </span>{" "}
                    since last week
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-4 col-sm-6 col-12">
              <div className="card">
                <div className="card-body card-height">
                  <div className="dash-widget-header">
                    <span className="dash-widget-icon bg-1">
                      <i className="fas fa-dollar-sign"></i>
                    </span>
                    <div className="dash-count">
                      <div className="dash-title">
                        <Link to="/client/creditrequest">Pending Dues</Link>
                      </div>
                      <div className="dash-counts">
                        <p>
                          <span className="currency-type-text">
                            {" "}
                            {walletData?.wallet?.currency || ""}{" "}
                          </span>{" "}
                          {walletData?.pending_dues}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="progress progress-sm mt-3">
                    <div
                      className="progress-bar bg-5"
                      role="progressbar"
                      style={{ width: "75%" }}
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p className="text-muted mt-3 mb-0">
                    <span className="text-danger me-1">
                      <i className="fas fa-arrow-down me-1"></i>
                      <span className="currency-type-text">
                        {" "}
                        {walletData?.wallet?.currency || ""}{" "}
                      </span>{" "}
                      {walletData?.pending_dues}
                    </span>{" "}
                    since last week
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-4 col-sm-6 col-12">
              <div className="card">
                <div className="card-body card-height">
                  <div className="dash-widget-header">
                    <span className="dash-widget-icon bg-4">
                      <i className="far fa-file"></i>
                    </span>
                    <div className="dash-count">
                      <div className="dash-title">
                        <Link to="/client/creditrequest">
                          Overdue to Pending
                        </Link>
                      </div>
                      <div className="dash-counts">
                        <p>
                          <span className="currency-type-text">
                            {walletData?.wallet?.currency || ""}{" "}
                          </span>{" "}
                          {walletData?.overdue_to_pending}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="progress progress-sm mt-3">
                    <div
                      className="progress-bar bg-8"
                      role="progressbar"
                      style={{ width: "45%" }}
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <p className="text-muted mt-3 mb-0">
                    <span className="text-danger me-1">
                      <i className="fas fa-arrow-down me-1"></i>
                      <span className="currency-type-text">
                        {" "}
                        {walletData?.wallet?.currency || ""}{" "}
                      </span>{" "}
                      {walletData?.overdue_to_pending}
                    </span>{" "}
                    since last week
                  </p>
                  {walletData?.penalty_start_date ? (
                  <p className="text-danger mt-3 mb-0">
                    Penalty Start Date: {format(
                              new Date(walletData?.penalty_start_date),
                              "dd MMMM, yyyy, hh:mm a"
                            )}
                  </p>
                  ) : <></>}
                </div>
              </div>
            </div>
          </div>
          <div></div>
          <div className="row my-2">
            <div className="col-md-12 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title">Wallet History</h5>
                    </div>
                    <div className="col-auto">
                      <Link
                        to="/client/wallet"
                        className="btn-right btn btn-sm btn-outline-primary"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card card-table">
                  <div className="card-body">
                    <div className="table-responsive custom-pagination">
                      <table className="table table-stripped table-hover datatable">
                        <thead className="thead-light">
                          <tr>
                            <th>#</th>
                            <th>Reference Number</th>
                            {/* <th>Pax Details</th> */}
                            <th>Type</th>
                            <th>Amount</th>
                            {/* <th>Due Date</th>
                            <th>Late Payment Fees</th> */}
                            <th>Transaction Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {walletTransaction.map((user, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {user?.reference_number}
                                <br />
                                <span>
                                  {format(
                                    new Date(user?.createdAt),
                                    "dd MMMM, yyyy, hh:mm a"
                                  )}
                                </span>
                              </td>
                              {/* <td>
                                {user?.booking?.product === "flight" && (
                                  <>
                                    {user?.booking?.metadata?.travellers.map(
                                      (traveller, index) => (
                                        <div key={index}>
                                          {index + 1}. {traveller?.title}{" "}
                                          {traveller?.first_name}{" "}
                                          {traveller?.last_name}{" "}
                                          <span>
                                            ({traveller?.passenger_type})
                                          </span>
                                        </div>
                                      )
                                    )}
                                  </>
                                )}
                                {user?.booking?.product === "hotel" && (
                                  <>
                                    {user?.booking?.metadata?.travellers.map(
                                      (traveller, index) => (
                                        <div key={index}>
                                          {index + 1}. {traveller?.title}{" "}
                                          {traveller?.first_name}{" "}
                                          {traveller?.last_name}{" "}
                                        </div>
                                      )
                                    )}
                                  </>
                                )}
                                {user?.booking?.product === "visa" && (
                                  <div>
                                    {user?.booking?.metadata?.visa?.first_name}{" "}
                                    {user?.booking?.metadata?.visa?.last_name}
                                  </div>
                                )}
                              </td> */}
                              <td>
                                <StartCase
                                  string={_.toLower(
                                    _.replace(user?.motive, "_", " ")
                                  )}
                                />
                              </td>
                              <td>
                                <span className="currency-type-text">
                                  {user?.transaction_currency || "-"}
                                </span>{" "}
                                {user?.transaction_amount || "-"}
                              </td>
                              {/* {user?.motive == "CREDIT_REQUEST" ? <>
                                { user?.due_date ?
                                    <td>
                                          {format(new Date(user?.due_date), "dd MMMM, yyyy")}
                                    </td> : <td>-</td> 
                                } </> : <td>-</td> 
                              }
                               {user?.motive == "CREDIT_REQUEST" ?
                                    <>
                                         {user?.paidAt == null && user?.penalty_start_date != null ?
                                                <>
                                                    {user?.penalty_period === "DAILY" && (
                                                        <td>
                                                          <span className="currency-type-text">{user?.transaction_currency}</span> <DailyPenalty startDate={new Date(user?.penalty_start_date)} amount={user?.penalty_amount} />
                                                        </td>
                                                      )
                                                    }
                                                    {user?.penalty_period === "WEEKLY" && (
                                                        <td>
                                                          <span className="currency-type-text">{user?.transaction_currency}</span> <WeeklyPenalty startDate={new Date(user?.penalty_start_date)} amount={user?.penalty_amount} />
                                                        </td>
                                                      )
                                                    }
                                                    {user?.penalty_period === "FORT_NIGHT" && (
                                                        <td>
                                                          <span className="currency-type-text">{user?.transaction_currency}</span> <FortNightPenalty startDate={new Date(user?.penalty_start_date)} amount={user?.penalty_amount} />
                                                        </td>
                                                      )
                                                    }
                                                </>
                                                :<td>-</td>
                                          }    
                                    </>
                                    : <td>-</td>   
                                } */}
                              {/* {user?.motive == "CREDIT_REQUEST" ?<>
                                { user?.penalty_amount ?
                                      <td>
                                          <span className="currency-type-text">{user?.transaction_currency || '-'}</span> {user?.penalty_amount} ( <StartCase string={_.toLower(_.replace(user?.penalty_period, "_", " "))} /> )
                                      </td> : <td>-</td>
                                } </> : <td>-</td>
                              } */}
                              {user?.transaction_type == "DEBIT" && (
                                <td>
                                  <span className="badge badge-pill bg-danger-light">
                                    Debit
                                  </span>
                                  {/* {user?.motive == "CREDIT_REQUEST" && <> {" "}|{" "}
                                              <Link to="/client/add-wallet-amount" state={myDataCard}
                                                  className="btn btn-sm btn-white text-success me-2" >
                                                  Pay Now
                                              </Link> </>
                                          } */}
                                </td>
                              )}
                              {user?.transaction_type == "CREDIT" && (
                                <td>
                                  <span className="badge badge-pill bg-success-light">
                                    Credit
                                  </span>
                                  {/* {user?.motive == "CREDIT_REQUEST" && <> {" "}|{" "}
                                              <Link to="/client/add-wallet-amount" state={myDataCard}
                                                  className="btn btn-sm btn-white text-success me-2" >
                                                  Pay Now
                                              </Link> </>
                                          } */}
                                </td>
                              )}
                            </tr>
                          ))}
                          {walletTransaction.length === 0 && (
                            <tr>
                              <td className="text-center" colSpan={5}>
                                No Records Found.
                              </td>
                            </tr>
                          )}
                          {/* <Pagination
                          colSpan={5}
                          itemsPerPage={rowsPerPage}
                          page={page}
                          totalItem={15}
                          handleChangePage={handleChangePage}
                          handleChangeRowsPerPage={handleChangeRowsPerPage}
                        /> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoard;
