import React, { useEffect, useState } from "react";
import SideMenu from "./SideMenu";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { GetFaqsCustomer } from "../../../Services/Api/master";
import parse from "html-react-parser";

const Faq = () => {
  const [faqsData, setFaqsData] = useState([]);

  const getFaqsData = async () => {
    GetFaqsCustomer()
      .then((res) => {
        setFaqsData(res?.data?.data?.faqs);
      })
      .catch((err) => {
        // if(typeof err.response.data.message !== 'undefined'){
        //   ErrorToast(err?.response?.data?.message || 'Server Error!!')
        // }else{
        //   ErrorToast(err?.message || 'Server Error!!')
        // }
      });
  };

  useEffect(() => {
    getFaqsData();
  }, []);
  return (
    <>
      <Helmet>
        <title>CPay | Customer FAQ</title>
      </Helmet>
      <div>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="page-title">Frequently Asked Questions</h3>
                  <ul className="breadcrumb">
                    {/* <li className="breadcrumb-item"><link to="/client/dashboard" />Dashboard</li> */}
                    <li className="breadcrumb-item">
                      <Link to="/client/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      frequently asked questions
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-md-4">
                <SideMenu />
              </div>
              <div className="col-xl-9 col-md-8">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Frequently Asked Questions</h5>
                    <p>Review below frequently asked questions</p>
                  </div>
                  <div className="card-body">
                    <div className="accordion" id="accordionExample">
                      {faqsData.map((faqs, index) => (
                        <div className="accordion-item" key={index}>
                          <h2
                            className="accordion-header"
                            id={`heading${index}`}
                          >
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${index}`}
                              aria-expanded="false"
                              aria-controls={`collapse${index}`}
                            >
                              {faqs.question}
                            </button>
                          </h2>
                          <div
                            id={`collapse${index}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`heading${index}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              {parse(faqs.answer)}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
