import { NavLink, Link, useLocation } from 'react-router-dom';
import React, { useState,useEffect } from 'react'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { clientGetUserPermissionService } from '../../../Services/Api/Client/userManagement';

export default function SideBar({setOpenSidebar}) {

  const [hideShow, setHideShow] = useState('none');
  const [walletShow,setWalletShow] = useState('none');
  const [arrowUserMenagement, setArrowUserMenagement] = useState('menu-arrow');
  const [arrowWalletRequest, setArrowWalletRequest] = useState('menu-arrow');
 
  useEffect(() => {
    // setArrowUserMenagement((hideShow == 'block' ? 'menu-arrow menu-arrow-down' : 'menu-arrow'))
    setArrowUserMenagement((path.pathname ==='/client/user' ||  path.pathname ==='/client/role-management') ?
    (hideShow === 'none' || hideShow === true ? 'menu-arrow menu-arrow-down' : 'menu-arrow')    
  :
    (hideShow === 'block' ? 'menu-arrow menu-arrow-down' : 'menu-arrow'))
    //  setArrowWalletRequest((walletShow == 'block' ? 'menu-arrow menu-arrow-down' : 'menu-arrow'))
     setArrowWalletRequest((path.pathname ==='/client/bank-detail' ||  path.pathname ==='/client/wallet-request-card'||
     path.pathname =='/client/wallet-request' ) ?
     (walletShow === 'none' || walletShow === true ? 'menu-arrow menu-arrow-down' : 'menu-arrow')    
   :
     (walletShow === 'block' ? 'menu-arrow menu-arrow-down' : 'menu-arrow'))
   });

   const path = useLocation()
  const [toggleActive, setToggleActive] = useState(0)

  const menuclick = (value) => {
    setHideShow('none')
    setWalletShow('none')
    setToggleActive(0)

    if(value === 'usermanagement'){
      // setHideShow('block')
      if(path.pathname ==='/client/user' ||  path.pathname ==='/client/role-management'){
        setHideShow('none')
      }else{
      setHideShow('none')
      }
    }
    if(value == 'walletRequest'){
      // setWalletShow('block')
      if(path.pathname =='/client/bank-detail' ||  path.pathname =='/client/wallet-request-card'||
      path.pathname =='/client/wallet-request' ){
        setWalletShow('none')
      }else{
        setWalletShow('none')
      }
    }
  };
  
  const [permission,setPermission] = useState([])
  const [role,setRole] = useState()

  const getPermission =async () => {
    await  clientGetUserPermissionService().then((res) => {
      const permissionArr = res?.data.data.permissions.role_meta
      const resourceArr = permissionArr.map((value) => {
        return value.role_resource.resource_key
      })
      setPermission(resourceArr)
     
      localStorage.setItem(
        "clientPermission",
        JSON.stringify(resourceArr)
      );
    }).catch((err) =>{
      // if (typeof err.response.data.message !== "undefined") {
      //   ErrorToast(err.response.data.message || "Server Error!!");
      // } else {
      //   ErrorToast(err?.message || "Server Error!!");
      // }
    })
  }
  useEffect(() => {
    const role = JSON.parse(localStorage.getItem('clientUserData'));
    setRole(role?.role);
    if(role?.role === "sub_customer"){
      getPermission()
    }
  }, [])
 
  return (
    <div className="sidebar" id="sidebar" style={setOpenSidebar == true ? { "marginLeft": "0px" } : { "marginLeft": "-225px" }}>
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="menu-title">
              <span>Main</span>
            </li>
            
            <li onClick={() => menuclick('dashboard')}>
              <NavLink to="/client/dashboard"><i data-feather="home"></i>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg><span>Dashboard</span></NavLink>
            </li>

            {role == "sub_customer" ?
               <>
                  {(permission.indexOf('user_management') > -1 || permission.indexOf('role_management') > -1 ) &&
                      <li className="submenu ">
                        <Link to="#">
                          {/* <div onClick={() => {setHideShow((hideShow === 'none' ?  'block' : 'none'))}}> */}
                          <div onClick={() => {setToggleActive(!toggleActive)
                              if(path.pathname =='/client/user' ||  path.pathname =='/client/role-management'){
                                setHideShow(!hideShow)
                              }else{
                                setHideShow((hideShow === 'none' ? 'block' : 'none'))
                              }} 
                              }>
                        <span className='bi'><ManageAccountsIcon className='ustyle' /></span>
                          <span> User Managment</span> <span className={arrowUserMenagement}/>
                          </div>
                        </Link>
                       {/* <ul style={{ display :( path.pathname =='/client/user' ? 'block' : hideShow)}} > */}
                          <ul style={{ display :( (path.pathname =='/client/user' ||  path.pathname =='/client/role-management') && toggleActive == 0 ? 'block' : hideShow)}}>
                              {permission.indexOf('user_management') > -1 &&
                                  <li><NavLink to="/client/user" onClick={() => menuclick('usermanagement')}>Users</NavLink></li>
                              }
                              {permission.indexOf('role_management') > -1 &&
                                  <li><NavLink to="/client/role-management" onClick={() => menuclick('usermanagement')}>Role Management</NavLink></li>
                              }
                          </ul>
                      </li>
                   } 
               </> :
               <>
                  <li className="submenu ">
                    <Link to="#">
                      {/* <div onClick={() => {setHideShow((hideShow === 'none' ?  'block' : 'none'))}}> */}
                      <div onClick={() => {setToggleActive(!toggleActive)
                          if(path.pathname =='/client/user' ||  path.pathname =='/client/role-management'){
                            setHideShow(!hideShow)
                          }else{
                            setHideShow((hideShow === 'none' ? 'block' : 'none'))
                          }} 
                          }>
                    <span className='bi'><ManageAccountsIcon className='ustyle' /></span>
                      <span> User Managment</span> <span className={arrowUserMenagement}/>
                      </div>
                    </Link>
                        {/* <ul style={{ display :( path.pathname =='/client/user' ? 'block' : hideShow)}} > */}
                        <ul style={{ display :( (path.pathname =='/client/user' ||  path.pathname =='/client/role-management') && toggleActive == 0? 'block' : hideShow)}}>
                           <li><NavLink to="/client/user" onClick={() => menuclick('usermanagement')}>Users</NavLink></li>
                           <li><NavLink to="/client/role-management" onClick={() => menuclick('usermanagement')}>Role Management</NavLink></li>
                        </ul>
                   </li>
               </>
            }

            {role == "sub_customer" ? 
              <>
                  {permission.indexOf('wallet') > -1 &&
                    <li onClick={() => menuclick('wallet')}>
                        <NavLink to="/client/wallet">
                          <i  className="bi bi-wallet" style={{color:"blue"}}/>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet" viewBox="0 0 16 16">
                            <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z"/>
                          </svg><span> Wallet</span>
                        </NavLink>
                    </li>
                  }
              </> :
              <>
                <li onClick={() => menuclick('wallet')}>
                        <NavLink to="/client/wallet">
                          <i  className="bi bi-wallet" style={{color:"blue"}}/>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet" viewBox="0 0 16 16">
                            <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z"/>
                          </svg><span> Wallet</span>
                        </NavLink>
                    </li>
              </>
            }

            {role == "sub_customer" ? 
              <>
                  {permission.indexOf('wallet_request') > -1 &&
                    <li className="submenu">
                      <Link to="#">
                        {/* <div onClick={() => {setWalletShow((walletShow === 'none' ? 'block' : 'none'))}}> */}
                        <div onClick={() => {setToggleActive(!toggleActive)
                            if(path.pathname =='/client/bank-detail' ||  path.pathname =='/client/wallet-request-card'||
                            path.pathname =='/client/wallet-request'){
                              setWalletShow(!walletShow)
                            }else{
                              setWalletShow((walletShow === 'none' ? 'block' : 'none'))
                            }} 
                            } >
                        <i data-feather="clipboard" />
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                        <span> Wallet Request</span> <span className={arrowWalletRequest}/>
                        </div>
                      </Link>
                      {/* <ul style={{ display: walletShow}}> */}
                      <ul style={{ display :( (path.pathname =='/client/bank-detail' ||  path.pathname =='/client/wallet-request-card'||
                            path.pathname =='/client/wallet-request')&& toggleActive == 0  ? 'block' : walletShow)}}>
                        <li><NavLink to="/client/wallet-request" onClick={() => menuclick('walletRequest')}>Cash / Cheque</NavLink></li>
                        <li><NavLink to="/client/wallet-request-card" onClick={() => menuclick('walletRequest')}>Card</NavLink></li>
                        <li><NavLink to="/client/bank-detail" onClick={() => menuclick('walletRequest')}>Banking Details</NavLink></li>
                      </ul>
                    </li>
                  } 
              </> :
              <>
                  <li className="submenu">
                      <Link to="#">
                        {/* <div onClick={() => {setWalletShow((walletShow === 'none' ? 'block' : 'none'))}}> */}
                        <div onClick={() => {setToggleActive(!toggleActive)
                            if(path.pathname =='/client/bank-detail' ||  path.pathname =='/client/wallet-request-card'||
                            path.pathname =='/client/wallet-request'){
                              setWalletShow(!walletShow)
                            }else{
                              setWalletShow((walletShow === 'none' ? 'block' : 'none'))
                            }} 
                            } >
                        <i data-feather="clipboard" />
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                        <span> Wallet Request</span> <span className={arrowWalletRequest}/>
                        </div>
                      </Link>
                      {/* <ul style={{ display: walletShow}}> */}
                      <ul style={{ display :( (path.pathname =='/client/bank-detail' ||  path.pathname =='/client/wallet-request-card'||
                            path.pathname =='/client/wallet-request') && toggleActive == 0 ? 'block' : walletShow)}}>
                        <li><NavLink to="/client/wallet-request" onClick={() => menuclick('walletRequest')}>Cash / Cheque</NavLink></li>
                        <li><NavLink to="/client/wallet-request-card" onClick={() => menuclick('walletRequest')}>Card</NavLink></li>
                        <li><NavLink to="/client/bank-detail" onClick={() => menuclick('walletRequest')}>Banking Details</NavLink></li>
                      </ul>
                  </li>
              </>
            } 

            {role == "sub_customer" ? 
              <>
                {permission.indexOf('credit_request') > -1 &&
                  <li onClick={() => menuclick('creditrequest')}>
                    <NavLink to="/client/creditrequest">
                      <i className="bi bi-credit-card"></i>
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="bi bi-credit-card" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"/>
                        <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"/>
                      </svg>
                      <span> Credit Request</span></NavLink>
                  </li>
                }
              </> :
              <>
                <li onClick={() => menuclick('creditrequest')}>
                    <NavLink to="/client/creditrequest">
                      <i className="bi bi-credit-card"></i>
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="bi bi-credit-card" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"/>
                        <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"/>
                      </svg>
                      <span> Credit Request</span></NavLink>
                  </li>
              </>
            }

            {role == "sub_customer" ?  
              <>
                {permission.indexOf('statement') > -1 &&
                  <li onClick={() => menuclick('statement')}>
                    <NavLink to="/client/statement" ><i></i>
                    <img src="../inner-assets/img/statement.svg" style={{height:"20px"}} alt="statement" />
                    <span>Statement</span></NavLink>
                  </li>
                }
              </>:
              <>
                  <li onClick={() => menuclick('statement')}>
                    <NavLink to="/client/statement" ><i></i>
                    <img src="../inner-assets/img/statement.svg" style={{height:"20px"}} alt="statement" />
                    <span>Statement</span></NavLink>
                  </li>
              </>
            }

            {role == "sub_customer" ?
              <>
                {permission.indexOf('bookings') > -1 &&
                  <li onClick={() => menuclick('booking')}>
                    <NavLink to="/client/bookings" ><i></i>
                      <img src="../inner-assets/img/Booking.svg" style={{height:"20px"}} alt="booking" />
                      <span> Bookings</span></NavLink>
                  </li>
                }
              </>:
              <>
                  <li onClick={() => menuclick('booking')}>
                    <NavLink to="/client/bookings" ><i></i>
                      <img src="../inner-assets/img/Booking.svg" style={{height:"20px"}} alt="booking" />
                      <span> Bookings</span></NavLink>
                  </li>
              </>
            }

            <li onClick={() => menuclick('profile')}>
              <NavLink to="/client/profile"><i data-feather="user"></i>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                <span> Profile</span></NavLink>
            </li>

            <li onClick={() => menuclick('setting')}>
              <NavLink to="/client/settings"><i data-feather="settings"></i>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                <span>Settings</span></NavLink>
            </li>

            <li onClick={() => menuclick('notification')}>
              <NavLink to="/client/notifications"><i data-feather="bell"></i>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                <span>Notifications</span></NavLink>
            </li>

            <li onClick={() => menuclick('logout')}>
              <Link to="/client/logout"><i data-feather="log-out"></i>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                <span>Logout</span></Link>
            </li>

          </ul>
        </div>
      </div>
    </div>
  );
}
