import React from 'react'
import { Navigate } from 'react-router-dom'

const ClientPrivateRoute = ({ Component }) => {
  const loginUser = localStorage.getItem('clientAccessToken');
  let isLogin = false;
  if (loginUser !== null) {
    isLogin = true;
  }
   return isLogin 
   ? <Component />
   : <Navigate to="/client/auth/login" />
}

export default ClientPrivateRoute