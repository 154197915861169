import React, { useEffect, useState } from "react";
import {
  TextArea,
  Approve,
  Confirmation,
  ErrorToast,
  SuccessToast,
  CloseCredit,
} from "../../../Utils/SweetAlert";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import { Pagination } from "../../../Utils";
import {
  DeleteCustomerCreditRequest,
  ApproveRejectCustomerCreditRequest,
  GetCustomerCreditRequestPaginate,
  CloseMerchantCreditRequest,
} from "../../../Services/Api/Merchant/Wallet";
import { Helmet } from "react-helmet-async";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import GppBadIcon from "@mui/icons-material/GppBad";
import DeleteIcon from "@mui/icons-material/Delete";
import { DailyPenalty, FortNightPenalty, WeeklyPenalty } from "../../../Hooks/CalculatePenalty";
import { PenaltyLogs } from "../../Client/Common";

const WalletCreditRequest = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [customerWalletRequest, setCustomerWalletRequest] = useState([]);
  const handleToggleLogsModal = (data, totalPenaltyAmount) => setPenaltyLogData((prevState) => ({...penaltyLogData, isOpenModal: !prevState.isOpenModal, data, totalPenaltyAmount}));
  const [penaltyLogData, setPenaltyLogData] = useState({
    data: [],
    totalPenaltyAmount: 0,
    isOpenModal: false,
    toggleModelFun: handleToggleLogsModal
  });

  const getCustomerList = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetCustomerCreditRequestPaginate({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setCustomerWalletRequest(res?.data?.data?.credit_request);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setCustomerWalletRequest([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setCustomerWalletRequest([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getCustomerList(limit, page);
  }, [limit, page]);

  const approveRequest = (item, mode = 1, amount) => {
    var postObj = { request_id: item._id, status: mode, amount: amount };
    if (mode == 1) {
      Approve({ denyButtonText: "Cancel", amount }).then(async (result) => {
        if (result.isConfirmed) {
          var comments = result?.value?.reason;
          postObj.amount = parseFloat(result?.value?.amount);
          var postData = {
            request_id: postObj.request_id,
            status: postObj.status,
            comments: comments,
            amount: postObj.amount,
          };
          await makeApproveReject(postData);
        }
      });
    } else if (mode == 2) {
      TextArea({ denyButtonText: "Cancel" }).then(async (result) => {
        if (result.isConfirmed) {
          var comments = result.value;
          var postData = {
            request_id: postObj.request_id,
            status: postObj.status,
            comments: comments,
          };
          await makeApproveReject(postData);
        }
      });
    } else if (mode == 3) {
      Confirmation("Want to delete credit request?").then(async (result) => {
        if (result.isConfirmed) {
          await DeleteCustomerCreditRequest(postObj.request_id)
            .then((res) => {
              SuccessToast(res?.data?.message || "Status Updated");
              getCustomerList(limit, page);
            })
            .catch((err) => {
              // if (typeof err.response.data.message !== "undefined") {
              //   ErrorToast(err.response.data.message || "Server Error!!");
              // } else {
              //   ErrorToast(err?.message || "Server Error!!");
              // }
            });
        }
      });
    }
  };

  async function makeApproveReject(postObj) {
    await ApproveRejectCustomerCreditRequest(postObj)
      .then((res) => {
        SuccessToast(res?.data?.message || "Status Updated");
        getCustomerList(limit, page);
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  }

  const closeCreditRequest = async (fileId) => {
    CloseCredit("Close Credit ?").then(async (confirm) => {
      if (confirm.isConfirmed) {
        const postData = {
          credit_request_id: fileId?.id,
          amount: fileId?.amount,
          close_credit_reason: confirm.value,
        };
        await CloseMerchantCreditRequest(postData)
          .then(async (res) => {
            SuccessToast(
              res?.data?.message || "Credit Request Closed successfull."
            );
            getCustomerList(limit, page);
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>CPay | Merchant Wallet Credit Request</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Credit Requests</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/merchant/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active"> Credit Requests</li>
                </ul>
              </div>
            </div>
          </div>
          {/* common component import here */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-stripped table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th>Action</th>
                          <th>Penalty Logs</th>
                          <th>Status</th>
                          <th>Reference Number</th>
                          <th>Amount</th>
                          <th>Remaining Credit Amount</th>
                          <th>Due Credit Amount</th>
                          <th>Paid Credit Amount</th>
                          <th>Reason</th>
                          <th>Due Date</th>
                          <th>Penalty Start Date</th>
                          <th>Late Payment Fees</th>
                          <th>Status Comment</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {customerWalletRequest.map((customer, index) => (
                              <tr key={index}>
                                <td>{limit * page + (index + 1)}</td>
                                <td>
                                  {customer?.status === 1 ? (
                                    <span className="btn btn-primary me-1" onClick={() => closeCreditRequest({id: customer._id, amount: customer?.remaining_amount})}>Close Credit</span>
                                  ) : "-"}
                                </td>
                                <td>
                                  {customer?.credit_request_penalty_logs?.length > 0 ? (
                                    <span
                                      onClick={() => handleToggleLogsModal(customer?.credit_request_penalty_logs, customer?.total_payable_penalty_amount || 0)}
                                      className="btn btn-sm btn-block btn-outline-success"
                                    >
                                      Penalty Logs
                                    </span>
                                  ) : '-'}
                                </td>
                                <td>
                                  {customer?.status === 1 && (
                                    <span className="badge badge-pill bg-success-light">
                                      Approved
                                    </span>
                                  )}
                                  {customer?.status === 2 && (
                                    <span className="badge badge-pill bg-danger-light">
                                      Rejected
                                    </span>
                                  )}
                                  {customer?.status === 3 && (
                                    <span className="badge badge-pill bg-info-light">
                                      Closed
                                    </span>
                                  )}
                                  {customer?.status === 0 && (
                                    <>
                                      <Tooltip
                                        title="Approve"
                                        className="btn btn-sm btn-white text-success me-2"
                                        onClick={() =>
                                          approveRequest(
                                            customer,
                                            1,
                                            customer?.amount || 0
                                          )
                                        }
                                      >
                                        <IconButton>
                                          <AssignmentTurnedInIcon />
                                        </IconButton>
                                      </Tooltip>

                                      <Tooltip
                                        title="Reject"
                                        className="btn btn-sm btn-white text-danger me-2"
                                        onClick={() =>
                                          approveRequest(customer, 2)
                                        }
                                      >
                                        <IconButton>
                                          <GppBadIcon />
                                        </IconButton>
                                      </Tooltip>

                                      <Tooltip
                                        title="Delete"
                                        className="btn btn-sm btn-white text-danger me-2"
                                        onClick={() =>
                                          approveRequest(customer, 3)
                                        }
                                      >
                                        <IconButton>
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {customer?.reference_number}
                                  <br />
                                  <span>
                                    {format(
                                      new Date(customer?.createdAt),
                                      "dd MMMM, yyyy, HH:mm a"
                                    )}{" "}
                                  </span>
                                </td>
                                <td>
                                  <span className="currency-type-text">
                                    {customer?.currency || ""}
                                  </span>{" "}
                                  {(customer?.amount || 0).toFixed(2)}
                                </td>
                                <td>
                                  <span className="currency-type-text">
                                    {customer.currency}{" "}
                                  </span>{" "}
                                  {(customer?.remaining_amount || 0).toFixed(2)}
                                </td>
                                <td>
                                  <span className="currency-type-text">
                                    {customer?.currency}{" "}
                                  </span>
                                  {(customer?.amount_due || 0).toFixed(2)}
                                </td>
                                <td>
                                  <span className="currency-type-text">
                                    {customer.currency}{" "}
                                  </span>{" "}
                                  {(customer?.amount_paid || 0).toFixed(2)}
                                </td>
                                <td>{customer?.reason || "-"}</td>
                                {customer?.due_date ? (
                                  <td>
                                    {format(
                                      new Date(customer?.due_date),
                                      "dd MMMM, yyyy"
                                    )}
                                  </td>
                                ) : (
                                  <td>-</td>
                                )}
                                {customer?.penalty_start_date ? (
                                  <td>
                                    {format(
                                      new Date(customer?.penalty_start_date),
                                      "dd MMMM, yyyy"
                                    )}
                                  </td>
                                ) : (
                                  <td>-</td>
                                )}
                                {customer?.paidAt == null &&
                                customer?.penalty_start_date != null ? (
                                  <>
                                    {customer?.penalty_period === "DAILY" && (
                                      <td>
                                        <span className="currency-type-text">
                                          {customer.currency}
                                        </span>{" "}
                                        <DailyPenalty
                                          startDate={
                                            new Date(
                                              customer?.penalty_start_date
                                            )
                                          }
                                          amount={customer?.penalty_amount}
                                        />
                                      </td>
                                    )}
                                    {customer?.penalty_period === "WEEKLY" && (
                                      <td>
                                        <span className="currency-type-text">
                                          {customer.currency}
                                        </span>{" "}
                                        <WeeklyPenalty
                                          startDate={
                                            new Date(
                                              customer?.penalty_start_date
                                            )
                                          }
                                          amount={customer?.penalty_amount}
                                        />
                                      </td>
                                    )}
                                    {customer?.penalty_period ===
                                      "FORT_NIGHT" && (
                                      <td>
                                        <span className="currency-type-text">
                                          {customer.currency}
                                        </span>{" "}
                                        <FortNightPenalty
                                          startDate={
                                            new Date(
                                              customer?.penalty_start_date
                                            )
                                          }
                                          amount={customer?.penalty_amount}
                                        />
                                      </td>
                                    )}
                                  </>
                                ) : (
                                  <td>-</td>
                                )}
                                <td>{customer?.status_comments || "-"}</td>
                              </tr>
                            ))}
                            {customerWalletRequest.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={13}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={13}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
      <PenaltyLogs {...penaltyLogData} />
    </>
  );
};

export default WalletCreditRequest;
