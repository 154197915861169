import React from 'react'
import { Navigate } from 'react-router-dom'

const MerchantGuestRoute = ({ Component, updateHeaderFun }) => {
    const loginMerchant = localStorage.getItem('merchantAccessToken');
    let isLogin = false;
    if (loginMerchant !== null) {
      isLogin = true;
    }
    return isLogin ? <Navigate to="/merchant/dashboard" /> : <Component updateHeaderFun={(typeof updateHeaderFun !== 'undefined') ? updateHeaderFun: '/merchant/auth/login' } />
}

export default MerchantGuestRoute