import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ResetPasswordService } from '../../../Services/Api/Client/customerAuth';
import { SuccessToast, ErrorToast } from "../../../Utils/SweetAlert";
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';

const ResetPassword = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();
  const signInSchema = Yup.object().shape({
    password: Yup
      .string()
      .required('Password is required')
      .test('uppderCase', 'Must contain at least one uppercase character', (value) => /[A-Z]/.test(value))
      .test('lowerCase', 'Must contain at least one lowercase character', (value) => /[a-z]/.test(value))
      .test('oneNumber', 'Must contain at least one number', (value) => /[0-9]/.test(value))
      .test('oneSpecial', 'Must contain at least one special character', (value) => /[!@#%&]/.test(value)),
    confirm_password: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });
  const initialValues = {
    password: "",
    confirm_password: "",
  };
  return (
    <>
      <Helmet>
        <title>CPay | Customer ResetPassword</title>
      </Helmet>
    <div className="common-page">
      <span className="scene d-none">
        <small className="d-none" data-depth="0.2"></small>
      </span>
      <div className="brand-logo">
        <Link to="#" className="brand-link">
          <img src="/image/register-login-common/logo-2.png" alt="logo_not_found" />
        </Link>
      </div>
      <section id="register-section" className="register-section clearfix">
        <div className="common-container">
          <div className="image-container">
            <img src="/image/register-login-common/common-1.png" alt="image_not_found" />
          </div>
        </div>
        <div className="common-container bg-default-blue login-container">
          <div className="item-content">
            <h2 className="title-text mb-4">We are CPay</h2>
            <p className="mb-5">
              Welcome Back ! Please Reset to your Account Password
            </p>
            <div className="register-form">
              <Formik
                initialValues={initialValues}
                validationSchema={signInSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  const resetPasswordObj = {
                    forgotten_password_code: token,
                    new_password: values.password,
                    confirm_new_password: values.confirm_password,
                  }
                  await ResetPasswordService(resetPasswordObj).then((res) => {
                    SuccessToast(res?.data?.message || 'Password has been reset successfully.');
                    setSubmitting(false);
                    navigate('/client/auth/login');
                  }).catch((err) => {
                    if (typeof err.response.data.message !== 'undefined') {
                      ErrorToast(err.response.data.message || 'Server Error!!');
                    } else {
                      ErrorToast(err?.message || 'Server Error!!');
                    }
                  })
                }}
              >
                {({
                  errors,
                  touched,
                  isSubmitting
                }) => {
                  return (
                    <Form id="register-form">
                      <div className="form-group">
                        <label htmlFor="reg-password" className="form-control-label">Password*</label>
                        <div className="pass-group">
                          <Field
                            type={isShowPassword ? 'text' : 'password'}
                            className={`form-control ${errors.password && touched.password ? "input-error" : null}`}
                            id="password"
                            name="password"
                            placeholder="Enter password"
                          />
                          <span onClick={() => setIsShowPassword(!isShowPassword)} className={`fas ${isShowPassword ? 'fa-eye-slash' : 'fa-eye'} toggle-password`} />
                        </div>
                        <ErrorMessage name="password" component="span" className="error" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="confreg-password" className="form-control-label">Confirm Password*</label>
                        <div className="pass-group">
                          <Field
                            type={isShowConfirmPassword ? 'text' : 'password'}
                            id="confirm_password"
                            className={`form-control ${errors.confirm_password && touched.confirm_password ? "input-error" : null}`}
                            name="confirm_password"
                            placeholder="Enter confirm password"
                          />
                          <span onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)} className={`fas ${isShowConfirmPassword ? 'fa-eye-slash' : 'fa-eye'} toggle-password`} />
                        </div>
                        <ErrorMessage name="confirm_password" component="span" className="error" />
                      </div>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-block btn-theme  w-100"
                        style={{ marginTop: "25px" }}
                      >
                        Reset&nbsp;&nbsp;
                        {isSubmitting && (
                          <i className="fas fa-spinner fa-spin"></i>
                        )}
                      </button>
                      <div className="login-or">
                        <span className="or-line"></span>
                        <span className="span-or forgot">or</span>
                      </div>
                      <div className="text-center dont-have register-link-client">Already have an account ? <Link to="/client/auth/login">Login</Link></div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
          <small className="shape-1"><img src="/image/register-login-common/cross.png" alt="image_not_found" /></small>
          <small className="shape-2"><img src="/image/register-login-common/flow-1.png" alt="image_not_found" /></small>
          <small className="shape-3"><img src="/image/register-login-common/box.png" alt="image_not_found" /></small>
          <small className="shape-4"><img src="/image/register-login-common/box.png" alt="image_not_found" /></small>
          <small className="shape-5"><img src="/image/register-login-common/circle-half.png" alt="image_not_found" /></small>
          <small className="shape-6"><img src="/image/register-login-common/cross.png" alt="image_not_found" /></small>
          <small className="shape-7"><img src="/image/register-login-common/flow-2.png" alt="image_not_found" /></small>
          <small className="shape-8"><img src="/image/register-login-common/circle.png" alt="image_not_found" /></small>
          <small className="shape-9"><img src="/image/register-login-common/circle-half.png" alt="image_not_found" /></small>
        </div>
      </section>
    </div>
    </>
  )
}

export default ResetPassword