import { BrowserRouter as Router} from "react-router-dom";
import Routings from "./routes";
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <Router>
          <Routings />
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;