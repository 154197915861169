import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { RegisterService } from '../../../Services/Api/Client/customerAuth';
import { SuccessToast, ErrorToast } from "../../../Utils/SweetAlert";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Helmet } from 'react-helmet-async';

const ClientRegister = () => {

  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const registerSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required.'),
    name: Yup.string().required('Name is required.').matches(/^[aA-zZ\s]+$/,'Only alphabets are allowed'),
    password: Yup
      .string()
      .required('Password is required.')
      .test('uppderCase', 'Must contain at least one uppercase character', (value) => /[A-Z]/.test(value))
      .test('lowerCase', 'Must contain at least one lowercase character', (value) => /[a-z]/.test(value))
      .test('oneNumber', 'Must contain at least one number', (value) => /[0-9]/.test(value))
      .test('oneSpecial', 'Must contain at least one special character', (value) => /[!@#%&]/.test(value)),
    mobile: Yup.string().matches(phoneRegExp, "Please enter a correct  number").required('Mobile is required.'),
    company_name: Yup.string().required('Company name is required.'),
    telephone_number: Yup.string().matches(phoneRegExp, "Please enter a correct number").required('Telephone number is required.'),
  });

  const initialValues = {
    email: "",
    name: "",
    password: "",
    mobile: "",
    company_name: "",
    telephone_number: "",
  };
  return (
    <>
      <Helmet>
        <title>CPay | Customer Register</title>
      </Helmet>
    <div className="common-page">
      <span className="scene d-none">
        <small className="d-none" data-depth="0.2"></small>
      </span>
      <div className="brand-logo">
        <Link to="#" className="brand-link">
          <img src="/image/register-login-common/logo-2.png" alt="logo_not_found" />
        </Link>
      </div>
      <section id="register-section" className="register-section clearfix">
        <div className="common-container register-container">
          <div className="image-container">
            <img src="/image/register-login-common/common-1.png" alt="image_not_found" />
          </div>
        </div>
        <div className="common-container bg-default-blue register-container">
          <div className="item-content">
            <h2 className="title-text mb-4">We are CPay</h2>
            <p className="mb-5">
               Please Register to your Account
            </p>
            <div className="register-form my-4">
              <Formik
                initialValues={initialValues}
                validationSchema={registerSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  const postDate = {
                    name : values.name,
                    mobile : values.mobile,
                    telephone_number :values.telephone_number,
                    email : values.email,
                    password : values.password,
                    company_name : values.company_name,
                  }
                  if(values.check){
                   await RegisterService(postDate).then((res) => {
                        SuccessToast(res?.data?.message || 'Register successfully.');
                        setSubmitting(false);
                        navigate('/client/auth/login');
                      }).catch((err) => {
                        if (typeof err.response.data.message !== 'undefined') {
                          ErrorToast(err.response.data.message || 'Server Error!!');
                        } else {
                          ErrorToast(err?.message || 'Server Error!!');
                        }
                      })
                 }else{
                   setShow(true)
                 }
                }}
              >
                {({
                  errors,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  values
                }) => {
                  return (
                    <Form onSubmit={handleSubmit} id="register-form">
                      <div className="form-group ">
                          <label htmlFor="reg-name" className='form-contol-label'>Full Name*</label>
                              <Field
                                type="text"
                                className={
                                  `form-control ${errors.name && touched.name ? "input-error" : null}`
                                }
                                name="name"
                                placeholder="Enter name"
                              />
                          <ErrorMessage name="name" component="span" className="error" />
                      </div>
                      <div className="form-group">
                          <label htmlFor="reg-email" className='form-contol-label'>Email*</label>
                              <Field
                                type="email"
                                className={
                                  `form-control ${errors.email && touched.email ? "input-error" : null}`
                                }
                                name="email"
                                placeholder="Enter email"
                              />
                          <ErrorMessage name="email" component="span" className="error" />
                      </div>
                      <div className="form-group remove-inner-input">
                          <label htmlFor="reg-mobile" className='form-contol-label'>Mobile Number ( Country Code )*</label>
                              <PhoneInput 
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="AE"
                                type="text"
                                value={values.mobile}
                                onChange={(newVal) => {
                                  setFieldValue('mobile', newVal)
                                 }}
                                className={
                                  `form-control ${errors.mobile && touched.mobile ? "input-error" : null}`
                                }
                                name='mobile'
                              />
                          <ErrorMessage name="mobile" component="span" className="error" />
                      </div>
                      <div className="form-group">
                          <label htmlFor="reg-companyname" className='form-contol-label'>Company Name*</label>
                              <Field
                                type="text"
                                className={
                                  `form-control ${errors.company_name && touched.company_name ? "input-error" : null}`
                                }
                                name="company_name"
                                placeholder="Enter company name"
                              />
                          <ErrorMessage name="company_name" component="span" className="error" />
                      </div>
                      <div className="form-group">
                          <label htmlFor="reg-password" className='form-contol-label'>Password*</label>
                            <div className="pass-group">
                              <Field
                                type={isShowPassword ? 'text' : 'password'}
                                className={`form-control ${errors.password && touched.password ? "input-error" : null}`}
                                name="password"
                                placeholder="Enter password"
                              />
                              <span onClick={() => setIsShowPassword(!isShowPassword)} className={`fas ${isShowPassword ? 'fa-eye-slash' : 'fa-eye'} toggle-password`} />
                            </div>
                          <ErrorMessage name="password" component="span" className="error" />
                      </div>
                      <div className="form-group remove-inner-input">
                          <label className='form-contol-label' htmlFor="reg-email">Company Number*</label>
                              <PhoneInput
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="AE"
                                type="text"
                                value={values.telephone_number}
                                onChange={(newVal) =>{ setFieldValue('telephone_number', newVal)
                              }}
                               className={
                                  `form-control ${errors.telephone_number && touched.telephone_number ? "input-error" : null}`
                                }
                                name='telephone_number'
                              />
                          <ErrorMessage name="telephone_number" component="span" className="error" />
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="custom-control custom-checkbox">
                            {/* <input type="checkbox" className="custom-control-input rememberMe" id="cb1" />
                            <label className="custom-control-label" htmlFor="cb1">&nbsp; I Agree with the Terms And Conditions </label> */}
                            <label className="custom_check" htmlFor="cb1">
                                    <input type="checkbox" name="check" id="cb1"
                                      onChange={(e) => {
                                        setFieldValue("check",e.target.checked)
                                        setShow(false)
                                      }}
                                    /> I Agree with the <Link className='terms-link' to="/client/terms-conditions" target='_blank'> Terms And Conditions </Link>
                                    <span className="checkmark" /><br/>
                                  {show &&  <span className='text-danger'><strong>Please accept terms and conditions.</strong></span>}
                            </label>
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-block btn-theme  w-100 mb-3"
                      >
                        Register Now&nbsp;&nbsp;
                        {isSubmitting && (
                          <i className="fas fa-spinner fa-spin"></i>
                        )}
                      </button>
                      <div className="text-center dont-have register-link-client">Already have an account ? <Link to="/client/auth/login">Login</Link></div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
          <small className="shape-2"><img src="/image/register-login-common/flow-1.png" alt="image_not_found" /></small>
          <small className="shape-1"><img src="/image/register-login-common/cross.png" alt="image_not_found" /></small>
          <small className="shape-3"><img src="/image/register-login-common/box.png" alt="image_not_found" /></small>
          <small className="shape-4"><img src="/image/register-login-common/box.png" alt="image_not_found" /></small>
          <small className="shape-5"><img src="/image/register-login-common/circle-half.png" alt="image_not_found" /></small>
          <small className="shape-6"><img src="/image/register-login-common/cross.png" alt="image_not_found" /></small>
          <small className="shape-7"><img src="/image/register-login-common/flow-2.png" alt="image_not_found" /></small>
          <small className="shape-8"><img src="/image/register-login-common/circle.png" alt="image_not_found" /></small>
          <small className="shape-9"><img src="/image/register-login-common/circle-half.png" alt="image_not_found" /></small>
        </div>
      </section>
    </div>
    </>
  )
}

export default ClientRegister