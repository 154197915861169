import React, { useState,useEffect } from "react";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import {  enGB } from "date-fns/locale";
import { defaultStaticRanges } from "./DefaultRange";

const staticRangesLabels = {
  Today: "Today",
  Yesterday: "Yesterday",
  "This Week": "This Week",
  "This Month": "This Month"
};

const DateRangeFilter = ({ onChange, openCal, setOpenCal, resetDate, closeDate }) => {
  const [state, setState] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection"
    }
  ]);
  const [showLabel, setShowLabel] = useState(false)
  const handleOnChange = (ranges) => {
       const { selection } = ranges;
        onChange(selection);
        setState([selection]);
        setShowLabel(true)
 };

  function translateRange(dictionary) {
    return (item) =>
      dictionary[item.label]
        ? { ...item, label: dictionary[item.label] }
        : item;
  }

  const ruStaticRanges = defaultStaticRanges.map(
    translateRange(staticRangesLabels)
  );

  const formatDate = (date) => moment(date).format("YYYY-MM-DD");
  const Endate = state[0].endDate === null ? formatDate(state[0].startDate)
         : formatDate(state[0].endDate);
  const startDate = state[0].startDate ? formatDate(state[0].startDate) : "";
  
  useEffect(() => {
      if(resetDate === 1){
        setShowLabel(false)
          setState([{
              startDate: "",
              endDate: "",
              key: "selection"
            }])
      }
  }, [resetDate])
  
  return (
    <>
    <div className='selectBox custom_select' onClick={() => setOpenCal(!openCal)}>
      <button  style={{border : 'none',position : 'relative',background :'local'}} className="buttonSelect">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar me-1 select-icon"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
      {showLabel === false && `Select Date`}
      {state[0].startDate !== "" && state[0].endDate !== "" && (
        <span>
          {startDate} {"- "}
          {Endate}
        </span>
      )}   
      </button>
      </div>
      
      {openCal && (
        <DateRangePicker
          onChange={handleOnChange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          editableDateInputs={true}
          dateDisplayFormat="MM-dd-yyyy"
          maxDate={new Date()}
          ranges={state}
          direction="horizontal"
          locale={enGB}
          rangeColors={["#00a870"]}
          staticRanges={ruStaticRanges}
        />
      )} 
    </>
  );
};

export default DateRangeFilter;
