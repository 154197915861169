import React, { useEffect, useState } from 'react'
import { Link, Navigate, useLocation } from 'react-router-dom'
import { getAllNotification, getUnreadNotificationCount } from '../../../Services/Api/Client/notification';
import moment from 'moment'
import { Helmet } from 'react-helmet-async';
import { onImageError } from '../../../Utils/CommanFunctions';

const Notifications = () => {
  const stateData = useLocation();
  const { id } = stateData?.state || '';
  const [notificationList, setNotificationList] = useState([])
  const getNotificationList = () => {
    getAllNotification(id).then((res) => {
      setNotificationList(res.data.data.notifications);
      getNotificationCount()
     }).catch(() => {
      setNotificationList([]);
    })
  };

  const getNotificationCount = () => {
    getUnreadNotificationCount().then((res) => {
    });
  };

  const getNotificationListFromCurrent = (id) => {
    getAllNotification(id).then((res) => {
      getNotificationCount()
    }).catch(() => {

    })
  };
  
  useEffect(() => {
    getNotificationList();
  }, []);
  return (
    <>
      <Helmet>
        <title>CPay | Customer Notification</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Notifications</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/client/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item active">Notifications</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="comp-sec-wrapper">
            <section className="comp-section">
              <div className="row">
                <div className="col-md-12">
                  <div className="card bg-white">
                    <div className="card-body">
                      <div className="notifications">
                        <ul className="notification-list">
                          {notificationList.length > 0 && notificationList.map((notification) => (
                           <li key={notification?._id} className="notification-message">
                            <span
                                onClick={() => {
                                  getNotificationListFromCurrent(notification._id)
                                }}
                              >
                              {notification?.type.slice(0, 1) === 'D' && (
                                <Link to="/client/legal-documents">
                                  <div className="media d-flex">
                                    <span className="avatar avatar-sm">
                                      <img className="avatar-img rounded-circle" src={notification?.user?.profile_picture || ""} alt="Profile pic" onError={onImageError} />
                                    </span>
                                    <div className="media-body">
                                      <p className="noti-details"><span className="noti-title">{notification?.title}</span></p>
                                      {/* <p className="noti-details">{notification?.message}</p> */}
                                      <p className="noti-time"><span className="notification-time">{moment(notification?.createdAt).fromNow(true)} ago</span></p>
                                    </div>
                                  </div>
                                </Link>
                              )}
                              {(notification?.type.slice(0, 1) !==  'D' && notification?.type !== 'WALLET_DEPOSIT_REQUEST_REJECTED' && notification?.type !== 'WALLET_DEPOSIT_REQUEST_DELETED' && notification?.type !== 'BOOKING_PURCHASE_ORDER' && notification?.type !== 'BOOKING_SUCCESS' && notification?.type !== 'PASSWORD_CHANGED' ) && (
                                <Link to="/client/creditrequest">
                                  <div className="media d-flex">
                                    <span className="avatar avatar-sm">
                                      <img className="avatar-img rounded-circle" src={notification?.user?.profile_picture || ""} alt="Profile pic" onError={onImageError} />
                                    </span>
                                    <div className="media-body">
                                      <p className="noti-details"><span className="noti-title">{notification?.title}</span></p>
                                      {/* <p className="noti-details">{notification?.message}</p> */}
                                      <p className="noti-time"><span className="notification-time">{moment(notification?.createdAt).fromNow(true)} ago</span></p>
                                    </div>
                                  </div>
                                </Link>
                              )}
                                {notification?.type.slice(0, 1) === 'B' && (
                                <Link to="/client/bookings">
                                  <div className="media d-flex">
                                    <span className="avatar avatar-sm">
                                      <img className="avatar-img rounded-circle" src={notification?.user?.profile_picture || ""} alt="Profile pic" onError={onImageError} />
                                    </span>
                                    <div className="media-body">
                                      <p className="noti-details"><span className="noti-title">{notification?.title}</span></p>
                                      {/* <p className="noti-details">{notification?.message}</p> */}
                                      <p className="noti-time"><span className="notification-time">{moment(notification?.createdAt).fromNow(true)} ago</span></p>
                                    </div>
                                  </div>
                                </Link>
                              )}
                              {(notification?.type === 'WALLET_DEPOSIT_REQUEST_REJECTED' || notification?.type === "WALLET_DEPOSIT_REQUEST_DELETED") && (
                                <Link to="/client/wallet-request">
                                  <div className="media d-flex">
                                    <span className="avatar avatar-sm">
                                      <img className="avatar-img rounded-circle" src={notification?.user?.profile_picture || ""} alt="Profile pic" onError={onImageError} />
                                    </span>
                                    <div className="media-body">
                                      <p className="noti-details"><span className="noti-title">{notification?.title}</span></p>
                                      {/* <p className="noti-details">{notification?.message}</p> */}
                                      <p className="noti-time"><span className="notification-time">{moment(notification?.createdAt).fromNow(true)} ago</span></p>
                                    </div>
                                  </div>
                                </Link>
                              )}
                              {(notification?.type === 'PASSWORD_CHANGED') && (
                                  <div className="media d-flex">
                                    <span className="avatar avatar-sm">
                                      <img className="avatar-img rounded-circle" src={notification?.user?.profile_picture || ""} alt="Profile pic" onError={onImageError} />
                                    </span>
                                    <div className="media-body">
                                      <p className="noti-details"><span className="noti-title">{notification?.title}</span></p>
                                      {/* <p className="noti-details">{notification?.message}</p> */}
                                      <p className="noti-time"><span className="notification-time">{moment(notification?.createdAt).fromNow(true)} ago</span></p>
                                    </div>
                                  </div>
                              )}
                              </span>
                            </li>
                           ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Notifications