import SideMenu from "./SideMenu";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-number-input";
import {
  SuccessToast,
  ErrorToast,
  verificationConfirmation,
} from "../../../Utils/SweetAlert";
import { SecurityService } from "../../../Services/Api/Client/SecurityService";
import { GetClientProfileById } from "../../../Services/Api/Client/customerAuth";
import { Helmet } from "react-helmet-async";

const Security = () => {
  const [userData, setUserData] = useState({});
  const getProfile = () => {
    GetClientProfileById()
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };
  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Customer Security</title>
      </Helmet>
      <div>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="page-title">Security</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/client/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Security</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-md-4">
                <SideMenu />
              </div>
              <div className="col-xl-9 col-md-8">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Security</h5>
                    <p>Please secure your account</p>
                  </div>
                  <div className="card-body">
                    <Formik
                      initialValues={{
                        mobile: userData.mobile || "",
                        status: userData.two_step_enabled || 0,
                      }}
                      enableReinitialize
                      validationSchema={Yup.object().shape({
                        mobile: Yup.string()
                          .matches(phoneRegExp, "Phone number is not valid")
                          .required("Mobile number is required"),
                        status: Yup.number(),
                      })}
                      onSubmit={async (values, { setSubmitting }) => {
                        await SecurityService(values)
                          .then((res) => {
                            SuccessToast(
                              res?.data?.message || "Service Success."
                            );
                            setSubmitting(false);
                          })
                          .catch((err) => {
                            if (
                              typeof err.response.data.message !== "undefined"
                            ) {
                              ErrorToast(
                                err.response.data.message || "Server Error!!"
                              );
                            } else {
                              ErrorToast(err?.message || "Server Error!!");
                            }
                          });
                      }}
                    >
                      {({
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        values,
                      }) => {
                        return (
                          <Form>
                            <div className="row form-group remove-inner-input">
                              <label
                                htmlFor="notificationmobile"
                                className="col-sm-3 col-form-label input-label"
                              >
                                Enter Mobile Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="col-sm-9">
                                <PhoneInput
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry="AE"
                                  type="text"
                                  value={values.mobile}
                                  onChange={(newVal) => {
                                    setFieldValue("mobile", newVal);
                                  }}
                                  className={`form-control ${
                                    errors.mobile && touched.mobile
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="mobile"
                                />
                                <ErrorMessage
                                  name="mobile"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <label
                              className="row form-group toggle-switch"
                              htmlFor="switchOn"
                            >
                              <span className="col-8 col-sm-9 toggle-switch-content ms-0">
                                <span className="d-block text-dark">
                                  Want to setup two step verification
                                </span>
                                <span className="d-block text-muted">
                                  When you login in to your account we required
                                  dual authentication to reach to your
                                  dashboard.
                                </span>
                              </span>
                              <span className="col-4 col-sm-3">
                                <input
                                  type="checkbox"
                                  name="status"
                                  value="1"
                                  checked={values.status}
                                  className="toggle-switch-input"
                                  id="switchOn"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      if (userData?.telegram_chat_id == null) {
                                        verificationConfirmation(
                                          "To enable two step verification please join our telegram channel to get verification code on telegram."
                                        ).then(async (confirm) => {
                                          if (confirm.isConfirmed) {
                                            window.open(
                                              `https://telegram.me/confirmfly_bot?start=${userData?.id}`,
                                              "_blank"
                                            );
                                            setFieldValue("status", 1);
                                          } else {
                                            setFieldValue("status", 0);
                                          }
                                        });
                                      } else {
                                        setFieldValue("status", 1);
                                      }
                                    } else {
                                      setFieldValue("status", 0);
                                    }
                                  }}
                                />
                                <span className="toggle-switch-label ms-auto">
                                  <span className="toggle-switch-indicator"></span>
                                </span>
                              </span>
                            </label>
                            <div className="text-end">
                              <button
                                className="btn btn-primary btnstl"
                                disabled={isSubmitting}
                              >
                                Save Changes&nbsp;&nbsp;
                                {isSubmitting && (
                                  <i className="fas fa-spinner fa-spin"></i>
                                )}
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Security;
