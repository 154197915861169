import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import PhoneInput from "react-phone-number-input";
import {
  ClientAddUserService,
  GetClientRoleListService,
} from "../../../Services/Api/Client/userManagement";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";

const AddUser = () => {
  const navigate = useNavigate();
  const [isShowPassword, setIsShowPassword] = useState(false);

  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  const signInSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    name: Yup.string().required("Name is required").test("", "Enter valid Name", (value) => isNaN(value)),
    // website: Yup.string()
    //   .matches(
    //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //     "Enter correct url!"
    //   )
    //   .required("Please enter website"),
    contact_number: Yup.string()
      .required("Contact number is required")
      .matches(phoneRegExp, "Contact number is not valid"),
    role: Yup.string().required("Please select role"),
    password: Yup.string()
      .required("Password is required")
      .test(
        "uppderCase",
        "Must contain at least one uppercase character",
        (value) => /[A-Z]/.test(value)
      )
      .test(
        "lowerCase",
        "Must contain at least one lowercase character",
        (value) => /[a-z]/.test(value)
      )
      .test("oneNumber", "Must contain at least one number", (value) =>
        /[0-9]/.test(value)
      )
      .test(
        "oneSpecial",
        "Must contain at least one special character",
        (value) => /[!@#%&]/.test(value)
      ),
  });

  const initialValues = {
    name: "",
    contact_number: "",
    email: "",
    password: "",
    role: "",
    // website: "",
  };
  const [roleList, setRoleList] = useState([]);

  const getRoles = async () => {
    await GetClientRoleListService().then((res) => {
      if (res?.data?.data?.roles.length > 0) {
        let rolearr = res?.data?.data?.roles;
        rolearr = rolearr.map((value) => {
          return {
            label: value.role_name,
            value: value._id,
          };
        });
        setRoleList(rolearr);
      } else {
        // ErrorToast("No Results.");
        setRoleList([]);
      }
    });
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Customer Add User</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Add User</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/client/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/client/user">Users</Link>
                  </li>
                  <li className="breadcrumb-item active">Add User</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/client/user" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Basic Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      const postData = {
                        name: values.name,
                        contact_number: values.contact_number,
                        email: values.email,
                        password: values.password,
                        role_id: values.role,
                      };
                      await ClientAddUserService(postData)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message || "User created successfully."
                          );
                          setSubmitting(false);
                          navigate("/client/user");
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const {
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        values,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Name <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.name && touched.name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="name"
                                  placeholder="Enter name"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Email <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="email"
                                  className={`form-control ${
                                    errors.email && touched.email
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="email"
                                  placeholder="Enter email"
                                />
                                <ErrorMessage
                                  name="email"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Role <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={roleList}
                                  onChange={(e) => {
                                    setFieldValue("role", e.value);
                                  }}
                                  name="role"
                                  placeholder="Select role"
                                />
                                <ErrorMessage
                                  name="role"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group remove-inner-input">
                                <label>
                                  Contact Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <PhoneInput
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry="AE"
                                  type="text"
                                  value={values.contact_number}
                                  onChange={(newVal) => {
                                    setFieldValue("contact_number", newVal);
                                  }}
                                  className={`form-control ${
                                    errors.contact_number &&
                                    touched.contact_number
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="contact_number"
                                />
                                <ErrorMessage
                                  name="contact_number"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group" style={{position: "relative"}}>
                                <label>
                                  Password{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type={isShowPassword ? "text" : "password"}
                                  className={`form-control ${
                                    errors.password && touched.password
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="password"
                                  placeholder="Enter password"
                                />
                                <span
                                  onClick={() =>
                                    setIsShowPassword(!isShowPassword)
                                  }
                                  className={`fas ${
                                    isShowPassword ? "fa-eye-slash" : "fa-eye"
                                  } toggle-password-user`} style={{top:"48px", right: "20px"}}
                                />
                                <ErrorMessage
                                  name="password"
                                  component="span"
                                  className="error"
                                />
                              </div>

                              {/* <div className="form-group">
                              <label>Website <span className="text-danger">*</span></label>
                              <Field
                                type="text"
                                className={`form-control ${errors.website && touched.website
                                  ? "input-error"
                                  : null
                                  }`}
                                name="website"
                              />
                              <ErrorMessage
                                name="website"
                                component="span"
                                className="error"
                              />
                            </div> */}
                            </div>
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add User
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
