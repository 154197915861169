import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "../../../Utils";
import { Helmet } from "react-helmet-async";
import {
  ClientGetWalletBalancewidgetService,
  GetClientProfileById,
  GetCustomerTransactionRequest,
} from "../../../Services/Api/Client/customerAuth";
// import { ErrorToast } from "../../../Utils/SweetAlert";
import { ClientProfileCompletion } from "../../../contexts/ClientProfileCompletion";
import format from "date-fns/format";
import { StartCase } from "react-lodash";
import _ from "lodash";
// import { DailyPenalty, WeeklyPenalty, FortNightPenalty } from '../../../Hooks/CalculatePenalty';
import { GetDepositOptionService } from "../../../Services/Api/Client/booking";

const Wallet = () => {
  // const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [customerTransactionRequest, setCustomerTransactionRequest] = useState(
    []
  );
  const [totalRecords, setTotalRecords] = useState(0);
  const [walletData, setWalletData] = useState([]);
  const [depositOption, setDepositOption] = useState([]);
  const [count, setCount] = useContext(ClientProfileCompletion);
  const [data, setData] = useState([]);
  const myDataCard = { id: 3, activeId: depositOption === 1 ? 1 : 0 };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCustomerList = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetCustomerTransactionRequest({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setCustomerTransactionRequest(res?.data?.data?.transactions);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setCustomerTransactionRequest([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setCustomerTransactionRequest([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  const getProfileData = async () => {
    GetClientProfileById()
      .then((res) => {
        // setData(res?.data?.data || {});
        setCount(res?.data?.data.profile_completed_percentage);
        const items = JSON.parse(localStorage.getItem("clientUserData"));
        const data = {
          ...items,
          profile_completed_percentage:
            res?.data?.data.profile_completed_percentage,
        };
        localStorage.setItem("clientUserData", JSON.stringify(data));
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  // const checkProfile = (count) => {
  //   if (count !== 100) {
  //     ErrorToast("Please complete your Profile 100%")
  //     navigate('/client/wallet')
  //   }
  // }

  const getDepositOption = async () => {
    GetDepositOptionService()
      .then((res) => {
        setDepositOption(res?.data?.data?.depositOptions?.cash);
      })
      .catch((err) => {
        // if(typeof err.response.data.message != 'undefined'){
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  useEffect(() => {
    // localStorage.setItem('clientUserData', JSON.stringify(data));
    getDepositOption();
  }, [data]);

  useEffect(() => {
    getCustomerList(limit, page);
    getProfileData();
  }, [limit, page]);

  const getWalletBalanceData = async () => {
    ClientGetWalletBalancewidgetService()
      .then((res) => {
        setWalletData(res?.data?.data?.wallet || {});
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };
  useEffect(() => {
    getWalletBalanceData();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Customer Wallet</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Wallet</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/client/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Wallet</li>
                </ul>
              </div>
              {/* <div className="col-auto" onClick={() => checkProfile(count)}> */}
              {depositOption?.cash && (
                <div className="col-auto">
                  <Link
                    to="/client/add-wallet-amount"
                    state={myDataCard}
                    className="btn btn-primary me-1"
                  >
                    <i className="fas fa-plus" /> Add Wallet Amount
                  </Link>
                </div>
              )}
            </div>
          </div>
          {/* <FilterComponent  isShowUser={true}/> */}
          <div className="row">
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="/inner-assets/img/icons/invoices-icon1.svg"
                        alt="wallet"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">
                          {walletData?.currency || ""}
                        </span>{" "}
                        {walletData?.total_amount || "0"}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">Wallet Balance</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="/inner-assets/img/icons/invoices-icon2.svg"
                        alt="wallet"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">
                          {walletData?.currency || ""}
                        </span>{" "}
                        {walletData?.cpay_credit || "0"}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">CPay Credit</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="/inner-assets/img/icons/invoices-icon3.svg"
                        alt="wallet"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">
                          {walletData?.currency || ""}
                        </span>{" "}
                        {walletData?.amount || "0"}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">Card / Cash Amount</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-stripped table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th>Reference Number</th>
                          {/* <th>Pax Details</th> */}
                          <th>Type</th>
                          <th>Amount</th>
                          {/* <th>Due Date</th>
                          <th>Late Payment Fees</th> */}
                          <th>Transaction Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {customerTransactionRequest.map(
                              (customer, index) => (
                                <tr key={index}>
                                  <td>
                                    {/* {index + 1} */}
                                    {/* {(page) * 10 + (index+1)} */}
                                    {limit * page + (index + 1)}
                                  </td>
                                  <td>
                                    {customer?.reference_number}
                                    <br />
                                    <span>
                                      {format(
                                        new Date(customer?.createdAt),
                                        "dd MMMM, yyyy, hh:mm a"
                                      )}
                                    </span>
                                  </td>
                                  {/* <td>
                                    {customer?.booking?.product === "flight" && (
                                      <>
                                        {customer?.booking?.metadata?.travellers.map(
                                          (traveller, index) => (
                                            <div key={index}>
                                              {index + 1}. {traveller?.title}{" "}
                                              {traveller?.first_name}{" "}
                                              {traveller?.last_name}{" "}
                                              <span>
                                                ({traveller?.passenger_type})
                                              </span>
                                            </div>
                                          )
                                        )}
                                      </>
                                    )}
                                    {customer?.booking?.product === "hotel" && (
                                      <>
                                        {customer?.booking?.metadata?.travellers.map(
                                          (traveller, index) => (
                                            <div key={index}>
                                              {index + 1}. {traveller?.title}{" "}
                                              {traveller?.first_name}{" "}
                                              {traveller?.last_name}{" "}
                                            </div>
                                          )
                                        )}
                                      </>
                                    )}
                                    {customer?.booking?.product === "visa" && (
                                      <div>
                                        {customer?.booking?.metadata?.visa?.first_name}{" "}
                                        {customer?.booking?.metadata?.visa?.last_name}
                                      </div>
                                    )}
                                  </td> */}
                                  {customer?.motive ? (
                                    <td>
                                      <StartCase
                                        string={_.toLower(
                                          _.replace(customer?.motive, "_", " ")
                                        )}
                                      />
                                    </td>
                                  ) : (
                                    <td>-</td>
                                  )}
                                  <td>
                                    <span className="currency-type-text">
                                      {customer?.transaction_currency || "-"}
                                    </span>{" "}
                                    {customer?.transaction_amount || "-"}
                                  </td>
                                  {/* {customer?.motive == "CREDIT_REQUEST" ? <>
                                  {customer?.due_date ? 
                                    <td>
                                        {format(new Date(customer?.due_date), "dd MMMM, yyyy")}
                                    </td>  : <td>-</td>  } </> : <td>-</td> 
                                } */}
                                  {/* {customer?.motive == "CREDIT_REQUEST" ?
                                    <>
                                         {customer?.paidAt == null && customer?.penalty_start_date != null ?
                                                <>
                                                    {customer?.penalty_period === "DAILY" && (
                                                        <td>
                                                          <span className="currency-type-text">{customer?.transaction_currency}</span> <DailyPenalty startDate={new Date(customer?.penalty_start_date)} amount={customer?.penalty_amount} />
                                                        </td>
                                                      )
                                                    }
                                                    {customer?.penalty_period === "WEEKLY" && (
                                                        <td>
                                                          <span className="currency-type-text">{customer?.transaction_currency}</span> <WeeklyPenalty startDate={new Date(customer?.penalty_start_date)} amount={customer?.penalty_amount} />
                                                        </td>
                                                      )
                                                    }
                                                    {customer?.penalty_period === "FORT_NIGHT" && (
                                                        <td>
                                                          <span className="currency-type-text">{customer?.transaction_currency}</span> <FortNightPenalty startDate={new Date(customer?.penalty_start_date)} amount={customer?.penalty_amount} />
                                                        </td>
                                                      )
                                                    }
                                                </>
                                                :<td>-</td>
                                          }    
                                    </>
                                    : <td>-</td>   
                                } */}
                                  {customer?.transaction_type == "DEBIT" && (
                                    <td>
                                      <span className="badge badge-pill bg-danger-light">
                                        Debit
                                      </span>
                                      {/* {customer?.motive == "CREDIT_REQUEST" && <> {" "}|{" "}
                                            <Link to="/client/add-wallet-amount" state={myDataCard}
                                              className="btn btn-sm btn-white text-success me-2" >
                                              Pay Now
                                            </Link> </>
                                        } */}
                                    </td>
                                  )}
                                  {customer?.transaction_type == "CREDIT" && (
                                    <td>
                                      <span className="badge badge-pill bg-success-light">
                                        Credit
                                      </span>
                                      {/* {customer?.motive == "CREDIT_REQUEST" && <> {" "}|{" "}
                                            <Link to="/client/add-wallet-amount" state={myDataCard}
                                              className="btn btn-sm btn-white text-success me-2" >
                                              Pay Now
                                            </Link> </>
                                        } */}
                                    </td>
                                  )}
                                </tr>
                              )
                            )}
                            {customerTransactionRequest.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={5}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          colSpan={5}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handleChangePage}
                          handleChangeRowsPerPage={handleLimitChange}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
