import React, { useState, useEffect } from "react";
import { Pagination } from "../../../Utils";
import { Link, useNavigate } from "react-router-dom";
import { FilterComponent } from "../Common";
import { Helmet } from "react-helmet-async";
// import Tooltip from '@mui/material/Tooltip'
import {
  GetWidgetCountBookingService,
  GetBookingListService,
  GetRejectBooking,
  GetBookingListFilter,
  GetBookingExport,
  GetCpayReceiptservice,
} from "../../../Services/Api/Client/booking";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import format from "date-fns/format";
import { StartCase } from "react-lodash";
import { saveAs } from "file-saver";
import NeedInvoiceIcon from "../Common/NeedInvoiceIcon";

const Booking = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [widgetData, setWidgetData] = useState([]);
  const [currency, setCurrency] = useState();
  const [bookingListPagination, setBookingListPagination] = useState([]);
  const [bookingListFilter, setBookingListFilter] = useState({
    offset: page || 0,
    limit: limit || 10,
    filter_date: "",
    filter_status: "",
    filter_category: "",
  });
  const [resetDate, setResetDate] = useState(0);
  const navigate = useNavigate();

  const handleReset = async ({ fDate, fStatus, fCategory }) => {
    await setResetDate(0);
    setResetDate(1);
    setPage(0);
    const postData = {
      offset: 0,
      limit: limit || 10,
      filter_date: fDate || "",
      filter_status: fStatus || "",
      filter_category: fCategory || "",
    };
    setBookingListFilter(postData);
  };

  const handleSubmitFilter = async ({ fDate, fStatus, fCategory }) => {
    setResetDate(0);
    setPage(0);
    const postData = {
      offset: 0,
      limit: limit || 10,
      filter_date: fDate || "",
      filter_status: fStatus || "",
      filter_category: fCategory || "",
    };
    setBookingListFilter(postData);
  };

  const handleExportFilter = async ({ fDate, fStatus, fCategory }) => {
    setResetDate(0);
    setPage(0);
    const postData = {
      filter_date: fDate || "",
      filter_status: fStatus || "",
      filter_category: fCategory || "",
    };
    await GetBookingExport(postData)
      .then((res) => {
        if (res?.data?.data?.url) {
          saveAs(res?.data?.data?.url, res?.data?.data?.file_name);
        } else {
          ErrorToast("Export data does not exist.");
        }
      })
      .catch((err) => {
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  const getWidgetData = async () => {
    GetWidgetCountBookingService()
      .then((res) => {
        setWidgetData(res?.data?.data);
      })
      .catch((err) => {
        // if(typeof err.response.data.message !== 'undefined'){
        //   ErrorToast(err?.response?.data?.message || 'Server Error!!')
        // }else{
        //   ErrorToast(err?.message || 'Server Error!!')
        // }
      });
  };
  const handleReject = (id) => {
    Confirmation("Want to reject?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        const deleteRequest = { id: id };
        getBookingListFilter(limit, page);
        GetRejectBooking(deleteRequest)
          .then((res) => {
            SuccessToast(
              res?.data?.message || "Booking request reject successfully."
            );
            getBookingListFilter(bookingListFilter);
          })
          .catch((err) => {
            // if(typeof err?.response?.data?.message !== "undefined"){
            //     ErrorToast(err?.response?.data?.message || 'Server Error!!');
            // }else{
            //     ErrorToast(err?.message || "Server Error!!")
            // }
          });
      }
    });
  };
  const handleCpayReceipt = async (id) => {
    await GetCpayReceiptservice(id)
      .then((res) => {
        if (res?.data?.data?.url) {
          saveAs(res?.data?.data?.url, res?.data?.data?.file_name);
        } else {
          ErrorToast("Export data does not exist.");
        }
      })
      .catch((err) => {
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };
  // const getBookingList = async (limitO = "", pageO = "" ) =>{
  //   setIsLoading(true);
  //   await GetBookingListService({limit: limitO, page: pageO })
  //     .then((res) =>{
  //       setIsLoading(false);
  //         if(res?.data?.data?.totalRecords > 0){
  //           setBookingListPagination(res?.data?.data?.bookings)
  //           setTotalRecords(res?.data?.data?.totalRecords);
  //         }else{
  //           setBookingListPagination([]);
  //           setTotalRecords(0);
  //           // ErrorToast("No Results.");
  //         }
  //     }).catch((err) =>{
  //           setBookingListPagination([]);
  //           setTotalRecords(0);
  //           setIsLoading(false);
  //            // if(typeof err?.response?.data?.message !== "undefined"){
  //             //     ErrorToast(err?.response?.data?.message || 'Server Error!!');
  //             // }else{
  //             //     ErrorToast(err?.message || "Server Error!!")
  //             // }
  //     })
  // }

  const getBookingListFilter = async (requestPayload) => {
    setIsLoading(true);
    await GetBookingListFilter(requestPayload)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setIsLoading(false);
          setBookingListPagination(res?.data?.data?.bookings);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setBookingListPagination([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setBookingListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if(typeof err?.response?.data?.message !== "undefined"){
        //     ErrorToast(err?.response?.data?.message || 'Server Error!!');
        // }else{
        //     ErrorToast(err?.message || "Server Error!!")
        // }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    setBookingListFilter((prevState) => ({ ...prevState, offset: pageNew }));
    // await getBookingList(limit, pageNew);
    // await getBookingListFilter(bookingListFilter);
  };
  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    // await getBookingListFilter(parseInt(event.target.value, 10), 0);
    setBookingListFilter((prevState) => ({
      ...prevState,
      limit: event.target.value || 10,
      offset: 0,
    }));
    //  setBookingListFilter({offset : 0 ,
    //   limit : event.target.value || 10,
    //   filter_date : "",
    //   filter_status : ""});
  };

  useEffect(() => {
    getWidgetData();
  }, []);

  useEffect(() => {
    const item = JSON.parse(localStorage.getItem("clientUserData"));
    setCurrency(item?.primary_currency);
    // getBookingList(limit,page);
    getBookingListFilter(bookingListFilter);
  }, [bookingListFilter]);

  return (
    <>
      <Helmet>
        <title>CPay | Customer Booking</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Bookings</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/client/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Bookings</li>
                </ul>
              </div>
            </div>
          </div>
          <FilterComponent
            handleSubmitFilter={handleSubmitFilter}
            handleReset={handleReset}
            resetDate={resetDate}
            handleExportFilter={handleExportFilter}
          />
          <div className="row">
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="../inner-assets/img/icons/invoices-icon1.svg"
                        alt="invoice"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">{currency} </span>
                        {widgetData?.booking?.all_bookings_amount}{" "}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    All Bookings{" "}
                    <span>{widgetData?.booking?.all_bookings_count}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="../inner-assets/img/icons/invoices-icon2.svg"
                        alt="invoice"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">{currency} </span>
                        {widgetData?.booking?.success_bookings_amount}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Success Bookings{" "}
                    <span>{widgetData?.booking?.success_bookings_count}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="../inner-assets/img/icons/invoices-icon3.svg"
                        alt="invoice"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">{currency} </span>
                        {widgetData?.booking?.pending_bookings_amount}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Pending Bookings{" "}
                    <span>{widgetData?.booking?.pending_bookings_count}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="../inner-assets/img/icons/invoices-icon4.svg"
                        alt="invoice"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">{currency} </span>
                        {widgetData?.booking?.failed_bookings_amount}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Failed Bookings{" "}
                    <span>{widgetData?.booking?.failed_bookings_count}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="../inner-assets/img/icons/invoices-icon1.svg"
                        alt="invoice"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">{currency} </span>
                        {widgetData?.booking?.cancelled_bookings_amount}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Cancelled Bookings{" "}
                    <span>{widgetData?.booking?.cancelled_bookings_count}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="../inner-assets/img/icons/invoices-icon2.svg"
                        alt="invoice"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">{currency} </span>
                        {widgetData?.booking?.refund_bookings_amount}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Refund Bookings{" "}
                    <span>{widgetData?.booking?.refund_bookings_count}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-stripped table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Invoice ID</th>
                          <th>PO No.</th>
                          <th>Status</th>
                          <th>Update Status</th>
                          <th className="text-end">Action</th>
                          <th>Category</th>
                          <th>Pax Details</th>
                          <th>Created On</th>
                          <th>Merchants</th>
                          <th>Amount</th>
                          <th>Credit Used</th>
                          <th>Cash Used</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {bookingListPagination.map((booking, index) => (
                              <tr
                                key={index}
                                className={`${
                                  booking?.update_status === 1
                                    ? "bit-light"
                                    : ""
                                }`}
                              >
                                <td>
                                  {/* <Link to="/client/view-invoice" className="invoice-link">{booking?.booking_reference}</Link> */}
                                  {/* <Link to="#" className="invoice-link"> */}
                                  <span
                                    className="invoice-link"
                                    onClick={() =>
                                      navigate("/client/view-booking", {
                                        state: {
                                          id: booking?.booking_reference,
                                        },
                                      })
                                    }
                                  >
                                    {booking?.booking_reference}
                                  </span>
                                  {!booking?.merchant_invoice && <NeedInvoiceIcon />}
                                </td>
                                <td>
                                  <span
                                    className="invoice-link"
                                    onClick={() =>
                                      navigate("/client/booking-approve", {
                                        state: {
                                          id: booking?.id,
                                        },
                                      })
                                    }
                                  >
                                    {booking?.order?.order_reference}
                                  </span>
                                </td>
                                <td>
                                  {booking?.update_status === 2 ? <span className="badge bg-success-light">
                                      Refunded
                                    </span> : <>
                                  {booking?.status === 0 && (
                                    <span className="badge bg-warning-light">
                                      Pending
                                    </span>
                                  )}
                                  {booking?.status === 1 && (
                                    <span className="badge bg-success-light">
                                      Success
                                    </span>
                                  )}
                                  {booking?.status === 2 && (
                                    <span className="badge bg-danger-light">
                                      Failed
                                    </span>
                                  )}
                                  {booking?.status === 3 && (
                                    <span className="badge bg-danger-light">
                                      Cancelled
                                    </span>
                                  )}
                                    </>}
                                </td>
                                <td>
                                  {booking?.update_status === 0 && (
                                    <span>-</span>
                                  )}
                                  {booking?.update_status === 1 && (
                                    <>
                                      <Link
                                        to="/client/booking-approve"
                                        state={{
                                          id: booking?.booking_reference,
                                        }}
                                        className="btn btn-sm btn-white text-success me-2"
                                      >
                                        Approve
                                      </Link>{" "}
                                      | &nbsp;&nbsp;
                                      <span
                                        onClick={() =>
                                          handleReject(booking?.id)
                                        }
                                        className="btn btn-sm btn-white text-danger me-2"
                                      >
                                        Reject
                                      </span>
                                    </>
                                  )}
                                  {booking?.update_status === 2 && (
                                    <span>Approved</span>
                                  )}
                                  {booking?.update_status === 3 && (
                                    <span>Rejected</span>
                                  )}
                                </td>
                                <td className="text-end">
                                  <div className="dropdown dropdown-action">
                                    <Link
                                      to="#"
                                      className="action-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis-v" />
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      {booking?.merchant_invoice ? (
                                        <a
                                          className="dropdown-item"
                                          href={booking?.merchant_invoice}
                                          target="_blank" rel="noreferrer"
                                        >
                                          <i className="far fa-eye me-2" />
                                          Receipt by Merchant
                                        </a>
                                      ) : (
                                        <span className="dropdown-item text-wrap text-hover-cursor">
                                          <i className="far fa-eye me-2" />
                                          Pending receipt by Merchant
                                        </span>
                                      )}
                                      <span
                                        className="dropdown-item text-hover-cursor"
                                        onClick={() =>
                                          handleCpayReceipt(booking?._id)
                                        }
                                      >
                                        <i className="far fa-eye me-2" />
                                        Receipt by CPAY
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <StartCase string={booking?.product} />
                                </td>
                                <td>
                                  {booking?.product === "flight" && (
                                    <>
                                      {booking?.metadata?.travellers.map(
                                        (traveller, index) => (
                                          <div key={index}>
                                            {index + 1}. {traveller?.title}{" "}
                                            {traveller?.first_name}{" "}
                                            {traveller?.last_name}{" "}
                                            <span>
                                              ({traveller?.passenger_type})
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                  {booking?.product === "hotel" && (
                                    <>
                                      {booking?.metadata?.travellers.map(
                                        (traveller, index) => (
                                          <div key={index}>
                                            {index + 1}. {traveller?.title}{" "}
                                            {traveller?.first_name}{" "}
                                            {traveller?.last_name}{" "}
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                  {booking?.product === "visa" && (
                                    <div>
                                      {booking?.metadata?.visa?.first_name}{" "}
                                      {booking?.metadata?.visa?.last_name}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <span>
                                    {" "}
                                    {format(
                                      new Date(booking?.createdAt),
                                      "dd MMMM, yyyy hh:mm:ss a"
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <h2 className="table-avatar">
                                    <Link to="/client/profile">
                                      {booking?.merchant?.name}
                                    </Link>
                                  </h2>
                                </td>
                                <td className="text-primary">
                                  <span className="currency-type-text">
                                    {currency}{" "}
                                  </span>
                                  {booking?.customer_payment_amount}
                                </td>
                                <td>
                                  <span className="currency-type-text">
                                    {currency}{" "}
                                  </span>
                                  {booking?.customer_credit_used}
                                </td>
                                <td>
                                  <span className="currency-type-text">
                                    {currency}{" "}
                                  </span>
                                  {booking?.customer_cash_used}{" "}
                                </td>
                              </tr>
                            ))}
                            {bookingListPagination.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={10}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        {/* <tr>
                          <td>18 Mar 2022&nbsp;
                            <Tooltip title="Late Payment Charge">
                              <Link to="#"><i className="far fa-eye me-2 font-red" /></Link>
                              </Tooltip>
                          </td>
                        </tr> */}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={10}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Booking;
