import { Route, Routes } from "react-router-dom";
import { ClientGuestRoute, ClientPrivateRoute, ClientRoutes, ClientAuthRoutes, ClientRoutePermission } from "./client";
import { MerchantAuthRoutes, MerchantGuestRoute, MerchantPrivateRoute, MerchantRoutes, MerchantRoutesPermission } from "./merchant";
import React,{useEffect, useState} from 'react'
import { clientGetUserPermissionService } from "../Services/Api/Client/userManagement";
import { merchantGetUserPermissionService } from "../Services/Api/Merchant/userManagement";
import { ClientTermsConditions } from "../Components/Client/TermsConditions";
import { MerchantTermsConditions } from "../Components/Merchant/TermsConditions"


export default function Routings() {

  const [roleClient,setRoleClient] = useState("customer")
  const [roleMerchant,setRoleMerchant] = useState("merchant")
  // const [clientPermission,setClientPermission] = useState([])
  // const [merchantPermission,setMerchantPermission] = useState([])
  const [loading, setLoading] = useState(true);

  const getClientPermission =async () => {
    await  clientGetUserPermissionService().then((res) => {
      const permissionArr = res?.data.data.permissions.role_meta
      const resourceArr = permissionArr.map((value) => {
        return value.role_resource.resource_key
      })
      // setClientPermission(resourceArr)
      localStorage.setItem("clientPermission",JSON.stringify(resourceArr));
      setLoading(false);
    }).catch((err) =>{
      // if (typeof err.response.data.message !== "undefined") {
      //   ErrorToast(err.response.data.message || "Server Error!!");
      // } else {
      //   ErrorToast(err?.message || "Server Error!!");
      // }
    })
  }
  const getMerchantPermission =async () => {
    await  merchantGetUserPermissionService ().then((res) => {
      const permissionArr = res?.data.data.permissions.role_meta
      const resourceArr = permissionArr.map((value) => {
        return value.role_resource.resource_key
      })
      // setMerchantPermission(resourceArr)
     
      localStorage.setItem(
        "merchantPermission",
        JSON.stringify(resourceArr)
      );
      setLoading(false);
    }).catch((err) =>{
      // if (typeof err.response.data.message !== "undefined") {
      //   ErrorToast(err.response.data.message || "Server Error!!");
      // } else {
      //   ErrorToast(err?.message || "Server Error!!");
      // }
    })
  }
  useEffect(() => {

    const client = JSON.parse(localStorage.getItem('clientUserData'));
    setRoleClient(client?.role);
    const merchant = JSON.parse(localStorage.getItem('merchantUserData'));
    setRoleMerchant(merchant?.role);
   if (client?.role === "sub_customer") {
      getClientPermission();
    }
    if (merchant?.role === "sub_merchant") {
      getMerchantPermission();
    }

    if ((client?.role === "customer" || typeof client?.role === "undefined") && (merchant?.role === "merchant" || typeof merchant?.role === "undefined")) {
      setLoading(false);
    }

  }, []);

  return (
    <>
      <Routes>
        {!loading && <>
        {roleClient === 'customer'? 
          <Route path="/client/*" element={<ClientPrivateRoute Component={ClientRoutes} />} /> :
          <Route path="/client/*" element={<ClientPrivateRoute Component={ClientRoutePermission} />} />
        }
        <Route path="/client/terms-conditions" element={ <ClientTermsConditions/>} />
        <Route path="/client/auth/*" element={<ClientGuestRoute Component={ClientAuthRoutes} />} />

        {roleMerchant === 'merchant'?
          <Route path="/merchant/*" element={<MerchantPrivateRoute Component={MerchantRoutes} />} />:
          <Route path="/merchant/*" element={<MerchantPrivateRoute Component={MerchantRoutesPermission} />} />
        }

        <Route path="/merchant/terms-conditions" element={ <MerchantTermsConditions/> } />
        <Route path="/merchant/auth/*" element={<MerchantGuestRoute Component={MerchantAuthRoutes} />} />
        </> }
      </Routes>
    </>
  );
}
