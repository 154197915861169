import Api from "../../../Utils/ClientAxios";

export const GetClientRoleListService = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await Api.get("/customer/role"));
        } catch (error) {
            reject(error);
        }
    })
}

export const GetClientResourceService = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await Api.get("/customer/resource"));
        } catch (error) {
            reject(error);
        }
    })
}

export const ClientAddRoleService = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.post('/customer/role',data));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const ClientDeleteRoleById = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.delete('/customer/role',{data: {id: id}}));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const GetClientRoleListById = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/customer/role?id=${id}`));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const ClientEditRoleService = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.put('customer/role',data));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const ClientAddUserService = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.post('/customer/users',data));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const GetClientUserPaginate = async ({limit,page}) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/customer/users?offset=${page}&limit=${limit}`));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const ClientDeleteUserById = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.delete(`/customer/users/${id}`));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const ClientActiveInactiveUser = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.post("/customer/users/active_inactive",data));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const ClientEditUserService = async (id,data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.put(`/customer/users/${id}`,data));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const ClientGetUserByIdService = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/customer/users//${id}`));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const clientGetUserPermissionService = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get("customer/users/permissions/retrive"));
      } catch (error) {
        reject(error);
      }
    })
  }
