import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProfileCompletion } from "../../../contexts/ProfileCompletion";

const ProfilePercentage = ({ profile_completed_percentage }) => {
  const [profilePercentage, setProfilePercentage] = useState(0);
  const [count, setCount] = useContext(ProfileCompletion);

  if (profile_completed_percentage) {
    setCount(profile_completed_percentage);
  }

  useEffect(() => {
    const merchantUserData = JSON.parse(localStorage.getItem("merchantUserData"));
    setProfilePercentage(profile_completed_percentage ? profile_completed_percentage : merchantUserData.profile_completed_percentage);
    // setProfilePercentage(merchantUserData.profile_completed_percentage || 0);
  }, []);

  useEffect(() => {
    setProfilePercentage(profile_completed_percentage ? profile_completed_percentage : count);
    // setProfilePercentage(count);
  }, [count]);
  
  return (
    <>
      <Link to="/merchant/profile" style={{ lineHeight: 0, paddingLeft: 0 }}>
        <label>Profile Completed</label>
      </Link>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${profilePercentage}%` }}
          aria-valuenow={profilePercentage}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          {profilePercentage}%
        </div>
        
      </div>
    </>
  );
};

export default ProfilePercentage;
