import Api from "../../Utils/ClientAxios";

export const GetCountry = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get("/master/country-state-city/country"));
    } catch (error) {
      reject(error);
    }
  });
};
export const getAllAgreement = async (id = "") => {
  return new Promise(async (resolve, reject) => {
    try {
      let userId = "";
      if (id !== "") {
        userId = `?user_id=${id}`;
      }
      resolve(await Api.get(`/master/agreement${userId}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetTermsConditions = async (type) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/general/get-terms-condition?type=${type}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetFaqsCustomer = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get("/master/general/get-faqs?type=customer"));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetFaqsMerchant = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get("/master/general/get-faqs?type=merchant"));
    } catch (error) {
      reject(error);
    }
  });
};
