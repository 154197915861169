import React, { useEffect } from 'react'
import { Route, Routes } from "react-router-dom";
import { Booking, BookingApprove } from "../../Components/Client/Bookings";
import { CreditRequest, AddCreditRequest, PayCredit, EditPayCredit } from "../../Components/Client/CreditRequest";
import { Notification } from "../../Components/Client/Notifications/Index";
import { Profile } from "../../Components/Client/Profile/Index";
import { Dashboard } from "../../Components/Client/DashBoard";
import { ViewInvoice } from "../../Components/Client/Invoice";
import { Wallet } from "../../Components/Client/Wallet";
import { Settings, Faq, ChangePassword, LegalDocs, Security, ContactUs, FeedBack, EditLegalDocs, TermsConditions } from "../../Components/Client/Settings";
import { AddRoleManagement, EditRolePermission, Role } from "../../Components/Client/RoleManagement";
import { AddUser, EditUser, User } from "../../Components/Client/User";
import { ClientProfileCompletionProvider } from "../../contexts/ClientProfileCompletion";
import { WalletRequest , AddWalletAmount, EditWalletAmount, WalletRequestCard, BankAccount} from '../../Components/Client/WalletRequest';
import { Statement, TransactionDetail} from '../../Components/Client/Statement';
import { Paypage } from '../../Components/Client/Paypage';
import { ComponentPrint } from '../../Components/Client/Common';
import { ClientLayout } from '../../Components/Layouts';

const Logout = () => {
  useEffect(() => {
    document.cookie = "clientCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/client;";
    localStorage.removeItem('clientAccessToken');
    localStorage.removeItem('clientUserData');
    localStorage.removeItem('clientPermission');
    window.location.assign('/client/');
  }, []);
}

export default function ClientRoutes() {
  return (
    <ClientProfileCompletionProvider>
      <ClientLayout />
      <Routes>
      <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/bookings" element={<Booking />} />
        <Route exact path="/view-booking" element={<ComponentPrint />} />
        <Route exact path="/creditrequest" element={<CreditRequest />} />
        <Route exact path="/pay-credit" element={<PayCredit />} />
        <Route exact path="/edit-pay-credit" element={<EditPayCredit />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/notifications" element={<Notification />} />
        <Route exact path="/addcreditrequest" element={<AddCreditRequest />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/wallet" element={<Wallet />} />
        <Route exact path="/wallet-request" element={<WalletRequest />} />
        <Route exact path="/wallet-request-card" element={<WalletRequestCard />} />
        <Route exact path="/add-wallet-amount" element={<AddWalletAmount />} />
        <Route exact path="/edit-wallet-amount" element={<EditWalletAmount />} />
        <Route exact path="/bank-detail" element={<BankAccount />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/legal-documents" element={<LegalDocs />} />
        <Route exact path="/edit-legal-documents" element={<EditLegalDocs />} />
        <Route exact path="/change-password" element={<ChangePassword />} />
        <Route exact path="/security" element={<Security />} />
        <Route exact path="/paypage" element={<Paypage />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/terms-conditions" element={<TermsConditions />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/view-invoice" element={<ViewInvoice />} />
        <Route exact path="/add-role-management" element={<AddRoleManagement />} />
        <Route exact path="/edit-role-permission" element={<EditRolePermission />} />
        <Route exact path="/role-management" element={<Role />} />
        <Route exact path="/user" element={<User />} />
        <Route exact path="/edit-user" element={<EditUser />} />
        <Route exact path="/add-user" element={<AddUser />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/statement" element={<Statement />} />
        <Route exact path="/transaction-detail" element={<TransactionDetail />} />
        <Route exact path="/booking-approve" element={<BookingApprove />} />
        <Route exact path="/feedback" element={<FeedBack />} />
      </Routes>
    </ClientProfileCompletionProvider>
  );
}
