import moment from "moment";
import { useState, useEffect } from "react";
import format from "date-fns/format"

export const DailyPenalty = ({startDate, amount}) => {
    var endDate = new Date();
    var difference = endDate.getTime() - startDate.getTime();
    var TotalDays = Math.ceil(difference / (1000 * 3600 * 24));

    return TotalDays > 0 ? (TotalDays * amount) : 0;
};

export const WeeklyPenalty = ({startDate, amount}) => {
    let [totalWeeks, setTotalWeeks] = useState(0);

    useEffect(() => {
        var endDate = new Date();
        for (var i = startDate; i <= endDate; i.setDate(i.getDate()+1)) {
            // Sunday - Saturday : 0 - 6
            if (i.getDay() === 6) {
                setTotalWeeks(prevTotalWeeks => prevTotalWeeks + 1);
            }
        }
    }, []);
    
    return totalWeeks * amount;
};

export const FortNightPenalty = ({startDate, amount}) => {
    var endDate = new Date();
    var difference = endDate.getTime() - startDate.getTime();
    var TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    if (TotalDays > 0) {
        var fortNightly = Math.trunc(TotalDays / 15)
        return fortNightly * amount;
    } else {
        return 0;
    }
};

export const listenCookieChangeClient = (callback, interval = 1000) => {
    let lastCookie = document.cookie;
    setInterval(()=> {
        let cookie = {};
        document.cookie.split(';').forEach(function(el) {
           let [key,value] = el.split('=');
            cookie[key.trim()] = value;
        });
        if(cookie?.clientCookie){
            let diff = moment().diff(moment(cookie?.clientCookie), 'seconds');
            try {
                if (diff>=900 || isNaN(diff)) {
                    callback({difference: true});
                }
                else{
                    callback({difference: false});
                }
            } finally {
                lastCookie = cookie;
            }
        }
        else{
            callback({difference: true});
        }
    }, interval);
}

export const listenCookieChangeMerchant = (callback, interval = 1000) => {
    let lastCookie = document.cookie;
    setInterval(()=> {
        let cookie = {};
        document.cookie.split(';').forEach(function(el) {
            let [key,value] = el.split('=');
            cookie[key.trim()] = value;
        });
        if(cookie?.merchantCookie){
            let diff =moment().diff(moment(cookie?.merchantCookie), 'seconds');
            try {
                if (diff>=900 || isNaN(diff)) {
                    callback({difference: true});
                }
                else{
                    callback({difference: false});
                }
            } finally {
                lastCookie = cookie;
            }
        }
        else{
            callback({difference: true});
        }
    }, interval);
  }