import React from 'react'
import { Link } from 'react-router-dom'

const SideMenu = () => {
   return (
      <div>
         <div className="widget settings-menu">
            <ul>
               <li className="nav-item">
                  <Link to="/client/settings" className="nav-link ">
                     <i className="fas fa-tools"></i> <span>Profile Settings</span>
                  </Link>
               </li>
               <li className="nav-item">
                  <Link to="/client/legal-documents" className="nav-link">
                     <i className="fas fa-file-alt"></i> <span>Legal  Documents</span>
                  </Link>
               </li>
               <li className="nav-item">
                  <Link to="/client/change-password" className="nav-link">
                     <i className="fas fa-unlock-alt"></i> <span>Change Password</span>
                  </Link>
               </li>
               <li className="nav-item">
                  <Link to="/client/security" className="nav-link">
                     <i className="fas fa-shield-alt"></i> <span>Security</span>
                  </Link>
               </li>
               <li className="nav-item">
                  <Link to="/client/contact-us" className="nav-link">
                     <i className="fas fa-phone-alt"></i> <span>Contact Us</span>
                  </Link>
               </li>
               <li className="nav-item">
                  <Link to="/client/feedback" className="nav-link">
                     <i className="fa fa-comments"></i> <span>Feedback</span>
                  </Link>
               </li>
               <li className="nav-item">
                  <Link to="/client/faq" className="nav-link">
                     <i className="fas fa-eye"></i> <span>FAQ</span>
                  </Link>
               </li>
               <li className="nav-item">
                  <Link to="/client/terms-conditions" target='_blank' className="nav-link">
                     <i className="fa fa-address-book" aria-hidden="true"></i> <span>Terms and Conditions</span>
                  </Link>
               </li>
            </ul>
         </div>
      </div>
   )
}

export default SideMenu