import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast, CustomToast } from "../../../Utils/SweetAlert";
import { AddCreditRequestService } from "../../../Services/Api/Client/customerCreditRequest";
import { GetDepositOptionService } from "../../../Services/Api/Client/booking";

const AddCreditRequest = () => {
  const [depositOption, setDepositOption] = useState([]);

  const navigate = useNavigate();
  const addCreditRequestSchema = Yup.object().shape({
    reason: Yup.string().required("Reason is required"),
    amount: Yup.number()
      .positive("Please enter a positive value")
      .required("Credit amount is required"),
  });
  const initialValues = {
    amount: "",
    reason: "",
  };

  const getDepositOption = async () => {
    GetDepositOptionService()
      .then((res) => {
        setDepositOption(res?.data?.data?.depositOptions || {});
      })
      .catch((err) => {
        // if(typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // })
      });
  };

  useEffect(() => {
    getDepositOption();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Customer Add Credit Request</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row justify-content-lg-center">
            <div className="page-header">
              <div className="row">
                <div className="col">
                  <h3 className="page-title">Credit</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/cClient/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/client/creditrequest">Credit</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      Add Credit Request
                    </li>
                  </ul>
                </div>
                <div className="col-auto">
                  <Link
                    to="/client/creditrequest"
                    className="btn btn-primary me-1"
                  >
                    <i className="fas fa-angle-double-left" /> Back
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={addCreditRequestSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      const postObj = {
                        currency: "AED",
                        amount: values.amount,
                        reason: values.reason,
                      };
                      await AddCreditRequestService(postObj)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message ||
                              "Credit Request send successfully."
                          );
                          setSubmitting(false);
                          navigate("/client/creditrequest");
                          resetForm();
                        })
                        .catch((err) => {
                          if (typeof err.response.data.message !== "undefined") {
                            if (err?.response?.data?.isCreditRequestExist) {
                              CustomToast(
                                'Cpay Reminder',
                                err?.response?.data?.message || "Credit Request send successfully.",
                                'warning'
                              );
                            } else {
                              ErrorToast(err.response.data.message || "Server Error!!");
                            }
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const {
                        values,
                        errors,
                        touched,
                        isSubmitting,
                        handleChange,
                      } = formik;
                      return (
                        <Form>
                          {depositOption?.credit === 1 && (
                            <>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label>
                                      Credit Amount{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text"
                                          id="basic-addon3"
                                        >
                                          {"AED"}
                                        </span>
                                      </div>
                                      <Field
                                        type="text"
                                        className={`form-control ${
                                          errors.amount && touched.amount
                                            ? "input-error"
                                            : null
                                        }`}
                                        id="amount"
                                        name="amount"
                                        placeholder="Enter amount "
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <ErrorMessage
                                      name="amount"
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>
                                      Reason For Credit{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      as="textarea"
                                      rows="5"
                                      cols="5"
                                      className={`form-control ${
                                        errors.reason && touched.reason
                                          ? "input-error"
                                          : null
                                      }`}
                                      placeholder="Enter reason"
                                      id="reason"
                                      name="reason"
                                      onChange={handleChange}
                                    />
                                    <ErrorMessage
                                      name="reason"
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="text-end mt-4">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={isSubmitting}
                                >
                                  Submit&nbsp;&nbsp;
                                  {isSubmitting && (
                                    <i className="fas fa-spinner fa-spin"></i>
                                  )}
                                </button>
                              </div>
                            </>
                          )}
                          {depositOption?.credit === 0 && (
                            <>
                              <h4 className="text-center">
                                Credit request currently disabled.
                              </h4>
                              <div className="text-center mt-4">
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    navigate("/client/creditrequest")
                                  }
                                >
                                  Back to list
                                </button>
                              </div>
                            </>
                          )}
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCreditRequest;
