import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { clearAllNotificationListSearvice, getAllNotification, getUnreadNotification, getUnreadNotificationCount,} from "../../../Services/Api/Merchant/notification";
import ProfilePercentage from "../../Merchant/Common/ProfilePercentage";
import { MerchantGetProfileService } from "../../../Services/Api/Merchant/merchantAuth";
// import { ErrorToast } from "../../../Utils/SweetAlert";
import { Helmet } from 'react-helmet-async';
import { onImageError } from "../../../Utils/CommanFunctions";

const MerchantHeader = (props) => {

  const path = useLocation()
  const navigate = useNavigate()
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationList, setNotificationList] = useState([]);
  const [userData, setUserData] = useState({});
  const [profileCompletedPercentage, setProfileCompletedPercentage] = useState(0);

  const getNotificationCount = () => {
    getUnreadNotificationCount().then((res) => {
      setNotificationCount(res.data.data.unread_count);
      setProfileCompletedPercentage(res.data.data?.profile_completed_percentage || 0);

    });
  };
  const getNotificationListFromCurrent = (id) => {
    getAllNotification(id).then((res) => {
      getNotificationCount()
     }).catch(() => {

    })
  };
  const getNotificationList = () => {
    getUnreadNotification()
      .then((res) => {
        setNotificationList(res.data.data.notifications);
      })
      .catch(() => {
        setNotificationList([]);
      });
  };
  const clearAllNotification = () => {
    clearAllNotificationListSearvice().then(() => {
      getNotificationList();
      setNotificationCount(0)
    });
  };
  const getProfile = () => {
    MerchantGetProfileService()
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  useEffect(() => {
    getNotificationCount();
    getProfile();
    const interval = setInterval(() => {
      getNotificationCount();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <Helmet>
        <title>CPay | Merchant Dashboard</title>
      </Helmet>
      <div>
        <div className="header-left header-left-one">
          <Link to="/merchant/dashboard" className="logo header-logo-margin">
            <img src="/inner-assets/img/logo.png" alt="Logo" />
          </Link>
          <Link to="/merchant/dashboard" className="logo logo-small">
            <img
              src="/inner-assets/img/logo-small.png"
              alt="Logo"
              width="30"
              height="30"
            />
          </Link>
        </div>
        <Link to="#" id="toggle_btn">
          <i className="fas fa-bars"></i>
        </Link>
        <Link to="#" className="mobile_btn" id="mobile_btn" onClick={() => props.onClick(!props.showSideBar)}>
          <i className="fas fa-bars"></i>
        </Link>
      </div>
      <ul className="nav nav-tabs user-menu">
        <li className="nav-item notification-btn-wd" >
        <ProfilePercentage profile_completed_percentage={profileCompletedPercentage} />
          {/* {path.pathname.indexOf('merchant/profile') != -1 && path.pathname.indexOf('merchant') != -1 && <ProfilePercentage />} */}
        </li>
        <li className="nav-item dropdown">
          <Link
            to="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
            onClick={() => getNotificationList()}
          >
            <i className="fa fa-bell"></i>
            <span className="badge rounded-pill">{notificationCount}</span>
          </Link>
          <div className="dropdown-menu notifications">
            <div className="topnav-dropdown-header">
              <span className="notification-title">Notifications</span>
              <Link
                to="#"
                onClick={() => clearAllNotification()}
                className="clear-noti"
              >
                Clear All
              </Link>
            </div>
            <div className="noti-content">
              <ul className="notification-list">
                {notificationList.length > 0 &&
                  notificationList.map((notification) => (
                    <li key={notification._id} className="notification-message">
                       <span
                     onClick={()=>{
                      if(path.pathname == "/merchant/notifications"){
                        getNotificationListFromCurrent(notification._id)
                       }else{
                        getNotificationListFromCurrent(notification._id)
                        navigate("/merchant/notifications", {state: { id: notification._id }})
                      }
                    }}>
                     {/* <div className="media d-flex">
                        <div  className="row  px-2">
                            <div className="col-2">
                              <span className="avatar avatar-sm">
                                <img
                                  className="avatar-img rounded-circle"
                                  src={
                                    notification?.user?.profile_picture ||
                                    "/inner-assets/img/profiles/default_image.jpg"
                                  }
                                  alt="Profile pic"
                                />
                              </span>
                            </div>
                            <div className="col-10">
                              <div className="media-body">
                                <p className="noti-details">
                                  {notification?.message}
                                </p>
                                <p className="noti-time">
                                  <span className="notification-time">{moment(notification?.createdAt).fromNow(true)} ago</span>
                                </p>
                              </div>
                            </div>
                        </div>
                      </div> */}
                      <div className="d-flex my-2">
                        <div className="left-img header-notification">
                          <img
                            className="avatar-img rounded-circle"
                            src={
                              notification?.user?.profile_picture ||
                              "/inner-assets/img/profiles/default_image.jpg"
                            }
                            alt="Profile pic"
                          />
                        </div>
                        <div className="right-content">
                          <p className="noti-details">
                            {notification?.message}
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">{moment(notification?.createdAt).fromNow(true)} ago</span>
                          </p>
                        </div>
                      </div>
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="topnav-dropdown-footer">
              <Link to={{ pathname: "/merchant/notifications" }}>
                View all Notifications
              </Link>
            </div>

            </div>
          </li>
          <li className="nav-item main-drop">
            <Link to="/merchant/profile" className="nav-link">
              <span className="user-img">
              <img
                src={
                  userData.profile_picture ||
                  ""
                }
                onError={onImageError}
                alt="imge"
              />
              <span className="status online"></span>
            </span>
            <span>{userData?.name || ""}</span>
          </Link>
        </li>
      </ul>
  </>
  );
};

export default MerchantHeader;
