import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import SideMenu from "./SideMenu";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { feedBackService } from "../../../Services/Api/Client/customerAuth";
import Select from "react-select";

const FeedBack = () => {
  const signInSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    topic: Yup.object().required("Topic is required"),
    message: Yup.string().required("Message is required"),
  });
  const initialValues = {
    email: "",
    message: "",
    topic: "",
  };
  const TopicOption = [
    { label: "Topic 1", value: "Topic 1" },
    { label: "Topic 2", value: "Topic 2" },
    { label: "Topic 3", value: "Topic 3" },
  ];
  return (
    <>
      <Helmet>
        <title>CPay | Customer Feedback</title>
      </Helmet>
      <div>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="page-title">Feedback</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/client/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Feedback</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-md-4">
                <SideMenu />
              </div>
              <div className="col-xl-9 col-md-8">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Feedback</h5>
                    {/* <p>Let's get connected...</p> */}
                  </div>
                  <div className="card-body">
                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={signInSchema}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        const postData = {
                          email: values.email,
                          topic: values.topic.value,
                          message: values.message,
                        };
                        await feedBackService(postData)
                          .then((res) => {
                            SuccessToast(
                              res?.data?.message ||
                                "Thank you for your feedback!."
                            );
                            setSubmitting(false);
                            resetForm();
                          })
                          .catch((err) => {
                            if (
                              typeof err.response.data.message !== "undefined"
                            ) {
                              ErrorToast(
                                err.response.data.message || "Server Error!!"
                              );
                            } else {
                              ErrorToast(err?.message || "Server Error!!");
                            }
                          });
                      }}
                    >
                      {(formik) => {
                        const {
                          errors,
                          touched,
                          isSubmitting,
                          setFieldValue,
                          values,
                        } = formik;
                        return (
                          <Form>
                            <div className="form-group">
                              <label>
                                Email <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="email"
                                className={`form-control ${
                                  errors.email && touched.email
                                    ? "input-error"
                                    : null
                                }`}
                                name="email"
                                placeholder="Enter email"
                              />
                              <ErrorMessage
                                name="email"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                Topic <span className="text-danger">*</span>
                              </label>
                              <Select
                                options={TopicOption}
                                onChange={(newVal) => {
                                  setFieldValue("topic", newVal);
                                }}
                                name="topic"
                                value={values.topic}
                                placeholder="Enter topic"
                              />
                              <ErrorMessage
                                name="topic"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                Your Message{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                as="textarea"
                                rows="5"
                                cols="5"
                                className={`form-control ${
                                  errors.message && touched.message
                                    ? "input-error"
                                    : null
                                }`}
                                placeholder="Enter message"
                                name="message"
                              />
                              <ErrorMessage
                                name="message"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary btnstl"
                                disabled={isSubmitting}
                              >
                                Submit&nbsp;&nbsp;
                                {isSubmitting && (
                                  <i className="fas fa-spinner fa-spin"></i>
                                )}
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedBack;
