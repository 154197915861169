import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import {
  GetBankingOptionPaginate,
  StatusBankingOption,
} from "../../../Services/Api/Merchant/bankingOption";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const BankingOptions = () => {
  const [page] = useState(0);
  const [limit] = useState(10);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [bankingOptionListPagination, setBankingOptionListPagination] =
    useState([]);
  const getGetBankingOptionList = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetBankingOptionPaginate({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.accounts.length > 0) {
          setBankingOptionListPagination(res?.data?.data?.accounts);
        } else {
          setBankingOptionListPagination([]);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setBankingOptionListPagination([]);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const StatusChange = (accounts, text) => {
    Confirmation(text).then(async (result) => {
      if (result.isConfirmed) {
        const postObj = {
          account_id: accounts._id,
          status: `${accounts.status ^ 1}`,
        };
        await StatusBankingOption(postObj)
          .then((res) => {
            SuccessToast(res?.data.message || "Status Updated");
            getGetBankingOptionList(limit, page);
          })
          .catch((err) => {
            // if (typeof err.response.data.message !== "undefined") {
            //   ErrorToast(err.response.data.message || "Server Error!!");
            // } else {
            //   ErrorToast(err?.message || "Server Error!!");
            // }
          });
      }
    });
  };

  useEffect(() => {
    getGetBankingOptionList(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>CPay | Merchant Banking Options</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Banking Options</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/merchant/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Banking Options</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/merchant/add-banking-options"
                  className="btn btn-primary me-1"
                >
                  <i className="fas fa-plus" /> Add Banking Options
                </Link>
                {/* <Link
                className="btn btn-primary filter-btn"
                to="/"
                id="filter_search"
              >
                <i className="fas fa-filter" />
              </Link> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-center table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Logo</th>
                          <th className="text-center">Status</th>
                          <th>Action</th>
                          <th>Title</th>
                          <th>Bank Holder / Beneficiary</th>
                          <th>Account Number</th>
                          <th>IBAN</th>
                          <th>Swift Code</th>
                          <th>Currency</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {bankingOptionListPagination.map(
                              (accounts, index) => (
                                <tr key={index}>
                                  <td>
                                    <img
                                      className="logoHeight"
                                      src={accounts?.logo}
                                      alt="BankLogo"
                                    />
                                  </td>
                                  <td className="text-center">
                                    {accounts?.status === 0 && (
                                      <button
                                        className="btn btn-sm btn-block btn-outline-danger"
                                        onClick={() => {
                                          StatusChange(
                                            accounts,
                                            "Want to active bank?"
                                          );
                                        }}
                                      >
                                        Inactive
                                      </button>
                                    )}
                                    {accounts?.status === 1 && (
                                      <button
                                        className="btn btn-sm btn-block btn-outline-success"
                                        onClick={() => {
                                          StatusChange(
                                            accounts,
                                            "Want to deactivate bank?"
                                          );
                                        }}
                                      >
                                        Active
                                      </button>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <Tooltip
                                      title="Edit"
                                      className="btn btn-sm btn-white text-success me-2"
                                      onClick={() =>
                                        navigate(
                                          "/merchant/edit-banking-options",
                                          {
                                            state: { id: accounts?._id },
                                          }
                                        )
                                      }
                                    >
                                      <IconButton>
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </td>
                                  <td>{accounts?.title}</td>
                                  <td>{accounts?.beneficiary}</td>
                                  <td>{accounts?.account_number}</td>
                                  <td>{accounts?.iban}</td>
                                  <td>{accounts?.swift_code}</td>
                                  <td>{accounts?.currency}</td>
                                </tr>
                              )
                            )}
                            {bankingOptionListPagination.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={8}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankingOptions;
