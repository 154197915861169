import Api from "../../../Utils/ClientAxios";

export const LoginService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/customer/login', data));
    } catch (error) {
      reject(error);
    }
  })
}

export const VerifyOtpService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/auth/verify_otp', data));
    } catch (error) {
      reject(error);
    }
  })
}

export const RegisterService = async(data) => {
  return new Promise(async (resolve, reject) => {
    try{
      resolve(await Api.post('customer/register' , data));
    }catch (error) {
      reject(error);
    }
  })
}

export const ForgotPasswordService = async(data) => {
  return new Promise(async (resolve,reject) => {
    try{
      resolve(await Api.post('customer/forgot_password' , data));
    }catch (error) {
      reject(error);
    }
  })
}

export const ResetPasswordService = async(data) => {
  return new Promise(async (resolve , reject) => {
    try{
      resolve(await Api.post('/auth/resetpassword' , data));
    }catch (error) {
      reject(error);
    }
  })
}

export const GetClientProfileById = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/customer/get_profile`));
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteAccountService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userData = JSON.parse(localStorage.getItem('clientUserData'));
      resolve(await Api.delete(`/auth/delete_account/${userData._id}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const CustomerChangePasswordService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/customer/change_password', data));
    } catch (error) {
      reject(error);
    }
  })
}

export const UpdateProfile = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/auth/update_profile', data));
    } catch (error) {
      reject(error);
    }
  })
}

export const VerifyOtpServiceEnable = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/auth/verify_otp', data));
    } catch (error) {
      reject(error);
    }
  })
}

export const AddWalletAmountService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/wallet/customer/add_amount', data));
    }catch (error){
      reject (error);
    }
  })
}
export const CardAddWalletAmountService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/payby/customer/create_order', data));
    }catch (error){
      reject (error);
    }
  })
}
export const EditWalletAmountService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/wallet/customer/update_deposit_request', data));
    }catch (error){
      reject (error);
    }
  })
}

export const ClientGetProfileService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get('/customer/get_profile', data));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetCustomerWalletRequestPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet/customer/list_deposits?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetCustomerWalletRequestCardPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/payby/card_deposits?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  });
};
export const DeleteCustomerWalletRequest = async (trans_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete('/master/wallet/customer/customer_request/'+trans_id));
    } catch (error) {
      reject(error);
    }
  })
}

export const ApproveRejectCustomerWalletRequest = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/wallet/customer/approve_reject_request', data));
    } catch (error) {
      reject(error);
    }
  })
}
export const GetCustomerTransactionRequest = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet/customer/transactions?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  });
}
export const ClientGetWalletBalanceService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get('/master/dashboard'));
    } catch (error) {
      reject(error);
    }
  })
}
export const ClientGetWalletBalancewidgetService = async (user_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get("/master/wallet?user_id"));
    } catch (error) {
      reject(error);
    }
  })
}

export const ContactUsService = async (data) =>{
  return new Promise(async (resolve, reject) => {
    try{
        resolve(await Api.post("/master/contact_us",data));
    }catch(error){
        reject(error);
    }
  })
}

export const feedBackService = async (data) =>{
  return new Promise(async (resolve, reject) => {
    try{
        resolve(await Api.post("/master/feedback/send",data));
    }catch(error){
        reject(error);
    }
  })
}