import React, { useState, createContext } from "react";

// Create Context Object
export const ProfileCompletion = createContext();

// Create a provider for components to consume and subscribe to changes
export const ProfileCompletionProvider = props => {
const merchantUserData = JSON.parse(localStorage.getItem("merchantUserData"));
const [count, setCount] = useState(merchantUserData.profile_completed_percentage || 0);

  return (
    <ProfileCompletion.Provider value={[count, setCount]}>
      {props.children}
    </ProfileCompletion.Provider>
  );
};
