import Api from "../../../Utils/MerchantAxios";

export const GetMerchantRoleListService = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await Api.get("/merchant/role"));
        } catch (error) {
            reject(error);
        }
    })
}

export const GetMerchantResourceService = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await Api.get("/merchant/resource"));
        } catch (error) {
            reject(error);
        }
    })
}

export const MerchantAddRoleService = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.post('/merchant/role',data));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const MerchantDeleteRoleById = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.delete('/merchant/role',{data: {id: id}}));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const GetMerchantRoleListById = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/merchant/role?id=${id}`));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const MerchantEditRoleService = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.put('merchant/role',data));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const MerchantAddUserService = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.post('/merchant/users',data));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const GetMerchantUserPaginate = async ({limit,page}) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/merchant/users?offset=${page}&limit=${limit}`));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const MerchantDeleteUserById = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.delete(`/merchant/users/${id}`));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const MerchantActiveInactiveUser = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.post("/merchant/users/active_inactive",data));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const MerchantEditUserService = async (id,data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.put(`/merchant/users/${id}`,data));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const merchantGetUserByIdService = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/merchant/users//${id}`));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const merchantGetUserPermissionService = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get("/merchant/users/permissions/retrive"));
      } catch (error) {
        reject(error);
      }
    })
  }