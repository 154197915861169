import React from "react";
import { FilterComponent } from "../Common";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ErrorToast } from "../../../Utils/SweetAlert";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import {
  GetBookingListService,
  BookingWidgetCountService,
  GetBookingListFilter,
  GetBookingExport,
  GetCpayReceiptservice,
} from "../../../Services/Api/Merchant/booking";
import format from "date-fns/format";
import { saveAs } from "file-saver";
import NeedInvoiceIcon from "../../Client/Common/NeedInvoiceIcon";
import { StartCase } from "react-lodash";
import { onImageError } from "../../../Utils/CommanFunctions";

const Booking = ({ primaryCurrency }) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [bookingListPagination, setBookingListPagination] = useState([]);
  const [widgetCount, setWidgetCount] = useState([]);
  const [bookingListFilter, setBookingListFilter] = useState({
    offset: page || 0,
    limit: limit || 10,
    filter_date: "",
    filter_status: "",
    filter_customer: "",
  });
  const navigate = useNavigate();
  const [resetDate, setResetDate] = useState(0);

  const handleReset = async ({ fDate, fStatus, fUser }) => {
    await setResetDate(0);
    setResetDate(1);
    setPage(0);
    const postData = {
      offset: 0,
      limit: limit || 10,
      filter_date: fDate || "",
      filter_status: fStatus || "",
      filter_customer: fUser || "",
    };
    setBookingListFilter(postData);
  };

  const handleSubmitFilter = async ({ fDate, fStatus, fUser }) => {
    setResetDate(0);
    setPage(0);
    const postData = {
      offset: 0,
      limit: limit || 10,
      filter_date: fDate || "",
      filter_status: fStatus || "",
      filter_customer: fUser || "",
    };
    setBookingListFilter(postData);
  };

  const handleExportFilter = async ({ fDate, fStatus, fUser }) => {
    setResetDate(0);
    setPage(0);
    const postData = {
      filter_date: fDate || "",
      filter_status: fStatus || "",
      filter_customer: fUser || "",
    };
    await GetBookingExport(postData)
      .then((res) => {
        if (res?.data?.data?.url) {
          saveAs(res?.data?.data?.url, res?.data?.data?.file_name);
        } else {
          ErrorToast("Export data does not exist.");
        }
      })
      .catch((err) => {
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };
  // const getBookingList = async (limitO = "", pageO = "") => {
  //   setIsLoading(true);
  //   await GetBookingListService({ limit: limitO, page: pageO })
  //       .then((res) => {
  //           setIsLoading(false);
  //           if (res?.data?.data?.totalRecords > 0) {
  //             setBookingListPagination(res?.data?.data?.bookings);
  //             setTotalRecords(res?.data?.data?.totalRecords);
  //           } else {
  //             setBookingListPagination([]);
  //               setTotalRecords(0);
  //               // ErrorToast("No Results.");
  //           }
  //       })
  //       .catch((err) => {
  //         setBookingListPagination([]);
  //           setTotalRecords(0);
  //           setIsLoading(false);
  //           // if (typeof err.response.data.message !== "undefined") {
  //           //     ErrorToast(err.response.data.message || "Server Error!!");
  //           // } else {
  //           //     ErrorToast(err?.message || "Server Error!!");
  //           // }
  //       });
  //   };

  const getBookingListFilter = async (requestPayload) => {
    setIsLoading(true);
    await GetBookingListFilter(requestPayload)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setIsLoading(false);
          setBookingListPagination(res?.data?.data?.bookings);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setBookingListPagination([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setBookingListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if(typeof err?.response?.data?.message !== "undefined"){
        //     ErrorToast(err?.response?.data?.message || 'Server Error!!');
        // }else{
        //     ErrorToast(err?.message || "Server Error!!")
        // }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    setBookingListFilter((prevState) => ({ ...prevState, offset: pageNew }));
    // await getBookingList(limit, pageNew);
  };
  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    // await getBookingListFilter(parseInt(event.target.value, 10), 0);
    setBookingListFilter({
      offset: 0,
      limit: event.target.value || 10,
      filter_date: "",
      filter_status: "",
      filter_customer: "",
    }); // await getBookingList(parseInt(event.target.value, 10), 0);
  };

  const getWidgetData = async (requestPayload) => {
    BookingWidgetCountService(requestPayload)
      .then((res) => {
        setWidgetCount(res?.data?.data);
      })
      .catch((err) => {
        // if(typeof err.response.data.message !== 'undefined'){
        //   ErrorToast(err?.response?.data?.message || 'Server Error!!')
        // }else{
        //   ErrorToast(err?.message || 'Server Error!!')
        // }
      });
  };
  const handleCpayReceipt = async (id) => {
    await GetCpayReceiptservice(id)
      .then((res) => {
        if (res?.data?.data?.url) {
          saveAs(res?.data?.data?.url, res?.data?.data?.file_name);
        } else {
          ErrorToast("Export data does not exist.");
        }
      })
      .catch((err) => {
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  useEffect(() => {
    getWidgetData();
  }, []);
  
  useEffect(() => {
    getWidgetData(bookingListFilter);
    getBookingListFilter(bookingListFilter);
  }, [bookingListFilter]);

  return (
    <>
      <Helmet>
        <title>CPay | Merchant Booking</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Bookings</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/merchant/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Bookings</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  className="btn btn-primary filter-btn"
                  to="#"
                  id="filter_search"
                >
                  <i className="fas fa-arrow-down" /> CPay Credit Status
                </Link>
              </div>
            </div>
          </div>
          <FilterComponent
            isShowCategory={true}
            isShowUser={true}
            handleSubmitFilter={handleSubmitFilter}
            handleReset={handleReset}
            resetDate={resetDate}
            handleExportFilter={handleExportFilter}
          />
          <div id="filter_inputs" className="filter-card">
            <div className="row">
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="card inovices-card">
                  <div className="card-body">
                    <div className="inovices-widget-header">
                      <span className="inovices-widget-icon">
                        <img
                          src="/inner-assets/img/icons/invoices-icon1.svg"
                          alt="logo"
                        />
                      </span>
                      <div className="inovices-dash-count">
                        <div className="inovices-amount">
                          <span className="currency-type-text">
                            {primaryCurrency || ""}{" "}
                          </span>
                          {widgetCount?.cpay_credit?.total_credit}
                        </div>
                      </div>
                    </div>
                    <p className="inovices-all">Total CPay Credit</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="card inovices-card">
                  <div className="card-body">
                    <div className="inovices-widget-header">
                      <span className="inovices-widget-icon">
                        <img
                          src="/inner-assets/img/icons/invoices-icon1.svg"
                          alt="logo"
                        />
                      </span>
                      <div className="inovices-dash-count">
                        <div className="inovices-amount">
                          <span className="currency-type-text">
                            {primaryCurrency || ""}{" "}
                          </span>
                          {widgetCount?.cpay_credit?.cash_credit}
                        </div>
                      </div>
                    </div>
                    <p className="inovices-all">CPay Cash Credit</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="card inovices-card">
                  <div className="card-body">
                    <div className="inovices-widget-header">
                      <span className="inovices-widget-icon">
                        <img
                          src="/inner-assets/img/icons/invoices-icon2.svg"
                          alt="logo"
                        />
                      </span>
                      <div className="inovices-dash-count">
                        <div className="inovices-amount">
                          <span className="currency-type-text">
                            {primaryCurrency || ""}{" "}
                          </span>
                          {widgetCount?.cpay_credit?.loan_credit}
                        </div>
                      </div>
                    </div>
                    <p className="inovices-all">CPay Loan Credit</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="card inovices-card">
                  <div className="card-body">
                    <div className="inovices-widget-header">
                      <span className="inovices-widget-icon">
                        <img
                          src="/inner-assets/img/icons/invoices-icon3.svg"
                          alt="logo"
                        />
                      </span>
                      <div className="inovices-dash-count">
                        <div className="inovices-amount">
                          <span className="currency-type-text">
                            {primaryCurrency || ""}{" "}
                          </span>
                          {widgetCount?.cpay_credit?.used_credit}
                        </div>
                      </div>
                    </div>
                    <p className="inovices-all">Total Used Credit</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="card inovices-card">
                  <div className="card-body">
                    <div className="inovices-widget-header">
                      <span className="inovices-widget-icon">
                        <img
                          src="/inner-assets/img/icons/invoices-icon4.svg"
                          alt="logo"
                        />
                      </span>
                      <div className="inovices-dash-count">
                        <div className="inovices-amount">
                          <span className="currency-type-text">
                            {primaryCurrency || ""}{" "}
                          </span>
                          {widgetCount?.cpay_credit?.transaction_fee}
                        </div>
                      </div>
                    </div>
                    <p className="inovices-all">
                      CPay Transcation Fee
                      {/* <span>10%</span> */}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="card inovices-card">
                  <div className="card-body">
                    <div className="inovices-widget-header">
                      <span className="inovices-widget-icon">
                        <img
                          src="/inner-assets/img/icons/invoices-icon4.svg"
                          alt="logo"
                        />
                      </span>
                      <div className="inovices-dash-count">
                        <div className="inovices-amount">
                          <span className="currency-type-text">
                            {primaryCurrency || ""}{" "}
                          </span>
                          {widgetCount?.cpay_credit?.pending_credit}
                        </div>
                      </div>
                    </div>
                    <p className="inovices-all">Pending Credit</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card invoices-tabs-card">
            <div className="card-body card-body pt-0 pb-0">
              <div className="invoices-main-tabs">
                <div className="row align-items-center">
                  <div className="col-lg-8 col-md-8">
                    <div className="invoices-tabs">
                      <ul>
                        <li>
                          <Link to="/merchant/booking" className="active">
                            All Bookings
                          </Link>
                        </li>
                        {/* <li><Link to="/merchant/booking-paid">Paid</Link></li>
                        <li><Link to="/merchant/booking-unpaid">UnPaid</Link></li>
                        <li><Link to="/merchant/booking-canceled">Cancelled</Link></li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="/inner-assets/img/icons/invoices-icon1.svg"
                        alt="logo"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">
                          {primaryCurrency || ""}{" "}
                        </span>
                        {widgetCount?.booking?.all_bookings_amount}{" "}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    All Bookings{" "}
                    <span>{widgetCount?.booking?.all_bookings_count}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="/inner-assets/img/icons/invoices-icon2.svg"
                        alt="logo"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">
                          {primaryCurrency || ""}{" "}
                        </span>
                        {widgetCount?.booking?.paid_bookings_amount}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Paid Bookings{" "}
                    <span>{widgetCount?.booking?.paid_bookings_count}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="/inner-assets/img/icons/invoices-icon3.svg"
                        alt="logo"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">
                          {primaryCurrency || ""}{" "}
                        </span>
                        {widgetCount?.booking?.unpaid_bookings_amount}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Unpaid Bookings{" "}
                    <span>{widgetCount?.booking?.unpaid_bookings_count}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="card inovices-card">
                <div className="card-body">
                  <div className="inovices-widget-header">
                    <span className="inovices-widget-icon">
                      <img
                        src="/inner-assets/img/icons/invoices-icon4.svg"
                        alt="logo"
                      />
                    </span>
                    <div className="inovices-dash-count">
                      <div className="inovices-amount">
                        <span className="currency-type-text">
                          {primaryCurrency || ""}{" "}
                        </span>
                        {widgetCount?.booking?.cancelled_bookings_amount}
                      </div>
                    </div>
                  </div>
                  <p className="inovices-all">
                    Cancelled Bookings{" "}
                    <span>
                      {widgetCount?.booking?.cancelled_bookings_count}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-stripped table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th>Status</th>
                          <th>Update Status</th>
                          <th>Reference Number</th>
                          <th>PO No.</th>
                          <th className="text-end">Action</th>
                          <th>Category</th>
                          <th>Pax Details</th>
                          <th>Customer</th>
                          <th>Amount</th>
                          <th>Merchant Amount</th>
                          <th>CPay Transcation Fee</th>

                          {/* <th>CPay Status</th>
                          <th className="text-end">Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {bookingListPagination.map((booking, index) => (
                              <tr key={index}>
                                <td>{limit * page + (index + 1)}</td>
                                <td>
                                  {booking?.update_status === 2 ? <span className="badge bg-success-light">
                                      Refunded
                                    </span> : <>
                                  {booking?.status === 0 && (
                                    <span className="badge bg-warning-light">
                                      Pending
                                    </span>
                                  )}
                                  {booking?.status === 1 && (
                                    <span className="badge bg-success-light">
                                      Success
                                    </span>
                                  )}
                                  {booking?.status === 2 && (
                                    <span className="badge bg-danger-light">
                                      Failed
                                    </span>
                                  )}
                                  {booking?.status === 3 && (
                                    <span className="badge bg-danger-light">
                                      Cancelled
                                    </span>
                                  )}
                                    </>}
                                </td>
                                <td>
                                  {booking?.update_status === 0 && (
                                    <span>-</span>
                                  )}
                                  {booking?.update_status === 1 && (
                                    <span>Pending</span>
                                  )}
                                  {booking?.update_status === 2 && (
                                    <span>Approved</span>
                                  )}
                                  {booking?.update_status === 3 && (
                                    <span>Rejected</span>
                                  )}
                                </td>
                                <td>
                                  <span
                                    className="invoice-link"
                                    onClick={() =>
                                      navigate("/merchant/view-booking", {
                                        state: {
                                          id: booking?.booking_reference,
                                        },
                                      })
                                    }
                                  >
                                    {booking?.booking_reference}
                                    <br />
                                    <span>
                                      {" "}
                                      {format(
                                        new Date(booking?.createdAt),
                                        "dd MMMM, yyyy"
                                      )}
                                    </span>
                                  </span>
                                  {!booking?.merchant_invoice && <NeedInvoiceIcon />}
                                </td>
                                <td>
                                  <span
                                    className="invoice-link"
                                    onClick={() =>
                                      navigate("/merchant/view-booking", {
                                        state: {
                                          id: booking?.booking_reference,
                                        },
                                      })
                                    }
                                  >
                                    {booking?.order?.order_reference}
                                  </span>
                                </td>
                                <td className="text-end">
                                  <div className="dropdown dropdown-action">
                                    <Link
                                      to="#"
                                      className="action-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis-v" />
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      {booking?.merchant_invoice ? (
                                        <a
                                          className="dropdown-item"
                                          href={booking?.merchant_invoice}
                                          target="_blank" rel="noreferrer"
                                        >
                                          <i className="far fa-eye me-2" />
                                          Receipt by Merchant
                                        </a>
                                      ) : (
                                        <span className="dropdown-item text-wrap text-hover-cursor">
                                          <i className="far fa-eye me-2" />
                                          Pending receipt by Merchant
                                        </span>
                                      )}
                                      <span
                                        className="dropdown-item text-hover-cursor"
                                        onClick={() =>
                                          handleCpayReceipt(booking?._id)
                                        }
                                      >
                                        <i className="far fa-eye me-2" />
                                        Receipt by CPAY
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <StartCase string={booking?.product} />
                                </td>
                                <td>
                                  {booking?.product === "flight" && (
                                    <>
                                      {booking?.metadata?.travellers.map(
                                        (traveller, index) => (
                                          <div key={index}>
                                            {index + 1}. {traveller?.title}{" "}
                                            {traveller?.first_name}{" "}
                                            {traveller?.last_name}{" "}
                                            <span>
                                              ({traveller?.passenger_type})
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                  {booking?.product === "hotel" && (
                                    <>
                                      {booking?.metadata?.travellers.map(
                                        (traveller, index) => (
                                          <div key={index}>
                                            {index + 1}. {traveller?.title}{" "}
                                            {traveller?.first_name}{" "}
                                            {traveller?.last_name}{" "}
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                  {booking?.product === "visa" && (
                                    <div>
                                      {booking?.metadata?.visa?.first_name}{" "}
                                      {booking?.metadata?.visa?.last_name}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <h2 className="table-avatar text-center">
                                    <img
                                      className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                      src={booking?.customer?.profile_picture || ""}
                                      alt="User"
                                      onError={onImageError}
                                    />
                                    {booking?.customer?.name}
                                  </h2>
                                </td>
                                <td className="text-primary">
                                  <span className="currency-type-text">
                                    {primaryCurrency || ""}{" "}
                                  </span>
                                  {booking?.amount}
                                </td>
                                <td className="text-primary">
                                  <span className="currency-type-text">
                                    {primaryCurrency || ""}{" "}
                                  </span>
                                  {booking?.merchant_payment_amount}
                                </td>
                                <td className="text-primary">
                                  <span className="currency-type-text">
                                    {primaryCurrency || ""}{" "}
                                  </span>
                                  {booking?.merchant_service_charge}
                                </td>
                                {/* <td><span className="badge bg-success-light">Paid</span></td> */}
                                {/* <td className="text-end">
                                  <div className="dropdown dropdown-action">
                                    <Link to="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-ellipsis-v" /></Link>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <Link className="dropdown-item" to="/merchant/edit-booking"><i className="far fa-edit me-2" />Edit Booking</Link>
                                      <Link className="dropdown-item" to="/merchant/view-booking"><i className="far fa-eye me-2" />View Booking</Link>
                                      <Link className="dropdown-item" to="#"><i className="far fa-trash-alt me-2" />Cancle Booking</Link>
                                    </div>
                                  </div>
                                </td> */}
                              </tr>
                            ))}
                            {bookingListPagination.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={8}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={8}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Booking;
