import React, { useState, useEffect } from "react";
import {
  GetBookingApproveService,
  GetBookingDetails,
} from "../../../Services/Api/Client/booking";
import { useLocation, useNavigate } from "react-router-dom";
import { SuccessToast } from "../../../Utils/SweetAlert";
import moment from "moment";
// import format from "date-fns/format";

const BookingApprove = () => {
  const [bookingInfo, setBookingInfo] = useState({});
  const [time, setTime] = useState();
  const stateData = useLocation();
  const { id } = stateData?.state || {};
  const navigate = useNavigate();

  const getBooking = async () => {
    await GetBookingDetails(id)
      .then((res) => {
        setBookingInfo(res?.data?.data);
        setTime(res?.data?.data?.booking?.update_info?.date_time);
      })
      .catch((err) => {
        // if(typeof err.response.data.message !== 'undefined'){
        //   ErrorToast(err?.response?.data?.message || 'Server Error!!')
        // }else{
        //   ErrorToast(err?.message || 'Server Error!!')
        // }
      });
  };

  const handleApprove = (id) => {
    const data = { id: id };
    GetBookingApproveService(data)
      .then((res) => {
        SuccessToast(
          res?.data?.message || "Booking request update successfully."
        );
        navigate("/client/bookings");
      })
      .catch((err) => {
        // if(typeof err.response.data.message !== 'undefined'){
        //   ErrorToast(err?.response?.data?.message || 'Server Error!!')
        // }else{
        //   ErrorToast(err?.message || 'Server Error!!')
        // }
      });
  };

  useEffect(() => {
    getBooking();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="card invoice-info-card">
              <div className="card-body">
                <div className="invoice-item invoice-item-one">
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="p-4">
                        <div className="invoice-logo text-center">
                          <img src="/inner-assets/img/logo.png" alt="logo" />
                        </div>
                        <div className="invoice-head">
                          <h2>Updated Details</h2>
                          <p>Booking Reference: {id} </p>
                          {/* <p className='my-3'> Date & Time : {format(new Date(time), "dd MMMM yyyy, hh:mm a")} </p> */}
                          <p className="my-3">
                            {" "}
                            Date & Time :{" "}
                            {moment(time).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}{" "}
                          </p>
                        </div>
                        <div className="booking-details">
                          <div className="my-4 p-4 booking-approve">
                            {bookingInfo?.booking?.update_status === 1 && (
                              <>
                                <p>
                                  Original booking amount :&nbsp;&nbsp;
                                  <span className="currency-type-text">
                                    AED{" "}
                                  </span>
                                  {
                                    bookingInfo?.booking
                                      ?.customer_payment_amount
                                  }
                                </p>
                                {bookingInfo?.booking?.update_info?.type ===
                                  "charge" && (
                                  <>
                                    <p>
                                      Updated amount :&nbsp;&nbsp;
                                      <span className="currency-type-text">
                                        AED{" "}
                                      </span>
                                      {parseFloat(
                                        (
                                          bookingInfo?.booking
                                            ?.customer_payment_amount +
                                          bookingInfo?.booking?.update_info
                                            ?.payment_info?.customer?.amount
                                        ).toFixed(2)
                                      ) || 0}{" "}
                                    </p>

                                    <p>
                                      You will be charged : &nbsp;&nbsp;
                                      <span className="currency-type-text">
                                        AED{" "}
                                      </span>
                                      {
                                        bookingInfo?.booking?.update_info
                                          ?.payment_info?.customer?.amount
                                      }{" "}
                                    </p>
                                  </>
                                )}
                                {bookingInfo?.booking?.update_info?.type ===
                                  "refund" && (
                                  <>
                                    <p>
                                      Updated amount :&nbsp;&nbsp;
                                      <span className="currency-type-text">
                                        AED{" "}
                                      </span>
                                      {parseFloat(
                                        (
                                          bookingInfo?.booking
                                            ?.customer_payment_amount -
                                          bookingInfo?.booking?.update_info
                                            ?.refund_info?.customer
                                            ?.total_amount
                                        ).toFixed(2)
                                      ) || 0}{" "}
                                    </p>

                                    <p>
                                      You will be refunded : &nbsp;&nbsp;
                                      <span className="currency-type-text">
                                        AED{" "}
                                      </span>
                                      {
                                        bookingInfo?.booking?.update_info
                                          ?.refund_info?.customer?.total_amount
                                      }{" "}
                                    </p>
                                  </>
                                )}
                                <p className="my-3">
                                  {" "}
                                  <b>Note</b> :{" "}
                                  {bookingInfo?.booking?.update_info?.type ===
                                  "refund" ? bookingInfo?.booking?.refund_notes : bookingInfo?.booking?.update_notes}
                                </p>
                                <div className="text-center">
                                  <button
                                    className="my-2 btn btn-primary"
                                    onClick={() =>
                                      handleApprove(bookingInfo?.booking?.id)
                                    }
                                  >
                                    Approve
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class=" container-fluid absolute pb-4">
            <footer className="text-center text-lg-start">
              <div className="text-center">Powered by Alsaffron System.</div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingApprove;
