import Api from "../../../Utils/MerchantAxios";

export const GetCustomerWalletRequestPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet/merchant/deposit_requests?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  })
}
export const ApproveRejectCustomerWalletRequest = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/wallet/merchant/approve_reject_request', data));
    } catch (error) {
      reject(error);
    }
  })
}
export const DeleteCustomerWalletRequest = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/wallet/merchant/delete_deposit_request', data));
    } catch (error) {
      reject(error);
    }
  })
}



export const GetCustomerCreditRequestPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/credit_request/merchant/list?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  })
}
export const ApproveRejectCustomerCreditRequest = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/credit_request/merchant/approve_reject_request', data));
    } catch (error) {
      reject(error);
    }
  })
}
export const DeleteCustomerCreditRequest = async (trans_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete(`/master/credit_request/merchant/credit_request/${trans_id}`));
    } catch (error) {
      reject(error);
    }
  })
}
export const CloseMerchantCreditRequest = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/master/credit_request/merchant/close_credit_request`, data));
    } catch (error) {
      reject(error);
    }
  })
}
export const GetCustomerWalletTransactionPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet/merchant/transactions?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  })
}
export const GetWalletRequestById = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet/merchant/deposit_request_details?transaction_id=${id}`));
    } catch (error) {
      reject(error);
    }
  })
}
export const MerchantGetWalletBalanceService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get("master/wallet/merchant/widget_counts"));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetWalletListFilter = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/wallet/merchant/transactions', data));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetWalletExport = async (data) => {
  return new Promise(async (resolve, reject) => {
      try{
          resolve(await Api.post("/master/wallet/merchant/export_transactions",data));
      }catch(error){
          reject(error);
      }
  })
}