import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { GetTermsConditions } from "../../../Services/Api/master";
import { ErrorToast } from "../../../Utils/SweetAlert";

const TermsConditions = () => {
  const [termsConditions, setTermsConditions] = useState({ __html: "" });

  const getTermsConditionData = async () => {
    GetTermsConditions("merchant").then((res) => {
      console.log(res?.data?.data);
      setTermsConditions({ __html: res?.data?.data });
    })
    .catch((err) => {
      if(typeof err.response.data.message !== 'undefined'){
        ErrorToast(err?.response?.data?.message || 'Server Error!!')
      }else{
        ErrorToast(err?.message || 'Server Error!!')
      }
    });
  };

  useEffect(() => {
    getTermsConditionData();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Merchant Terms And Condition</title>
      </Helmet>
      <div className="terms-conditions">
      <div class="header header-one" bis_skin_checked="1">
        <div bis_skin_checked="1">
          <div class="header-left header-left-one" bis_skin_checked="1">
            <span
              class="logo"
              bis_size='{"x":66,"y":0,"w":108,"h":60,"abs_x":66,"abs_y":0}'
            >
              <img
                src="/inner-assets/img/logo.png"
                alt="Logo"
                bis_size='{"x":66,"y":9,"w":108,"h":45,"abs_x":66,"abs_y":9}'
                bis_id="bn_lotwgcxq45a1o1gr5g97zo"
              />
            </span>
            <span class="logo logo-small">
              <img
                src="/inner-assets/img/logo-small.png"
                alt="Logo"
                width="30"
                height="30"
              />
            </span>
          </div>
        </div>
      </div>
      <div dangerouslySetInnerHTML={termsConditions} />
      </div>
    </>
  );
};

export default TermsConditions;