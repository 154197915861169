import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import {
  AddWalletAmountService,
  CardAddWalletAmountService,
} from "../../../Services/Api/Client/customerAuth";
import { bankAccountListService } from "../../../Services/Api/Client/customerWalletRequest";
import { fileUploadToBucket } from "../../../Services/Api/Client/fileUpload";
import _ from "lodash";
import DragAndDropFile from "../../../Utils/DragAndDropFile";
import { useDebounce } from "use-debounce";
import { GetDepositOptionService } from "../../../Services/Api/Client/booking";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import format from "date-fns/format";

const AddWalletAmount = () => {
  const fileTypes = ["image/*", ".pdf", ".docx", ".doc", ".pptx"];
  const [imageKey, setImageKey] = useState(0);
  const [debounceKey] = useDebounce(imageKey, 1000);

  const history = useNavigate();
  const [items, setItems] = useState("");
  const [name, setName] = useState("");
  const [logo, setLogo] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [isBankShow, setIsBankShow] = useState(false);
  const [bankName, setBankName] = useState([]);
  const [beneficiary, setBeneficiary] = useState("");
  const [title, setTitle] = useState("");
  const [iban, setIban] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [bankCurrency, setBankCurrency] = useState("");
  const [disableField, setDisableField] = useState(false);
  const [tokenUrl, setTokenUrl] = useState(null);
  const [showPaybyFrame, setShowPaybyFrame] = useState(false);
  const [showCardForm, setShowCardForm] = useState(true);
  const [depositOption, setDepositOption] = useState([]);
  const [receiptFileError, setReceiptFileError] = useState();
  const [onClickTrueError, setOnClickTrueError] = useState(false);

  const typeOFPayment = [
    { label: "CASH DEPOSIT", value: "CASH_DEPOSIT" },
    { label: "CHEQUE DEPOSIT", value: "CHEQUE_DEPOSIT" },
    { label: "BANK TRANSFER", value: "BANK_TRANSFER" },
  ];

  const signInSchema = Yup.object().shape({
    bank_reference: Yup.string().required("Bank reference is required"),
    user_reference: Yup.string().required("User reference is required"),
    destination_bank: Yup.string().nullable().required("Please select a bank"),
    deposited_amount: Yup.number()
      .nullable()
      .positive()
      .required("Deposited amount is required"),
    transaction_currency: Yup.string()
      .nullable()
      .required("Please select a transaction currency type"),
    motive: Yup.string().required("Please select type of payment"),
    transaction_amount: Yup.number()
      .nullable()
      .positive()
      .required("Credited / Transaction amount is required"),
    date_of_payment: Yup.date().required("Please select a Date"),
    comments: Yup.string().required("Comments is required"),
    receipt_file: Yup.mixed().required("Please upload receipt."),
  });
  const initialValues = {
    date_of_payment: "",
    destination_bank: "",
    on_behalf_of: name,
    deposited_currency: "",
    deposited_amount: "",
    transaction_currency: "AED",
    transaction_amount: "",
    bank_reference: "",
    user_reference: "",
    comments: "",
    motive: "",
    receipt_file: "",
  };
  const initialCardValues = {
    amount: "",
    details: "",
  };
  const cardSignInSchema = Yup.object().shape({
    amount: Yup.number()
      .moreThan(0, "Amount must be greater than 0")
      .required("Enter amount"),
    details: Yup.string().required("Enter detail"),
  });
  const bankAccountList = async () => {
    bankAccountListService()
      .then((res) => {
        if (res?.data?.data?.accounts.length > 0) {
          let bankarr = res?.data?.data?.accounts;
          bankarr = bankarr.map((value) => {
            return {
              label: value.title,
              value: value.id,
              cur: value.currency,
              logo: value.logo,
              accountNumber: value.account_number,
              beneficiary: value.beneficiary,
              iban: value.iban,
              swiftCode: value.swift_code,
              title: value.title,
            };
          });
          setBankName(bankarr);
        } else {
          // ErrorToast("No Results.");
          setBankName([]);
        }
      })
      .catch((err) => {
        setBankName([]);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  const disable = () => {
    if (bankCurrency == "AED") {
      setDisableField(true);
    } else {
      setDisableField(false);
    }
  };
  const location = useLocation();
  const data = location.state;
  const [activeId, setActiveId] = useState(data?.activeId || 0);
  const checkNavigate = () => {
    if (data.id === 1) {
      history("/client/wallet-request");
      setActiveId(1);
    } else if (data.id === 2) {
      history("/client/wallet-request-card");
      setActiveId(0);
    } else if (data.id === 3) {
      history("/client/wallet");
      setActiveId(1);
    } else if (data.id === 4) {
      history("/client/dashboard");
      setActiveId(1);
    } else if (data.id === 5) {
      history("/client/bookings");
      setActiveId(1);
    }
  };

  const getDepositOption = async () => {
    GetDepositOptionService()
      .then((res) => {
        setDepositOption(res?.data?.data?.depositOptions);
      })
      .catch((err) => {
        // if(typeof err.response.data.message != 'undefined'){
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const loadPaybyFrame = () => {
    setShowPaybyFrame(true);
    setShowCardForm(false);
    const script = document.createElement("script");
    script.src = "https://checkout.payby.com/sdk/payby-sdk.umd.js";
    script.async = true;
    document.body.appendChild(script);

    setTimeout(() => {
      window.PayBy.createIframe({
        id: "paypageContainer",
        lang: "en",
        tokenUrl: tokenUrl,
        error: function (msg) {
          document.getElementById("paypageError").innerText = msg;
        },
      });
    }, 5000);
  };

  useEffect(() => {
    if (tokenUrl) {
      setShowPaybyFrame(true);
      setShowCardForm(false);
      const script = document.createElement("script");
      script.src = "https://checkout.payby.com/sdk/payby-sdk.umd.js";
      script.async = true;
      document.body.appendChild(script);

      setTimeout(() => {
        window.PayBy.createIframe({
          id: "paypageContainer",
          lang: "en",
          tokenUrl: tokenUrl,
          error: function (msg) {
            document.getElementById("paypageError").innerText = msg;
          },
        });
      }, 5000);
    }
  }, [tokenUrl]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("clientUserData"));
    if (items) {
      setItems(items);
      setName(items.name);
    }
    bankAccountList();
    getDepositOption();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Customer Add Wallet Amount</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Add Wallet Amount</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/client/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Add Wallet Amount</li>
                </ul>
              </div>
              <div className="col-auto">
                {/* <Link to="/client/wallet-request" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link> */}
                <span
                  className="btn btn-primary me-1"
                  onClick={() => checkNavigate()}
                >
                  <i className="fas fa-angle-double-left" /> Back
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card bg-white">
                <div className="card-body">
                  <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                    {depositOption?.cash === 1 && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            activeId === 1 ? "active" : ""
                          }`}
                          href="#cash-wallet-amount"
                          data-bs-toggle="tab"
                        >
                          Cash / Cheque
                        </a>
                      </li>
                    )}
                    {depositOption?.card === 1 && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            activeId === 0 ? "active" : ""
                          }`}
                          href="#card-wallet-amount"
                          data-bs-toggle="tab"
                        >
                          Card
                        </a>
                      </li>
                    )}
                  </ul>
                  <div className="tab-content">
                    {depositOption?.cash === 1 && (
                      <div
                        className={`tab-pane show ${
                          activeId === 1 ? "active" : ""
                        }`}
                        id="cash-wallet-amount"
                      >
                        <Formik
                          enableReinitialize
                          initialValues={initialValues}
                          validationSchema={signInSchema}
                          validateOnChange
                          onSubmit={async (values, { setSubmitting }) => {
                            const postObj = {
                              user_id: items.id,
                              date_of_payment: format(
                                new Date(values.date_of_payment),
                                "yyyy-MM-dd"
                              ),
                              destination_bank: values.destination_bank,
                              on_behalf_of: values.on_behalf_of,
                              deposited_currency: bankCurrency,
                              deposited_amount: values.deposited_amount,
                              transaction_currency: "AED",
                              transaction_amount: values.transaction_amount,
                              bank_reference: values.bank_reference,
                              user_reference: values.user_reference,
                              motive: values.motive,
                              comments: values.comments,
                              receipt_file: values.receipt_file,
                            };
                            await AddWalletAmountService(postObj)
                              .then((res) => {
                                SuccessToast(
                                  res?.data?.message || "Customer Added Amount."
                                );
                                setSubmitting(false);
                                setTimeout(() => {
                                  history("/client/wallet-request");
                                }, 1000);
                              })
                              .catch((err) => {
                                if (
                                  typeof err.response.data.message !==
                                  "undefined"
                                ) {
                                  ErrorToast(
                                    err.response.data.message ||
                                      "Server Error!!"
                                  );
                                } else {
                                  ErrorToast(err?.message || "Server Error!!");
                                }
                                setSubmitting(false);
                              });
                            setTimeout(() => {
                              setSubmitting(false);
                            }, 400);
                          }}
                        >
                          {(formik) => {
                            const {
                              touched,
                              errors,
                              setFieldValue,
                              handleChange,
                              isSubmitting,
                              values,
                            } = formik;
                            return (
                              <Form>
                                <div className="row">
                                  <div className="col-md-8">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label className="label-dark">
                                            Date of Payment{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <DatePicker
                                            autoComplete="off"
                                            className={`form-control ${
                                              errors.date_of_payment &&
                                              touched.date_of_payment
                                                ? "input-error"
                                                : null
                                            }`}
                                            name="date_of_payment"
                                            onChange={(date) =>
                                              setFieldValue(
                                                "date_of_payment",
                                                date
                                              )
                                            }
                                            selected={values.date_of_payment}
                                            placeholderText="mm-dd-yyyy"
                                            maxDate={new Date()}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                          />
                                          <ErrorMessage
                                            name="date_of_payment"
                                            component="span"
                                            className="error"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group destination-dropdown">
                                          <label htmlFor="bank">
                                            Destination/Bank{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <Select
                                            options={bankName}
                                            placeholder="Select bank"
                                            onChange={(e) => {
                                              setFieldValue(
                                                "destination_bank",
                                                e.value
                                              );
                                              setFieldValue("cur", e.cur);
                                              setFieldValue("logo", e.logo);
                                              setFieldValue(
                                                "accountNumber",
                                                e.account_number
                                              );
                                              setFieldValue(
                                                "beneficiary",
                                                e.beneficiary
                                              );
                                              setFieldValue("iban", e.iban);
                                              setFieldValue(
                                                "swiftCode",
                                                e.swift_code
                                              );
                                              setFieldValue("title", e.title);
                                              setTitle(e.title);
                                              setIban(e.iban);
                                              setLogo(e.logo);
                                              setAccountNumber(e.accountNumber);
                                              setBeneficiary(e.beneficiary);
                                              setBankCurrency(e.cur);
                                              setSwiftCode(e.swiftCode);
                                              if (!isBankShow) {
                                                setIsBankShow(true);
                                              }
                                              // disable()
                                            }}
                                            name="destination_bank"
                                          />
                                          <ErrorMessage
                                            name="destination_bank"
                                            component="span"
                                            className="error"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group ">
                                          <label>
                                            Amount Deposited{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                              <span
                                                className="input-group-text"
                                                id="basic-addon3"
                                              >
                                                {bankCurrency || "-"}
                                              </span>
                                            </div>
                                            <Field
                                              type="text"
                                              aria-describedby="basic-addon3"
                                              className={`form-control ${
                                                errors.deposited_amount &&
                                                touched.deposited_amount
                                                  ? "input-error"
                                                  : null
                                              }`}
                                              name="deposited_amount"
                                              placeholder="Enter deposited amount"
                                              onChange={handleChange}
                                              onBlur={(e) => {
                                                if (bankCurrency === "AED") {
                                                  setFieldValue(
                                                    "transaction_amount",
                                                    e.target.value
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                          <label>
                                            Actual currency and amount been
                                            deposited{" "}
                                          </label>
                                          <ErrorMessage
                                            name="deposited_amount"
                                            component="span"
                                            className="error"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Amount to be Credited{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                              <span
                                                className="input-group-text"
                                                id="basic-addon3"
                                              >
                                                AED
                                              </span>
                                            </div>
                                            <Field
                                              type="text"
                                              placeholder="Enter credited amount"
                                              className={`form-control ${
                                                errors.transaction_amount &&
                                                touched.transaction_amount
                                                  ? "input-error"
                                                  : null
                                              }`}
                                              name="transaction_amount"
                                              disabled={disableField}
                                              // value={bankCurrency === "AED" ?  values.deposited_amount : values.transaction_amount }
                                              value={values.transaction_amount}
                                              onChange={handleChange}
                                            />
                                          </div>
                                          <span>
                                            Conversion rate :&nbsp;&nbsp;
                                            {values.deposited_amount != "" &&
                                            values.transaction_amount != "" ? (
                                              <>
                                                <span className="currency-type-text">
                                                  {bankCurrency}
                                                </span>{" "}
                                                {(
                                                  values.deposited_amount /
                                                  values.transaction_amount
                                                ).toFixed(4)}{" "}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                          <br />
                                          <label>
                                            Amount and currency you claim to
                                            credit into your account
                                          </label>
                                          <ErrorMessage
                                            name="transaction_amount"
                                            component="span"
                                            className="error"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            On Behalf of{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <Field
                                            type="text"
                                            onChange={handleChange}
                                            className={`form-control ${
                                              errors.on_behalf_of &&
                                              touched.on_behalf_of
                                                ? "input-error"
                                                : null
                                            }`}
                                            name="on_behalf_of"
                                            value={name}
                                            disabled
                                          />
                                          <ErrorMessage
                                            name="on_behalf_of"
                                            component="span"
                                            className="error"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Bank Reference{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <Field
                                            type="text"
                                            placeholder="Enter bank reference"
                                            className={`form-control ${
                                              errors.bank_reference &&
                                              touched.bank_reference
                                                ? "input-error"
                                                : null
                                            }`}
                                            name="bank_reference"
                                            onBlur={(e) => {
                                              if (!values.user_reference) {
                                                setFieldValue(
                                                  "user_reference",
                                                  e.target.value
                                                );
                                              }
                                            }}
                                          />
                                          <ErrorMessage
                                            name="bank_reference"
                                            component="span"
                                            className="error"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Your Reference{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <Field
                                            type="text"
                                            placeholder="Enter your reference"
                                            className={`form-control ${
                                              errors.user_reference &&
                                              touched.user_reference
                                                ? "input-error"
                                                : null
                                            }`}
                                            name="user_reference"
                                            onChange={handleChange}
                                            value={values.user_reference}
                                          />
                                          <ErrorMessage
                                            name="user_reference"
                                            component="span"
                                            className="error"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group destination-dropdown">
                                          <label htmlFor="bank">
                                            Type of Payment{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <Select
                                            options={typeOFPayment}
                                            onChange={(e) => {
                                              setFieldValue("motive", e.value);
                                            }}
                                            name="motive"
                                            placeholder="Select type of payment"
                                          />
                                          <ErrorMessage
                                            name="motive"
                                            component="span"
                                            className="error"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Comments{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <Field
                                            as="textarea"
                                            rows={5}
                                            cols={5}
                                            type="text"
                                            className={`form-control ${
                                              errors.comments &&
                                              touched.comments
                                                ? "input-error"
                                                : null
                                            }`}
                                            name="comments"
                                            placeholder="Enter comment"
                                            onChange={handleChange}
                                          />
                                          <ErrorMessage
                                            name="comments"
                                            component="span"
                                            className="error"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group dropzone-image-area">
                                          <label>
                                            Receipt{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <DragAndDropFile
                                            randerKey={debounceKey}
                                            maxFileSize={2000000} //2mb
                                            label="Drag and drop a image or click"
                                            fileTypes={[
                                              "image/*",
                                              ".pdf",
                                              ".docx",
                                              ".doc",
                                              ".pptx",
                                            ]}
                                            className={`form-control dropzoneBox ${
                                              errors.receipt_file &&
                                              touched.receipt_file
                                                ? "input-error"
                                                : null
                                            }`}
                                            handleChange={async (file) => {
                                              setFieldValue(
                                                "receipt_file",
                                                file[0]
                                              );
                                              let imageData = file[0];
                                              const imagePostData = {
                                                user_id: items.id,
                                                file_name: imageData?.name,
                                                file_module: "WALLET_RECEIPT",
                                                mime_type: imageData?.type,
                                                document_sides: "NONE",
                                              };
                                              await fileUploadToBucket({
                                                data: imagePostData,
                                                file: imageData,
                                              })
                                                .then(async (res) => {
                                                  setOnClickTrueError(false);
                                                  setFieldValue(
                                                    "receipt_file",
                                                    res?.data?.data?.s3_key
                                                  );
                                                })
                                                .catch((err) => {
                                                  setOnClickTrueError(true);
                                                  if (
                                                    typeof err.response.data
                                                      .message != "undefined"
                                                  ) {
                                                    ErrorToast(
                                                      err.response.data
                                                        .message ||
                                                        "Server Error!!"
                                                    );
                                                  } else {
                                                    ErrorToast(
                                                      err?.message ||
                                                        "Server Error!!"
                                                    );
                                                  }
                                                });
                                            }}
                                            acceptedFiles={fileTypes}
                                          />
                                          {onClickTrueError ? (
                                            <span className=" error">
                                              {receiptFileError}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          <ErrorMessage
                                            name="receipt_file"
                                            component="span"
                                            className="error"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* col-md-8 */}
                                  <div
                                    className={`col-md-4${
                                      !isBankShow ? ` d-none` : ``
                                    }`}
                                  >
                                    <div className="text-center">
                                      <img src={logo} alt="bank logo" />{" "}
                                    </div>
                                    <table className="table table-stripped table-responsive custom-table d-block">
                                      <tbody>
                                        <tr>
                                          <td style={{ width: "10%" }}>Bank</td>
                                          <th>{title}</th>
                                        </tr>
                                        <tr>
                                          <td style={{ width: "10%" }}>
                                            Holder / Beneficiary
                                          </td>
                                          <th>{beneficiary}</th>
                                        </tr>
                                        <tr>
                                          <td style={{ width: "10%" }}>
                                            Account Number
                                          </td>
                                          <th>{accountNumber}</th>
                                        </tr>
                                        <tr>
                                          <td style={{ width: "10%" }}>IBAN</td>
                                          <th>{iban}</th>
                                        </tr>
                                        <tr>
                                          <td style={{ width: "10%" }}>
                                            Swift Code
                                          </td>
                                          <th>{swiftCode}</th>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="text-center  mt-4">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                      setOnClickTrueError(true);
                                      setReceiptFileError(
                                        errors.receipt_file &&
                                        JSON.stringify(
                                          errors.receipt_file
                                        ).replace(/['"]+/g, "")
                                      );
                                    }}
                                  >
                                    Submit&nbsp;&nbsp;
                                    {isSubmitting && (
                                      <i className="fas fa-spinner fa-spin"></i>
                                    )}
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    )}
                    {depositOption?.card === 1 && (
                      <div
                        className={`tab-pane ${activeId === 0 ? "active" : ""}`}
                        id="card-wallet-amount"
                      >
                        {showCardForm && (
                          <Formik
                            enableReinitialize
                            initialValues={initialCardValues}
                            validationSchema={cardSignInSchema}
                            validateOnChange
                            onSubmit={async (values, { setSubmitting }) => {
                              const postObj = {
                                user_id: items.id,
                                amount: values.amount,
                                details: values.details,
                              };
                              await CardAddWalletAmountService(postObj)
                                .then((res) => {
                                  SuccessToast(res?.data?.message || "");
                                  setSubmitting(false);
                                  const paymentTokenUrl =
                                    res?.data?.data?.interActionParams.tokenUrl;
                                  // setTokenUrl(paymentTokenUrl);
                                  window.open(paymentTokenUrl, "_blank");
                                  // loadPaybyFrame();
                                })
                                .catch((err) => {
                                  if (
                                    typeof err.response.data.message !==
                                    "undefined"
                                  ) {
                                    ErrorToast(
                                      err.response.data.message ||
                                        "Server Error!!"
                                    );
                                  } else {
                                    ErrorToast(
                                      err?.message || "Server Error!!"
                                    );
                                  }
                                  setSubmitting(false);
                                });
                              setTimeout(() => {
                                setSubmitting(false);
                              }, 400);
                            }}
                          >
                            {(formik) => {
                              const {
                                touched,
                                errors,
                                values,
                                handleChange,
                                isSubmitting,
                              } = formik;
                              return (
                                <Form>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Amount{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          type="number"
                                          className={`form-control ${
                                            errors.amount && touched.amount
                                              ? "input-error"
                                              : null
                                          }`}
                                          name="amount"
                                          onChange={handleChange}
                                          placeholder="Enter amount"
                                        />
                                        <p className="mx-2">
                                          {depositOption?.convenience_fee}
                                          %&nbsp;convenience fee included{" "}
                                          <span className="mail-view-action">
                                            You have to pay :&nbsp;
                                            {values.amount != "" ? (
                                              <>
                                                {(
                                                  values?.amount +
                                                  (values.amount / 100) *
                                                    depositOption?.convenience_fee
                                                ).toFixed(2)}{" "}
                                              </>
                                            ) : (
                                              0.0
                                            )}
                                          </span>
                                        </p>
                                        <ErrorMessage
                                          name="amount"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Details{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          className={`form-control ${
                                            errors.details && touched.details
                                              ? "input-error"
                                              : null
                                          }`}
                                          name="details"
                                          onChange={handleChange}
                                          placeholder="Enter detail"
                                        />
                                        <ErrorMessage
                                          name="details"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-center  mt-4">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      disabled={isSubmitting}
                                    >
                                      Submit&nbsp;&nbsp;
                                      {isSubmitting && (
                                        <i className="fas fa-spinner fa-spin"></i>
                                      )}
                                    </button>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        )}
                        {showPaybyFrame && (
                          <>
                            <div id="paypageContainer">
                              <div className="loading-div">
                                <img
                                  className="frame-loader"
                                  src="/inner-assets/img/loading.gif"
                                  alt="Loading..."
                                />
                              </div>
                            </div>
                            <div id="paypageError"></div>
                          </>
                        )}
                      </div>
                    )}
                    {depositOption?.cash === 0 && depositOption?.card === 0 && (
                      <>
                        <h4 className="text-center">
                          Card and Cash deposit currently disabled.
                        </h4>
                        <div className="text-center mt-4">
                          <button
                            className="btn btn-primary"
                            onClick={() => checkNavigate()}
                          >
                            Back to list
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddWalletAmount;
