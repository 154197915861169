import React, {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { MerchantRegisterRequestService, MerchantRegisterService } from "../../../Services/Api/Merchant/merchantAuth"
import { SuccessToast, ErrorToast } from "../../../Utils/SweetAlert";
import { Helmet } from 'react-helmet-async';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { url } from '../../../Utils/constants';

const Register = () => {

  // const [isShowPassword, setIsShowPassword] = useState(false);
  // const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/
  const initialValues = {
    company_name: "",
    website: "",
    contact_person_name: "",
    department: "",
    designation: "",
    telephone_number: "",
    email: "",
    // password: "",
    // confirmPassword: "",
  };
  
  const merchantRegisterSchema = Yup.object().shape({
    company_name: Yup.string().required("Company name is required"),
    website: Yup.string().matches(url, 'Invalid URL format').required('Website URL is required'),
    contact_person_name: Yup.string().required("Contact person name is required").matches(/^[aA-zZ\s]+$/,'Only alphabets are allowed'),
    department: Yup.string().required("Department is required"),
    designation: Yup.string().required("Designation is required"),
    telephone_number: Yup.string().matches(phoneRegExp, "Please enter a correct  number").required('Telephone number is required.'),
    email: Yup.string().email().required("Email is required"),
    // password: Yup.string()
    //   .required('Password is required')
    //   .test('uppderCase', 'Must contain at least one uppercase character', (value) => /[A-Z]/.test(value))
    //   .test('lowerCase', 'Must contain at least one lowercase character', (value) => /[a-z]/.test(value))
    //   .test('oneNumber', 'Must contain at least one number', (value) => /[0-9]/.test(value))
    //   .test('oneSpecial', 'Must contain at least one special character', (value) => /[!@#%&]/.test(value)),
    // confirmPassword: Yup.string()
    //   .required('Confirm password is required')
    //   .oneOf([Yup.ref('password')], 'Passwords must match')
  });

  return (
    <>
      <Helmet>
        <title>CPay | Merchant Register</title>
      </Helmet>
    <div className="main-wrapper login-body">
      <div className="login-wrapper">
        <div className="container">
          <img className="img-fluid logo-dark mb-2" src="/inner-assets/img/logo.png" alt="Logo" />
          <div className="loginbox">
            <div className="login-right">
              <div className="login-right-wrap">
                <h1>Register</h1>
                <p className="account-subtitle">Access to our dashboard</p>
                <Formik
                  initialValues={initialValues}
                  validationSchema={merchantRegisterSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    const postObj = {
                      company_name: values.company_name,
                      website: values.website,
                      contact_person_name: values.contact_person_name,
                      department: values.department,
                      designation: values.designation,
                      telephone_number: values.telephone_number,
                      email: values.email,
                    }
                    // await MerchantRegisterService(postObj).then((res) => {
                    await MerchantRegisterRequestService(postObj).then((res) => {
                      SuccessToast(res?.data?.message || 'Register successfully.');
                      setSubmitting(false);
                      navigate('/merchant/auth/login');
                    }).catch((err) => {
                      if (typeof err.response.data.message !== 'undefined') {
                        ErrorToast(err.response.data.message || 'Server Error!!');
                      } else {
                        ErrorToast(err?.message || 'Server Error!!');
                      }
                    })
                  }}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    values,
                    setFieldValue,
                    handleSubmit
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label className="form-control-label">Comapny Name <span className='text-danger'>*</span></label>
                          <Field
                            type="text"
                            className={`form-control ${errors.company_name && touched.company_name ? "input-error" : null}`}
                            name="company_name"
                            placeholder="Enter company name"
                          />
                          <ErrorMessage name="company_name" component="span" className="error" />
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">Website <span className='text-danger'>*</span></label>
                          <Field
                            type="text"
                            className={`form-control ${errors.website && touched.website ? "input-error" : null}`}
                            name="website"
                            placeholder="Enter Website Url"
                          />
                          <ErrorMessage name="website" component="span" className="error" />
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">Contact Person Name <span className='text-danger'>*</span></label>
                          <Field
                            type="text"
                            className={`form-control ${errors.contact_person_name && touched.contact_person_name ? "input-error" : null}`}
                            name="contact_person_name"
                            placeholder="Enter contact person name"
                          />
                          <ErrorMessage name="contact_person_name" component="span" className="error" />
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">Department <span className='text-danger'>*</span></label>
                          <Field
                            type="text"
                            className={`form-control ${errors.department && touched.department ? "input-error" : null}`}
                            name="department"
                            placeholder="Enter department"
                          />
                          <ErrorMessage name="department" component="span" className="error" />
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">Designation <span className='text-danger'>*</span></label>
                          <Field
                            type="text"
                            className={`form-control ${errors.designation && touched.designation ? "input-error" : null}`}
                            name="designation"
                            placeholder="Enter designation"
                          />
                          <ErrorMessage name="designation" component="span" className="error" />
                        </div>
                        <div className="form-group remove-inner-input">
                          <label className='form-contol-label'>Telephone Number*</label>
                                <PhoneInput
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="AE"
                                  type="text"
                                  value={values.telephone_number}
                                  onChange={(newVal) =>{ setFieldValue('telephone_number', newVal)
                                }}
                                className={
                                    `form-control ${errors.telephone_number && touched.telephone_number ? "input-error" : null}`
                                  }
                                  name='telephone_number'
                                />
                            <ErrorMessage name="telephone_number" component="span" className="error" />
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">Email <span className='text-danger'>*</span></label>
                          <Field
                            type="email"
                            className={`form-control ${errors.email && touched.email ? "input-error" : null}`}
                            name="email"
                            placeholder="Enter email"
                          />
                          <ErrorMessage name="email" component="span" className="error" />
                        </div>
                        {/* <div className="form-group">
                          <label className="form-control-label">Password <span className='text-danger'>*</span></label>
                          <div className="pass-group">
                            <Field
                              type={isShowPassword ? 'text' : 'password'}
                              id="password"
                              className={`form-control ${errors.password && touched.password ? "input-error" : null}`}
                              name="password"
                              placeholder="Enter password"
                            />
                            <span onClick={() => setIsShowPassword(!isShowPassword)} className={`fas ${isShowPassword ? 'fa-eye-slash' : 'fa-eye'} toggle-password`} />
                          </div>
                          <ErrorMessage name="password" component="span" className="error" />
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">Confirm Password <span className='text-danger'>*</span></label>
                          <div className="pass-group">
                            <Field
                              type={isShowConfirmPassword ? 'text' : 'password'}
                              className={`form-control ${errors.confirmPassword && touched.confirmPassword ? "input-error" : null}`}
                              name="confirmPassword"
                              placeholder="Enter confirm password"
                            />
                            <span onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
                             className={`fas ${isShowConfirmPassword ? 'fa-eye-slash' : 'fa-eye'} toggle-password`} />
                          </div>
                          <ErrorMessage name="confirmPassword" component="span" className="error" />
                        </div> */}
                        <div className="form-group mb-0">
                          <button
                            className="btn btn-lg btn-block btn-primary w-100"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Register&nbsp;&nbsp;
                            {isSubmitting && (
                              <i className="fas fa-spinner fa-spin"></i>
                            )}
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
                <div className="login-or">
                  <span className="or-line" />
                  <span className="span-or">or</span>
                </div>
                <div className="text-center dont-have">Already have an account? <Link to="/merchant/auth/login">Login</Link></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Register