import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ForgotPasswordService } from '../../../Services/Api/Client/customerAuth';
import { SuccessToast, ErrorToast } from "../../../Utils/SweetAlert";
import { Helmet } from 'react-helmet-async';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const forgotSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
  });

  const initialValues = {
    email: "",
  };

  return (
    <>
      <Helmet>
        <title>CPay | Customer ForgotPassword</title>
      </Helmet>
    <div className="common-page">
      <span className="scene d-none">
        <small className="d-none" data-depth="0.2"></small>
      </span>
      <div className="brand-logo">
        <Link to="#" className="brand-link">
          <img src="/image/register-login-common/logo-2.png" alt="logo_not_found" />
        </Link>
      </div>
      <section id="register-section" className="register-section clearfix">
        <div className="common-container">
          <div className="image-container">
            <img src="/image/register-login-common/common-1.png" alt="image_not_found" />
          </div>
        </div>
        <div className="common-container bg-default-blue login-container">
          <div className="item-content">
            <h2 className="title-text mb-4">We are CPay</h2>
            <p className="mb-5">
             Please Recover your Password
            </p>
            <div className="register-form">
              <Formik
                initialValues={initialValues}
                validationSchema={forgotSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  await ForgotPasswordService(values).then((res) => {
                    SuccessToast(res?.data?.message || 'Password changed Successfully');
                    setSubmitting(false);
                    navigate('/client/auth/login');
                  }).catch((err) => {
                    if (typeof err.response.data.message !== 'undefined') {
                      ErrorToast(err.response.data.message || 'Server Error!!');
                    } else {
                      ErrorToast(err?.message || 'Server Error!!');
                    }
                  })
                  setTimeout(() => {
                    setSubmitting(false);
                  }, 400);
                }}
              >
                {({
                  errors,
                  touched,
                  isSubmitting
                }) => {
                  return (
                    <Form id="register-form">
                      <div className="form-group">
                        <label htmlFor="reg-email" className='form-control-label'>Email*</label>
                        <Field
                          type="email"
                          className={`form-control ${errors.email && touched.email ? "input-error" : null}`}
                          id="reg-email"
                          name="email"
                          placeholder="Enter email"
                        />
                        <ErrorMessage name="email" component="span" className="error" />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-block btn-theme  w-100"
                      >
                        Submit&nbsp;&nbsp;
                        {isSubmitting && (
                          <i className="fas fa-spinner fa-spin"></i>
                        )}
                      </button>
                      <div className="login-or">
                        <span className="or-line"></span>
                        <span className="span-or forgot">or</span>
                      </div>
                      <div className="text-center dont-have register-link-client"><b><u><Link to="/client/auth/login">Login</Link></u></b></div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
          <small className="shape-1"><img src="/image/register-login-common/cross.png" alt="image_not_found" /></small>
          <small className="shape-2"><img src="/image/register-login-common/flow-1.png" alt="image_not_found" /></small>
          <small className="shape-3"><img src="/image/register-login-common/box.png" alt="image_not_found" /></small>
          <small className="shape-4"><img src="/image/register-login-common/box.png" alt="image_not_found" /></small>
          <small className="shape-5"><img src="/image/register-login-common/circle-half.png" alt="image_not_found" /></small>
          <small className="shape-6"><img src="/image/register-login-common/cross.png" alt="image_not_found" /></small>
          <small className="shape-7"><img src="/image/register-login-common/flow-2.png" alt="image_not_found" /></small>
          <small className="shape-8"><img src="/image/register-login-common/circle.png" alt="image_not_found" /></small>
          <small className="shape-9"><img src="/image/register-login-common/circle-half.png" alt="image_not_found" /></small>
        </div>
      </section>
    </div>
    </>
  )
}

export default ForgotPassword