import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import { ErrorToast } from "../../../Utils/SweetAlert";
import {
  GetStatementPaginate,
  GetStatementPaginateFilter,
  GetTransactionExportFile,
  GetTransactionExportFileFilter,
} from "../../../Services/Api/Client/statement";
import format from "date-fns/format";
import { saveAs } from "file-saver";
import { FilterComponentStatement } from "../Common";

const Statement = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [statementListPagination, setStatementListPagination] = useState([]);
  const [dateForReport, setDateForReport] = useState("");
  const [statementFilter, setStatementFilter] = useState({
    offset: page || 0,
    limit: limit || 10,
    user_id: "",
    filter_date: "",
  });
  const [resetDate, setResetDate] = useState(0);
  const navigate = useNavigate();

  const handleReset = async ({ fDate }) => {
    await setResetDate(0);
    setResetDate(1);
    setPage(0);
    const postData = {
      offset: 0,
      limit: limit || 10,
      user_id: "",
      filter_date: fDate || "",
    };
    setDateForReport("");
    setStatementFilter(postData);
  };

  const handleSubmitFilter = async ({ fDate }) => {
    setResetDate(0);
    setPage(0);
    const postData = {
      offset: 0,
      limit: limit || 10,
      user_id: "",
      filter_date: fDate || "",
    };
    setStatementFilter(postData);
  };

  // const getStatementList = async (limitO = "", pageO = "") => {
  //     setIsLoading(true);
  //     await GetStatementPaginate({ limit: limitO, page: pageO })
  //         .then((res) => {
  //             setIsLoading(false);
  //             if (res?.data?.data?.totalRecords > 0) {
  //                 setStatementListPagination(res?.data?.data?.transactions);
  //                 setTotalRecords(res?.data?.data?.totalRecords);
  //             } else {
  //                 setStatementListPagination([]);
  //                 setTotalRecords(0);
  //                 // ErrorToast("No Results.");
  //             }
  //         })
  //         .catch((err) => {
  //             setStatementListPagination([]);
  //             setTotalRecords(0);
  //             setIsLoading(false);
  //             // if (typeof err.response.data.message !== "undefined") {
  //             //     ErrorToast(err.response.data.message || "Server Error!!");
  //             // } else {
  //             //     ErrorToast(err?.message || "Server Error!!");
  //             // }
  //         });
  // };

  const getStatementListFilter = async (requestPayload) => {
    setIsLoading(true);
    await GetStatementPaginateFilter(requestPayload)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setStatementListPagination(res?.data?.data?.transactions);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setStatementListPagination([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setStatementListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //     ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //     ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  const handleStatementExport = async (type) => {
    setResetDate(0);
    // setPage(0);
    const postData = {
      type: type,
      user_id: "",
      filter_date: dateForReport || "",
    };
    await GetTransactionExportFileFilter(postData)
      .then((res) => {
        if (res?.data?.data?.url) {
          saveAs(res?.data?.data?.url, res?.data?.data?.file_name);
        } else {
          ErrorToast("Export data does not exist.");
        }
      })
      .catch((err) => {
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    // await getStatementList(limit, pageNew);
    setStatementFilter((prevState) => ({ ...prevState, offset: pageNew }));
  };
  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    // await getStatementList(parseInt(event.target.value, 10), 0);
    setStatementFilter((prevState) => ({
      ...prevState,
      limit: event.target.value || 10,
      offset: 0,
    }));
  };

  useEffect(() => {
    // getStatementList(limit, page);
    getStatementListFilter(statementFilter);
  }, [statementFilter]);

  return (
    <>
      <Helmet>
        <title>CPay | Customer Statement</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Statement</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/client/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Statement</li>
                </ul>
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-sm btn-success"
                  style={{ marginRight: "5px" }}
                  onClick={() => handleStatementExport("EXCEL")}
                >
                  Export as Excel
                </button>
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => handleStatementExport("PDF")}
                >
                  Export as PDF
                </button>
              </div>
            </div>
          </div>
          <FilterComponentStatement
            handleSubmitFilter={handleSubmitFilter}
            handleReset={handleReset}
            resetDate={resetDate}
            setDateForReport={setDateForReport}
          />
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Sr. No.</th>
                          <th>Reference Number</th>
                          {/* <th>Pax Details</th> */}
                          <th>Description</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Opening Balance</th>
                          <th>Closing Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {statementListPagination.map((statement, index) => (
                              <tr
                                key={index}
                                // style={ { backgroundColor: '#e5e5e5' } }
                                // className={
                                //   statement?.motive == "CASH_DEPOSIT" ||
                                //   statement?.motive == "CHEQUE_DEPOSIT"
                                //     ? "table-success"
                                //     : statement?.motive == "CREDIT_REQUEST"
                                //     ? "bit-light"
                                //     : "table-info"
                                // }
                                className={
                                  statement.motive == "CREDIT_REQUEST" &&
                                  statement.closing_balance < 0
                                    ? "table-danger-regular"
                                    : statement.motive != "CREDIT_REQUEST"
                                    ? "table-normal"
                                    : "table-success"
                                }
                              >
                                <td>{limit * page + (index + 1)}</td>
                                <td>
                                  <a
                                    className="invoice-link"
                                    onClick={() =>
                                      navigate("/client/transaction-detail", {
                                        state: {
                                          id: statement?.reference_number,
                                        },
                                      })
                                    }
                                  >
                                    {statement?.reference_number}
                                    <br />
                                  </a>
                                  <span>
                                    {format(
                                      new Date(statement?.transactionDate),
                                      "dd MMMM, yyyy, hh:mm a"
                                    )}
                                  </span>
                                </td>
                                {/* <td>
                                  {statement?.booking?.product === "flight" && (
                                    <>
                                      {statement?.booking?.metadata?.travellers.map(
                                        (traveller, index) => (
                                          <div key={index}>
                                            {index + 1}. {traveller?.title}{" "}
                                            {traveller?.first_name}{" "}
                                            {traveller?.last_name}{" "}
                                            <span>
                                              ({traveller?.passenger_type})
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                  {statement?.booking?.product === "hotel" && (
                                    <>
                                      {statement?.booking?.metadata?.travellers.map(
                                        (traveller, index) => (
                                          <div key={index}>
                                            {index + 1}. {traveller?.title}{" "}
                                            {traveller?.first_name}{" "}
                                            {traveller?.last_name}{" "}
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                  {statement?.booking?.product === "visa" && (
                                    <div>
                                      {statement?.booking?.metadata?.visa?.first_name}{" "}
                                      {statement?.booking?.metadata?.visa?.last_name}
                                    </div>
                                  )}
                                </td> */}
                                <td className="content-new-line">{statement?.description}</td>
                                {(statement?.transaction_type == "DEBIT" && (
                                  <td>
                                    <span className="currency-type-text">
                                      {statement?.transaction_currency}
                                    </span>{" "}
                                    {statement?.transaction_amount}
                                  </td>
                                )) || <td>-</td>}
                                {(statement?.transaction_type == "CREDIT" && (
                                  <td>
                                    <span className="currency-type-text">
                                      {statement?.transaction_currency}
                                    </span>{" "}
                                    {statement?.transaction_amount}
                                  </td>
                                )) || <td>-</td>}
                                <td>
                                  <span className="currency-type-text">
                                    {statement?.transaction_currency}
                                  </span>{" "}
                                  {statement?.opening_balance}
                                </td>
                                <td>
                                  <span className="currency-type-text">
                                    {statement?.transaction_currency}
                                  </span>{" "}
                                  {statement?.closing_balance}
                                </td>
                              </tr>
                            ))}
                            {statementListPagination.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={7}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={7}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Statement;
