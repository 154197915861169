import React from 'react'
import InfoIcon from '@mui/icons-material/Info';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const NeedInvoiceIcon = () => {

  return (
    <>
      <Tooltip
        title="Merchant need to upload invoice"
      >
        <IconButton style={{ color: "#ed4646" }}>
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default NeedInvoiceIcon