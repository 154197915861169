import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "../../../Utils";
// import { ErrorToast } from "../../../Utils/SweetAlert";
import format from "date-fns/format";
import { GetCustomerWalletRequestPaginate } from "../../../Services/Api/Merchant/Wallet";
import { Helmet } from "react-helmet-async";
import { StartCase } from "react-lodash";
import _ from "lodash"

const WalletRequest = () => {

	const navigate = useNavigate();
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);
	const [totalRecords, setTotalRecords] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [customerWalletRequest, setCustomerWalletRequest] = useState([]);

	const getCustomerList = async (limitO = "", pageO = "") => {
		setIsLoading(true);
		await GetCustomerWalletRequestPaginate({ limit: limitO, page: pageO })
			.then((res) => {
				setIsLoading(false);
				if (res?.data?.data?.totalRecords > 0) {
					setCustomerWalletRequest(res?.data?.data?.transactions);
					setTotalRecords(res?.data?.data?.totalRecords);
				} else {
					setCustomerWalletRequest([]);
					setTotalRecords(0);
					// ErrorToast("No Results.");
				}
			})
			.catch((err) => {
				setCustomerWalletRequest([]);
				setTotalRecords(0);
				setIsLoading(false);
				// if (typeof err.response.data.message !== "undefined") {
				// 	ErrorToast(err.response.data.message || "Server Error!!");
				// } else {
				// 	ErrorToast(err?.message || "Server Error!!");
				// }
			});
	};

	const handlePageChange = async (e, pageNew) => {
		setPage(pageNew);
	};

	const handleLimitChange = async (event) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		getCustomerList(limit, page);
	}, [limit, page]);

	return (
		<>
			<Helmet>
				<title>CPay | Merchant Wallet Request</title>
			</Helmet>
			<div className="page-wrapper">
				<div className="content container-fluid">
					<div className="page-header">
						<div className="row align-items-center">
							<div className="col">
								<h3 className="page-title">Wallet Requests</h3>
								<ul className="breadcrumb">
									<li className="breadcrumb-item">
										<Link to="/merchant/dashboard">Dashboard</Link>
									</li>
									<li className="breadcrumb-item active"> Wallet Requests</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12">
							<div className="card card-table">
								<div className="card-body">
									<div className="table-responsive custom-pagination">
										<table className="table table-stripped table-hover datatable">
											<thead className="thead-light">
												<tr>
													<th>#</th>
													<th className='text-center'>Status</th>
													<th>Reference Number</th>
													<th>Bank Details</th>
													<th>Type</th>
													<th>Deposited Amount</th>
													<th>Credited Amount</th>
													<th>Bank Reference</th>
													<th>User Reference</th>
													<th>On Behalf Of</th>
												</tr>
											</thead>
											<tbody>
												{!isLoading && (
													<>
														{customerWalletRequest.map((customer, index) => (
															<tr key={index}>
																<td>
																	{(limit * page) + (index + 1)}
																</td>
																<td>
																	{customer?.status == 1 ?
																		<span className="badge badge-pill bg-success-light">Approved</span>
																		: customer?.status == 2 ?
																			<span className="badge badge-pill bg-danger-light">Rejected</span>
																			: ''
																	}
																	{(customer?.status == 0) && (
																		<span className="btn btn-sm btn-block btn-outline-success" onClick={() =>
																			navigate("/merchant/wallet-request-details", {
																				state: { id: customer?._id },
																			})}>
																			View Detail
																		</span>
																	)}
																</td>
																<td>
																	{customer?.reference_number || "-"}<br />
																	<span>{format(new Date(customer?.createdAt), "dd MMMM, yyyy, hh:mm a")}</span>
																</td>
																<td>
																	<span>{customer?.destination_bank?.title}</span>-
																	<span>{customer?.destination_bank?.account_number}</span>&nbsp;&nbsp;
																	<span>{customer?.destination_bank?.currency}</span><br />
																	<span>{customer?.destination_bank?.beneficiary}</span>
																</td>
																<td>
																	<StartCase string={_.toLower(_.replace(customer?.motive, "_", " "))} />
																</td>
																<td>
																	<span className="currency-type-text">{customer?.deposited_currency}</span>&nbsp;
																	{customer?.deposited_amount}
																</td>
																<td>
																	<span className="currency-type-text">{customer?.transaction_currency}</span>&nbsp;
																	{customer?.transaction_amount}
																</td>
																<td>
																	{customer?.bank_reference}
																</td>
																<td>
																	{customer?.user_reference}
																</td>
																<td>
																	{customer?.on_behalf_of || '-'}
																</td>
															</tr>
														))}
														{customerWalletRequest.length === 0 && (
															<tr>
																<td className="text-center" colSpan={10}>No Records Found.</td>
															</tr>
														)}
													</>
												)}
												<Pagination
													totalItem={totalRecords}
													itemsPerPage={limit}
													page={page}
													handleChangePage={handlePageChange}
													handleChangeRowsPerPage={handleLimitChange}
													colSpan={10}
												/>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
					</div>
				</div>
			</div>
		</>
	);
};

export default WalletRequest;
