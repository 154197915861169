import React, { useState, useEffect } from "react";
import SideMenu from "./SideMenu";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { ContactUsService } from "../../../Services/Api/Client/customerAuth";
import { DragAndDropFile } from "../Common";
import { fileUploadToBucket } from "../../../Services/Api/Client/fileUpload";
import { useDebounce } from "use-debounce";

const ContactUs = () => {
  const [frontImageKey, setfrontImageKey] = useState(0);
  const [debounceKey] = useDebounce(frontImageKey, 1000);
  const [clientData, setClientData] = useState([]);
  const fileTypes = ["image/*", ".pdf", ".docx", ".doc", ".pptx"];

  const signInSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });
  const initialValues = {
    email: "",
    message: "",
    subject: "",
    attachment: "",
  };

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("clientUserData"));
    if (items) {
      setClientData(items);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Customer Contact Us</title>
      </Helmet>
      <div>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="page-title">Contact Us</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/client/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Contact Us</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-md-4">
                <SideMenu />
              </div>
              <div className="col-xl-9 col-md-8">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Contact US</h5>
                    <p>Let's get connected...</p>
                  </div>
                  <div className="card-body">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={signInSchema}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        const postObj = {
                          attachment: values.attachment || "",
                          email: values.email,
                          subject: values.subject,
                          message: values.message,
                        };
                        await ContactUsService(postObj)
                          .then((res) => {
                            SuccessToast(
                              res?.data?.message ||
                                "Thank you for getting in touch!."
                            );
                            setSubmitting(false);
                            setfrontImageKey(Math.random());
                            resetForm();
                          })
                          .catch((err) => {
                            if (
                              typeof err.response.data.message !== "undefined"
                            ) {
                              ErrorToast(
                                err.response.data.message || "Server Error!!"
                              );
                            } else {
                              ErrorToast(err?.message || "Server Error!!");
                            }
                          });
                      }}
                    >
                      {(formik) => {
                        const { errors, touched, isSubmitting, setFieldValue } =
                          formik;
                        return (
                          <Form>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group ">
                                  <label>
                                    Email <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    type="email"
                                    className={`form-control ${
                                      errors.email && touched.email
                                        ? "input-error"
                                        : null
                                    }`}
                                    name="email"
                                    placeholder="Enter email"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                                <div className="form-group">
                                  <label>
                                    Subject{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    className={`form-control ${
                                      errors.subject && touched.subject
                                        ? "input-error"
                                        : null
                                    }`}
                                    name="subject"
                                    placeholder="Enter subject"
                                  />
                                  <ErrorMessage
                                    name="subject"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group dropzone-input-area">
                                  <label>Attachment</label>
                                  <DragAndDropFile
                                    randerKey={debounceKey}
                                    maxFileSize={2000000} //2mb
                                    label="Drag and drop a image or click"
                                    fileTypes={[
                                      "image/*",
                                      ".pdf",
                                      ".docx",
                                      ".doc",
                                      ".pptx",
                                    ]}
                                    className={`form-control dropzoneBox ${
                                      errors.attachment && touched.attachment
                                        ? "input-error"
                                        : null
                                    }`}
                                    handleChange={async (file) => {
                                      setFieldValue("attachment", file[0]);
                                      let imageData = file[0];
                                      const imagePostData = {
                                        user_id: clientData?.id,
                                        file_name: imageData?.name,
                                        file_module: "CONTACT_US",
                                        mime_type: imageData?.type,
                                        document_sides: "NONE",
                                      };
                                      await fileUploadToBucket({
                                        data: imagePostData,
                                        file: imageData,
                                      })
                                        .then(async (res) => {
                                          setFieldValue(
                                            "attachment",
                                            res?.data?.data?.s3_key
                                          );
                                        })
                                        .catch((err) => {
                                          if (
                                            typeof err.response.data.message !=
                                            "undefined"
                                          ) {
                                            ErrorToast(
                                              err.response.data.message ||
                                                "Server Error!!"
                                            );
                                          } else {
                                            ErrorToast(
                                              err?.message || "Server Error!!"
                                            );
                                          }
                                        });
                                    }}
                                    acceptedFiles={fileTypes}
                                  />
                                  <ErrorMessage
                                    name="attachment"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>
                                Your Message{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                as="textarea"
                                rows="5"
                                cols="5"
                                className={`form-control ${
                                  errors.message && touched.message
                                    ? "input-error"
                                    : null
                                }`}
                                placeholder="Enter message"
                                name="message"
                              />
                              <ErrorMessage
                                name="message"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary btnstl"
                                disabled={isSubmitting}
                              >
                                Submit&nbsp;&nbsp;
                                {isSubmitting && (
                                  <i className="fas fa-spinner fa-spin"></i>
                                )}
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
