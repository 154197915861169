import Api from "../../../Utils/ClientAxios";

export const SecurityService = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.post('/auth/two_step_verification', data));
      } catch (error) {
        reject(error);
      }
    })
  }