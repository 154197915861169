import React, { useState, useEffect } from "react";
import SideMenu from "./SideMenu";
import { Link } from "react-router-dom";
import { getPdfFile } from "../../../Services/Api/Merchant/pdfFile";
// import { ErrorToast } from "../../../Utils/SweetAlert";
import format from "date-fns/format";
import { Helmet } from "react-helmet-async";

const AggrementDocument = () => {
  const [isLoader, setIsLoader] = useState(true);
  const [pdfList, setPdfList] = useState([]);
  const getPdfAgreementFileList = async () => {
    setIsLoader(true);
    await getPdfFile()
      .then(async (res) => {
        setPdfList(res?.data?.data.agreement || {});
        setIsLoader(false);
      })
      .catch((err) => {
        setPdfList([]);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  useEffect(() => {
    getPdfAgreementFileList();
  }, []);
  return (
    <>
      <Helmet>
        <title>CPay | Merchant Aggrement Document</title>
      </Helmet>
      <div>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="page-title">Aggrement Documents</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/merchant/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      Aggrement Documents
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-md-4">
                <SideMenu />
              </div>
              <div className="col-xl-9 col-md-8">
                <div className="card card-table">
                  <div className="card-header">
                    <div className="row">
                      <div className="col">
                        <h5 className="card-title">Aggrement Documents</h5>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped custom-table mb-0">
                        <thead>
                          <tr>
                            <th>Number</th>
                            <th>Name</th>
                            <th>Uploaded Date</th>
                            <th className="text-end">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isLoader &&
                            pdfList.map((agreement, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>Agreement</td>
                                <td>
                                  {format(
                                    new Date(agreement?.createdAt),
                                    "dd MMMM, yyyy"
                                  )}
                                </td>
                                <td className="text-end">
                                  <a
                                    href={agreement?.media_url}
                                    target="_blank"
                                    className="btn btn-sm btn-white text-success me-2"
                                  >
                                    <i className="far fa-eye me-1"></i> View
                                  </a>
                                </td>
                              </tr>
                            ))}
                          {pdfList.length === 0 && (
                            <tr>
                              <td className="text-center" colSpan={4}>
                                No Records Found.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AggrementDocument;
