import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { baseURL } from "../../../../Utils/ClientAxios";
const appBaseUrl = process.env.REACT_APP_FRONT_URL;

const Plugin = () => {
  const apiBaseURL = baseURL.replace('api/v1', 'api-docs');
  return (
    <>
      <Helmet>
        <title>CPay | Merchant API Documentation</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Plugin</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/merchant/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Developer Settings / Api Documentation / Plugin
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card bg-white">
                <div>
                  <div className="my-5">
                    <div className="row pd-row">
                      {/* <div className="d-flex"> */}
                      <div className="col-lg-3  p-option">
                        <span>
                          <h4 className="mx-4">Plugin</h4>
                        </span>
                        <div
                          className="nav flex-column nav-pills me-3 custom-design-btn"
                          id="v-pills-tab"
                          role="tablist"
                          aria-orientation="vertical"
                        >
                          <button
                            className="nav-link active my-2 mx-3"
                            id="v-pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-home"
                            rel="noopener noreferrer"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-home"
                            aria-selected="true"
                          >
                            About plugin
                          </button>
                          <button
                            className="nav-link my-2 mx-3"
                            id="v-pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-profile"
                            rel="noopener noreferrer"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-profile"
                            aria-selected="false"
                          >
                            How to integrate
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-12 ">
                        <div
                          className="tab-content p-3"
                          id="v-pills-tabContent"
                        >
                          <div
                            className="tab-pane fade show active"
                            id="v-pills-home"
                            role="tabpanel"
                            aria-labelledby="v-pills-home-tab"
                          >
                            <div className="card-body">
                              <div className="sec-head">
                                <h2>JS Web Integration</h2>
                                <p className="f-size-plug">
                                  Integrate CPay Standard Checkout with your
                                  website to start accepting bookings.
                                </p>
                                <hr />
                              </div>
                              <div className="main-head">
                                <p>
                                  You can start accepting Flight, Hotel and Visa
                                  bookings from customers on your website using
                                  the CPay Standard Web Checkout.
                                </p>
                                <p>
                                  CPay has developed the Standard Checkout
                                  method and manages it.
                                </p>
                                <img
                                  src="/images/demo/images/CPay.png"
                                  alt="banner"
                                  className="img-sp"
                                />
                                <h2 className="my-2">Demos</h2>
                                {/* <a href="https://front.cpay.ws/checkout/demos/flight.html" target="_blank" rel="noopener noreferrer" className='btn btn-primary '>Flight Booking</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                <a
                                  href={`${appBaseUrl}checkout/demos/flight.html`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn btn-primary m-2"
                                >
                                  Flight Booking
                                </a>
                                {/* <a href="https://front.cpay.ws/checkout/demos/hotel.html" target="_blank" rel="noopener noreferrer" className='btn btn-primary'>Hotel Booking</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                <a
                                  href={`${appBaseUrl}checkout/demos/hotel.html`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn btn-primary m-2"
                                >
                                  Hotel Booking
                                </a>
                                <a
                                  href={`${appBaseUrl}checkout/demos/visa.html`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn btn-primary m-2"
                                >
                                  Visa Booking
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="v-pills-profile"
                            role="tabpanel"
                            aria-labelledby="v-pills-profile-tab"
                          >
                            <div className="card-body">
                              <div className="sec-head">
                                <h2 className="integration-guide">
                                  Integration Guide
                                </h2>
                                <p className="f-size-plug">
                                  To integrate JS Checkout you need to make few
                                  server side API call. Learn more about{" "}
                                  <Link to="/merchant/rest-api">REST API</Link>.
                                </p>
                                <hr />
                              </div>
                              <div className="step-style main-head">
                                <h3>1. Create a Purchase Order in Server</h3>
                                <p>
                                  Purchase Order is an important step in the
                                  booking process.
                                </p>
                                <ul>
                                  <li>
                                    Purchase Order should be created for every
                                    booking.
                                  </li>
                                  <li>
                                    You can create a purchase order using the{" "}
                                    <a
                                      href={`${apiBaseURL}#/Order/post_order_create`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Order API
                                    </a>
                                    . It is a server-side API call. You need to
                                    pass <strong>token</strong> in header of
                                    create a purchase order API call. You can
                                    get <strong>token</strong> using the{" "}
                                    <a
                                      href={`${apiBaseURL}#/Account/get_account_token`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      GET /account/token
                                    </a>{" "}
                                    API call.
                                  </li>
                                  <li>
                                    The order_id received in the response should
                                    be passed to the checkout. This ties the
                                    Purchase Order with the booking and secures
                                    the request from being tampered.
                                  </li>
                                </ul>
                                <h3>
                                  2. Pass Order ID and Other Options to the
                                  Checkout
                                </h3>
                                <p>Sample codes to help you integrate</p>
                                <div className="sample-code">
                                  <pre>
                                    {"\t"}
                                    {"\t"}
                                    {"\t"}
                                    <code>
                                      {"\n"}
                                      {"\t"}&lt;html&gt;{"\n"}
                                      {"\t"}
                                      {"\t"}&lt;button id="cpay-button1"&gt;Pay
                                      with CPay&lt;/button&gt;{"\n"}
                                      {"\t"}
                                      {"\t"}&lt;form name='cpayform'
                                      action="redirect.php" method="POST"&gt;
                                      {"\n"}
                                      {"\t"}
                                      {"    "}
                                      {"\t"}
                                      {"\t"}&lt;input type="hidden"
                                      name="cpay_booking_status"
                                      id="cpay_booking_status"&gt;{"\n"}
                                      {"\t"}
                                      {"        "}
                                      {"\t"}&lt;input type="hidden"
                                      name="cpay_order_reference"
                                      id="cpay_order_reference"&gt;{"\n"}
                                      {"\t"}
                                      {"        "}
                                      {"\t"}&lt;input type="hidden"
                                      name="cpay_booking_reference"
                                      id="cpay_booking_reference"&gt;{"\n"}
                                      {"\t"}
                                      {"    "}
                                      {"\t"}&lt;/form&gt;{"\n"}
                                      {"\n"}
                                      {"\t"}
                                      {"\t"}&lt;script
                                      src="https://front.cpay.ws/checkout/checkout.js"&gt;&lt;/script&gt;
                                      {"\n"}
                                      {"\t"}
                                      {"\t"}&lt;script
                                      type="text/javascript"&gt;{"\n"}
                                      {"\t"}
                                      {"\t"}
                                      {"\t"}var options = {"{"}
                                      {"\n"}
                                      {"\t"}
                                      {"            "}merchantId:
                                      "YOUR_MERCHANT_ID",{"\n"}
                                      {"\t"}
                                      {"            "}merchantToken:
                                      "YOUR_MERCHANT_TOKEN",{"\n"}
                                      {"\t"}
                                      {"            "}orderId: "ORDER_ID"{"\n"}
                                      {"\t"}
                                      {"          "}
                                      {"}"};{"\n"}
                                      {"\n"}
                                      {"\t"}
                                      {"          "}options.handler = function
                                      (response) {"{"}
                                      {"\n"}
                                      {"\t"}
                                      {"            "}
                                      document.getElementById('cpay_booking_status').value
                                      = response.booking_status;{"\n"}
                                      {"\t"}
                                      {"            "}
                                      document.getElementById('cpay_order_reference').value
                                      = response.order_reference;{"\n"}
                                      {"\t"}
                                      {"            "}
                                      document.getElementById('cpay_booking_reference').value
                                      = response.booking_reference;{"\n"}
                                      {"\t"}
                                      {"            "}
                                      document.cpayform.submit();{"\n"}
                                      {"\t"}
                                      {"          "}
                                      {"}"};{"\n"}
                                      {"\n"}
                                      {"\t"}
                                      {"          "}var cpay = new
                                      Cpay(options);{"\n"}
                                      {"\n"}
                                      {"\t"}
                                      {"          "}
                                      document.getElementById('cpay-button1').onclick
                                      = function(e) {"{"}
                                      {"\n"}
                                      {"\t"}
                                      {"            "}cpay.open();{"\n"}
                                      {"\t"}
                                      {"            "}e.preventDefault();{"\n"}
                                      {"\t"}
                                      {"          "}
                                      {"}"}
                                      {"\n"}
                                      {"\t"}
                                      {"\t"}&lt;/script&gt;{"\n"}
                                      {"\t"}&lt;/html&gt;{"\n"}
                                      {"\t"}
                                      {"\t"}
                                      {"\t"}
                                    </code>
                                    {"\n"}
                                    {"\t"}
                                    {"\t"}
                                  </pre>
                                </div>
                                <h3>3. Store the fields in Your Database</h3>
                                <p>
                                  A complete booking returns the following
                                  fields to the Checkout form.
                                </p>
                                <div className="sample-code">
                                  <pre>
                                    {""}
                                    <code>
                                      {"\n"}
                                      {"     "}
                                      {"{"}
                                      {"\n"}
                                      {"          "}booking_status:
                                      "BOOKING_SUCCESS",{"\n"}
                                      {"          "}order_reference:
                                      "ord_1234567890",{"\n"}
                                      {"          "}booking_reference:
                                      "BKNG_1234567890"{"\n"}
                                      {"     "}
                                      {"}"}
                                      {"\n"}
                                      {""}
                                    </code>
                                    {"\n"}
                                    {""}
                                  </pre>
                                </div>
                                <table className="table table-rest table-responsive">
                                  <thead className="thead-light">
                                    <tr>
                                      <th scope="col">Fields</th>
                                      <th scope="col">Values</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>booking_status</td>
                                      <td>
                                        BOOKING_SUCCESS / BOOKING_FAILED /
                                        BOOKING_ABANDONED
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>order_reference</td>
                                      <td>CPay unique order reference</td>
                                    </tr>
                                    <tr>
                                      <td>booking_reference</td>
                                      <td>CPay unique booking reference</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br />
                                <ul className="my-4">
                                  <li>
                                    You need to store these fields in your
                                    server.
                                  </li>
                                  <li>
                                    You can get booking details using the{" "}
                                    <a
                                      href={`${apiBaseURL}#/Booking/get_booking_retrieve__booking_reference__details`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Booking API
                                    </a>
                                  </li>
                                </ul>

                                <h3>
                                  4. Update Invoice Link Into Cpay
                                </h3>
                                <p className="my-4">
                                  After successful booking you need to update
                                  PDF invoice link for perticular booking using{" "}
                                  <a
                                    href={`${apiBaseURL}#/Booking/put_booking_update__booking_reference__invoice`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Update Invoice
                                  </a>{" "}
                                  endpoint
                                </p>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plugin;
