import MerchantSideBar from "./MerchantSideBar";
import MerchantHeader from "./MerchantHeader";
import {useEffect, useRef, useState } from "react";
import moment from "moment";
import { listenCookieChangeMerchant } from "../../../Hooks/CalculatePenalty";
import MerchantNewsFlash from "./MerchantNewsFlash";

export default function ClientLayout(params) {

  const [openSidebar, setOpenSidebar] = useState(true);
  const sidebarRef = useRef(null); // used to get sidebar width
  const [isMobile, setMobile] = useState(document.body.clientWidth < 991);
  // use 'init' | 'open' | 'close', that you don't need remember if suer clicked
  const [sidebarStatus, setSidebarStatus] = useState("init");
  const [showNews, setShowNews] = useState(false)

  useEffect(() => {
    // add listener only once, or many listeners would be created every render
    const mq = window.matchMedia("(max-width: 991px)");
    mq.addListener((res) => {
      setMobile(res.matches);
    });
    return () => mq.removeListener(toggleSidebar);
  }, []);

  const showSidebar =
    sidebarStatus === "open" || (!isMobile && sidebarStatus === "init");

  const toggleSidebar = (open) => {
    setSidebarStatus(open ? "open" : "close");
  };


  const toggleNews = () =>{
    setShowNews(false);
    document.cookie = `merchantCookie=${moment()}`;
  }
  useEffect(() => {
    listenCookieChangeMerchant(({difference})=> {
      setShowNews(difference);
    }, 1000);
  }, []);

    return (
    <>
      <div className="header header-one">

        {showNews && <MerchantNewsFlash showNews={showNews} toggleNews={toggleNews}/> }

        <MerchantHeader onClick={toggleSidebar} showSideBar={showSidebar}/>
      </div>
      <div className="sidebar" id="sidebar" ref={sidebarRef} style={setOpenSidebar == true ? { "marginLeft": "0px" } : { "marginLeft": "-225px" }}>
       {showSidebar && <MerchantSideBar setOpenSidebar={openSidebar}  handleonClick={toggleSidebar} showSideBar={showSidebar}/>}
      </div>
    </>
  );
}
