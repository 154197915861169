import React, {useEffect,useState} from 'react'
import ReactTyped from "react-typed"
import { GetNewsFlashService } from '../../../Services/Api/Merchant/notification'

const MerchantNewsFlash = ({toggleNews}) => {

    const [News,setNews] = useState([])

    const getNewsFlashList = async () =>{
        await GetNewsFlashService().then((res)=>{
            const newsArr = res.data.data.news_flash.map((msg) =>{
              return ( msg.message)
            })
            setNews(newsArr)
        }).catch((err) =>{
          setNews([])
          // if (typeof err.response.data.message !== "undefined") {
          //   ErrorToast(err.response.data.message || "Server Error!!");
          // } else {
          //   ErrorToast(err?.message || "Server Error!!");
          // }
        })
    } 

    useEffect(() => {
        getNewsFlashList()
      }, [])

  return (
    <>
     {News.length > 0 &&
          <div className='news'>
                <div className='row'>
                    <div className='col-10 col-lg-11 col-sm-11'>
                      <div className='flash-message'>
                       {News.length > 0 && <ReactTyped
                          strings={News}
                          typeSpeed={40}
                          backSpeed={500}
                          showCursor={false}
                          backDelay={5000}
                          fadeOut={true}
                            loop
                          >
                        </ReactTyped>}
                      </div>
                    </div>
                    <div className='col-2 col-lg-1 col-sm-1 text-end'>
                        <button className='btn' onClick={() => toggleNews()}>X</button>
                    </div>
                </div>
            </div>
      }
    </>
  )
}

export default MerchantNewsFlash