import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { ClientProfileCompletion } from "../../../contexts/ClientProfileCompletion";

const ProfilePercentage = ({ profile_completed_percentage }) => {

  const [profilePercentage, setProfilePercentage] = useState(0);
  const [count, setCount] = useContext(ClientProfileCompletion);
  if (profile_completed_percentage) {
    setCount(profile_completed_percentage);
  }

  useEffect(() => {
    const clientUserData = JSON.parse(localStorage.getItem("clientUserData"));
    setProfilePercentage(profile_completed_percentage ? profile_completed_percentage : clientUserData.profile_completed_percentage);
  }, []);

  useEffect(() => {
    setProfilePercentage(profile_completed_percentage ? profile_completed_percentage : count);
  }, [count]);

  return (
    <>
      <Link to="/client/profile" style={{ lineHeight: 0, paddingLeft: 0 }}><label>Profile Completed</label></Link>
      <div className="progress">
        <div className="progress-bar" role="progressbar" 
        style={{ width: `${profilePercentage}%` }} aria-valuenow={profilePercentage} 
        aria-valuemin={0} aria-valuemax={100}>
          {profilePercentage} %
        </div>
      </div>
    </>
  )
}

export default ProfilePercentage