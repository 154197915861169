import Api from "../../../Utils/MerchantAxios";


export const  getApiKeyService = async ()=>{
    return new Promise(async (resolve,reject) => {
        try{
            resolve(await Api.get('/merchant/api_keys'));
        }catch(error){
            reject(error);
        }
    })
}