import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "../../../Utils";
import { Helmet } from "react-helmet-async";
// import { ErrorToast, } from "../../../Utils/SweetAlert";
import {
  GetCustomerWalletRequestCardPaginate,
  GetClientProfileById,
} from "../../../Services/Api/Client/customerAuth";
import { ClientProfileCompletion } from "../../../contexts/ClientProfileCompletion";
import format from "date-fns/format";
import { StartCase } from "react-lodash";
import _ from "lodash";
import { GetDepositOptionService } from "../../../Services/Api/Client/booking";

const WalletRequestCard = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [customerWalletRequest, setCustomerWalletRequest] = useState([]);
  const [depositOption, setDepositOption] = useState([]);
  const [count, setCount] = useContext(ClientProfileCompletion);
  const [data, setData] = useState([]);
  const myDataCard = { id: 2, activeId: 0 };

  const getCustomerList = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetCustomerWalletRequestCardPaginate({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setCustomerWalletRequest(res?.data?.data?.transactions);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setCustomerWalletRequest([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setCustomerWalletRequest([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };
  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getProfileData = async () => {
    GetClientProfileById()
      .then((res) => {
        // setData(res?.data?.data || {});
        setCount(res?.data?.data.profile_completed_percentage);
        const items = JSON.parse(localStorage.getItem("clientUserData"));
        const data = {
          ...items,
          profile_completed_percentage:
            res?.data?.data.profile_completed_percentage,
        };
        localStorage.setItem("clientUserData", JSON.stringify(data));
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  // const checkProfile = (count) => {
  //   if (count !== 100) {
  //     ErrorToast("Please complete your Profile 100%")
  //     navigate('/client/wallet')
  //   }
  // }

  const getDepositOption = async () => {
    GetDepositOptionService()
      .then((res) => {
        setDepositOption(res?.data?.data?.depositOptions);
      })
      .catch((err) => {
        // if(typeof err.response.data.message != 'undefined'){
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  useEffect(() => {
    // localStorage.setItem('clientUserData', JSON.stringify(data));
    getDepositOption();
  }, [data]);

  useEffect(() => {
    getCustomerList(limit, page);
    getProfileData();
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>CPay | Customer Card Deposit</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Card Deposit</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/client/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Card Deposit Details
                  </li>
                </ul>
              </div>
              {/* <div className="col-auto" onClick={() => checkProfile(count)}> */}
              {depositOption?.card === 1 && (
                <div className="col-auto">
                  <Link
                    to="/client/add-wallet-amount"
                    state={myDataCard}
                    className="btn btn-primary me-1"
                  >
                    <i className="fas fa-plus" /> Add Wallet Amount
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-stripped table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th>Status</th>
                          <th>Date</th>
                          <th>Order No</th>
                          <th>Amount</th>
                          <th>Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {customerWalletRequest.map((customer, index) => (
                              <tr key={index}>
                                <td>{limit * page + (index + 1)}</td>
                                <td>
                                  <StartCase
                                    string={_.toLower(
                                      _.replace(customer?.paybyStatus, "_", " ")
                                    )}
                                  />
                                </td>
                                <td>
                                  {format(
                                    new Date(customer?.createdAt),
                                    "dd MMMM, yyyy, hh:mm a"
                                  )}
                                </td>
                                <td>{customer?.merchantOrderNo || "-"}</td>
                                <td>
                                  <span className="currency-type-text">
                                    {" "}
                                    {customer?.currency}{" "}
                                  </span>{" "}
                                  {customer?.amount || "-"}
                                </td>
                                <td>{customer?.details || "-"}</td>
                              </tr>
                            ))}
                            {customerWalletRequest.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={6}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={6}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletRequestCard;
