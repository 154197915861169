import React from 'react'
import { Navigate } from 'react-router-dom'

const ClientGuestRoute = ({ Component, updateHeaderFun }) => {
  const loginUser = localStorage.getItem('clientAccessToken');
  let isLogin = false;
  if (loginUser !== null) {
    isLogin = true;
  }
  return isLogin ? <Navigate to="/client/dashboard" /> : <Component updateHeaderFun={(typeof updateHeaderFun !== 'undefined') ? updateHeaderFun: '/client/auth/login' } />
}

export default ClientGuestRoute