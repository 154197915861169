import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { fileUploadToBucket } from "../../../Services/Api/Client/fileUpload";
import { GetClientProfileById } from "../../../Services/Api/Client/customerAuth";
import { ErrorToast } from "../../../Utils/SweetAlert";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import { ClientProfileCompletion } from "../../../contexts/ClientProfileCompletion";
import { onImageError } from "../../../Utils/CommanFunctions";

const Profile = () => {
  const [profileData, setProfileData] = useState({});
  const [loader, setLoader] = useState(false);
  const [loaderCover, setLoaderCover] = useState(false);
  const [count, setCount] = useContext(ClientProfileCompletion);

  const getProfileData = async () => {
    GetClientProfileById()
      .then((res) => {
        setProfileData(res?.data?.data || {});
        setCount(res?.data?.data.profile_completed_percentage);
        const items = JSON.parse(localStorage.getItem("clientUserData"));
        const data = {
          ...items,
          profile_completed_percentage:
            res?.data?.data.profile_completed_percentage,
        };
        localStorage.setItem("clientUserData", JSON.stringify(data));
      })
      .catch((err) => {
        if (typeof err.response.data.message !== "undefined") {
          // ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          // ErrorToast(err?.message || "Server Error!!");
        }
      });
  };
  const uploadProfile = async (e) => {
    setLoader(true);
    const profilePic = e.target.files[0];
    const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    const profilePostData = {
      user_id: profileData._id,
      file_name: profilePic.name,
      file_module: "PROFILE_PICTURE",
      mime_type: profilePic.type,
      document_sides: "NONE",
    };
    if (profilePic && allowedTypes.includes(profilePic.type)) {
      await fileUploadToBucket({ data: profilePostData, file: profilePic }).then(
        () => {
          getProfileData();
          setLoader(false);
          window.location.reload();
        }
      );
      e.target.value = null;
    } else {
      ErrorToast("Please upload a valid image file (JPG, PNG, JPEG)");
      e.target.value = null;
      setLoader(false);
    }
  };
  const uploadProfileCover = async (e) => {
    setLoaderCover(true);
    const profilePic = e.target.files[0];
    const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    const profilePostData = {
      user_id: profileData._id,
      file_name: profilePic.name,
      file_module: "PROFILE_COVER",
      mime_type: profilePic.type,
      document_sides: "NONE",
    };
    if (profilePic && allowedTypes.includes(profilePic.type)) {
      await fileUploadToBucket({ data: profilePostData, file: profilePic }).then(
        () => {
          getProfileData();
          setLoaderCover(false);
          window.location.reload();
        }
      );
      e.target.value = null;
    } else {
      ErrorToast("Please upload a valid image file (JPG, PNG, JPEG)");
      e.target.value = null;
      setLoaderCover(false);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Customer Profile</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row justify-content-lg-center">
            <div className="col-lg-10">
              <div className="page-header">
                <div className="row">
                  <div className="col">
                    <h3 className="page-title">Profile</h3>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/client/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Profile</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="profile-cover">
                <div className="profile-cover-wrap">
                  <label className="w-100" htmlFor="cover_upload">
                    <img
                      className="profile-cover-img"
                      src={
                        profileData?.profile_cover ||
                        ""
                      }
                      onError={onImageError}
                      alt="Profile pic"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      id="cover_upload"
                      onChange={(e) => uploadProfileCover(e)}
                      className="d-none"
                    />
                    <span className="avatar-edit">{/* style={{ zIndex: 5 }} */}
                      {loaderCover && (
                        <i className="fas fa-spinner fa-spin"></i>
                      )}
                      {!loaderCover && (
                        <i
                          data-feather="edit-2"
                          className="avatar-uploader-icon fa fa-pencil-alt shadow-soft"
                        />
                      )}
                    </span>
                  </label>
                </div>
              </div>
              <div className="text-center mb-5">
                <label
                  className="avatar pro-avatar-xxl profile-cover-avatar"
                  htmlFor="avatar_upload"
                >
                  <img
                    className="avatar-img"
                    src={
                      profileData?.profile_picture ||
                      ""
                    }
                    onError={onImageError}
                    alt="Profile pic"
                  />
                  <input
                    type="file"
                    accept="image/*"
                    id="avatar_upload"
                    onChange={(e) => uploadProfile(e)}
                  />
                  <span className="avatar-edit">
                    {loader && <i className="fas fa-spinner fa-spin"></i>}
                    {!loader && (
                      <i
                        data-feather="edit-2"
                        className="avatar-uploader-icon fa fa-pencil-alt shadow-soft"
                      />
                    )}
                  </span>
                </label>
                <h2>
                  {profileData?.name || "-"}{" "}
                  <i className="fas fa-certificate text-primary small" />
                </h2>
                <ul className="list-inline">
                  <li className="list-inline-item mx-2">
                    <i className="far fa-building mx-2" />{" "}
                    <span>{profileData?.company_name || "-"}</span>
                  </li>
                  <li className="list-inline-item mx-2">
                    <i className="fas fa-map-marker-alt mx-2" />
                    {profileData?.billing_address?.address
                      ? `${profileData?.billing_address?.address}`
                      : ""}
                    {profileData?.billing_address?.city
                      ? `, ${profileData?.billing_address?.city}-${profileData?.billing_address?.zip_code}`
                      : ""}
                    {profileData?.billing_address?.state
                      ? `, ${profileData?.billing_address?.state}`
                      : ""}
                    {profileData?.billing_address?.country
                      ? `, ${profileData?.billing_address?.country}`
                      : ""}
                  </li>
                  <li className="list-inline-item mx-2">
                    <i className="far fa-calendar-alt mx-2" />
                    {moment(profileData?.createdAt).format("ll")}
                  </li>
                </ul>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="card card-body">
                    <h5>Complete your profile</h5>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${profileData.profile_completed_percentage}%`,
                        }}
                        aria-valuenow={profileData.profile_completed_percentage}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        {count}%
                      </div>
                    </div>
                    <div className="my-2">
                      {" "}
                      <strong> Remaining Profile to complete</strong>
                    </div>

                    {profileData.information_required?.company_license && (
                      <>
                        <div className="angle-icon">
                          <i className="fa fa-angle-right my-1" />
                          <Link to="/client/legal-documents" className="mx-2">
                            {`${profileData.information_required?.company_license}  ` ||
                              ""}
                          </Link>
                        </div>
                      </>
                    )}

                    {profileData.information_required
                      ?.authorized_person_name && (
                        <>
                          <div className="angle-icon">
                            <i className="fa fa-angle-right my-1" />
                            <Link to="/client/settings" className="mx-2">
                              {`${profileData.information_required?.authorized_person_name}  ` ||
                                ""}
                            </Link>
                          </div>
                        </>
                      )}
                    {profileData.information_required?.billing_address && (
                      <>
                        <div className="angle-icon">
                          <i className="fa fa-angle-right my-1" />
                          <Link to="/client/settings" className="mx-2">
                            {`${profileData.information_required?.billing_address}  ` ||
                              ""}
                          </Link>
                        </div>
                      </>
                    )}
                    {profileData.information_required?.mobile && (
                      <>
                        <div className="angle-icon">
                          <i className="fa fa-angle-right my-1" />
                          <Link to="/client/settings" className="mx-2">
                            {`${profileData.information_required?.mobile}  ` ||
                              ""}
                          </Link>
                        </div>
                      </>
                    )}
                    {profileData.information_required?.emirates_id && (
                      <>
                        <div className="angle-icon">
                          <i className="fa fa-angle-right my-1" />
                          <Link to="/client/legal-documents" className="mx-2">
                            {`${profileData.information_required?.emirates_id}  ` ||
                              ""}
                          </Link>
                        </div>
                      </>
                    )}
                    {profileData.information_required?.vat_certificate && (
                      <>
                        <div className="angle-icon">
                          <i className="fa fa-angle-right my-1" />
                          <Link to="/client/legal-documents" className="mx-2">
                            {`${profileData.information_required?.vat_certificate}  ` ||
                              ""}
                          </Link>
                        </div>
                      </>
                    )}
                    {profileData.information_required?.passport && (
                      <>
                        <div className="angle-icon">
                          <i className="fa fa-angle-right my-1" />
                          <Link to="/client/legal-documents" className="mx-2">
                            {`${profileData.information_required?.passport}  ` ||
                              ""}
                          </Link>
                        </div>
                      </>
                    )}
                    {profileData.information_required?.profile_picture && (
                      <>
                        <div className="angle-icon">
                          <i className="fa fa-angle-right my-1" />
                          <Link to="/client/settings" className="mx-2">
                            {`${profileData.information_required?.profile_picture}  ` ||
                              ""}
                          </Link>
                        </div>
                      </>
                    )}
                  </div>
                  {profileData?.telegram_chat_id == "" ||
                    (profileData?.telegram_chat_id == null && (
                      <div className="card card-body">
                        <h5>Join Telegram</h5>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center">
                          <p>
                            Join our telegram channel to get updates on telegram
                          </p>
                        </div>
                        <div className="my-2">
                          <strong>
                            <a
                              className="invoice-link"
                              rel="noopener noreferrer"
                              href={`https://telegram.me/confirmfly_bot?start=${profileData?.id}`}
                              target="_blank"
                            >
                              {" "}
                              Join Telegram
                            </a>
                          </strong>
                        </div>
                      </div>
                    ))}
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title d-flex justify-content-between">
                        <span>Profile</span>
                        <Link
                          className="btn btn-sm btn-white"
                          to="/client/settings"
                        >
                          View/Edit
                        </Link>
                      </h5>
                      {/* <div>
                      Profile Completed{" "}
                      {profileData.profile_completed_percentage}
                    </div> */}
                    </div>

                    <div className="card-body">
                      <ul className="list-unstyled mb-0">
                        {profileData?.name && (
                          <li className="py-0">
                            <strong className="text-dark">About</strong>
                          </li>
                        )}
                        <li>{profileData?.name || ""}</li>
                        <li>
                          {profileData?.billing_address?.address
                            ? `${profileData?.billing_address?.address}`
                            : ""}
                          {profileData?.billing_address?.city
                            ? `, ${profileData?.billing_address?.city}-${profileData?.billing_address?.zip_code}`
                            : ""}
                          {profileData?.billing_address?.state
                            ? `, ${profileData?.billing_address?.state},`
                            : ""}
                          {profileData?.billing_address?.country
                            ? `, ${profileData?.billing_address?.country}`
                            : ""}
                        </li>

                        <li className="pt-2 pb-0">
                          <strong className="text-dark">Contacts</strong>
                        </li>

                        <li>{profileData?.email || ""}</li>
                        <li>{profileData?.mobile || ""}</li>
                        {profileData?.billing_address?.address && (
                          <li className="pt-2 pb-0">
                            <strong className="text-dark">Address</strong>
                          </li>
                        )}
                        <li>
                          {profileData?.billing_address?.address && (
                            <>
                              {profileData?.billing_address?.address},<br />
                            </>
                          )}
                          {profileData?.billing_address?.city && (
                            <>
                              {profileData?.billing_address?.city}-
                              {profileData?.billing_address?.zip_code},<br />
                            </>
                          )}
                          {profileData?.billing_address?.state && (
                            <>
                              {profileData?.billing_address?.state},<br />
                            </>
                          )}
                          {profileData?.billing_address?.country && (
                            <>{profileData?.billing_address?.country}</>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Activity</h5>
                    </div>
                    <div className="card-body card-body-height">
                      <ul className="activity-feed">
                        <li className="feed-item">
                          <div className="feed-date">No Activity Found.</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
