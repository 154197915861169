import React from 'react'
import { Navigate } from 'react-router-dom'

const MerchantPrivateRoute = ({ Component, updateHeaderFun }) => {
    const loginMerchant = localStorage.getItem('merchantAccessToken');
    let isLogin = false;
    if (loginMerchant !== null) {
      isLogin = true;
    }
    return isLogin ? <Component /> : <Navigate to="/merchant/auth/login" />
}

export default MerchantPrivateRoute