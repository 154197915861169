import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ForgotPassword, Register, Login, ResetPassword } from '../../Components/Auth/Merchant'

const MerchantAuthRoutes = () => {
  return (
    <>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/reset-password/:token" element={<ResetPassword />} />
      </Routes>
    </>
  )
}

export default MerchantAuthRoutes