import Api from "../../../Utils/MerchantAxios";

export const GetStatementPaginate = async ({ limit, page }) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await Api.get(`/master/wallet/merchant/statements?offset=${page}&limit=${limit}`));
        } catch (error) {
            reject(error);
        }
    })
}

export const GetStatementPaginateFilter = async (data) => {
  return new Promise(async (resolve, reject) => {
      try {
          resolve(await Api.post("/master/wallet/merchant/statements",data));
      } catch (error) {
          reject(error);
      }
  })
}

export const GetTransactionDetailsById = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet/transaction_details?reference_number=${id}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetTransactionExportFile = async (type) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet/export_statements?type=${type}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetTransactionExportFileFilter = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/master/wallet/export_statements",data));
    } catch (error) {
      reject(error);
    }
  })
}