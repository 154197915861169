import React, { useState } from 'react'
import { FilterComponent } from '../Common'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';
import { Pagination } from '../../../Utils';

const CancelBookingStatus = ({ primaryCurrency }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <Helmet>
        <title>CPay | Merchant Cancel Booking Status</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Cancle Bookings Status</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/merchant/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item active">Cancle Bookings Status</li>
                </ul>
              </div>
            </div>
          </div>
          <FilterComponent isShowCategory={true} isShowUser={true} />
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-stripped table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Booking ID</th>
                          <th>Category</th>
                          <th>Created On</th>
                          <th>Customer</th>
                          <th>Amount</th>
                          <th>Refund Amount</th>
                          <th>Merchant Cancle Fee</th>
                          <th>CPay Cancle Fee</th>
                          <th>Booking Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Link to="/merchant/view-booking" className="invoice-link">IN093439#@09</Link>
                          </td>
                          <td>Visa Application</td>
                          <td>16 Mar 2022</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link to="/merchant/profile"><img className="avatar avatar-sm me-2 avatar-img rounded-circle" src="/inner-assets/img/profiles/avatar-04.jpg" alt="User" /> Barbara Moore</Link>
                            </h2>
                          </td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 1,54,220</td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 1,54,100 </td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 100</td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 20</td>
                          <td><span className="badge bg-primary-light">Cancelled</span></td>
                        </tr>
                        <tr>
                          <td>
                            <Link to="/merchant/view-booking" className="invoice-link">IN093439#@10</Link>
                          </td>
                          <td>Ticket Booking</td>
                          <td>14 Mar 2022</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link to="/merchant/profile"><img className="avatar avatar-sm me-2 avatar-img rounded-circle" src="/inner-assets/img/profiles/avatar-06.jpg" alt="User" /> Karlene Chaidez</Link>
                            </h2>
                          </td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 1,222</td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 1,102</td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 100</td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 20</td>
                          <td><span className="badge bg-success-light">Approved</span></td>
                        </tr>
                        <tr>
                          <td>
                            <Link to="/merchant/view-booking" className="invoice-link">IN093439#@11</Link>
                          </td>
                          <td>Ticket Booking</td>
                          <td>7 Mar 2022</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link to="/merchant/profile"><img className="avatar avatar-sm me-2 avatar-img rounded-circle" src="/inner-assets/img/profiles/avatar-08.jpg" alt="User" /> Russell Copeland</Link>
                            </h2>
                          </td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 3,470</td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 3,350</td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 100</td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 20</td>
                          <td><span className="badge bg-success-light">Approved</span></td>
                        </tr>
                        <tr>
                          <td>
                            <Link to="/merchant/view-booking" className="invoice-link">IN093439#@12</Link>
                          </td>
                          <td>Visa Application</td>
                          <td>24 Mar 2022</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link to="/merchant/profile"><img className="avatar avatar-sm me-2 avatar-img rounded-circle" src="/inner-assets/img/profiles/avatar-10.jpg" alt="User" /> Joseph Collins</Link>
                            </h2>
                          </td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 8,265</td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 8,145</td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 100</td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 20</td>
                          <td><span className="badge bg-success-light">Approved</span></td>
                        </tr>
                        <tr>
                          <td>
                            <Link to="/merchant/view-booking" className="invoice-link">IN093439#@13</Link>
                          </td>
                          <td>Hotel Booking</td>
                          <td>17 Mar 2022</td>
                          <td>
                            <h2 className="table-avatar">
                              <Link to="/merchant/profile"><img className="avatar avatar-sm me-2 avatar-img rounded-circle" src="/inner-assets/img/profiles/avatar-11.jpg" alt="User" /> Jennifer Floyd</Link>
                            </h2>
                          </td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 5,200</td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 5,080</td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 100</td>
                          <td className="text-primary"><span className="currency-type-text">{primaryCurrency || ''}</span> 20</td>
                          <td><span className="badge bg-primary-light">Cancelled</span></td>
                        </tr>
                        <Pagination
                          colSpan={9}
                          itemsPerPage={rowsPerPage}
                          page={page}
                          totalItem={50}
                          handleChangePage={handleChangePage}
                          handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CancelBookingStatus
