
import Api from "../../../Utils/MerchantAxios";

export const getPdfFile = async (id = '') => {
        return new Promise(async (resolve, reject) => {
          try {
            let userId = '';
            if (id !== '') {
              userId = `?user_id=${id}`
            }
            resolve(await Api.get(`/master/agreement${userId}`));
          } catch (error) {
            reject(error);
          }
        })
      }