import React, { useState } from "react";
import SideMenu from "./SideMenu";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { StartCase } from "react-lodash";
import _ from "lodash";
import format from "date-fns/format";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDebounce } from "use-debounce";
import { Helmet } from "react-helmet-async";
import { fileUploadToBucket } from "../../../Services/Api/Merchant/fileUpload";
import { DragAndDropFile } from "../Common";

const EditLegalDocs = () => {
  const [frontImageKey, setfrontImageKey] = useState(0);
  const [debounceKey] = useDebounce(frontImageKey, 1000);
  const stateData = useLocation();
  const navigate = useNavigate();
  const {
    documentid,
    userId,
    documentType,
    expiryDate,
    documentImg,
    documentSides,
  } = stateData?.state || {};

  const initialValues = {
    frontImage: "",
    date: expiryDate || "",
  };

  const signInSchema = Yup.object().shape({
    frontImage: Yup.mixed(),
    date: Yup.date().required("Please select date"),
  });

  return (
    <>
      <Helmet>
        <title>CPay | Merchant Legal Document</title>
      </Helmet>
      <div>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col">
                  <h3 className="page-title">Edit Legal Document</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/merchant/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      Edit Legal Document
                    </li>
                  </ul>
                </div>
                <div className="col-auto">
                  <Link
                    to="/merchant/legal-documents"
                    className="btn btn-primary me-1"
                  >
                    <i className="fas fa-angle-double-left" /> Back
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-md-4">
                <SideMenu />
              </div>
              <div className="col-xl-9 col-md-8">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Edit Legal Documents</h5>
                    <p>Let's verify your self...</p>
                  </div>
                  <div className="card-body">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={signInSchema}
                      enableReinitialize
                      validateOnChange
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        if (values?.frontImage?.name == null) {
                          ErrorToast("Please upload image.");
                          return false;
                        }
                        const frontPostData = {
                          user_id: userId,
                          file_name: values.frontImage.name,
                          file_module: documentType,
                          mime_type: values.frontImage?.type,
                          document_sides: documentSides,
                          expiry_date: format(
                            new Date(values.date),
                            "yyyy-MM-dd"
                          ),
                        };
                        await fileUploadToBucket({
                          data: frontPostData,
                          file: values.frontImage,
                          old_file_id: documentid,
                        })
                          .then(async (res) => {
                            setfrontImageKey(Math.random());
                            SuccessToast(
                              res?.data?.message ||
                                "Your document uploaded successfully."
                            );
                            navigate("/merchant/legal-documents");
                            setSubmitting(false);
                            resetForm();
                          })
                          .catch((err) => {
                            if (
                              typeof err.response.data.message !== "undefined"
                            ) {
                              ErrorToast(
                                err.response.data.message || "Server Error!!"
                              );
                            } else {
                              ErrorToast(err?.message || "Server Error!!");
                            }
                            setSubmitting(false);
                          });
                      }}
                    >
                      {({
                        errors,
                        touched,
                        setFieldValue,
                        isSubmitting,
                        values,
                      }) => {
                        return (
                          <Form>
                            <div className="form-row row">
                              <div className="col-md-6 mb-3">
                                <div className="form-group">
                                  <label>Docuement Type</label>
                                  <br />
                                  <b>
                                    {" "}
                                    <StartCase
                                      string={_.toLower(documentType)}
                                    />
                                  </b>
                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <div className="form-group">
                                  <label>
                                    Expiry Date{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <DatePicker
                                    autoComplete="off"
                                    className={`form-control ${
                                      errors.date && touched.date
                                        ? "input-error"
                                        : null
                                    }`}
                                    name="date"
                                    onChange={(date) =>
                                      setFieldValue("date", date)
                                    }
                                    value={moment(values.date).format(
                                      "YYYY-MM-DD"
                                    )}
                                    minDate={new Date()}
                                    placeholderText="mm-dd-yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                  />
                                  <ErrorMessage
                                    name="date"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-row row">
                              <div className="col-md-6 mb-3">
                                <div className="form-group">
                                  <label>
                                    Upload Document{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <DragAndDropFile
                                    fileTypes={[
                                      "image/*",
                                      ".pdf",
                                      ".docx",
                                      ".doc",
                                      ".pptx",
                                    ]}
                                    randerKey={debounceKey}
                                    maxFileSize={2000000} //2mb
                                    label="Drag and drop a image or click"
                                    className={`form-control ${
                                      errors.frontImage && touched.frontImage
                                        ? "input-error"
                                        : null
                                    }`}
                                    handleChange={(file) => {
                                      setFieldValue("frontImage", file[0]);
                                    }}
                                  />
                                  <ErrorMessage
                                    name="frontImage"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <div className="form-group text-center p-2">
                                  <img
                                    src={documentImg}
                                    alt="logo"
                                    className="img-size i-size"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary btnstl"
                                disabled={isSubmitting}
                              >
                                Save Changes&nbsp;&nbsp;
                                {isSubmitting && (
                                  <i className="fas fa-spinner fa-spin"></i>
                                )}
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditLegalDocs;
