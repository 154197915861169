import React, { useState, useEffect, forwardRef } from "react";
import { PersonOutline, Apartment, Flight } from "@mui/icons-material";
import { GetBookingDetails } from "../../../Services/Api/Client/booking";
import { useLocation } from "react-router-dom";

// function ViewBooking(props) {
const ViewBooking = forwardRef((props, ref) => {
  const [bookingInfo, setBookingInfo] = useState({});

  const stateData = useLocation();
  const { id } = stateData?.state || {};

  const getBooking = async () => {
    await GetBookingDetails(id)
      .then((res) => {
        setBookingInfo(res?.data?.data);
      })
      .catch((err) => {
        // if(typeof err.response.data.message !== 'undefined'){
        //   ErrorToast(err?.response?.data?.message || 'Server Error!!')
        // }else{
        //   ErrorToast(err?.message || 'Server Error!!')
        // }
      });
  };

  useEffect(() => {
    getBooking();
  }, []);

  return (
    <div className="row justify-content-center" ref={ref}>
      <div className="col-xl-10">
        <div className="card invoice-info-card">
          <div className="card-body">
            <div className="invoice-item invoice-item-one">
              <div className="row">
                <div className="col-md-12 ">
                  <div className="invoice-logo text-center">
                    <img src="/inner-assets/img/logo.png" alt="logo" />
                  </div>
                  <div className="invoice-head">
                    <h2>Booking Details</h2>
                    <p>
                      Booking Reference:{" "}
                      {bookingInfo?.booking?.booking_reference}
                    </p>
                    <hr />
                      <div className="view-booking">
                        <p>
                        P.O. SENT TO CPAY BY /{" "}
                        {bookingInfo?.booking?.customer?.name}
                      </p>
                      <p>
                      CPAY'S P.O. ACCEPTED BY /{" "}
                        {bookingInfo?.booking?.merchant?.name}
                      </p>
                      </div>
                      <hr />
                  </div>
                  <div className="booking-details">
                    {bookingInfo?.booking?.product === "flight" && (
                      <>
                        <div className="your-booking">
                          <h5>Your Booking</h5>
                          <h5 className="gray-text">
                            {bookingInfo?.booking?.metadata?.flights?.type.replace(
                              /_/g,
                              " "
                            )}{" "}
                            flight
                          </h5>
                        </div>
                        <div className="flight-itenary">
                          <div className="itenary-info">
                            {bookingInfo?.booking?.metadata?.flights?.flight_itinerary.map(
                              (flight, index) => (
                                <div
                                  className="itenary-left border-bottom"
                                  key={index}
                                >
                                  <div className="itenary-left-img">
                                    <Flight />
                                    <div className="itenary-time">
                                      <h5>{flight?.departure_datetime}</h5>
                                      <h6>{flight?.departure_city}</h6>
                                    </div>
                                  </div>
                                  <div className="time-duration">
                                    <h6>{flight?.duration}</h6>
                                  </div>
                                  <div className="itenary-time">
                                    <h5>{flight?.arrival_datetime}</h5>
                                    <h6>{flight?.arrival_city}</h6>
                                  </div>
                                </div>
                              )
                            )}
                            <div className="border-bottom traveller-details">
                              <div className="traveller-heading">
                                <PersonOutline />
                                <h5 className="my-3">Traveller(s)</h5>
                              </div>
                              <div className="traveller-info">
                                {bookingInfo?.booking?.metadata.travellers.map(
                                  (traveller, index) => (
                                    <h5 key={index}>
                                      {index + 1}.{" "}
                                      {traveller?.title +
                                        " " +
                                        traveller?.first_name +
                                        " " +
                                        traveller?.last_name}{" "}
                                      <span>({traveller?.passenger_type})</span>
                                    </h5>
                                  )
                                )}
                                <h6>
                                  {
                                    bookingInfo?.booking?.metadata
                                      ?.contact_details?.email
                                  }{" "}
                                  |{" "}
                                  {
                                    bookingInfo?.booking?.metadata
                                      ?.contact_details?.mobile
                                  }
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="fare-summary-details">
                            <div className="fare-summary">
                              <h5>Payment Summary</h5>
                            </div>
                            <div className="fare-date">
                              <div className="fare">
                                <h5>Fare</h5>
                                <h5>
                                  <span className="currency-type-text">
                                    AED
                                  </span>{" "}
                                  {
                                    bookingInfo?.booking
                                      ?.customer_payment_amount
                                  }
                                </h5>
                              </div>
                              <div className="others">
                                <h5 className="gray-text">Cash Used</h5>
                                <h5 className="gray-text">
                                  <span className="currency-type-text">
                                    AED
                                  </span>{" "}
                                  {bookingInfo?.booking?.customer_cash_used}
                                </h5>
                              </div>
                              <div className="others">
                                <h5 className="gray-text">Credit Used</h5>
                                <h5 className="gray-text">
                                  <span className="currency-type-text">
                                    AED
                                  </span>{" "}
                                  {bookingInfo?.booking?.customer_credit_used}
                                </h5>
                              </div>
                            </div>
                            <div>
                              <div className="total-due">
                                <h5>Total Due</h5>
                                <h5>
                                  <span className="currency-type-text">
                                    AED
                                  </span>{" "}
                                  {
                                    bookingInfo?.booking
                                      ?.customer_payment_amount
                                  }
                                </h5>
                              </div>
                              <h6 className="conveni-text">
                                Convenience fee added
                              </h6>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {bookingInfo?.booking?.product === "hotel" && (
                      <>
                        <div className="your-booking">
                          <h5>Your Booking</h5>
                          <h5 className="gray-text">Hotel Booking</h5>
                        </div>
                        <div className="hotel-itenary">
                          <div className="itenary-info">
                            <div className="border-bottom">
                              <div className="Hotels">
                                <Apartment />
                                <h5>
                                  {bookingInfo?.booking?.metadata?.hotel?.name}
                                </h5>
                              </div>
                              <ul>
                                <li>
                                  <strong>Address:</strong>{" "}
                                  {
                                    bookingInfo?.booking?.metadata?.hotel
                                      ?.address
                                  }
                                </li>
                                <li>
                                  <strong>Phone:</strong>{" "}
                                  {
                                    bookingInfo?.booking?.metadata?.hotel
                                      ?.contact_number
                                  }
                                </li>
                              </ul>
                            </div>
                            <div className="itenary-left border-bottom">
                              <div className="itenary-time">
                                <h5>Check In</h5>
                                <h6>
                                  {
                                    bookingInfo?.booking?.metadata?.hotel
                                      ?.checkin_date
                                  }
                                </h6>
                              </div>
                              <div className="itenary-time">
                                <h5>Check Out</h5>
                                <h6>
                                  {
                                    bookingInfo?.booking?.metadata?.hotel
                                      ?.checkout_date
                                  }
                                </h6>
                              </div>
                              <div className="guest-info">
                                <h6>
                                  Rooms:{" "}
                                  {bookingInfo?.booking?.metadata?.hotel?.rooms}
                                </h6>
                                <h6>
                                  Adults:{" "}
                                  {
                                    bookingInfo?.booking?.metadata?.hotel
                                      ?.adults
                                  }
                                </h6>
                                {bookingInfo?.booking?.metadata?.hotel?.child >
                                  0 && (
                                  <h6>
                                    Child:{" "}
                                    {
                                      bookingInfo?.booking?.metadata?.hotel
                                        ?.child
                                    }
                                  </h6>
                                )}
                              </div>
                            </div>
                            <div className="border-bottom traveller-details">
                              <div className="traveller-heading">
                                <PersonOutline />
                                <h5 className="my-3">Traveller(s)</h5>
                              </div>
                              <div className="traveller-info">
                                {bookingInfo?.booking?.metadata?.travellers.map(
                                  (traveller, index) => (
                                    <h5 key={index}>
                                      {index + 1}.{" "}
                                      {traveller?.title +
                                        " " +
                                        traveller?.first_name +
                                        " " +
                                        traveller?.last_name}
                                    </h5>
                                  )
                                )}
                                <h6>
                                  {
                                    bookingInfo?.booking?.metadata
                                      ?.contact_details?.email
                                  }{" "}
                                  |{" "}
                                  {
                                    bookingInfo?.booking?.metadata
                                      ?.contact_details?.mobile
                                  }
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="fare-summary-details">
                            <div className="fare-summary">
                              <h5>Payment Summary</h5>
                            </div>
                            <div className="fare-date">
                              <div className="fare">
                                <h5>Hotel Charges</h5>
                                <h5>
                                  <span className="currency-type-text">
                                    AED
                                  </span>{" "}
                                  {
                                    bookingInfo?.booking
                                      ?.customer_payment_amount
                                  }
                                </h5>
                              </div>
                              <div className="others">
                                <h5 className="gray-text">Cash Used</h5>
                                <h5 className="gray-text">
                                  <span className="currency-type-text">
                                    AED
                                  </span>{" "}
                                  {bookingInfo?.booking?.customer_cash_used}
                                </h5>
                              </div>
                              <div className="others">
                                <h5 className="gray-text">Credit Used</h5>
                                <h5 className="gray-text">
                                  <span className="currency-type-text">
                                    AED
                                  </span>{" "}
                                  {bookingInfo?.booking?.customer_credit_used}
                                </h5>
                              </div>
                            </div>
                            <div>
                              <div className="total-due">
                                <h5>Total Due</h5>
                                <h5>
                                  <span className="currency-type-text">
                                    AED
                                  </span>{" "}
                                  {
                                    bookingInfo?.booking
                                      ?.customer_payment_amount
                                  }
                                </h5>
                              </div>
                              <h6 className="conveni-text">
                                Convenience fee added
                              </h6>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {bookingInfo?.booking?.product === "visa" && (
                      <>
                        <div className="your-booking">
                          <h5>Your Booking</h5>
                          <h5 className="gray-text">VISA</h5>
                        </div>
                        <div className="hotel-itenary">
                          <div className="itenary-info">
                            <div className="border-bottom">
                              <ul>
                                <li>
                                  <strong>Visa Type :</strong>{" "}
                                  {
                                    bookingInfo?.booking?.metadata?.visa
                                      ?.visa_type
                                  }
                                </li>
                                <li>
                                  <strong>Source Type :</strong>{" "}
                                  {
                                    bookingInfo?.booking?.metadata?.visa
                                      ?.source_type
                                  }
                                </li>
                                <li>
                                  <strong>Present Nationality :</strong>{" "}
                                  {
                                    bookingInfo?.booking?.metadata?.visa
                                      ?.present_nationality
                                  }
                                </li>
                                <li>
                                  <strong>Passport Number :</strong>{" "}
                                  {
                                    bookingInfo?.booking?.metadata?.visa
                                      ?.passport_number
                                  }
                                </li>
                                <li>
                                  <strong>First Name :</strong>{" "}
                                  {
                                    bookingInfo?.booking?.metadata?.visa
                                      ?.first_name
                                  }
                                </li>
                                <li>
                                  <strong>Last Name :</strong>{" "}
                                  {
                                    bookingInfo?.booking?.metadata?.visa
                                      ?.last_name
                                  }
                                </li>
                                <li>
                                  <strong>Gender :</strong>{" "}
                                  {bookingInfo?.booking?.metadata?.visa?.gender}
                                </li>
                                <li>
                                  <strong>Marital Status :</strong>{" "}
                                  {
                                    bookingInfo?.booking?.metadata?.visa
                                      ?.marital_status
                                  }
                                </li>
                                <li>
                                  <strong>Birth Date :</strong>{" "}
                                  {
                                    bookingInfo?.booking?.metadata?.visa
                                      ?.birth_date
                                  }
                                </li>
                                <li>
                                  <strong>Birth Country :</strong>{" "}
                                  {
                                    bookingInfo?.booking?.metadata?.visa
                                      ?.birth_country
                                  }
                                </li>
                              </ul>
                            </div>
                            <div className="itenary-left border-bottom">
                              <div className="itenary-time">
                                <h5>Date of Issue</h5>
                                <h6>
                                  {
                                    bookingInfo?.booking?.metadata?.visa
                                      ?.date_of_issue
                                  }
                                </h6>
                              </div>
                              <div className="itenary-time">
                                <h5>Expiration Date</h5>
                                <h6>
                                  {
                                    bookingInfo?.booking.metadata?.visa
                                      ?.expiration_date
                                  }
                                </h6>
                              </div>
                            </div>
                            <div className="border-bottom traveller-details">
                              <div className="traveller-heading">
                                <PersonOutline />
                                <h5>Contact Details</h5>
                              </div>
                              <div className="traveller-info">
                                <h5>
                                  Email :{" "}
                                  {
                                    bookingInfo?.booking?.metadata
                                      ?.contact_details?.email
                                  }
                                </h5>
                                <h5>
                                  Phone :{" "}
                                  {
                                    bookingInfo?.booking?.metadata
                                      ?.contact_details?.mobile
                                  }
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className="fare-summary-details">
                            <div className="fare-summary">
                              <h5>Payment Summary</h5>
                            </div>
                            <div className="fare-date">
                              <div className="fare">
                                <h5>Charges</h5>
                                <h5>
                                  AED{" "}
                                  {
                                    bookingInfo?.booking
                                      ?.customer_payment_amount
                                  }
                                </h5>
                              </div>
                              <div className="others">
                                <h5 className="gray-text">Cash Used</h5>
                                <h5 className="gray-text">
                                  AED {bookingInfo?.booking?.customer_cash_used}
                                </h5>
                              </div>
                              <div className="others">
                                <h5 className="gray-text">Credit Used</h5>
                                <h5 className="gray-text">
                                  AED{" "}
                                  {bookingInfo?.booking?.customer_credit_used}
                                </h5>
                              </div>
                            </div>
                            <div>
                              <div className="total-due">
                                <h5>Total Due</h5>
                                <h5>
                                  AED{" "}
                                  {
                                    bookingInfo?.booking
                                      ?.customer_payment_amount
                                  }
                                </h5>
                              </div>
                              <h6 className="conveni-text">
                                Convenience fee added
                              </h6>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                    {bookingInfo?.updateNotes && (
                      <div>
                        <hr />
                        <h4>Booking Upated Notes</h4>
                        {bookingInfo?.updateNotes.map((updateNote,index) => (
                          <p className="text-capitalize" key={index}>{index+1}: {updateNote}</p>
                        ))}
                      </div>
                    )}                  
                </div>
              </div>
            </div>
          </div>
          <div class=" container-fluid absolute pb-4">
            <footer className="text-center text-lg-start">
              <div className="text-center">Powered by Alsaffron System.</div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ViewBooking;
