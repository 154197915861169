import React from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { SuccessToast, ErrorToast } from "../../../Utils/SweetAlert";
import { MerchantForgotPasswordService } from '../../../Services/Api/Merchant/merchantAuth';
import { Helmet } from 'react-helmet-async';

const Forgotpassword = () => {
  const merchantForgotSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
  });
  const initialValues = {
    email: "",
  };
  return (
    <>
      <Helmet>
        <title>CPay | Merchant ForgotPassword</title>
      </Helmet>
    <div className="main-wrapper login-body">
      <div className="login-wrapper">
        <div className="container">
          <img className="img-fluid logo-dark mb-2" src="/inner-assets/img/logo.png" alt="Logo" />
          <div className="loginbox">
            <div className="login-right">
              <div className="login-right-wrap">
                <h1>Forgot Password</h1>
                <p className="account-subtitle">Enter your email to get a password reset link</p>
                <Formik
                  initialValues={initialValues}
                  validationSchema={merchantForgotSchema}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    await MerchantForgotPasswordService(values).then((res) => {
                      SuccessToast(res?.data?.message || 'Password changed.');
                      setSubmitting(false);
                      resetForm();
                    }).catch((err) => {
                      if (typeof err.response.data.message !== 'undefined') {
                        ErrorToast(err.response.data.message || 'Server Error!!');
                      } else {
                        ErrorToast(err?.message || 'Server Error!!');
                      }
                    })
                    setTimeout(() => {
                      setSubmitting(false);
                    }, 400);
                  }}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting
                  }) => {
                    return (
                      <Form>
                        <div className="form-group">
                          <label className="form-control-label">Email <span className='text-danger'>*</span></label>
                          <Field
                            type="email"
                            className={`form-control ${errors.email && touched.email ? "input-error" : null}`}
                            id="reg-email"
                            name="email"
                            placeholder="Enter email"
                          />
                          <ErrorMessage name="email" component="span" className="error" />
                        </div>
                        <div className="form-group mb-0">
                          <button
                            className="btn btn-lg btn-block btn-primary w-100"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Reset Password&nbsp;&nbsp;
                            {isSubmitting && (
                              <i className="fas fa-spinner fa-spin"></i>
                            )}
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
                <div className="text-center dont-have">Remember your password? <Link to="/merchant/auth/login">Login</Link></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Forgotpassword