import Api from "../../../Utils/MerchantAxios";

export const GetBookingListService = async ({ limit, page }) =>{
    return new Promise(async (resolve,reject)=>{
        try{
            resolve(await Api.get(`/merchant/bookings/list?offset=${page}&limit=${limit}`))
        }catch(error){
            reject(error);
        }
    })
} 

export const GetCustomerListService = async ({limit,page}) => {
    return new Promise(async (resolve,reject)=>{
        try{
            resolve(await Api.get(`/merchant/customers/list?offset=${page}&limit=${limit}`))
        }catch(error){
            reject(error);
        }
    })
}

export const BookingWidgetCountService = async (data) =>{
    return new Promise(async (resolve,reject) =>{
        try{
            if (data) {
                let filterUrl = '';

                if (data.filter_customer) {
                    filterUrl += '?filter_customer='+data.filter_customer;
                }
                
                if (data.filter_date) {
                    filterUrl += (filterUrl == '') ? '?filter_date='+data.filter_date : '&filter_date='+data.filter_date;
                }

                if (data.filter_status) {
                    filterUrl += (filterUrl == '') ? '?filter_status='+data.filter_status : '&filter_status='+data.filter_status;
                }

                resolve(await Api.get(`/merchant/bookings/widget_count${filterUrl}`));
            }
        }catch(error){
            reject(error)
        }
    })
}
export const GetBookingDetails = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/merchant/bookings/details/${id}`));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const GetCustomerPaginateService = async ({ limit, page, paginate = false }) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/merchant/customers/list?offset=${page}&limit=${limit}&paginate=${paginate}`));
      } catch (error) {
        reject(error);
      }
    });
  };

  
export const GetBookingListFilter = async (data) => {
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.post("/merchant/bookings/list",data));
        }catch(error){
            reject(error);
        }
    })
}

export const getGenerateReport = async (data) => {
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.post("/merchant/customers/generate_report",data));
        }catch(error){
            reject(error);
        }
    })
}

export const GetBookingExport = async (data) => {
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.post("/merchant/bookings/export",data));
        }catch(error){
            reject(error);
        }
    })
}

export const GetBookingAnalytics = async (period) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/master/dashboard/booking_analytics?period=${period}`));
      } catch (error) {
        reject(error);
      }
    })
  }

export const GetCpayReceiptservice = async (id) => {
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.get(`/merchant/bookings/download_receipt/${id}`));
        }catch(error){
            reject(error);
        }
    })
}