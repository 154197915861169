import React, { useEffect, useState } from 'react'
import { GetPaymentOrderService } from '../../../Services/Api/Client/booking'
import { useSearchParams, useNavigate } from "react-router-dom"

const Paypage = () => {

  const [reloadSpin, setReloadSpin] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState([])
  const history = useNavigate();
  const [searchParams] = useSearchParams()

  const handleRedirect = () => {
    const goTo =searchParams.get("back")
      if( goTo === "card"){
        history('/client/wallet-request-card')
      }else{
        history('/client/creditrequest')
      }
  }

  const fetchOrder = async () => {
    setReloadSpin(true);
    const orderNo = searchParams.get("orderNo");
    const payload = {
      merchantOrderNo: orderNo
    }
    await GetPaymentOrderService(payload).then((res) => {
      setReloadSpin(false);
      setPaymentDetail(res?.data?.data);
    });
  }

  useEffect(() => {
    (async () => {
      const orderNo = searchParams.get("orderNo");
      const payload = {
        merchantOrderNo: orderNo
      }
      await GetPaymentOrderService(payload).then((res) => {
        setPaymentDetail(res?.data?.data);
      });
    })();
  }, [searchParams])


  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
          </div>
        </div>
        <div className='mai-sec-success'>
          <div className="row">
            <div className="col-md-12">
              <div className="card bg-white">
                <div className="card-body">
                  <div className='paid-success-main-sec'>
                    <div className='check-icon'>
                       <i className="fa fa-check" aria-hidden="true"></i>
                    </div>
                    {paymentDetail?.order?.acquireOrder.status === 'CREATED' && 
                        <h5> Payment in process..</h5>
                    }
                    {paymentDetail?.order?.acquireOrder.status === 'SETTLED' && 
                        <h5>Payment Success</h5>
                    }
                    <button className='btn btn-success' onClick={handleRedirect}>Back to List</button>
                    {paymentDetail?.order?.acquireOrder.status === 'CREATED' && <>
                        <div className='reload-icon' title='Reload'>
                            <i className={`fas fa-sync ${reloadSpin ? 'fa-spin' : ''}`} aria-hidden="true" onClick={fetchOrder}></i>
                        </div>
                    </>}
                    <h3 className='amount'><span className='currency-type-text'>{paymentDetail?.order?.acquireOrder.totalAmount?.currency} </span>&nbsp;{paymentDetail?.order?.acquireOrder.totalAmount?.amount}</h3>
                    <p>{paymentDetail?.convenience_fee}%&nbsp;convenience fee included</p>
                    <hr />
                    <div className='transaction-detail-sec my-3'>
                      <p className='title'>Payment Details</p>
                      <div className='contect-space'>
                        <div className='left-part'>
                            <p>Order Amount</p>
                            <p>Merchant Order No.</p>
                            <p>Order No.</p>
                        </div>
                        <div className='right-part'>
                            <p><span className='currency-type-text'>{paymentDetail?.order?.acquireOrder.totalAmount?.currency}</span>&nbsp;{paymentDetail?.order?.acquireOrder.totalAmount?.amount}</p>
                            <p>{paymentDetail?.order?.acquireOrder.merchantOrderNo}</p>
                            <p>{paymentDetail?.order?.acquireOrder.orderNo}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Paypage