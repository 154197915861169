import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { bankAccountListService } from "../../../Services/Api/Client/customerWalletRequest";
import { fileUploadToBucket } from "../../../Services/Api/Client/fileUpload";
import DragAndDropFile from "../../../Utils/DragAndDropFile";
import { useDebounce } from "use-debounce";
import { GetDepositOptionService } from "../../../Services/Api/Client/booking";
import {
  EditPayCreditCashChequeService,
  PayCreditAmountServiceById,
} from "../../../Services/Api/Client/customerCreditRequest";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditPayCredit = () => {
  const fileTypes = ["image/*", ".pdf", ".docx", ".doc", ".pptx"];
  const [imageKey, setImageKey] = useState(0);
  const [debounceKey] = useDebounce(imageKey, 1000);

  const stateData = useLocation();
  const { id } = stateData?.state || {};
  const history = useNavigate();
  const [items, setItems] = useState("");
  const [name, setName] = useState("");
  const [logo, setLogo] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [isBankShow, setIsBankShow] = useState(false);
  const [bankName, setBankName] = useState([]);
  const [beneficiary, setBeneficiary] = useState("");
  const [title, setTitle] = useState("");
  const [iban, setIban] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [bankCurrency, setBankCurrency] = useState("");
  const [disableField, setDisableField] = useState(false);
  const [payCreditData, setPayCreditData] = useState([]);
  const [titleName, setTitleName] = useState("");

  const typeOFPayment = [
    { label: "CASH DEPOSIT", value: "CASH_DEPOSIT" },
    { label: "CHEQUE DEPOSIT", value: "CHEQUE_DEPOSIT" },
    { label: "BANK TRANSFER", value: "BANK_TRANSFER" },
  ];

  const signInSchema = Yup.object().shape({
    bank_reference: Yup.string().required("Bank reference is required"),
    user_reference: Yup.string().required("User reference is required"),
    destination_bank: Yup.object().nullable().required("Please select a bank"),
    deposited_amount: Yup.number()
      .nullable()
      .positive()
      .required("Deposited amount is required"),
    transaction_currency: Yup.string()
      .nullable()
      .required("Please select a transaction currency type"),
    motive: Yup.string().required("Please select type of payment"),
    transaction_amount: Yup.number()
      .moreThan(0, "Amount must be greater than 0")
      .lessThan(
        payCreditData?.payment_info?.amount + 0.01,
        "Amount should be less than or equal to credit amount"
      )
      .required("Credited / Transaction amount is required"),
    date_of_payment: Yup.date().required("Please select a Date"),
    comments: Yup.string().required("Comments is required"),
    receipt_file: Yup.mixed(),
  });
  const initialValues = {
    date_of_payment:
      payCreditData?.credit_request?.payment_request?.date_of_payment || "",
    destination_bank: payCreditData?.credit_request?.payment_request
      ?.destination_bank
      ? {
          ...payCreditData?.credit_request?.payment_request?.destination_bank,
          label:
            payCreditData?.credit_request?.payment_request?.destination_bank
              ?.title,
          value:
            payCreditData?.credit_request?.payment_request?.destination_bank
              ?.title,
        }
      : "",
    on_behalf_of: name,
    deposited_currency:
      payCreditData?.credit_request?.payment_request?.deposited_currency || "",
    deposited_amount:
      payCreditData?.credit_request?.payment_request?.deposited_amount || "",
    transaction_currency: "AED",
    transaction_amount: payCreditData?.payment_info?.amount || 0,
    bank_reference:
      payCreditData?.credit_request?.payment_request?.bank_reference || "",
    user_reference:
      payCreditData?.credit_request?.payment_request?.user_reference || "",
    comments: payCreditData?.credit_request?.payment_request?.comments || "",
    motive: payCreditData?.credit_request?.payment_request?.motive || "",
    receipt_file: "",
  };

  const bankAccountList = async () => {
    bankAccountListService()
      .then((res) => {
        if (res?.data?.data?.accounts.length > 0) {
          let bankarr = res?.data?.data?.accounts;
          bankarr = bankarr.map((value) => {
            return {
              label: value.title,
              value: value.id,
              cur: value.currency,
              logo: value.logo,
              accountNumber: value.account_number,
              beneficiary: value.beneficiary,
              iban: value.iban,
              swiftCode: value.swift_code,
              title: value.title,
            };
          });
          setBankName(bankarr);
        } else {
          // ErrorToast("No Results.");
          setBankName([]);
        }
      })
      .catch((err) => {
        setBankName([]);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  const disable = () => {
    if (bankCurrency == "AED") {
      setDisableField(true);
    } else {
      setDisableField(false);
    }
  };
  const PayCreditAmount = async () => {
    await PayCreditAmountServiceById(id)
      .then((res) => {
        setPayCreditData(res?.data?.data);
        let bankData =
          res?.data?.data?.credit_request?.payment_request?.destination_bank;
        if (typeof bankData !== "undefined" && bankData !== "") {
          setTitle(bankData.id);
          setIban(bankData.iban);
          setLogo(bankData.logo);
          setAccountNumber(bankData.account_number);
          setBeneficiary(bankData.beneficiary);
          setBankCurrency(bankData.currency);
          setSwiftCode(bankData.swift_code);
          setIsBankShow(true);
          setTitleName(bankData.title);
        }
      })
      .catch((err) => {
        // if(typeof err.response.data.message != 'undefined'){
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  const getDepositOption = async () => {
    GetDepositOptionService()
      .then((res) => {})
      .catch((err) => {
        // if(typeof err.response.data.message != 'undefined'){
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("clientUserData"));
    if (items) {
      setItems(items);
      setName(items.name);
    }
    bankAccountList();
    getDepositOption();
    PayCreditAmount();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Customer Edit Credit Payment Request</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Edit Credit Payment Request</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/client/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Edit Credit Payment Request
                  </li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/client/creditrequest"
                  className="btn btn-primary me-1"
                >
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card bg-white">
                <div className="card-body">
                  <ul className="nav nav-tabs nav-tabs-solid nav-justified"></ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane show active"
                      id="cash-wallet-amount"
                    >
                      <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={signInSchema}
                        validateOnChange
                        onSubmit={async (values, { setSubmitting }) => {
                          const postObj = {
                            user_id: items.id,
                            credit_request_id: id,
                            payment_request_id:
                              payCreditData?.credit_request?.payment_request_id,
                            date_of_payment: moment(
                              values.date_of_payment
                            ).format("YYYY-MM-DD"),
                            destination_bank: title,
                            on_behalf_of: values.on_behalf_of,
                            deposited_currency: bankCurrency,
                            deposited_amount: values.deposited_amount,
                            transaction_currency: "AED",
                            transaction_amount: values.transaction_amount,
                            bank_reference: values.bank_reference,
                            user_reference: values.user_reference,
                            motive: values.motive,
                            comments: values.comments,
                            receipt_file: values.receipt_file,
                          };
                          await EditPayCreditCashChequeService(postObj)
                            .then((res) => {
                              SuccessToast(
                                res?.data?.message ||
                                  "Customer pay credit Amount."
                              );
                              setSubmitting(false);
                              setTimeout(() => {
                                history("/client/creditrequest");
                              }, 1000);
                            })
                            .catch((err) => {
                              if (
                                typeof err.response.data.message !== "undefined"
                              ) {
                                ErrorToast(
                                  err.response.data.message || "Server Error!!"
                                );
                              } else {
                                ErrorToast(err?.message || "Server Error!!");
                              }
                              setSubmitting(false);
                            });
                          setTimeout(() => {
                            setSubmitting(false);
                          }, 400);
                        }}
                      >
                        {(formik) => {
                          const {
                            touched,
                            errors,
                            setFieldValue,
                            handleChange,
                            isSubmitting,
                            values,
                          } = formik;
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-8">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label className="label-dark">
                                          Date of Payment{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <DatePicker
                                          autoComplete="off"
                                          className={`form-control ${
                                            errors.date_of_payment &&
                                            touched.date_of_payment
                                              ? "input-error"
                                              : null
                                          }`}
                                          name="date_of_payment"
                                          onChange={(date) =>
                                            setFieldValue(
                                              "date_of_payment",
                                              date
                                            )
                                          }
                                          // selected ={values.date_of_payment}
                                          maxDate={new Date()}
                                          placeholderText="yyyy-mm-dd"
                                          value={moment(
                                            values.date_of_payment
                                          ).format("YYYY-MM-DD")}
                                          peekNextMonth
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="select"
                                        />
                                        <ErrorMessage
                                          name="date_of_payment"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group destination-dropdown">
                                        <label htmlFor="bank">
                                          Destination/Bank{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                          options={bankName}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "destination_bank",
                                              e
                                            );
                                            setFieldValue("cur", e.cur);
                                            setFieldValue("logo", e.logo);
                                            setFieldValue(
                                              "accountNumber",
                                              e.account_number
                                            );
                                            setFieldValue(
                                              "beneficiary",
                                              e.beneficiary
                                            );
                                            setFieldValue("iban", e.iban);
                                            setFieldValue(
                                              "swiftCode",
                                              e.swift_code
                                            );
                                            setFieldValue("title", e.id);
                                            setFieldValue("titleName", e.title);
                                            setTitleName(e.title);
                                            setTitle(e.value);
                                            setIban(e.iban);
                                            setLogo(e.logo);
                                            setAccountNumber(e.accountNumber);
                                            setBeneficiary(e.beneficiary);
                                            setBankCurrency(e.cur);
                                            setSwiftCode(e.swiftCode);
                                            if (!isBankShow) {
                                              setIsBankShow(true);
                                            }
                                            disable();
                                          }}
                                          name="destination_bank"
                                          placeholder="Select bank"
                                          value={values.destination_bank}
                                        />
                                        <ErrorMessage
                                          name="destination_bank"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group ">
                                        <label>
                                          Amount Deposited{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span
                                              className="input-group-text"
                                              id="basic-addon3"
                                            >
                                              {bankCurrency || "-"}
                                            </span>
                                          </div>
                                          <Field
                                            type="text"
                                            aria-describedby="basic-addon3"
                                            className={`form-control ${
                                              errors.deposited_amount &&
                                              touched.deposited_amount
                                                ? "input-error"
                                                : null
                                            }`}
                                            name="deposited_amount"
                                            onChange={handleChange}
                                            placeholder="Enter deposited amount"
                                            value={values.deposited_amount}
                                          />
                                        </div>
                                        <label>
                                          Actual currency and amount been
                                          deposited{" "}
                                        </label>
                                        <ErrorMessage
                                          name="deposited_amount"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Amount to be Credited{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span
                                              className="input-group-text"
                                              id="basic-addon3"
                                            >
                                              AED
                                            </span>
                                          </div>
                                          <Field
                                            type="text"
                                            className={`form-control ${
                                              errors.transaction_amount &&
                                              touched.transaction_amount
                                                ? "input-error"
                                                : null
                                            }`}
                                            name="transaction_amount"
                                            placeholder="Enter credited amount"
                                            disabled={disableField}
                                            value={values.transaction_amount}
                                            onChange={handleChange}
                                          />
                                        </div>
                                        {/* <span>Conversion rate :&nbsp;&nbsp;{values.deposited_amount != "" && values.transaction_amount != "" ?
                                       <><span className="currency-type-text">{bankCurrency}</span> {(values.deposited_amount / values.transaction_amount).toFixed(4)} </> : ""}</span><br /> */}
                                        {/* <span>
                                          Penalty amount :&nbsp;{payCreditData?.payment_info?.penalty_payment_amount || 0}<br/>
                                          Total payment amount :&nbsp;{parseFloat(payCreditData?.payment_info?.penalty_payment_amount
                                             +  payCreditData?.payment_info?.amount).toFixed(2) || 0}
                                      </span> */}
                                        <label>
                                          Amount and currency you claim to
                                          credit into your account
                                        </label>
                                        <ErrorMessage
                                          name="transaction_amount"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          On Behalf of{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          onChange={handleChange}
                                          className={`form-control ${
                                            errors.on_behalf_of &&
                                            touched.on_behalf_of
                                              ? "input-error"
                                              : null
                                          }`}
                                          name="on_behalf_of"
                                          value={name}
                                          disabled
                                        />
                                        <ErrorMessage
                                          name="on_behalf_of"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Bank Reference{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          placeholder="Enter bank reference"
                                          className={`form-control ${
                                            errors.bank_reference &&
                                            touched.bank_reference
                                              ? "input-error"
                                              : null
                                          }`}
                                          name="bank_reference"
                                          onBlur={(e) => {
                                            if (!values.user_reference) {
                                              setFieldValue(
                                                "user_reference",
                                                e.target.value
                                              );
                                            }
                                          }}
                                          value={values.bank_reference}
                                        />
                                        <ErrorMessage
                                          name="bank_reference"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Your Reference{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          placeholder="Enter user reference"
                                          className={`form-control ${
                                            errors.user_reference &&
                                            touched.user_reference
                                              ? "input-error"
                                              : null
                                          }`}
                                          name="user_reference"
                                          onChange={handleChange}
                                          value={values.user_reference}
                                        />
                                        <ErrorMessage
                                          name="user_reference"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group destination-dropdown">
                                        <label htmlFor="bank">
                                          Type of Payment{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                          options={typeOFPayment}
                                          placeholder="Select type of payment"
                                          onChange={(e) => {
                                            setFieldValue("motive", e.value);
                                          }}
                                          name="motive"
                                          value={typeOFPayment.filter(
                                            (option) =>
                                              option.value === values.motive
                                          )}
                                        />
                                        <ErrorMessage
                                          name="motive"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Comments{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          as="textarea"
                                          placeholder="Enter comment"
                                          rows={5}
                                          cols={5}
                                          type="text"
                                          className={`form-control ${
                                            errors.comments && touched.comments
                                              ? "input-error"
                                              : null
                                          }`}
                                          name="comments"
                                          onChange={handleChange}
                                          value={values.comments}
                                        />
                                        <ErrorMessage
                                          name="comments"
                                          component="span"
                                          className="error"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group dropzone-image-area">
                                        <label>Receipt</label>
                                        <DragAndDropFile
                                          randerKey={debounceKey}
                                          maxFileSize={2000000} //2mb
                                          label="Drag and drop a image or click"
                                          fileTypes={[
                                            "image/*",
                                            ".pdf",
                                            ".docx",
                                            ".doc",
                                            ".pptx",
                                          ]}
                                          className={`form-control dropzoneBox ${
                                            errors.receipt_file &&
                                            touched.receipt_file
                                              ? "input-error"
                                              : null
                                          }`}
                                          handleChange={async (file) => {
                                            setFieldValue(
                                              "receipt_file",
                                              file[0]
                                            );
                                            let imageData = file[0];
                                            const imagePostData = {
                                              user_id: items.id,
                                              file_name: imageData?.name,
                                              file_module: "WALLET_RECEIPT",
                                              mime_type: imageData?.type,
                                              document_sides: "NONE",
                                            };
                                            await fileUploadToBucket({
                                              data: imagePostData,
                                              file: imageData,
                                            })
                                              .then(async (res) => {
                                                setFieldValue(
                                                  "receipt_file",
                                                  res?.data?.data?.s3_key
                                                );
                                              })
                                              .catch((err) => {
                                                if (
                                                  typeof err.response.data
                                                    .message != "undefined"
                                                ) {
                                                  ErrorToast(
                                                    err.response.data.message ||
                                                      "Server Error!!"
                                                  );
                                                } else {
                                                  ErrorToast(
                                                    err?.message ||
                                                      "Server Error!!"
                                                  );
                                                }
                                              });
                                          }}
                                          acceptedFiles={fileTypes}
                                        />
                                        <ErrorMessage
                                          name="profile_picture"
                                          component="span"
                                          className="error"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* col-md-8 */}
                                <div
                                  className={`col-md-4${
                                    !isBankShow ? ` d-none` : ``
                                  }`}
                                >
                                  <div className="text-center">
                                    <img src={logo} alt="bank logo" />{" "}
                                  </div>
                                  <table className="table table-stripped table-responsive custom-table d-block">
                                    <tbody>
                                      <tr>
                                        <td style={{ width: "10%" }}>Bank</td>
                                        <th>{title}</th>
                                      </tr>
                                      <tr>
                                        <td style={{ width: "10%" }}>
                                          Holder / Beneficiary
                                        </td>
                                        <th>{beneficiary}</th>
                                      </tr>
                                      <tr>
                                        <td style={{ width: "10%" }}>
                                          Account Number
                                        </td>
                                        <th>{accountNumber}</th>
                                      </tr>
                                      <tr>
                                        <td style={{ width: "10%" }}>IBAN</td>
                                        <th>{iban}</th>
                                      </tr>
                                      <tr>
                                        <td style={{ width: "10%" }}>
                                          Swift Code
                                        </td>
                                        <th>{swiftCode}</th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="text-center  mt-4">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={isSubmitting}
                                >
                                  Submit&nbsp;&nbsp;
                                  {isSubmitting && (
                                    <i className="fas fa-spinner fa-spin"></i>
                                  )}
                                </button>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPayCredit;
