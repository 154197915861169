import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { GetTransactionDetailsById } from "../../../Services/Api/Merchant/statement";
import format from "date-fns/format";
import { Replace } from "react-lodash";

const TransactionDetails = () => {
  const stateData = useLocation();
  const { id } = stateData?.state || {};
  const [transactionDetails, setTransactionDetails] = useState({});
  const getTransactionDetailsData = async () => {
    await GetTransactionDetailsById(id).then((res) => {
      const resTransactionDetailsData = res?.data?.data.transaction || {};
      setTransactionDetails(resTransactionDetailsData);
    });
  };

  useEffect(() => {
    getTransactionDetailsData();
  }, []);

  return (
    <>
      <Helmet>
        <title>CPay | Merchant Transaction Details</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="card invoice-info-card">
                <div className="card-body">
                  <div className="invoice-item invoice-item-one">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="invoice-head">
                          <h2>Transaction Details</h2>
                          <p>
                            Reference Number :{" "}
                            {transactionDetails?.reference_number}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center mt-3">
                    <div className="col-lg-12 col-md-12">
                      <div className="row">
                        <div className="invoice-terms col-md-6">
                          <h6>Date</h6>
                          <p className="mb-0">
                            {format(
                              new Date(transactionDetails?.createdAt || null),
                              "dd MMMM, yyyy HH:mm a"
                            )}
                          </p>
                        </div>
                        {transactionDetails?.date_of_payment && (
                          <>
                            <div className="invoice-terms col-md-6">
                              <h6>Date of Payment</h6>
                              <p className="mb-0">
                                {format(
                                  new Date(transactionDetails?.date_of_payment),
                                  "dd MMMM, yyyy"
                                )}
                              </p>
                            </div>
                          </>
                        )}
                        {transactionDetails?.motive && (
                          <>
                            <div className="invoice-terms col-md-6">
                              <h6>Motive</h6>
                              <p className="mb-0">
                                <Replace
                                  string={transactionDetails?.motive}
                                  pattern={"_"}
                                  replacement={" "}
                                />
                              </p>
                            </div>
                          </>
                        )}
                        {transactionDetails?.bank_reference && (
                          <>
                            <div className="invoice-terms col-md-6">
                              <h6>Bank Reference</h6>
                              <p className="mb-0">
                                {transactionDetails?.bank_reference}
                              </p>
                            </div>
                          </>
                        )}
                        {transactionDetails?.user_reference && (
                          <>
                            <div className="invoice-terms col-md-6">
                              <h6>User Reference</h6>
                              <p className="mb-0">
                                {transactionDetails?.user_reference}
                              </p>
                            </div>
                          </>
                        )}
                        {transactionDetails?.related_user && (
                          <>
                            <div className="invoice-terms col-md-6">
                              <h6>Creditor</h6>
                              <p className="mb-0">
                                <b>Name :</b>{" "}
                                {transactionDetails?.related_user.name}
                                {transactionDetails?.related_user.mobile && (
                                  <>
                                    <br></br>
                                    <b>Mobile :</b>{" "}
                                    {transactionDetails?.related_user.mobile}
                                  </>
                                )}
                                {transactionDetails?.related_user.email && (
                                  <>
                                    <br></br>
                                    <b>Email :</b>{" "}
                                    {transactionDetails?.related_user.email}
                                  </>
                                )}
                              </p>
                            </div>
                          </>
                        )}
                        {transactionDetails?.destination_bank && (
                          <>
                            <div className="invoice-terms col-md-6">
                              <h6>Destination / Bank</h6>
                              <p className="mb-0">
                                <b>Bank :</b>{" "}
                                {transactionDetails?.destination_bank.title}
                                <br></br>
                                <b>Beneficiary :</b>{" "}
                                {
                                  transactionDetails?.destination_bank
                                    .beneficiary
                                }
                                <br></br>
                                <b>Account Number :</b>{" "}
                                {
                                  transactionDetails?.destination_bank
                                    .account_number
                                }
                                <br></br>
                                <b>Currency :</b>{" "}
                                {transactionDetails?.destination_bank.currency}
                              </p>
                            </div>
                          </>
                        )}
                        {transactionDetails?.statusUpdatedBy && (
                          <>
                            <div className="invoice-terms col-md-6">
                              <h6>Reviewed By</h6>
                              <p className="mb-0">
                                <b>Name :</b>{" "}
                                {transactionDetails?.statusUpdatedBy.name}
                                {transactionDetails?.statusUpdatedBy.mobile && (
                                  <>
                                    <br></br>
                                    <b>Mobile :</b>{" "}
                                    {transactionDetails?.statusUpdatedBy.mobile}
                                  </>
                                )}
                                {transactionDetails?.statusUpdatedBy.email && (
                                  <>
                                    <br></br>
                                    <b>Email :</b>{" "}
                                    {transactionDetails?.statusUpdatedBy.email}
                                  </>
                                )}
                                {transactionDetails?.status_comments && (
                                  <>
                                    <br></br>
                                    <b>Comments :</b>{" "}
                                    {transactionDetails?.status_comments}
                                  </>
                                )}
                              </p>
                            </div>
                          </>
                        )}
                        {transactionDetails?.description && (
                          <>
                            <div className="invoice-terms col-md-6">
                              <h6>Description</h6>
                              <p className="mb-0">
                                {transactionDetails?.description}
                              </p>
                            </div>
                          </>
                        )}
                        {transactionDetails?.comments && (
                          <>
                            <div className="invoice-terms col-md-6">
                              <h6>Comments</h6>
                              <p className="mb-0">
                                {transactionDetails?.comments}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="invoice-total-card">
                        <div className="invoice-total-box">
                          <div className="invoice-total-inner">
                            <p>
                              Opening Balance{" "}
                              <span className="currency-type-text">
                                {transactionDetails?.transaction_currency}{" "}
                                <b className="font-size-14">
                                  {transactionDetails?.opening_balance}
                                </b>
                              </span>
                            </p>
                            <p>
                              Debit
                              <span className="currency-type-text">
                                {transactionDetails?.transaction_type ==
                                "DEBIT" ? (
                                  <>
                                    {transactionDetails?.transaction_currency}
                                    &nbsp;
                                    <b className="font-size-14">
                                      {transactionDetails?.transaction_amount}
                                    </b>
                                  </>
                                ) : (
                                  "-"
                                )}
                              </span>
                            </p>
                            <p>
                              Credit
                              <span className="currency-type-text">
                                {transactionDetails?.transaction_type ==
                                "CREDIT" ? (
                                  <>
                                    {transactionDetails?.transaction_currency}
                                    &nbsp;
                                    <b className="font-size-14">
                                      {transactionDetails?.transaction_amount}
                                    </b>
                                  </>
                                ) : (
                                  "-"
                                )}
                              </span>
                            </p>
                          </div>
                          <div className="invoice-total-footer">
                            <h4>
                              Closing Balance{" "}
                              <span className="currency-type-text">
                                {transactionDetails?.transaction_currency}{" "}
                                <b className="font-size-14">
                                  {transactionDetails?.closing_balance}
                                </b>
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionDetails;
