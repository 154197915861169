import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
// import { ErrorToast } from "../../../Utils/SweetAlert";
import { getGetBankAccountListService } from "../../../Services/Api/Client/customerWalletRequest"

const BankAccount = () => {
  
  const [page] = useState(0);
  const [limit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [bankAccountList, setBankAccountList] = useState([]);

  const getGetBankAccountList = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await getGetBankAccountListService({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.accounts?.length > 0) {
          setBankAccountList(res?.data?.data?.accounts);
        } else {
          setBankAccountList([]);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setBankAccountList([]);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  useEffect(() => {
    getGetBankAccountList(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>CPay | Bank Details</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Bank Details</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/client/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Bank Details</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-center table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Logo</th>
                          <th>Title</th>
                          <th>Bank Holder / Beneficiary</th>
                          <th>Account Number</th>
                          <th>IBAN</th>
                          <th>Swift Code</th>
                          <th>Currency</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {bankAccountList.map((accounts, index) => (
                              <tr key={index}>
                                  <td>
                                        <img
                                          className="logoHeight"
                                          src={accounts?.logo}
                                          alt="User"
                                        />
                                  </td>
                                  <td>
                                        {accounts?.title}
                                  </td>
                                  <td>
                                        {accounts?.beneficiary}
                                  </td>
                                  <td>
                                        {accounts?.account_number}
                                  </td>
                                  <td>
                                        {accounts?.iban}
                                  </td>
                                  <td>
                                        {accounts?.swift_code}
                                  </td>
                                  <td>
                                        {accounts?.currency}
                                  </td>
                              </tr>
                            ))}
                            {bankAccountList.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={7}>No Records Found.</td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class=" container-fluid absolute pb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BankAccount
