import Api from "../../../Utils/MerchantAxios";

export const MerchantLoginService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/merchant/login', data));
    } catch (error) {
      reject(error);
    }
  })
}

export const MerchantRegisterService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/merchant/register', data));
    } catch (error) {
      reject(error);
    }
  })
}

export const MerchantForgotPasswordService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/merchant/forgot_password', data));
    } catch (error) {
      reject(error);
    }
  })
}

export const MerchantResetPasswordService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('auth/resetpassword', data));
    } catch (error) {
      reject(error);
    }
  })
}

export const MerchantChangePasswordService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/merchant/change_password', data));
    } catch (error) {
      reject(error);
    }
  })
}

export const MerchantGetProfileService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get('/merchant/get_profile', data));
    } catch (error) {
      reject(error);
    }
  })
}

export const DeleteAccountService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userData = JSON.parse(localStorage.getItem('merchantUserData'));
      resolve(await Api.delete(`/auth/delete_account/${userData._id}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const UpdateProfile = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/auth/update_profile', data));
    } catch (error) {
      reject(error);
    }
  })
}

export const VerifyOtpService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/auth/verify_otp', data));
    } catch (error) {
      reject(error);
    }
  })
}
export const MerchanttGetWalletBalanceService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get('/master/dashboard'));
    } catch (error) {
      reject(error);
    }
  })
};
export const GetMerchantCreditRequestPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/credit_request/merchant/list?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  })
};

export const ContactUsService = async (data) =>{
  return new Promise(async (resolve, reject) => {
    try{
        resolve(await Api.post("/master/contact_us",data));
    }catch(error){
        reject(error);
    }
  })
}

export const MerchantRegisterRequestService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/merchant/registration_request', data));
    } catch (error) {
      reject(error);
    }
  })
}