import { Route, Routes } from "react-router-dom";
import { MerchantLayout } from "../../Components/Layouts";
import { Booking, CancelBookingStatus, EditBookingStatus, EditBooking, BookingPaid, BookingUnPaid, BookingCanceled } from "../../Components/Merchant/Bookings";
import { Notification } from "../../Components/Merchant/Notifications/Index";
import { Profile } from "../../Components/Merchant/Profile/Index";
import { Settings, Faq, ChangePassword, Security, ContactUs, LegalDocs, TermsAndConditions, EditLegalDocs, AggrementDocument} from "../../Components/Merchant/Settings";
import { Dashboard } from "../../Components/Merchant/DashBoard";
import { Customer } from "../../Components/Merchant/Customers";
import { WalletCreditRequest, WalletTransaction } from "../../Components/Merchant/WalletReport";
import { User, AddUser, EditUser } from "../../Components/Merchant/User";
import { Role, AddRoleManagement, EditRolePermission } from "../../Components/Merchant/RoleManagement";
import { DevloperApi, Plugin, RestApi } from "../../Components/Merchant/DevloperSettings";
import { useEffect, useState } from "react";
import { ProfileCompletionProvider } from "../../contexts/ProfileCompletion";
import { WalletRequest, WalletDetails } from "../../Components/Merchant/WalletRequest";
import { BankingOptions, AddBankingOption, EditBankingOption} from "../../Components/Merchant/Banking";
import { Statement, TransactionDetails } from "../../Components/Merchant/Statement";
import { ComponentPrint } from "../../Components/Merchant/Common";

const Logout = () => {

  useEffect(() => {
    document.cookie = "merchantCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/merchant;";
    localStorage.removeItem('merchantAccessToken');
    localStorage.removeItem('merchantUserData');
    localStorage.removeItem('merchantPermission');
    window.location.assign('/merchant/');
  }, []);
}

export default function MerchantRoutes() {

  const [primaryCurrency, setPrimaryCurrency] = useState([]);
  const merchantInformation = () => {
    const item = JSON.parse(localStorage.getItem('merchantUserData'));
    if (item) {
      setPrimaryCurrency(item?.primary_currency || '');
    }
  }
  useEffect(() => {
    merchantInformation();
  }, []);
  
  return (
    <ProfileCompletionProvider>
      <MerchantLayout />
      <Routes>
        <Route exact path="/" element={<Dashboard/>} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/notifications" element={<Notification />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/booking" element={<Booking primaryCurrency={primaryCurrency} />} />
        <Route exact path="/editbookingstatus" element={<EditBookingStatus primaryCurrency={primaryCurrency} />} />
        <Route exact path="/cancelbookingstatus" element={<CancelBookingStatus primaryCurrency={primaryCurrency} />} />
        <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route exact path="/agreement-documents" element={<AggrementDocument />} />
        <Route exact path="/change-password" element={<ChangePassword />} />
        <Route exact path="/security" element={<Security />} />
        <Route exact path="/legal-documents" element={<LegalDocs />} />
        <Route exact path="/edit-legal-documents" element={<EditLegalDocs />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/customers" element={<Customer primaryCurrency={primaryCurrency} />} />
        <Route exact path="/wallet-transaction" element={<WalletTransaction />} />
        <Route exact path="/wallet-credit-request" element={<WalletCreditRequest/>} />
        <Route exact path="/wallet-request" element={<WalletRequest />} />
        <Route exact path="/user" element={<User />} />
        <Route exact path="/role-management" element={<Role />} />
        <Route exact path="/add-role-management" element={<AddRoleManagement />} />
        <Route exact path="/add-user" element={<AddUser />} />
        <Route exact path="/developer-api" element={<DevloperApi />} />
        <Route exact path="/edit-booking" element={<EditBooking />} />
        <Route exact path="/view-booking" element={<ComponentPrint />} />
        <Route exact path="/edit-user" element={<EditUser />} />
        <Route exact path="/edit-role-permission" element={<EditRolePermission />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/booking-paid" element={<BookingPaid primaryCurrency={primaryCurrency} />} />
        <Route exact path="/booking-unpaid" element={<BookingUnPaid primaryCurrency={primaryCurrency} />} />
        <Route exact path="/booking-canceled" element={<BookingCanceled primaryCurrency={primaryCurrency} />} />
        <Route exact path="/banking-options" element={<BankingOptions />} />
        <Route exact path="/add-banking-options" element={<AddBankingOption />} />
        <Route exact path="/edit-banking-options" element={<EditBankingOption />} />
        <Route exact path="/statement" element={<Statement />} />
        <Route exact path="/transaction-details" element={<TransactionDetails />} />
        <Route exact path="/wallet-request-details" element={<WalletDetails />} />
        <Route exact path="/plugin" element={<Plugin />} />
        <Route exact path="/rest-api" element={<RestApi />} />
        <Route exact path="/logout" element={<Logout />} />
      </Routes>
    </ProfileCompletionProvider>
  );
}
