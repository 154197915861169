import React, { useEffect } from 'react'
import { Route, Routes,useLocation } from "react-router-dom";
import { Booking, BookingApprove } from "../../Components/Client/Bookings";
import { CreditRequest, AddCreditRequest, PayCredit, EditPayCredit } from "../../Components/Client/CreditRequest";
import { Notification } from "../../Components/Client/Notifications/Index";
import { Profile } from "../../Components/Client/Profile/Index";
import { Dashboard } from "../../Components/Client/DashBoard";
import { ViewInvoice } from "../../Components/Client/Invoice";
import { Wallet } from "../../Components/Client/Wallet";
import { Settings, Faq, ChangePassword, LegalDocs, Security, ContactUs, FeedBack, EditLegalDocs, TermsConditions } from "../../Components/Client/Settings";
import { AddRoleManagement, EditRolePermission, Role } from "../../Components/Client/RoleManagement";
import { AddUser, EditUser, User } from "../../Components/Client/User";
import { ClientProfileCompletionProvider } from "../../contexts/ClientProfileCompletion";
import { WalletRequest , AddWalletAmount, EditWalletAmount, WalletRequestCard, BankAccount} from '../../Components/Client/WalletRequest';
import { Statement, TransactionDetail} from '../../Components/Client/Statement';
import { Paypage } from '../../Components/Client/Paypage';
import { ComponentPrint } from '../../Components/Client/Common';
import { useState } from 'react';
import { PermissionAlert } from '../../Components/Client/PermissionAlert';
import { ClientLayout } from '../../Components/Layouts';


const Logout = () => {
  useEffect(() => {
    document.cookie = "clientCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/client;";
    localStorage.removeItem('clientAccessToken');
    localStorage.removeItem('clientUserData');
    localStorage.removeItem('clientPermission');
    window.location.assign('/client/');
 }, []);
}


export default function ClientRoutePermission() {

    const location = useLocation()
    const route = location.pathname.slice(8)
    const [loading, setLoading] = useState(true);
    const permission = JSON.parse(localStorage.getItem('clientPermission'));
    useEffect(() => {  
        setLoading(false);
    }, [permission]);
    
   
  return (
    <ClientProfileCompletionProvider>
      <ClientLayout />
       {!loading &&
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/dashboard" element={<Dashboard />} />

        {permission?.indexOf("bookings")!==-1 && route==="bookings" ?
            <Route exact path="/bookings" element={<Booking />} /> :
            <Route exact path="/bookings" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("bookings")!==-1 && route==="view-booking" ?
            <Route exact path="/view-booking" element={<ComponentPrint />} />:
            <Route exact path="/view-booking" element={<PermissionAlert/>} /> 
         }

         {permission?.indexOf("bookings")!==-1 && route==="booking-approve" ?
            <Route exact path="/booking-approve" element={<BookingApprove />} />:
            <Route exact path="/booking-approve" element={<PermissionAlert/>} /> 
         }

         {permission?.indexOf("credit_request")!==-1 && route==="creditrequest" ?
            <Route exact path="/creditrequest" element={<CreditRequest />} />:
            <Route exact path="/creditrequest" element={<PermissionAlert/>} /> 
         }

         {permission?.indexOf("credit_request")!==-1 && route==="pay-credit" ?
            <Route exact path="/pay-credit" element={<PayCredit />} />:
            <Route exact path="/pay-credit" element={<PermissionAlert/>} /> 
         }

        {permission?.indexOf("credit_request")!==-1 && route==="edit-pay-credit" ?
            <Route exact path="/edit-pay-credit" element={<EditPayCredit />} />:
            <Route exact path="/edit-pay-credit" element={<PermissionAlert/>} /> 
         }
         {permission?.indexOf("credit_request")!==-1 && route==="addcreditrequest" ?
            <Route exact path="/addcreditrequest" element={<AddCreditRequest />} />:
            <Route exact path="/addcreditrequest" element={<PermissionAlert/>} /> 
         }

        {permission?.indexOf("wallet")!==-1 && route==="wallet" ?
            <Route exact path="/wallet" element={<Wallet />} />:
            <Route exact path="/wallet" element={<PermissionAlert/>} /> 
         }
        
        {permission?.indexOf("wallet_request")!==-1 && route==="wallet-request" ?
            <Route exact path="/wallet-request" element={<WalletRequest />} />:
            <Route exact path="/wallet-request" element={<PermissionAlert/>} /> 
         }

         {permission?.indexOf("wallet_request")!==-1 && route==="wallet-request-card" ?
            <Route exact path="/wallet-request-card" element={<WalletRequestCard />} />:
            <Route exact path="/wallet-request-card" element={<PermissionAlert/>} /> 
         }

        {permission?.indexOf("wallet_request")!==-1 && route==="add-wallet-amount" ?
            <Route exact path="/add-wallet-amount" element={<AddWalletAmount />} />:
            <Route exact path="/add-wallet-amount" element={<PermissionAlert/>} /> 
         }

        {permission?.indexOf("wallet_request")!==-1 && route==="edit-wallet-amount" ?
            <Route exact path="/edit-wallet-amount" element={<EditWalletAmount />} />:
            <Route exact path="/edit-wallet-amount" element={<PermissionAlert/>} /> 
         }

       
        {permission?.indexOf("manage_documents")!==-1 && route==="legal-documents" ?
            <Route exact path="/legal-documents" element={<LegalDocs />} />:
            <Route exact path="/legal-documents" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("manage_documents")!==-1 && route==="edit-legal-documents" ?
            <Route exact path="/edit-legal-documents" element={<EditLegalDocs />} />:
            <Route exact path="/edit-legal-documents" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("role_management")!==-1 && route==="role-management" ?
            <Route exact path="/role-management" element={<Role />} />:
            <Route exact path="/role-management" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("role_management")!==-1 && route==="add-role-management" ?
            <Route exact path="/add-role-management" element={<AddRoleManagement />} />:
            <Route exact path="/add-role-management" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("role_management")!==-1 && route==="edit-role-permission" ?
            <Route exact path="/edit-role-permission" element={<EditRolePermission />} />:
            <Route exact path="/edit-role-permission" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("user_management")!==-1 && route==="user" ?
            <Route exact path="/user" element={<User />} />:
            <Route exact path="/user" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("user_management")!==-1 && route==="edit-user" ?
            <Route exact path="/edit-user" element={<EditUser />} />:
            <Route exact path="/edit-user" element={<PermissionAlert/>} /> 
        }

        {permission?.indexOf("user_management")!==-1 && route==="add-user" ?
            <Route exact path="/add-user" element={<AddUser />} />:
            <Route exact path="/add-user" element={<PermissionAlert/>} /> 
        }
       
        {permission?.indexOf("statement")!==-1 && route==="statement" ?
            <Route exact path="/statement" element={<Statement />} />:
            <Route exact path="/statement" element={<PermissionAlert/>} /> 
         }

        <Route exact path="/change-password" element={<ChangePassword />} />
        <Route exact path="/security" element={<Security />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/terms-conditions" element={<TermsConditions />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/view-invoice" element={<ViewInvoice />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/notifications" element={<Notification />} />

        <Route exact path="/bank-detail" element={<BankAccount />} />
        <Route exact path="/transaction-detail" element={<TransactionDetail />} />
        <Route exact path="/paypage" element={<Paypage />} />
        <Route exact path="/feedback" element={<FeedBack />} />

      </Routes>
      }
    </ClientProfileCompletionProvider>
  );
}
