import Api from "../../../Utils/ClientAxios";

export const bankAccountListService = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get('/master/bank_account'));
      } catch (error) {
        reject(error);
      }
    })
  }

  export const GetEditWalletRequestById = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/master/wallet/customer/deposit_request_details?transaction_id=${id}`));
      } catch (error) {
        reject(error);
      }
    });
  };

  export const getGetBankAccountListService =async () =>{
    return new Promise(async (resolve,reject)=>{
      try{
        resolve(await Api.get('/master/bank_account'));
      }catch(error){
        reject(error);
      }
    })
  }