import React,{useState, useEffect, useRef} from 'react'
import { StyledMenu } from '../../../Utils';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import moment from "moment";
import DateRangeFilter from '../../../Utils/DateRangeFilter';
import { GetCustomerPaginateService } from '../../../Services/Api/Merchant/booking';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const statusArr = [
  { label :'Active', value :0},
  { label :'Inactive', value :1},
];
const categoryArr = [
  'Advertising',
  'Food',
  'Marketing',
  'Repairs',
  'Software',
  'Stationary',
  'Travel',
];
const CustomerFilterComponent = ({isShowCategory, isShowUser, resetDate, handleGenerateReport}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [status, setStatus] = React.useState([]);
    const [category, setCategory] = React.useState([]);
    const [user, setUser] = React.useState([]);
    const open = Boolean(anchorEl);
    const [passDate,setPassDate] = useState()
    const [customerListPagination, setCustomerListPagination] = useState([]);
    
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleChangeStatus = (event) => {
      const {
        target: { value },
      } = event;
      setStatus(
        typeof value === 'string' ? value.split(',') : value,
      );
    };
    const status1 = status.map(u => u).join(",");
  
    const handleChangeCategory = (event) => {
      const {
        target: { value },
      } = event;
      setCategory(
        typeof value === 'string' ? value.split(',') : value,
      );
    };
  
    const handleChangeUser = (event) => {
      const {
        target: { value },
      } = event;
      setUser(
        typeof value === 'string' ? value.split(',') : value,
      );
    };
      const user1 = user.map(u => u).join(",");
    
  
    const [openCal, setOpenCal] = useState(false);
    const onChange = (ranges) => {
      if (
        moment(ranges.startDate).format("MM-DD-YYYY") !==
        moment(ranges.endDate).format("MM-DD-YYYY")
      ) {
        setOpenCal(false);
      } else if (ranges.startDate === "" && ranges.endDate === "") {
        setOpenCal(false);
      }
      const formatDate = (date) => moment(date).format("YYYY-MM-DD");
      const endDate = ranges.endDate === null ? formatDate(ranges.startDate) : formatDate(ranges.endDate);
      const startDate = ranges.startDate ? formatDate(ranges.startDate) : "";
       setPassDate(startDate +","+endDate)
    };
  
    const getCustomerList = async () => {
      await GetCustomerPaginateService({ limit: 10, page: 0, paginate: false })
          .then((res) => {
              if (res?.data?.data?.customers.length > 0) {
                let customerArr = res?.data?.data?.customers;
                customerArr = customerArr.map((value) => {
                  return {
                    label: value.customer.name,
                    value: value.customer.id,
                  };
                });
                setCustomerListPagination(customerArr);
              } else {
                  // ErrorToast("No Results.");
                  setCustomerListPagination([]);
              }
          })
          .catch((err) => {
              setCustomerListPagination([]);
              // if (typeof err.response.data.message !== "undefined") {
              //   ErrorToast(err.response.data.message || "Server Error!!");
              // } else {
              //   ErrorToast(err?.message || "Server Error!!");
              // }
          });
    };
  
    useEffect(() => {
      getCustomerList();
    }, [])
  /* On Outside Click Close DateRangePicker */
  const dateRangeBox = useRef(null);
  useEffect(() => {
    function handleOutsideClick(event) {
      if (dateRangeBox.current && !dateRangeBox.current.contains(event.target)) {
        setOpenCal(false)
      }
    }
 
    // Adding click event listener
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [dateRangeBox]);

  return (
    <div className="card report-card">
    <div className="card-body pb-0">
      <div className="row">
        <ul className="transactions-listing">
           <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12'>
            <li>
            <div className="multipleSelection">
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-checkbox-label"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bookmark me-1 select-icon"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
                    Select User</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={user}
                    options={customerListPagination}
                    onChange={handleChangeUser}
                    input={<OutlinedInput label={(<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bookmark me-1 select-icon"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
                      Select User</>)} />}
                    renderValue={(selected) =>
                      customerListPagination.filter(customer => selected.includes(customer.value))
                          .map(record => record.label)
                          .join(", ")}
                    MenuProps={MenuProps}
                    onMouseDown={() => {setOpenCal(false)}}
                  >
                   {customerListPagination.map((name, index) => (
                        <MenuItem key={index} value={name.value}>
                            <Checkbox checked={user.indexOf(name.value) > -1} />
                            <ListItemText primary={name.label} />
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </li>
            </div>
            <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12'>
            <li>
                <div className="multipleSelection" ref={dateRangeBox}>
                <DateRangeFilter onChange={onChange} openCal={openCal} setOpenCal={setOpenCal} resetDate={resetDate} />
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                </StyledMenu>
              </div>
            </li> 
            </div>
            <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12'>
            <li>
              <div className="multipleSelection">
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-checkbox-label"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-book-open me-1 select-icon"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path></svg>
                    Select Status</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={status}
                    onChange={handleChangeStatus}
                    input={<OutlinedInput label={(<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-book-open me-1 select-icon"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path></svg>
                      Select Status</>)} />}
                      renderValue={(selected) =>
                        statusArr.filter(status => selected.includes(status.value))
                                    .map(record => record.label)
                                    .join(", ")
                                  }
                  MenuProps={MenuProps}
                  onMouseDown={() => {setOpenCal(false)}}
                  > 
                   {statusArr.map((name, index) => (
                        <MenuItem key={index} value={name.value}>
                            <Checkbox checked={status.indexOf(name.value) > -1} />
                            <ListItemText primary={name.label} />
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </li> 
            </div>
            {/* {isShowCategory &&  (
            <li>
             <div className="multipleSelection">
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-checkbox-label"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bookmark me-1 select-icon"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
                    By Category</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={category}
                    onChange={handleChangeCategory}
                    input={<OutlinedInput label={(<><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bookmark me-1 select-icon"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
                      By Category</>)} />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {categoryArr.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={category.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </li>)} */}
             <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12'>
            <li>
              <div className="report-btn">
                {/* <button type="button" className="btn btn-block btn-outline-primary btn-reposrt"
                 onClick={() => handleSubmitFilter({fStatus : status1, fDate : passDate, fUser : user1 })}
                 >Submit</button> */}
                 <button type="button" className="btn btn-block btn-outline-primary btn-reposrt"
                    onClick={() =>{
                      handleGenerateReport({fStatus : status1, fDate : passDate, fUser : user1 })
                      setPassDate("");
                      setStatus([])
                      setUser([])
                     }}
                    >
                    <img src="../inner-assets/img/icons/invoices-icon5.svg" alt="" className="me-2" />Genereate Report</button>
                    {/* <button type="button" className="btn btn-block btn-outline-primary  btn-reposrt mx-2"
                      onClick={() =>{
                      handleReset({fStatus:setStatus([]), fUser: setUser([]), fDate: "" });
                      setPassDate("");
                    }}
                     >Reset</button> */}
              </div>
            </li>
            </div>
          </ul>
     </div>
    </div>
    </div>
  )
}

export default CustomerFilterComponent