import Api from "../../../Utils/ClientAxios";
 
export const CreditRequest = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/customer/credit_request`));
    } catch (error) {
      reject(error);
    }
  })
}

export const AddCreditRequestService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/credit_request/customer/create',data));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetCustomercreditRequestPaginate = async ({ limit, page, status }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/credit_request/customer/list?offset=${page}&limit=${limit}&status=${status}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const PayCreditAmountServiceById = async (id) => {
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.get(`/master/credit_request/customer/details/${id}`))
        }catch(error){
            reject(error)
        }
    })
}

export const PayCreditCashChequeService = async (data) => {
  return new Promise(async (resolve,reject) => {
    try{
        resolve(await Api.post("/master/credit_request/customer/create_payment_request",data))
    }catch(error){
        reject(error)
    }
  })
}

export const EditPayCreditCashChequeService = async (data) => {
return new Promise(async (resolve,reject) => {
  try{
      resolve(await Api.post("master/credit_request/customer/update_payment_request",data))
  }catch(error){
      reject(error)
  }
})
}

export const PayCreditCardService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/credit_request/customer/pay', data));
    }catch (error){
      reject (error);
    }
  })
}

export const CloseCreditRequestService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/credit_request/customer/close_credit', data));
    }catch (error){
      reject (error);
    }
  })
}