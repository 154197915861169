import Api from "../../../Utils/ClientAxios";

export const GetWidgetCountBookingService = async () =>{
    return new Promise(async (resolve,reject) =>{
        try{
            resolve(await Api.get("customer/bookings/widget_count"));
        }catch(error){
            reject(error);
        }
    })
}

export const GetBookingListService = async ({limit, page}) =>{
    return new Promise(async (resolve,reject) =>{
        try{
            resolve(await Api.get(`customer/bookings/list?offset=${page}&limit=${limit}`));
        }catch(error){
            reject(error);
        }
    })
}

export const GetPaymentOrderService =async (data) =>{
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.post('/master/payby/customer/get_order', data));
        }catch(error){
            reject(error);
        }
    })
}

export const GetDepositOptionService = async () =>{
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.get("/master/deposit_options"));
        }catch(error){
            reject(error);
        }
    })
}

export const GetBookingDetails = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await Api.get(`/customer/bookings/details/${id}`));
      } catch (error) {
        reject(error);
      }
    })
  }

export const GetRejectBooking = async (data) => {
    return  new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.post('customer/bookings/reject_update',data));
        }catch(error){
            reject(error);
        }
    })
}

export const GetBookingApproveService = async (data) => {
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.post("/customer/bookings/approve_update",data));
        }catch(error){
            reject(error);
        }
    })
}

export const GetBookingListFilter = async (data) => {
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.post("/customer/bookings/list",data));
        }catch(error){
            reject(error);
        }
    })
}
export const GetBookingExport = async (data) => {
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.post("/customer/bookings/export",data));
        }catch(error){
            reject(error);
        }
    })
}

export const GetCpayReceiptservice = async (id) => {
    return new Promise(async (resolve, reject) => {
        try{
            resolve(await Api.get(`/customer/bookings/download_receipt/${id}`));
        }catch(error){
            reject(error);
        }
    })
}